export const buildPreviewPage = async (sketchPngUri: string, areaTablePngUri: string) => {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio || 1;

  const context = canvas.getContext('2d')!;
  context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

  const sketchImg = new Image();
  sketchImg.src = sketchPngUri;
  await sketchImg.decode();

  const areaTableImg = new Image();
  areaTableImg.src = areaTablePngUri;
  await areaTableImg.decode();

  const areaTableY = sketchImg.height;
  const areaTableX = 0;

  const tableWidthDiff = sketchImg.width - areaTableImg.width;
  const adjustedAreaTableHeight = areaTableImg.height + tableWidthDiff;

  canvas.width = sketchImg.width;
  canvas.height = sketchImg.height + adjustedAreaTableHeight;
  context.drawImage(sketchImg as CanvasImageSource, 0, 0);
  context.drawImage(areaTableImg as CanvasImageSource, areaTableX, areaTableY, sketchImg.width, adjustedAreaTableHeight);

  return canvas.toDataURL('image/png');
};

export const buildAreaTablePreviewPage = async (previewPages: Page[]) => {
  const canvas = document.createElement('canvas');
  const pixelRatio = window.devicePixelRatio || 1;

  // TODO: Replace these magic numbers
  canvas.width = 1000 * pixelRatio;
  canvas.height = 1000 * pixelRatio;

  const context = canvas.getContext('2d')!;
  context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

  let yPos = 0;

  const imgOffset = 400;

  for (let i = 0; i < previewPages.length; i++) {
    const areaTableImg = new Image();
    areaTableImg.src = previewPages[i].previewPngs.areaTable;
    // eslint-disable-next-line
    await areaTableImg.decode();

    context.drawImage(areaTableImg as CanvasImageSource, 0, yPos);
    yPos += areaTableImg.height - imgOffset;
  }

  return canvas.toDataURL('image/png');
};
