import {
  all, takeLatest, call, select, put,
} from 'redux-saga/effects';

import { Box } from 'types/box';
import { CoordinatePoint } from 'types/point';
import { PayloadAction } from 'types/payloadAction';
import { getPanBack } from 'helpers/draw/getPanBack';
import { SET_END_POINT, SET_END_POINT_WITHOUT_SNAP } from 'ducks/draw/draw';
import { SET_BOX_SELECT_END_POINT } from 'ducks/boxSelect';
import { actions as viewportActions, selectors as viewportSelectors } from 'ducks/viewport';

const NAME = 'drawAndPan';

const PAN_TO_POINT = `${NAME}/PAN_TO_POINT`;

/**
 * i.e. we slowdown pan's(by mouse) speed by the constant
 */
const panSlowDownSpeed = 20;

// Action Creators
export const actions = {
  panToPoint: (point: CoordinatePoint) => ({
    type: PAN_TO_POINT,
    payload: point,
  }),
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doPanWithDelay(point: CoordinatePoint, delay: number) {
    const viewBox: Box = viewportSelectors.getViewBox(yield select());
    const d = getPanBack(viewBox, point);

    if (d.x !== 0 || d.y !== 0) {
      yield put(viewportActions.startPan({ x: 0, y: 0 }));
      yield put(viewportActions.panViewBox({
        x: d.x * delay,
        y: d.y * delay,
      }));
      yield put(viewportActions.endPan());
    }
  }

  function* doPan({ payload }: PayloadAction) {
    const point = payload as CoordinatePoint;
    const zoom: number = (viewportSelectors.getZoomInPercent(yield select())) / 100;
    // so pan speed depends on zoom level,
    // for bigger zoom, pan speed is faster
    // for smaller zoom we pan slowly
    yield call(doPanWithDelay, point, zoom / panSlowDownSpeed);
  }

  function* doPanToPoint({ payload }: PayloadAction) {
    if (!payload.center) return;
    const point = payload.endPoint as CoordinatePoint;
    yield put(viewportActions.panToCenter({
      x: point.x,
      y: point.y,
    }));
  }

  return function* saga() {
    yield all([
      takeLatest(PAN_TO_POINT, doPanToPoint),
      takeLatest(SET_END_POINT, doPan),
      takeLatest(SET_END_POINT_WITHOUT_SNAP, doPanToPoint),
      takeLatest(SET_BOX_SELECT_END_POINT, doPan),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
