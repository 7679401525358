import { PointType } from './pointType';

export interface CoordinatePoint {
  x: number;
  y: number;
}

export interface Point extends CoordinatePoint {
  pointId: string; // pointId defined only for point in the sketch
  pointType?: PointType; // type of point: corner, arc-point, or center
}

export const isPoint = (p: Point | CoordinatePoint): p is Point => 'pointId' in p;
