// custom webkit scrollbar
export const scrollbar = { // eslint-disable-line import/prefer-default-export
  scrollbarColor: '#305e9d #FFF',
  scrollbarWidth: 'thin',

  '&::-webkit-scrollbar': {
    width: 5,
    height: 5,
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(48, 94, 157, 0.3)',
    minWidth: 40,
    minHeight: 40,
    borderRadius: 4,
    margin: 1,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(48, 94, 157, 0.5)',
  },
  '&::-webkit-scrollbar-thumb:active': {
    background: 'rgba(48, 94, 157, 0.8)',
  },
} as const;
