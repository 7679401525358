/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type, consistent-return, no-nested-ternary */
import Immutable from 'immutable';
import { Group } from 'types/group';
import { FEET_IN_METER, SQUARE_FEET_IN_SQUARE_METER } from './constants';

export const trace = (log: any, shouldReturn = true): any | void => {
  // eslint-disable-next-line no-console
  //console.log({ log });
  if (shouldReturn) return log;
};

export const isNaN = (x: any): boolean => Number.isNaN(x);

export const isNumber = (x: any): boolean => !isNaN(x);

export const isFinite = (x: any): boolean => Number.isFinite(x);

export const identity = (x: any): any => x;

export const concat = (args: any) => (data: any) => data.concat(args);

export const flatten = (args: any) => [...args].reduce((acc: any, arg: any) => acc.concat(arg), []);

export const compose = (...fns: any) => (args: any): any => fns.reduceRight((arg: any, fn: any) => fn(arg), args);

export const listToArray = (list: any) => list.valueSeq().toArray();

export const isNothing = (val: any): boolean => [null, undefined].includes(val);

export const isSomething = (val: any): boolean => !isNothing(val);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const defaultTo = (defaultValue: any) => (val: any): any => isSomething(val) ? val : defaultValue;

export const mapBy = <T>(fn: any) => (data: any): Array<T> | any => Array.isArray(data)
  ? data.map(fn)
  : Object.keys(data).length
    ? Object.keys(data).reduce(
      (acc, key, index, arr) => ({
        ...acc,
        [key]: fn(data[key], key, index, arr),
      }),
      {},
    )
    : [];

export const filterBy = (fn: any) => (data: any): any => Array.isArray(data)
  ? data.filter(fn)
  : Object.keys(data).length
    ? Object.keys(data).reduce(
      (acc, key, index, arr) => compose(
        (isTrue: boolean) => isTrue ? ({
          ...acc,
          [key]: data[key],
        }) : acc
      )(fn(data[key], key, index, arr)),
      {},
    )
    : [];

export const groupBy = (fn: any) => (data: any[]): any => data.reduce(
  (acc: any, item: any, index: number, arr: any[]) => compose(
    (key: string | number) => {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    },
  )(fn(item, index, arr)),
  {},
);

export const includes = (search: any) => (data: any) => data.includes(search);

export const findIndexBy = (fn: any) => (data: any) => data.findIndex(fn);

export const findBy = (fn: any) => (data: any) => data.find(fn);

// eslint-disable-next-line no-return-assign
export const pick = (keys: any) => (data: any) => keys.reduce((acc: any, key: any) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  Object.prototype.hasOwnProperty.call(data, key) ? (acc[key] = data[key], acc) : acc, {});

export const pickBy = (fn: any) => (data: any) => compose(
  filterBy(fn),
)(data);

export const arrayEquals = (arr1: any[]) => (arr2: any[]): boolean => Array.isArray(arr1)
  && Array.isArray(arr2)
  && arr1.length === arr2.length
  && arr1.every((val, index) => val === arr2[index]);

export const objectEquals = (obj1: Record<any, any>) => (obj2: Record<any, any>): boolean => compose(
  ([aProps, bProps]: Array<Array<string>>) => aProps.length === bProps.length && aProps.every((key: any) => obj1[key] === obj2[key]),
  mapBy(Object.getOwnPropertyNames),
)([obj1, obj2]);

export const isFromGroup = (groups: Immutable.Map<string, Group>, objectId: string): boolean => groups.max()!
  && groups.some(({ selectedObjects }) => compose(
    includes(objectId),
    listToArray,
  )(selectedObjects));

export const percentageFromInt = compose(
  (decimal: number) => decimal.toPrecision(2),
  (parsedValue: number) => parsedValue / 100,
  parseFloat,
);

export const feetToMeters = (feet: number): number => feet / FEET_IN_METER;
export const metersToFeet = (meters: number): number => meters * FEET_IN_METER;

export const sqFeetToSqMeters = (sqFeet: number): number => sqFeet / SQUARE_FEET_IN_SQUARE_METER;
export const sqMetersToSqFeet = (sqMeters: number): number => sqMeters * SQUARE_FEET_IN_SQUARE_METER;

export const divideBy = (denominator: number) => (numerator: number) => +(numerator / denominator);
export const multiplyBy = (multiplier: number) => (multiplicand: number) => multiplicand * multiplier;
export const addTo = (operand: number) => (num: number): number => +(num + operand);
export const subtractFrom = (num: number) => (operand: number): number => +(num - operand);
export const square = (number: number): number => number ** 2;
export const reciprocal = (num: number): number => 1 / num;
export const toPrecision = (precision: number) => (num: number): number => +num.toPrecision(precision);
export const toFixed = (fixecd: number) => (num: number): number => +num.toFixed(fixecd);

export const roundToNearest = (toNearest: number) => (n: number) => (Math.round(n / toNearest) / (1 / toNearest)).toFixed(1 / toNearest / 2);

// This function will effectively find the
// top left {lx, ly} and
// bottom right { hx, hy } extremes of a given set of points.
// Array<{x, y}> -> Object: {lx, ly, hx, hy}
export const coordinateExtremes = (pts: any[]) => pts.reduce(
  (
    {
      lx, ly, hx, hy,
    }, { x, y }, idx
  ) => ({
    lx: idx > 0 ? Math.min(lx, x) : x,
    ly: idx > 0 ? Math.min(ly, y) : y,
    hx: idx > 0 ? Math.max(hx, x) : x,
    hy: idx > 0 ? Math.max(hy, y) : y,
  }),
  {},
);

export const defaultToObj = defaultTo({});
