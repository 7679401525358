/** @jsx jsx */
import React from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { css, jsx } from '@emotion/react';

import { DragItem, ItemTypes } from 'types/dnd';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';

const previewImageStyles = css`
  max-height: 44px;
  width: 44px;
`;

const getStyle = (currentOffset: XYCoord): React.CSSProperties => {
  const transform = `translate(calc(${currentOffset.x}px - 50%), calc(${currentOffset.y}px - 50%))`;
  return {
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    transform,
    WebkitTransform: transform,
  };
};

// inspired by react-dnd-preview
// but using getClientOffset instead of getSourceClientOffset and centering preview around cursor
// https://github.com/LouisBrunner/dnd-multi-backend/blob/master/packages/react-dnd-preview/src/index.js
const Preview: React.FC = () => {
  const { currentOffset, isDragging, item } = useDragLayer((monitor) => ({
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as DragItem,
  }));

  if (!isDragging || currentOffset === null || item.type === undefined) {
    return null;
  }

  const style = getStyle(currentOffset);

  switch (item.type) {
    case ItemTypes.Symbol:
      return <img alt="Preview" src={item.preview} style={style} css={previewImageStyles} />;
    case ItemTypes.Label:
    case ItemTypes.Page:
      return <div style={style}>{item.preview}</div>;
    default: throw new UnreachableCaseError(item);
  }
};

export default Preview;
