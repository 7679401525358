import {
  put, all, takeLatest, select, call,
} from 'redux-saga/effects';
import Immutable from 'immutable';

import { GeometryType } from 'types/geometryType';
import { selectors as editModeSelectors, actions as editModeActions } from 'ducks/editMode';
import { selectors as groupSelectors, actions as groupActions } from 'ducks/groupObjects';
import { selectors as modelSelectors } from 'ducks/model/model';
import { removeFigure } from 'ducks/remove/removeFigure';
import { removeWall } from 'ducks/remove/removeWall';
import { removePoint } from 'ducks/remove/removePoint';
import { removePositionedLabel } from 'ducks/remove/removePositionedLabel';
import { removePositionedSymbol } from 'ducks/remove/removePositionedSymbol';
import { listToArray } from 'helpers/utils';

// Action Types
const NAME = 'removeObjects';

const REMOVE_SELECTED_OBJECTS = `${NAME}/REMOVE_SELECTED_OBJECTS`;

// Action Creators
export const actions = {
  removeSelectedObjects: () => ({
    type: REMOVE_SELECTED_OBJECTS,
  }),
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doRemoveSelectedObjects() {
    const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(yield select());
    const groups = groupSelectors.getAllGroups(yield select());
    yield put(editModeActions.switchToSelection());
    for (let i = 0; i < selectedObjects.size; i++) {
      const objectId = selectedObjects.get(i)!;
      const objectType: GeometryType = modelSelectors.getGeometryType(yield select(), objectId);

      if (groups.max()) {
        let groupId = false;
        groups.entrySeq().forEach((group: any) => {
          const selected = listToArray(group[1].selectedObjects);
          if (selected.includes(objectId)) {
            [groupId] = group;
          }
        });
        if (groupId) yield put(groupActions.removeGroup(groupId));
      }

      switch (objectType) {
        case GeometryType.FIGURE:
          yield call(removeFigure, objectId);
          break;

        case GeometryType.POINT:
          yield call(removePoint, objectId);
          break;

        case GeometryType.WALL:
          yield call(removeWall, objectId);
          break;

        case GeometryType.POSITIONED_LABEL:
          yield call(removePositionedLabel, objectId);
          break;

        case GeometryType.POSITIONED_SYMBOL:
          yield call(removePositionedSymbol, objectId);
          break;

        default:
          break;
      }
    }
  }

  return function* saga() {
    yield all([takeLatest(REMOVE_SELECTED_OBJECTS, doRemoveSelectedObjects)]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
