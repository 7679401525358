import { put, call } from 'redux-saga/effects';

import { sketchConfig } from 'config/sketchConfig';
import { loadTestModel } from 'ducks/model/testModel';
import { actions as sketchPersistenceActions } from 'ducks/persistence/sketchPersistence';
import { actions as sketchActions } from 'ducks/model/sketch';
import { actions as editModeActions } from 'ducks/editMode';

// Action Types
const NAME = 'mount';

export const MOUNTED = `${NAME}/MOUNTED`;

// Action Creators
export const actions = {
  mounted: () => ({
    type: MOUNTED,
  }),
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doMount() {
    // add test data
    if (sketchConfig.loadTestModel) {
      yield call(loadTestModel);
    } else {
      // add empty model
      yield put(sketchActions.initialise());
    }

    // load from local storage starts here
    yield put(sketchPersistenceActions.loadLastSketch());

    // notify all about mounting is ready to show
    yield put(editModeActions.switchToEdit());
    yield put(actions.mounted());
  }

  return function* saga() {
    yield doMount();
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
