export const DEGREES_PER_RADIAN = 180 / Math.PI;
export const RADIANS_PER_DEGREE = 1 / DEGREES_PER_RADIAN;

/*
            QUARTER
              |
 NONE/FULL -- 0 -- HALF
              |
        THREE QUARTER
*/
export const Angle = {
  NONE: -Math.PI,
  QUARTER: -Math.PI / 2,
  HALF: 0,
  THREE_QUARTER: Math.PI / 2,
  FULL: Math.PI,
};

export const addCircular = (a: number, b: number): number => {
  const aPlusB = a + b;
  if (aPlusB <= Angle.FULL) {
    return aPlusB;
  }
  const deltaAPlusB = aPlusB - Angle.FULL;
  return deltaAPlusB - Angle.FULL;
};
