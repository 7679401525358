/**
 * Specifies how much pixels should be copy moved against original (right and down)
 */
export const copyConfig = {
  positionedSymbolOffset: {
    x: 100,
    y: 100,
  },
  positionedLabelOffset: {
    x: 100,
    y: 100,
  },
  figureOffset: {
    x: 100,
    y: 100,
  },
};
