import { SelectableObjects } from 'types/selection';
import { isClosedFigure } from 'types/figure';
import { getFiguresWithWall, isSubPath } from 'helpers/model/figureWalls';

/**
 * get all sub-paths for the figure
 * i.e. we try to find figures with next property:
 * all walls for sub-figure belongs to original figure
 */
export const getSubPaths = (selectableObjects: SelectableObjects, figureId: string): string[] => {
  const subPaths: string[] = [];

  const { figures } = selectableObjects;
  const figure = figures.get(figureId)!;
  const figureWalls = figure.walls;
  figureWalls.forEach((wallId) => {
    const otherFigureIds = getFiguresWithWall(figures, wallId)
      .filter(fId => fId !== figureId && !subPaths.includes(fId));

    otherFigureIds.forEach((otherFigureId) => {
      const otherFigure = figures.get(otherFigureId)!;
      if (isSubPath(otherFigure.walls, figureWalls)) {
        subPaths.push(otherFigureId);
      }
    });
  });

  return subPaths;
};

/**
 * get all shared-wall-paths for the figure
 */
export const getSharedWallPaths = (selectableObjects: SelectableObjects, figureId: string): string[] => {
  const subPaths: string[] = [];

  const { figures } = selectableObjects;
  const figure = figures.get(figureId)!;
  const figureWalls = figure.walls;
  figureWalls.forEach((wallId) => {
    const otherFigureIds = getFiguresWithWall(figures, wallId)
      .filter(fId => fId !== figureId && !subPaths.includes(fId));

    otherFigureIds.forEach((otherFigureId) => {
      const otherFigure = figures.get(otherFigureId)!;
      if (!isClosedFigure(otherFigure)) {
        subPaths.push(otherFigureId);
      }
    });
  });

  return subPaths;
};
