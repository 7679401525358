import { CoordinatePoint } from 'types/point';

const magnitude = (value: number): number => {
  if (value === 0) {
    return 0;
  }
  return value > 0 ? 1 : -1;
};

export const lineDirection = (from: CoordinatePoint) => (to: CoordinatePoint): CoordinatePoint => ({
  x: magnitude(to.x - from.x),
  y: magnitude(to.y - from.y),
});
