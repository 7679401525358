// /<summary>
// /The purpose of this class is to hold all Spacing/Layout Definitions we utilize in this library in a single place.
// /</summary>
//----------

// /<summary>
// /An Object with all the defined Vertical Spacing Definitions we support and use
// /This array can be imported directly in the theme spacing attribute
// /</summary>
export const aciThemeSpacing = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128];

// /<summary>
// /The Sister Object to aciThemeSpacing; this is an Enum to let us call the theme spacing index's by the intended space amount, rather then the index.
// /</summary>
export const aciThemeEnum = {
  _0: 0,
  _4: 1,
  _8: 2,
  _12: 3,
  _16: 4,
  _24: 5,
  _32: 6,
  _48: 7,
  _64: 8,
  _96: 9,
  _128: 10,
};

// /<summary>
// /A single collection of all the Spacing/Layout Definitions
// /</summary>
const AciStyleSpacing = {
  aciThemeSpacing,
  aciThemeEnum,
};

export default AciStyleSpacing;
