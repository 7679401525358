import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';

import { authConfig } from 'config/authConfig';
import { messages } from 'config/messages';
import { ApiLabel } from 'types/api/apiLabel';
import { LogLevel } from 'types/log';
import { mapLabelFromApi } from 'helpers/mappers';
import log from 'helpers/log';
import { RootState } from 'reducers/rootReducer';
import { getAccessToken } from 'effects/auth';
import { actions as labelActions } from 'ducks/model/labels';
import * as ApiEffects from 'effects/api';
import { mapBy } from 'helpers/utils';

// Action Types
const NAME = 'labelPersistence';

export interface LabelPersistenceState {
  readonly isLoading: boolean;
}

// Initial State
const initialState: LabelPersistenceState = {
  isLoading: false,
};

// Action Creators
export const actions = {
  loadLabels: createAction(`${NAME}/loadLabels`)<void>(),
  setLoadingLabels: createAction(`${NAME}/setLoadingLabels`)<boolean>(),
  persistLabelOrder: createAction(`${NAME}/persistLabelOrder`)<void>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getState = (rootState: RootState): LabelPersistenceState => rootState[NAME];

const isLoading = (rootState: RootState): boolean => getState(rootState).isLoading;

export const selectors = {
  isLoading,
};

// Reducers
const setLoadingLabelsReducer = (
  state: LabelPersistenceState, loading: boolean,
): LabelPersistenceState => ({
  ...state,
  isLoading: loading,
});

export const reducer = (
  state: LabelPersistenceState = initialState, action: Actions,
): LabelPersistenceState => {
  switch (action.type) {
    case getType(actions.setLoadingLabels):
      return setLoadingLabelsReducer(state, action.payload as boolean);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doLoadLabels(): any {
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      yield put(actions.setLoadingLabels(true));
      let response: ApiLabel[] | null = null;
      try {
        const accessToken = yield call(getAccessToken);
        response = yield call(ApiEffects.getLabels, accessToken);
      } catch (e) {
        toast(messages.cannotLoadLabels, {
          type: toast.TYPE.WARNING,
        });
        log(LogLevel.error, e);
      }

      if (response !== null) {
        yield put(labelActions.set({
          clear: true,
          labels: response.map(label => mapLabelFromApi(label)),
        }));
      }
      yield put(actions.setLoadingLabels(false));
    }
  }

  function* doPersistLabelOrder(action: any): any {
    const { labels } = action.payload;
    const labelIds = mapBy(({ labelId }: { labelId: string }) => labelId)(labels);
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      try {
        const accessToken = yield call(getAccessToken);
        yield call(ApiEffects.persistLabelOrder, accessToken, { labelIds });
        const storedLabels = localStorage.getItem('label');
        const storedLabelsJSON = storedLabels && JSON.parse(storedLabels);
        const newOrder = labelIds.map((labelId: string, index: number) => ({ ...storedLabelsJSON.find(({ id }: any) => id === labelId), index }));
        window.postMessage({ key: 'label', message: JSON.stringify(newOrder) }, '*');
        // yield call(doLoadLabels);
      } catch (e) {
        toast(messages.cannotPersistLabelOrder, {
          type: toast.TYPE.WARNING,
        });
        log(LogLevel.error, e);
      }
    }
  }

  return function* saga() {
    yield all([
      takeLatest(actions.loadLabels, doLoadLabels),
      takeLatest(actions.persistLabelOrder, doPersistLabelOrder),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
