import { useRef, useEffect } from 'react';

const usePortal = (id: string): any => {
  const rootRef = useRef(document.createElement('div'));

  useEffect(() => {
    const parentElement = document.getElementById(id);
    parentElement!.appendChild(rootRef.current);
    return () => {
      rootRef.current.remove();
    };
  }, [id]);

  return rootRef.current;
};

export default usePortal;
