import Immutable from 'immutable';
import {
  all, takeLatest, put, select,
} from 'redux-saga/effects';
import uuid from 'uuid/v4';
import { createAction, getType, ActionType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { Point } from 'types/point';
import { ClosedFigure } from 'types/figure';
import { PointType } from 'types/pointType';
import { PayloadAction } from 'types/payloadAction';
import { GlaType } from 'types/glaType';
import { getCentroid } from 'helpers/geometry';
import { createAreaTypeLabel } from 'helpers/label/areaTypeLabel';
import { actions as pointsActions } from 'ducks/model/points';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';
import { actions as sidebarActions } from 'ducks/sidebar/sidebar';

// Action Types
const NAME = 'areaTypeModal';

export interface FigureInfoPayload {
  readonly figureId: string;
  readonly areaTypeId: string;
  readonly glaType: GlaType;
  readonly glaMultiplier: number;
  readonly meta: any;
  readonly isMultiFamily: boolean;
}

export interface AreaTypeModalState {
  readonly isShowing: boolean;
  readonly selectedFigureId: string | null;
  readonly isMultiFamily: boolean;
}

// Initial State
const initialState: AreaTypeModalState = {
  isShowing: false,
  selectedFigureId: null,
  isMultiFamily: false,
};

// Action Creators
export const actions = {
  hide: createAction(`${NAME}/hide`)<void>(),
  showForFigure: createAction(`${NAME}/showForFigure`)<string>(),

  addAreaTypeForFigure: createAction(
    `${NAME}/addAreaTypeForFigure`,
    (figureId: string, areaTypeId: string, glaType: GlaType, meta?: any, isMultiFamily?: boolean) => ({
      figureId, areaTypeId, glaType, meta, isMultiFamily,
    }),
  )<FigureInfoPayload>(),

  changeAreaTypeForFigure: createAction(
    `${NAME}/changeAreaTypeForFigure`,
    (figureId: string, areaTypeId: string, glaType: GlaType, meta?: any, isMultiFamily?: boolean) => ({
      figureId, areaTypeId, glaType, meta, isMultiFamily,
    }),
  )<FigureInfoPayload>(),

  changeGlaMultiplierForFigure: createAction(
    `${NAME}/changeGlaMultiplierForFigure`,
    (figureId: string, glaMultiplier: number) => ({ figureId, glaMultiplier }),
  )<FigureInfoPayload>(),

  setMultiFamily: createAction(`${NAME}/setMultiFamily`)<void>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getAreaTypeModal = (rootState: RootState): AreaTypeModalState => rootState.areaTypeModal;

const isShowing = (rootState: RootState): boolean => getAreaTypeModal(rootState).isShowing;

const getSelectedFigureId = (rootState: RootState): string | null => getAreaTypeModal(rootState).selectedFigureId;

const isMultiFamily = (rootState: RootState): boolean => getAreaTypeModal(rootState).isMultiFamily;

export const selectors = {
  isShowing,
  getSelectedFigureId,
  isMultiFamily,
};

// Reducers
const showForFigureReducer = (state: AreaTypeModalState, figureId: string): AreaTypeModalState => ({
  ...state,
  isShowing: true,
  selectedFigureId: figureId,
});

const hideReducer = (state: AreaTypeModalState): AreaTypeModalState => ({
  ...state,
  isShowing: false,
  selectedFigureId: null,
});

const toggleMultiFamilyReducer = (state: AreaTypeModalState): AreaTypeModalState => ({
  ...state,
  isMultiFamily: !state.isMultiFamily,
});

export const reducer = (state: AreaTypeModalState = initialState, action: Actions): AreaTypeModalState => {
  switch (action.type) {
    case getType(actions.hide):
      return hideReducer(state);

    case getType(actions.showForFigure):
      return showForFigureReducer(state, action.payload as string);

    case getType(actions.setMultiFamily):
      return toggleMultiFamilyReducer(state);

    default:
      return state;
  }
};

// Sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doAddAreaType({ payload }: PayloadAction) {
    const {
      // eslint-disable-next-line no-shadow
      figureId, glaType, areaTypeId, meta, isMultiFamily,
    }: {
      figureId: string; glaType: GlaType; areaTypeId: string; meta: any; isMultiFamily: boolean;
    } = payload;

    const figurePoints: Immutable.List<Point> = figuresSelectors.getFigurePoints(yield select(), figureId);
    const position = getCentroid(figurePoints);

    const pointId = uuid();
    const point = {
      ...position,
      pointId,
      pointType: PointType.LABEL,
    };

    yield put(pointsActions.add(point));

    const positionedAreaLabel = createAreaTypeLabel(point);

    yield put(figuresActions.update(figureId, {
      positionedAreaLabel,
      glaType,
      areaTypeId,
      meta,
      isMultiFamily,
    }));
  }

  function* doChangeAreaType({ payload }: PayloadAction) {
    const {
      // eslint-disable-next-line no-shadow
      figureId, glaType, areaTypeId, meta, isMultiFamily,
    }: {
      figureId: string; glaType: GlaType; areaTypeId: string; meta: any; isMultiFamily: boolean;
    } = payload;
    const figure: ClosedFigure = figuresSelectors.getClosedFigureById(yield select(), figureId)!;

    yield put(figuresActions.update(figureId, {
      positionedAreaLabel: {
        ...figure.positionedAreaLabel!,
      },
      glaType,
      areaTypeId,
      meta,
      isMultiFamily,
    }));
  }

  function* doChangeGlaMultiplier({ payload }: PayloadAction) {
    const { figureId, glaMultiplier }: {
      figureId: string; glaMultiplier: number;
    } = payload;
    yield put(figuresActions.update(figureId, {
      glaMultiplier,
    }));
  }

  function* doHideSidebar() {
    yield put(sidebarActions.hide());
  }

  return function* saga() {
    yield all([
      takeLatest(actions.addAreaTypeForFigure, doAddAreaType),
      takeLatest(actions.changeAreaTypeForFigure, doChangeAreaType),
      takeLatest(actions.showForFigure, doHideSidebar),
      takeLatest(actions.changeGlaMultiplierForFigure, doChangeGlaMultiplier),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
