import { all, takeLatest, put, call, select, delay } from 'redux-saga/effects';
import { createAction } from 'typesafe-actions';
import Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import * as ApiEffects from 'effects/api';
import { getAccessToken } from 'effects/auth';

// Action Types
const NAME = 'network';

const CHECK_CONNECTION = `${NAME}/CHECK_CONNECTION`;
const HANDLE_PERSISTENCE = `${NAME}/HANDLE_PERSISTENCE`;
const UPDATE_OFFLINE_MODE_ACTIONS = `${NAME}/UPDATE_OFFLINE_MODE_ACTIONS`;
const RESET_OFFLINE_MODE_ACTIONS = `${NAME}/RESET_OFFLINE_MODE_ACTIONS`;
const TOGGLE_IS_OFFLINE = `${NAME}/TOGGLE_IS_OFFLINE`;
const OFFLINE_INTERVAL = `${NAME}/OFFLINE_INTERVAL`;

// Action Creators
export const actions = {
  checkConnection: createAction(CHECK_CONNECTION)<void>(),
  handlePersistence: createAction(HANDLE_PERSISTENCE)<void>(),
  updateOfflineModeActions: createAction(UPDATE_OFFLINE_MODE_ACTIONS)<any>(),
  resetOfflineModeActions: createAction(RESET_OFFLINE_MODE_ACTIONS)<void>(),
  toggleIsOffline: createAction(TOGGLE_IS_OFFLINE)<boolean>(),
  setOffLineInterval: createAction(OFFLINE_INTERVAL)<number>(),
};

export interface NetworkPersistenceState {
  readonly offlineModeActions: Immutable.List<any>
  readonly isOffline: boolean
  readonly offLineInterval: number
}

// Initial State
const initialState: NetworkPersistenceState = {
  /**
   * actions taking during offline mode
   */
  offlineModeActions: Immutable.List(),
  /**
   * boolean determining last known network status
   */
  isOffline: false,
  /**
   * boolean determining last known network status
   */
  offLineInterval: 3000
};

// Selectors
const getState = (rootState: RootState): NetworkPersistenceState => rootState.networkPersistence;

const getOfflineModeActions = (rootState: RootState): any => rootState.networkPersistence.offlineModeActions;

const getIsOffline = (rootState: RootState): any => rootState.networkPersistence.isOffline;

const getOffLineInterval = (rootState: RootState): any => rootState.networkPersistence.offLineInterval;

export const selectors = {
  getState,
  getOfflineModeActions,
  getIsOffline,
  getOffLineInterval
};

// Reducers
const setOfflineModeActionsReducer = (state: NetworkPersistenceState, payload: any): NetworkPersistenceState => ({
  ...state,
  offlineModeActions: state.offlineModeActions.push(payload)
});

const resetOfflineModeActionsReduer = (state: NetworkPersistenceState): NetworkPersistenceState => ({
  ...state,
  offlineModeActions: Immutable.List()
});

const setIsOfflineReducer = (state: NetworkPersistenceState, payload: any): NetworkPersistenceState => ({
  ...state,
  isOffline: payload
});

const setOffLineIntervalReducer = (state: NetworkPersistenceState, payload: any): NetworkPersistenceState => ({
  ...state,
  offLineInterval: payload
});

export const reducer = (
  state: NetworkPersistenceState = initialState, action: any,
): NetworkPersistenceState => {
  switch (action.type) {
    case UPDATE_OFFLINE_MODE_ACTIONS:
      return setOfflineModeActionsReducer(state, action.payload);

    case RESET_OFFLINE_MODE_ACTIONS:
      return resetOfflineModeActionsReduer(state);

    case TOGGLE_IS_OFFLINE:
      return setIsOfflineReducer(state, action.payload);

    case OFFLINE_INTERVAL:
      return setOffLineIntervalReducer(state, action.payload);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doCheckConnection(): any {
    const accessToken: string = yield call(getAccessToken);
    const rootState: RootState = yield select();

    try {
      const response = yield call(ApiEffects.getOnline, accessToken, { apiOnly: true });
      if (response) {
        yield call(doHandlePersistence);
        const isOffline = selectors.getIsOffline(rootState);
        if (isOffline) {
          yield put(actions.setOffLineInterval(3000));
          yield put(actions.toggleIsOffline(false));
        }
      } else {
        yield put(actions.toggleIsOffline(true));
        // if not online, ping more frequently
        yield put(actions.setOffLineInterval(1000));
      }
    } catch (e) {
      yield put(actions.toggleIsOffline(true));
    }

    const intervalTime = getOffLineInterval(yield select());
    yield delay(intervalTime);
    yield call(doCheckConnection);
  }

  function* doHandlePersistence() {
    const offlineModeActions = getOfflineModeActions(yield select()).toArray();
    const accessToken: string = yield call(getAccessToken);

    // loop through offlineModeActions, make appropriate api call per item
    for (let i = 0; i < offlineModeActions.length; i++) {
      const action = offlineModeActions[i];
      switch (action.type) {
        // if label has had POST/PUT/DELETE
        case 'label':
          if (action.requestType === 'POST') {
            const { id, isDefault, label_type, text } = action;
            const labelObj = { id, isDefault, label_type, text };
            yield call(ApiEffects.createLabel, accessToken, labelObj);
          }
          if (action.requestType === 'PUT') {
            const { isDefault, label_type, text } = action;
            const id = action.id ? action.id : action.labelId;
            const labelObj = { id, isDefault, label_type, text };
            yield call(ApiEffects.updateLabel, accessToken, labelObj);
          }
          if (action.requestType === 'DELETE') {
            const id = action.id ? action.id : action.labelId;
            yield call(ApiEffects.deleteLabel, accessToken, id);
          }
          break;

        // if settings has had POST
        case 'setting':
          yield call(ApiEffects.updateSetting, accessToken, { uuid: action.uuid, value: action.value });
          break;

        default:
      }
    }
    yield put(actions.resetOfflineModeActions());
  }

  return function* saga() {
    yield all([
      takeLatest(CHECK_CONNECTION, doCheckConnection),
      takeLatest(HANDLE_PERSISTENCE, doHandlePersistence),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
