import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';

import { authConfig } from 'config/authConfig';
import { messages } from 'config/messages';
import { LogLevel } from 'types/log';
import { ApiSketch } from 'types/api/apiSketch';
import log from 'helpers/log';
import { RootState } from 'reducers/rootReducer';
import { getAccessToken } from 'effects/auth';
import * as ApiEffects from 'effects/api';

// Action Types
const NAME = 'sketchesPersistence';

export interface SketchesPersistenceState {
  readonly isLoading: boolean;
  readonly sketches: ApiSketch[];
  readonly searchTerm: string;
}

// Initial State
const initialState: SketchesPersistenceState = {
  isLoading: false,
  sketches: [],
  searchTerm: ''
};

// Action Creators
export const actions = {
  loadSketches: createAction(`${NAME}/loadSketches`)<void>(),
  setLoadingSketches: createAction(`${NAME}/setLoadingSketches`)<boolean>(),
  setSketches: createAction(`${NAME}/setSketches`)<ApiSketch[]>(),
  setSearchTerm: createAction(`${NAME}/setSearchTerm`)<string>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getState = (rootState: RootState): SketchesPersistenceState => rootState[NAME];

const isLoading = (rootState: RootState): boolean => getState(rootState).isLoading;

const getSketches = (rootState: RootState): ApiSketch[] => rootState[NAME].sketches;

const getSearchTerm = (rootState: RootState): string => rootState[NAME].searchTerm;

export const selectors = {
  isLoading,
  getSketches,
  getSearchTerm
};

// Reducers
const setLoadingSketchesReducer = (
  state: SketchesPersistenceState, loading: boolean,
): SketchesPersistenceState => ({
  ...state,
  isLoading: loading,
});

const setSketchesReducer = (
  state: SketchesPersistenceState, sketches: ApiSketch[],
): SketchesPersistenceState => ({
  ...state,
  sketches
});

const setSearchTermReducer = (
  state: SketchesPersistenceState, value: string,
): SketchesPersistenceState => ({
  ...state,
  searchTerm: value
});

export const reducer = (
  state: SketchesPersistenceState = initialState, action: Actions,
): SketchesPersistenceState => {
  switch (action.type) {
    case getType(actions.setLoadingSketches):
      return setLoadingSketchesReducer(state, action.payload as boolean);

    case getType(actions.setSketches):
      return setSketchesReducer(state, action.payload);

    case getType(actions.setSearchTerm):
      return setSearchTermReducer(state, action.payload);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doloadSketches(action: any): any {
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      yield put(actions.setLoadingSketches(true));
      let response: any | null = null;
      try {
        const accessToken = yield call(getAccessToken);
        if (action.payload) {
          response = yield call(ApiEffects.getSketchesBySearch, accessToken, action.payload);
        } else {
          response = yield call(ApiEffects.getSketches, accessToken);
        }
      } catch (e) {
        toast(messages.cannotLoadSketches, {
          type: toast.TYPE.WARNING,
        });
        log(LogLevel.error, e);
      }

      if (response) {
        yield put(actions.setSketches(response.sketches));
      }
    }
    yield put(actions.setLoadingSketches(false));
  }

  return function* saga() {
    yield all([
      takeLatest(actions.loadSketches, doloadSketches),
      takeLatest(actions.setSearchTerm, doloadSketches),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
