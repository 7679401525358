/** @jsx jsx */
import { useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';

import { Page } from 'types/page';

import { buildPreviewPage } from 'helpers/save/buildPreviewPage';

const imageWrapper = css`
  height: calc(100% - 82px);
  text-align: center;
  overflow: auto;
`;

const imageStyle = css`
  max-width: 90%;
  max-height: 90%;
`;

const noDataStyle = css`
  margin: 8vh auto;
  text-align: center;
`;

interface StateProps {
  readonly currentPreviewPage?: Page;
  readonly isSeparateAreaTable?: boolean;
}

type Props = StateProps;

const PrintPreviewSketch = ({ currentPreviewPage, isSeparateAreaTable }: Props): JSX.Element => {
  const [builtPngUri, setBuiltPngUri] = useState<string | undefined>('');

  useEffect(() => {
    if (currentPreviewPage?.previewPngs?.sketch && currentPreviewPage?.previewPngs?.sketch !== 'data:,') {
      const { sketch, areaTable } = currentPreviewPage.previewPngs;

      if (!isSeparateAreaTable) {
        (async () => {
          const pngUri = await buildPreviewPage(sketch, areaTable);

          setBuiltPngUri(pngUri);
        })();
      } else if (isSeparateAreaTable) {
        setBuiltPngUri(currentPreviewPage?.previewPngs.sketch);
      }
    } else {
      setBuiltPngUri('');
    }
  }, [currentPreviewPage, isSeparateAreaTable]);

  return builtPngUri ? (
    <div css={imageWrapper}>
      <img src={builtPngUri} css={imageStyle} alt="Print Preview" />
    </div>
  ) : (
    <div css={noDataStyle}>No sketch data for this page</div>
  );
};

export default PrintPreviewSketch;
