import { CoordinatePoint } from 'types/point';

const equals = '=';
const plus = '+';
const minus = '-';
const underscore = '_';
const arrowUp = 'ArrowUp';
const arrowDown = 'ArrowDown';
const arrowLeft = 'ArrowLeft';
const arrowRight = 'ArrowRight';

const zero = '0';
const one = '1';
const two = '2';
const three = '3';
const four = '4';
const five = '5';
const six = '6';
const seven = '7';
const eight = '8';
const nine = '9';
const comma = ',';
const dot = '.';

export const Keys = {
  zero,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  comma,
  dot,
  escape: 'Escape',
  enter: 'Enter',
  shift: 'Shift',
  alt: 'Alt',
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd',
  f: 'f',
  g: 'g',
  i: 'i',
  l: 'l',
  n: 'n',
  p: 'p',
  r: 'r',
  s: 's',
  t: 't',
  u: 'u',
  v: 'v',
  x: 'x',
  y: 'y',
  z: 'z',
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  plusKeys: [equals, plus],
  minusKeys: [minus, underscore],

  // IE and Edge workaround: https://caniuse.com/#search=KeyboardEvent.key
  plusKeysCodes: [107, 187],
  minusKeysCodes: [109, 189],

  arrowKeys: [arrowUp, arrowDown, arrowLeft, arrowRight],
  digitKeys: [zero, one, two, three, four, five, six, seven, eight, nine, comma, dot],
  delete: 'Delete',
  backspace: 'Backspace',
};

interface ArrowDeltas {
  [key: string]: CoordinatePoint;
}

export const arrowDeltas: ArrowDeltas = {
  [arrowUp]: { x: 0, y: 10 },
  [arrowDown]: { x: 0, y: -10 },
  [arrowLeft]: { x: 10, y: 0 },
  [arrowRight]: { x: -10, y: 0 },
};
