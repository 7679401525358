const symbolGroup = [
    {
      "id": "ae486c6e-5864-4563-8148-4e04027f6986",
      "description": "A collection of Staircases",
      "name": "Staircases"
    },
    {
      "id": "a1ed5d84-0734-4d36-9fb4-6d021fc08065",
      "description": "A collection of Doors",
      "name": "Doors"
    },
    {
      "id": "9ac017fb-b390-465a-9123-f3b5c9ab509e",
      "description": "A collection of Arrows",
      "name": "Arrows"
    },
    {
      "id": "555af967-6d40-409b-9c26-28300dbf2da2",
      "description": "A collection of Appliances",
      "name": "Appliances"
    },
    {
      "id": "00875e2b-09f2-4def-8145-9717244bef65",
      "description": "A collection for Bathrooms",
      "name": "Bathrooms"
    },
    {
      "id": "728d188f-ebed-46ae-a67b-feea3c2bb988",
      "description": "A collection of Recreation",
      "name": "Recreation"
    },
    {
      "id": "8e75976e-8744-4762-b93d-e7d9390142d3",
      "description": "A collection of Swimming Pools",
      "name": "Swimming Pools"
    },
    {
      "id": "d573e995-ce5c-4caf-8aa0-3f3e2f4315ad",
      "description": "A collection of Windows",
      "name": "Windows"
    }
  ];

  export default symbolGroup