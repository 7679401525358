/** @jsx jsx */

import Immutable from 'immutable';
import React, { PureComponent, Ref } from 'react';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import posed from 'react-pose';
import { DisplayPose, appearFromLeft } from 'components/animations';

import { RootState } from 'reducers/rootReducer';
import { toolbar } from 'config/paletteConfig';
import { toolbarLeftTextConfig as text } from 'config/messages';
import { selectors as pointSelectors } from 'ducks/model/points';
import { selectors as pageGroupsModalSelectors, actions as pageGroupsModalActions } from 'ducks/modal/pageGroupsModal';
import { actions as numpadModalActions, selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { selectors as glaBreakdownModalSelectors, actions as glaBreakdownModalActions } from 'ducks/modal/glaBreakdownModal';
import { selectors as settingsModalSelectors, actions as settingsModalActions } from 'ducks/modal/settingsModal';
import { selectors as sketchSearchModalSelectors, actions as sketchSearchModalActions } from 'ducks/modal/sketchSearchModal';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { actions as removeObjects } from 'ducks/remove/removeObjects';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { actions as drawActions, selectors as drawSelectors } from 'ducks/draw/draw';
import { actions as drawCurveActions } from 'ducks/draw/drawCurve';
import { actions as sidebarActions, selectors as sidebarSelectors } from 'ducks/sidebar/sidebar';
import { actions as viewportActions } from 'ducks/viewport';
import { actions as resizeSketchModalActions, selectors as resizeSketchModalSelectors } from 'ducks/modal/resizeSketchModal';
import { actions as copyActions } from 'ducks/copy/copy';

import { ReactComponent as IconCursor } from 'assets/icons/cursor.svg';
import { ReactComponent as IconCursorSelected } from 'assets/icons/cursor-selected.svg';
import { ReactComponent as IconMulti } from 'assets/icons/cursor-multi.svg';
import { ReactComponent as IconMultiSelected } from 'assets/icons/cursor-multi-selected.svg';

import { ReactComponent as IconBox } from 'assets/icons/box-select.svg';
import { ReactComponent as ExteriorWall } from 'assets/icons/draw.svg';
import { ReactComponent as IconShape } from 'assets/icons/shape-tool.svg';
import { ReactComponent as InteriorWall } from 'assets/icons/interior-walls.svg';

import { ReactComponent as IconLabel } from 'assets/icons/label.svg';
import { ReactComponent as IconSymbol } from 'assets/icons/symbols.svg';

import { ReactComponent as IconCorner } from 'assets/icons/add-point.svg';
import { ReactComponent as IconCurve } from 'assets/icons/curved-walls.svg';
import { ReactComponent as IconDuplicate } from 'assets/icons/duplicate.svg';
import { ReactComponent as IconResize } from 'assets/icons/resize-walls.svg';
import { ReactComponent as IconRescale } from 'assets/icons/re-scale-wall.svg';

import { ReactComponent as IconCenter } from 'assets/icons/re-center.svg';

import { ReactComponent as IconDelete } from 'assets/icons/delete.svg';
import { TooltipContent } from 'components/elements/Tooltip';
import ToolbarButton from './ToolbarButton';

const base = css`
  position: absolute;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 3px solid ${toolbar.border};
  align-items: center;
  background: ${toolbar.background};
  overflow-y: auto;

  button + button {
    margin-top: 0;
  }
`;

const mediaQueries = css`
  & {
    @media (max-height: 1024px) {
      margin-top: 4px;
      margin-bottom: 4px;
    }
    @media (max-height: 800px) {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
`;

const Button = styled(ToolbarButton)(mediaQueries);

const activeStyle = css`
  color: ${toolbar.button.textActive};
`;

const Separator = styled.div`
  width: 40px;
  border-top: 1px solid ${toolbar.border};
  height: 1px;
`;

interface ToolbarLeftProps {
  readonly top?: number;
  readonly left?: number;
}

interface InputProps {
  readonly pose: DisplayPose;
  readonly left?: number;
}

const ToolbarLeftStyle = styled.div<ToolbarLeftProps>(({ top = 0, left }) => ({
  top,
  left,
  bottom: 0,
}));

const ToolbarLeftPosed = posed(ToolbarLeftStyle)(appearFromLeft);

interface OwnProps {
  innerRef: Ref<HTMLDivElement>;
}
interface StateProps {
  readonly isShowingToolbarLeft: boolean;
  readonly isShowingGlaBreakdownModal: boolean;
  readonly isShowingSettingsModal: boolean;
  readonly isShowingSketchSearchModal: boolean;
  readonly isShowingPageGroupsModal: boolean;
  readonly isShowingRescaleGridModal: boolean;
  readonly isShowingNumpadModal: boolean;
  readonly isSelectMode: boolean;
  readonly isEditMode: boolean;
  readonly isSplittingMode: boolean;
  readonly isDrawingMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isMultiselectMode: boolean;
  readonly isBoxSelectMode: boolean;
  readonly isBoxSelectEditMode: boolean;
  readonly isMovingMode: boolean;
  readonly canRemoveSelectedObjects: boolean;
  readonly isWallSelected: boolean;
  readonly isShowingLabelSidebar: boolean;
  readonly isShowingSymbolSidebar: boolean;
  readonly isShowingPolygonSidebar: boolean;
  readonly isDrawingInteriorWalls: boolean;
  readonly isDrawingPreshapes: boolean;
  readonly isShowingResizeSketchModal: boolean;
  readonly selectedObjects: Immutable.List<string>;
  readonly isSketchEmpty: boolean;
  readonly isDuplicableSelected: boolean;
}

interface ActionProps {
  readonly removeSelectedObjects: () => void;
  readonly centerAll: () => void;
  readonly addCurve: () => void;
  readonly showLabelSidebar: () => void;
  readonly showSymbolSidebar: () => void;
  readonly showPolygonSidebar: () => void;
  readonly hideSidebar: () => void;
  readonly toggleDrawingInteriorWalls: (drawInteriorWalls: boolean) => void;
  readonly toggleDrawingPreshapes: (drawPreshapes: boolean) => void;
  readonly hideResizeSketchModal: () => void;
  readonly showResizeSketchModal: () => void;
  readonly hideRescaleGridModal: () => void;
  readonly showRescaleGridModal: () => void;
  readonly hideGlaBreakdownModal: () => void;
  readonly hideNumpadModal: () => void;
  readonly hideSketchSearchModal: () => void;
  readonly hideSettingsModal: () => void;
  readonly hidePageGroupsModal: () => void;
  readonly switchToSelection: typeof editModeActions.switchToSelection;
  readonly switchToSelected: typeof editModeActions.switchToSelected;
  readonly startMultiselectMode: typeof editModeActions.startMultiselectMode;
  readonly endMultiselectMode: typeof editModeActions.endMultiselectMode;
  readonly startBoxSelectMode: typeof editModeActions.startBoxSelectMode;
  readonly endBoxSelectMode: typeof editModeActions.endBoxSelectMode;
  readonly switchToEdit: typeof editModeActions.switchToEdit;
  readonly switchToSplit: typeof editModeActions.switchToSplit;
  readonly copy: typeof copyActions.copy;
  readonly paste: typeof copyActions.paste;
}

type Props = OwnProps & StateProps & ActionProps & InputProps;

class ToolbarLeft extends PureComponent<Props> {
  private switchToSelectionOrSelectedMode = (): void => {
    const { switchToSelection, switchToSelected, selectedObjects } = this.props;
    if (selectedObjects.size === 0) {
      switchToSelection();
    } else {
      switchToSelected(selectedObjects.toArray());
    }
  };

  private duplicate = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const { copy, paste } = this.props;
    copy();
    paste();
  };

  private switchToSelectMode = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { endMultiselectMode, endBoxSelectMode } = this.props;
    this.switchToSelectionOrSelectedMode();
    endMultiselectMode();
    endBoxSelectMode();
  };

  private switchToMultiselectMode = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { startMultiselectMode, endBoxSelectMode } = this.props;
    this.switchToSelectionOrSelectedMode();
    endBoxSelectMode();
    startMultiselectMode();
  };

  private switchToBoxSelectMode = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { startBoxSelectMode, endMultiselectMode } = this.props;
    this.switchToSelectionOrSelectedMode();
    endMultiselectMode();
    startBoxSelectMode();
  };

  private switchToEditMode = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { switchToEdit, toggleDrawingInteriorWalls, toggleDrawingPreshapes } = this.props;
    toggleDrawingInteriorWalls(false);
    toggleDrawingPreshapes(false);
    switchToEdit();
  };

  private switchToInteriorWallsMode = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { switchToEdit, toggleDrawingInteriorWalls, toggleDrawingPreshapes } = this.props;

    this.hideToolbarTopModals();
    toggleDrawingInteriorWalls(true);
    toggleDrawingPreshapes(false);
    switchToEdit();
  };

  private switchToSplitMode = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { switchToSplit } = this.props;
    switchToSplit();
  };

  private toggleLabelSidebar = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { isShowingLabelSidebar, hideSidebar, showLabelSidebar } = this.props;

    this.hideToolbarTopModals();

    if (isShowingLabelSidebar) {
      hideSidebar();
    } else {
      showLabelSidebar();
    }
  };

  private toggleSymbolSidebar = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { isShowingSymbolSidebar, hideSidebar, showSymbolSidebar } = this.props;

    this.hideToolbarTopModals();

    if (isShowingSymbolSidebar) {
      hideSidebar();
    } else {
      showSymbolSidebar();
    }
  };

  private togglePolygonSidebar = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { isShowingPolygonSidebar, hideSidebar, showPolygonSidebar, toggleDrawingInteriorWalls, toggleDrawingPreshapes } = this.props;

    this.hideToolbarTopModals();
    toggleDrawingInteriorWalls(false);
    toggleDrawingPreshapes(true);

    if (isShowingPolygonSidebar) {
      hideSidebar();
    } else {
      showPolygonSidebar();
    }
  };

  private toggleResizeImageModal = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { isShowingResizeSketchModal, hideResizeSketchModal, showResizeSketchModal } = this.props;

    this.hideToolbarTopModals();

    if (isShowingResizeSketchModal) {
      hideResizeSketchModal();
    } else {
      showResizeSketchModal();
    }
  };

  private toggleGridScaleModal = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { isShowingRescaleGridModal, hideRescaleGridModal, showRescaleGridModal } = this.props;

    this.hideToolbarTopModals();

    if (isShowingRescaleGridModal) {
      hideRescaleGridModal();
    } else {
      showRescaleGridModal();
    }
  }

  private removeSelectedObjects = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { removeSelectedObjects } = this.props;
    removeSelectedObjects();
  };

  private addCurveHandler = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    const { addCurve } = this.props;
    addCurve();
  };

  private centerHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const { centerAll } = this.props;
    centerAll();
  };

  private hideToolbarTopModals = (): void => {
    const {
      isShowingGlaBreakdownModal,
      isShowingSketchSearchModal,
      isShowingSettingsModal,
      isShowingPageGroupsModal,
      isShowingNumpadModal,
      hidePageGroupsModal,
      hideGlaBreakdownModal,
      hideSettingsModal,
      hideNumpadModal,
      hideSketchSearchModal,
    } = this.props;
    if (isShowingGlaBreakdownModal) hideGlaBreakdownModal();
    if (isShowingPageGroupsModal) hidePageGroupsModal();
    if (isShowingSettingsModal) hideSettingsModal();
    if (isShowingNumpadModal) hideNumpadModal();
    if (isShowingSketchSearchModal) hideSketchSearchModal();
  }

  public render(): JSX.Element {
    const {
      left,
      pose,
      isSelectMode,
      isEditMode,
      isSplittingMode,
      isDrawingMode,
      isSelectedMode,
      isMultiselectMode,
      isBoxSelectMode,
      isBoxSelectEditMode,
      isMovingMode,
      canRemoveSelectedObjects,
      isWallSelected,
      isShowingLabelSidebar,
      isShowingSymbolSidebar,
      isDrawingInteriorWalls,
      isDrawingPreshapes,
      isShowingResizeSketchModal,
      isShowingRescaleGridModal,
      isSketchEmpty,
      isDuplicableSelected,
      innerRef,
    } = this.props;

    return (
      <div className="toolbarLeftDiv">
        <ToolbarLeftPosed left={left} pose={pose}>
          <div css={base} ref={innerRef}>
            <Button
              onClick={this.switchToSelectMode}
              aria-label={text.select.ariaLabel}
              touchLabel={text.select.touchLabel}
              active={!isMultiselectMode && !isBoxSelectMode && (isSelectMode || isSelectedMode || isMovingMode)}
              icon={<IconCursor />}
              iconActive={<IconCursorSelected />}
              tooltip={<TooltipContent title={text.select.title} description={text.select.description} shortcut={text.select.shortcut} />}
            />
            <Button
              onClick={this.switchToMultiselectMode}
              aria-label={text.multiselect.ariaLabel}
              touchLabel={text.multiselect.touchLabel}
              active={isMultiselectMode && !isBoxSelectMode && (isSelectMode || isSelectedMode || isMovingMode)}
              icon={<IconMulti />}
              iconActive={<IconMultiSelected />}
              tooltip={<TooltipContent title={text.multiselect.title} description={text.multiselect.description} shortcut={text.multiselect.shortcut} />}
            />
            <Button
              onClick={this.switchToBoxSelectMode}
              aria-label={text.boxSelect.ariaLabel}
              touchLabel={text.boxSelect.touchLabel}
              active={isBoxSelectMode && !isMultiselectMode && (isSelectMode || isSelectedMode || isMovingMode || isBoxSelectEditMode)}
              icon={<IconBox />}
              iconActive={<IconBox />}
              tooltip={<TooltipContent title={text.boxSelect.title} description={text.boxSelect.description} shortcut={text.boxSelect.shortcut} />}
            />
            <Button
              onClick={this.switchToEditMode}
              aria-label={text.sketchTool.ariaLabel}
              touchLabel={text.sketchTool.touchLabel}
              active={(isEditMode || isDrawingMode) && !isDrawingInteriorWalls && !isDrawingPreshapes}
              icon={<ExteriorWall />}
              tooltip={<TooltipContent title={text.sketchTool.title} description={text.sketchTool.description} shortcut={text.sketchTool.shortcut} />}
            />
            <Button
              onClick={this.togglePolygonSidebar}
              aria-label={text.polygon.ariaLabel}
              touchLabel={text.polygon.touchLabel}
              active={(isEditMode || isDrawingMode) && isDrawingPreshapes}
              icon={<IconShape />}
              tooltip={<TooltipContent title={text.polygon.title} description={text.polygon.description} shortcut={text.polygon.shortcut} />}
            />

            <Separator />

            <Button
              onClick={this.toggleLabelSidebar}
              aria-label={text.label.ariaLabel}
              touchLabel={text.label.touchLabel}
              active={isShowingLabelSidebar}
              icon={<IconLabel />}
              tooltip={<TooltipContent title={text.label.title} description={text.label.description} shortcut={text.label.shortcut} />}
            />
            <Button
              onClick={this.toggleSymbolSidebar}
              aria-label={text.symbol.ariaLabel}
              touchLabel={text.symbol.touchLabel}
              active={isShowingSymbolSidebar}
              icon={<IconSymbol />}
              tooltip={<TooltipContent title={text.symbol.title} description={text.symbol.description} shortcut={text.symbol.shortcut} />}
            />

            <Button
              onClick={this.switchToInteriorWallsMode}
              aria-label={text.interiorWallsTool.ariaLabel}
              touchLabel={text.interiorWallsTool.touchLabel}
              active={(isEditMode || isDrawingMode) && isDrawingInteriorWalls}
              icon={<InteriorWall />}
              tooltip={(
                <TooltipContent
                  title={text.interiorWallsTool.title}
                  description={text.interiorWallsTool.description}
                  shortcut={text.interiorWallsTool.shortcut}
                />
              )}
            />
            <Separator />

            <Button
              onClick={this.switchToSplitMode}
              aria-label={text.point.ariaLabel}
              touchLabel={text.point.touchLabel}
              active={isSplittingMode}
              tooltip={<TooltipContent title={text.point.title} description={text.point.description} shortcut={text.point.shortcut} />}
            >
              <IconCorner />
            </Button>
            <Button
              onClick={this.addCurveHandler}
              disabled={!isWallSelected}
              aria-label={text.curveWall.ariaLabel}
              touchLabel={text.curveWall.touchLabel}
              icon={<IconCurve />}
              tooltip={<TooltipContent title={text.curveWall.title} description={text.curveWall.description} shortcut={text.curveWall.shortcut} />}
              tooltipHide={!isWallSelected}
            />

            <Button
              onClick={this.duplicate}
              disabled={!isDuplicableSelected}
              aria-label={text.duplicate.ariaLabel}
              touchLabel={text.duplicate.touchLabel}
              icon={<IconDuplicate />}
              tooltip={<TooltipContent title={text.duplicate.title} description={text.duplicate.description} shortcut={text.duplicate.shortcut} />}
              tooltipHide={!isDuplicableSelected}
            />

            <Button
              css={isShowingResizeSketchModal && activeStyle}
              onClick={this.toggleResizeImageModal}
              disabled={!isWallSelected}
              aria-label={text.resize.ariaLabel}
              touchLabel={text.resize.touchLabel}
              icon={<IconResize />}
              tooltip={<TooltipContent title={text.resize.title} description={text.resize.description} />}
              tooltipHide={!isWallSelected}
            />

            <Button
              css={isShowingRescaleGridModal && activeStyle}
              onClick={this.toggleGridScaleModal}
              disabled={!isWallSelected}
              aria-label={text.rescale.ariaLabel}
              touchLabel={text.rescale.touchLabel}
              icon={<IconRescale />}
              tooltip={<TooltipContent title={text.rescale.title} description={text.rescale.description} />}
              tooltipHide={!isWallSelected}
            />

            <Separator />

            <Button
              onClick={this.centerHandler}
              disabled={isSketchEmpty}
              aria-label={text.center.ariaLabel}
              touchLabel={text.center.touchLabel}
              icon={<IconCenter />}
              tooltip={<TooltipContent title={text.center.title} description={text.center.description} shortcut={text.center.shortcut} />}
              tooltipHide={isSketchEmpty}
            />

            <Separator />

            <Button
              onClick={this.removeSelectedObjects}
              disabled={!isSelectedMode || !canRemoveSelectedObjects}
              aria-label={text.removeSelected.ariaLabel}
              touchLabel={text.removeSelected.touchLabel}
              tooltip={
                <TooltipContent title={text.removeSelected.title} description={text.removeSelected.description} shortcut={text.removeSelected.shortcut} />}
              tooltipHide={!isSelectedMode || !canRemoveSelectedObjects}
            >
              {!isSelectedMode || !canRemoveSelectedObjects ? <IconDelete /> : <IconDelete style={{ color: '#ed3870' }} />}
            </Button>
          </div>
        </ToolbarLeftPosed>
      </div>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    isShowingToolbarLeft: sidebarSelectors.isShowingToolbarLeft(state),
    isShowingGlaBreakdownModal: glaBreakdownModalSelectors.isShowing(state),
    isShowingSketchSearchModal: sketchSearchModalSelectors.isShowing(state),
    isShowingSettingsModal: settingsModalSelectors.isShowing(state),
    isShowingPageGroupsModal: pageGroupsModalSelectors.isShowing(state),
    isShowingNumpadModal: numpadModalSelectors.isShowing(state),
    isSelectMode: editModeSelectors.isSelectMode(state),
    isEditMode: editModeSelectors.isEditMode(state),
    isSplittingMode: editModeSelectors.isSplittingMode(state),
    isDrawingMode: editModeSelectors.isDrawingMode(state),
    isSelectedMode: editModeSelectors.isSelectedMode(state),
    isMultiselectMode: editModeSelectors.isMultiselectMode(state),
    isBoxSelectMode: editModeSelectors.isBoxSelectMode(state),
    isBoxSelectEditMode: editModeSelectors.isBoxSelectEditMode(state),
    isMovingMode: editModeSelectors.isMovingMode(state),
    canRemoveSelectedObjects: selectionSelectors.canRemoveSelectedObjects(state),
    isWallSelected: selectionSelectors.isLineWallSelected(state),
    isShowingLabelSidebar: sidebarSelectors.isShowingLabels(state),
    isShowingSymbolSidebar: sidebarSelectors.isShowingSymbols(state),
    isShowingPolygonSidebar: sidebarSelectors.isShowingPolygons(state),
    isDrawingInteriorWalls: drawSelectors.isDrawingInteriorWalls(state),
    isShowingResizeSketchModal: resizeSketchModalSelectors.isResizeShowing(state),
    isShowingRescaleGridModal: resizeSketchModalSelectors.isRescaleShowing(state),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
    isSketchEmpty: pointSelectors.getAllPointsList(state).size === 0,
    isDuplicableSelected: selectionSelectors.areDuplicableSelected(state),
    isDrawingPreshapes: drawSelectors.isDrawingPreshapes(state),
  }),
  {
    switchToSelection: editModeActions.switchToSelection,
    switchToSelected: editModeActions.switchToSelected,
    startMultiselectMode: editModeActions.startMultiselectMode,
    endMultiselectMode: editModeActions.endMultiselectMode,
    startBoxSelectMode: editModeActions.startBoxSelectMode,
    endBoxSelectMode: editModeActions.endBoxSelectMode,
    switchToEdit: editModeActions.switchToEdit,
    switchToSplit: editModeActions.switchToSplit,
    removeSelectedObjects: removeObjects.removeSelectedObjects,
    centerAll: viewportActions.centerAll,
    toggleDrawingInteriorWalls: drawActions.toggleDrawingInteriorWalls,
    toggleDrawingPreshapes: drawActions.toggleDrawingPreshapes,
    addCurve: drawCurveActions.addCurve,
    hideSidebar: sidebarActions.hide,
    showLabelSidebar: sidebarActions.showLabels,
    showSymbolSidebar: sidebarActions.showSymbols,
    showPolygonSidebar: sidebarActions.showPolygons,
    showResizeSketchModal: resizeSketchModalActions.showResize,
    hideResizeSketchModal: resizeSketchModalActions.hideResize,
    showRescaleGridModal: resizeSketchModalActions.showRescale,
    hideRescaleGridModal: resizeSketchModalActions.hideRescale,
    hideNumpadModal: numpadModalActions.hide,
    hideGlaBreakdownModal: glaBreakdownModalActions.hide,
    hideSketchSearchModal: sketchSearchModalActions.hide,
    hideSettingsModal: settingsModalActions.hide,
    hidePageGroupsModal: pageGroupsModalActions.hide,
    copy: copyActions.copy,
    paste: copyActions.paste,
  },
)(ToolbarLeft);
