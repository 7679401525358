import { gridConfig } from 'config/gridConfig';
import { grid } from 'config/paletteConfig';

const gridStyles = {
  container: {
    width: '100%',
    height: '100%',
  },
  small: {
    fill: 'none',
    strokeWidth: `${gridConfig.smallGridStrokeWidth}px`,
    stroke: grid.small,
  },
  darkSmall: {
    fill: 'none',
    strokeWidth: `${gridConfig.smallGridStrokeWidth}px`,
    stroke: grid.darkSmall,
  },
  bigLight: {
    fill: 'none',
    strokeWidth: `${gridConfig.bigGridStrokeWidth}px`,
    stroke: grid.light,
  },
  bigDark: {
    fill: 'none',
    strokeWidth: `${gridConfig.bigGridStrokeWidth}px`,
    stroke: grid.dark,
  },
};

export default gridStyles;
