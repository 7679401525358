import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import styled from '@emotion/styled';
import { transparentize } from 'helpers/colorTransforms';

import { RootState } from 'reducers/rootReducer';
import { Point, CoordinatePoint } from 'types/point';
import { PointType } from 'types/pointType';
import { getCurveByArcPoint } from 'helpers/curve/getArcPoint';
import { selectors as editModeSelectors } from 'ducks/editMode';

import { GlaType } from 'types/glaType';
import areaStyles, { OPACITY } from './styles';

interface InputProps {
  readonly figureId: string;
  readonly line: Immutable.List<Point | CoordinatePoint>;
  readonly isFigureClosed: boolean;
  readonly isTransparent: boolean;
  readonly glaType?: GlaType;
  readonly colorFill: string;
}

interface StateProps {
  readonly isSelectMode: boolean;
  readonly isReadyToMove: boolean;
  readonly selectedObjects: Immutable.List<string>;
}

type Props = InputProps & StateProps;

interface SketchLineCompProps {
  readonly isFigureClosed: boolean;
  readonly isSelected: boolean;
  readonly isReadyToMove: boolean;
  readonly isTransparent: boolean;
  readonly isSelectMode: boolean;
  readonly glaType?: GlaType;
  readonly colorFill: any;
}

const SketchLineComp = styled.path<SketchLineCompProps>(
  areaStyles.base,
  ({ colorFill, isSelectMode, isReadyToMove }) => (isSelectMode || isReadyToMove) && colorFill ? colorFill : areaStyles.hover,
  ({ colorFill, isFigureClosed }) => isFigureClosed && colorFill ? colorFill : areaStyles.closed,
  ({ isSelected }) => isSelected && areaStyles.active,
  ({ isTransparent }) => isTransparent && areaStyles.interior,
  ({ glaType }) => glaType === GlaType.NON_GLA && areaStyles.nonGla,
);

const SketchLine = (props: Props): JSX.Element => {
  const {
    line, selectedObjects, figureId, isSelectMode, isReadyToMove,
    isFigureClosed, isTransparent, glaType, colorFill,
  } = props;

  const isSelected = selectedObjects.includes(figureId);
  const polygon = line;
  const closedCmd = isFigureClosed ? ' Z' : '';

  let pathData = '';
  polygon.forEach((p, index) => {
    let cmd = '';
    if (index === 0) {
      cmd = 'M ';
    } else {
      const pPrev = polygon.get(index - 1)!;
      if (!('pointType' in pPrev) || pPrev.pointType !== PointType.ARC) {
        cmd = 'L ';
      }
    }

    if (!('pointType' in p) || p.pointType !== PointType.ARC) {
      pathData += `${cmd} ${p.x} ${p.y} `;
    } else {
      const p1 = polygon.get(index - 1)!;
      const p2 = ((index + 1) < polygon.size)
        ? polygon.get(index + 1)!
        : polygon.get(0)!;
      const curveParameters = getCurveByArcPoint(p1, p2, p);
      const curveOrientation = (curveParameters.arcHeight < 0) ? 1 : 0;
      // eslint-disable-next-line max-len
      pathData += `A ${curveParameters.r} ${curveParameters.r} ${curveParameters.angle} ${curveParameters.bigArc} ${curveOrientation} `;

      if ((index + 1) === polygon.size) {
        pathData += `${p2.x} ${p2.y}`;
      }
    }
  });

  pathData += closedCmd;

  const customColorFill = {
    fill: transparentize(colorFill, OPACITY),
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'pointer',
    },
  };

  return (
    <SketchLineComp
      colorFill={customColorFill}
      isFigureClosed={isFigureClosed}
      isSelected={isSelected}
      isTransparent={isTransparent}
      isSelectMode={isSelectMode}
      isReadyToMove={isReadyToMove}
      glaType={glaType}
      d={pathData}
    />
  );
};

export default connect(
  (state: RootState): StateProps => ({
    isSelectMode: editModeSelectors.isSelectMode(state),
    isReadyToMove: editModeSelectors.isSelectedMode(state),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
  }),
)(SketchLine);
