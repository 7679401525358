/* eslint-disable react/no-unused-prop-types */
/** @jsx jsx */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';

import { Point, CoordinatePoint } from 'types/point';
import { PositionedLabel } from 'types/positionedLabel';
import { Label } from 'types/label';
import { Figure } from 'types/figure';
import { AreaType } from 'types/areaType';
import { Precision } from 'types/precision';
import { Group } from 'types/group';
import { Wall, CircleWall } from 'types/wall';

import { RootState } from 'reducers/rootReducer';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';
import { selectors as labelsSelectors } from 'ducks/model/labels';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as wallsSelectors } from 'ducks/model/walls';

import { filterBy, isSomething } from 'helpers/utils';
import { ReactComponent as IconChevron } from 'assets/icons/chevron.svg';
import GlaBreakdownRow, { EmptyRow } from './GlaBreakdownRow';
import SubAreaBreakdownRow from './SubAreaBreakdownRow';

const groupingContainer = css`
  display: flex;
  align-items: center;
`;

const chevron = css`
  padding: 6px;
  cursor: pointer;
`;

const opened = css`
  transform: rotate(90deg);
`;

const buttonStyle = css`
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: transform 0.2s ease;
`;

interface InputProps {
  readonly group: Group;
  readonly positionedLabelId: string;
  readonly labelId?: string;
}

interface StateProps {
  readonly figures?: Immutable.List<Figure>;
  readonly label?: Label;
  readonly positionedLabel?: PositionedLabel;
  readonly figurePoints?: Immutable.List<Point | CoordinatePoint>;
  readonly areaType?: AreaType;
  readonly precision: Precision;
  readonly unitOfMeasure: string;
  readonly gridSizeInFeet: number;
  readonly walls: Immutable.Map<string, Wall | CircleWall>;
}

type Props = InputProps & StateProps;

const GroupingBreakdownRow: React.FC<Props> = ({ label, figures, walls }) => {
  const [isOpen, setIsOpen] = useState(false);
  const filteredFigures = filterBy(isSomething)(figures);

  const figuresBySubAreas =
    filteredFigures &&
    filteredFigures.reduce((acc: any, figure: Figure): object => {
      const { subAreaText } = figure;
      if (subAreaText) {
        acc[subAreaText] = acc[subAreaText] ? [...acc[subAreaText], figure] : [figure];
      }
      return acc;
    }, {});

  const figuresWithoutSubAreas =
    filteredFigures &&
    filteredFigures.reduce((acc: any, figure: Figure): object => {
      const { subAreaText } = figure;
      if (!subAreaText) {
        acc[figure.figureId] = [figure];
      }
      return acc;
    }, {});

  return (
    <React.Fragment>
      {label ? (
        <tr>
          <td>
            <div css={groupingContainer}>
              <IconChevron css={[buttonStyle, chevron, isOpen ? opened : null]} onClick={() => setIsOpen((prevState) => !prevState)} />
              <div>{`${label.text}`}</div>
            </div>
          </td>
          <th />
          <th />
        </tr>
      ) : (
        <EmptyRow />
      )}
      {isOpen &&
        Object.entries(figuresBySubAreas)
          .sort()
          .map((subAreaGrouping: any) => <SubAreaBreakdownRow key={subAreaGrouping[0]} subAreaGrouping={subAreaGrouping} />)}
      {isOpen &&
        Object.entries(figuresWithoutSubAreas)
          .sort()
          .map((figure: any) => <GlaBreakdownRow key={figure[0]} figure={figure[1][0]} walls={walls} isGrouped />)}
    </React.Fragment>
  );
};

export default connect((state: RootState, props: InputProps): StateProps => {
  let positionedLabel;
  if (props.positionedLabelId) {
    positionedLabel = positionedLabelsSelectors.getPositionedLabelById(state, props.positionedLabelId);
  }
  const labelId = props.labelId || (positionedLabel && positionedLabel.labelId);
  let label;
  if (labelId) {
    label = labelsSelectors.getLabelById(state, labelId);
  }
  let figures;
  if (props.group.figures) {
    figures = props.group.figures.map((figureId) => figuresSelectors.getFigureById(state, figureId));
  }
  return {
    figures,
    label,
    positionedLabel,
    walls: wallsSelectors.getAllWalls(state),
    precision: settingsSelectors.getPrecision(state),
    unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
    gridSizeInFeet: settingsSelectors.getGridSizeInFeet(state),
  };
})(GroupingBreakdownRow);
