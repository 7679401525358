import { Box } from 'types/box';
import { CoordinatePoint } from 'types/point';

/**
 * distance (in percents) where pan should be activated
 */
const distanceFromBorder = 0.1;

/**
 * returns vector, which should be applied to pan viewBox to visible area
 */
export const getPanBack = (viewBox: Box, point: CoordinatePoint): CoordinatePoint => {
  const minX = viewBox.x + viewBox.width * distanceFromBorder;
  const maxX = viewBox.x + viewBox.width - viewBox.width * distanceFromBorder;
  const minY = viewBox.y + viewBox.height * distanceFromBorder;
  const maxY = viewBox.y + viewBox.height - viewBox.height * distanceFromBorder;
  let dx = 0;
  let dy = 0;
  if (point.x < minX) {
    dx = minX - point.x;
  }

  if (point.x > maxX) {
    dx = maxX - point.x;
  }

  if (point.y < minY) {
    dy = minY - point.y;
  }

  if (point.y > maxY) {
    dy = maxY - point.y;
  }

  return { x: dx, y: dy };
};
