import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { distance } from 'helpers/geometry';
import { pixelDistanceInFeet, pixelDistanceInMeters, formatLinearLabel } from 'helpers/pixelsToDistance';
import { actions as resizeSketchModalActions } from 'ducks/modal/resizeSketchModal';
import { actions as resizeSketchActions } from 'ducks/bluePrintImage/resizeSketch';
import { actions as editModeActions } from 'ducks/editMode';
import { actions as drawActions } from 'ducks/draw/draw';
import { SelectedLine, selectors as selectionSelectors } from 'ducks/selection/selection';
import { selectors as settingsSelectors } from 'ducks/settings';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { positionedModal, fade } from 'components/animations';
import { ModalWallResize, ButtonBig, ModalBackdrop } from 'components/modal/Modal';
import { sidebarItemButtonStyles } from 'components/sidebar/styles';
import { compose, multiplyBy } from 'helpers/utils';
import { CoordinatePoint } from 'types/point';

const Backdrop = styled(ModalBackdrop)({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
});

const BackdropAnimated = posed(Backdrop)(fade);

const ModalAnimated = posed(ModalWallResize)(positionedModal);
const CloseButton = styled.button(sidebarItemButtonStyles);

interface StateProps {
  readonly canRescaleGrid: boolean;
  readonly selectedWall?: SelectedLine;
  readonly unitOfMeasure: string;
  readonly precision: number;
  readonly gridSizeInFeet: number;
}
interface ActionProps {
  readonly close: () => void;
  readonly rescaleGrid: typeof resizeSketchActions.rescaleGrid;
  readonly toggleDrawing: (drawingWalls: boolean) => void;
  readonly switchToEdit: typeof editModeActions.switchToEdit;
}

type Props = StateProps & ActionProps;

interface State {
  readonly size: string;
  readonly position: ResizePosition;
}

interface ResizePosition {
  readonly top?: number;
  readonly left?: number;
}

const computeModalPosition = (
  {
    top, left, width, height,
  }: ClientRect,
  modalHeight: number,
): ResizePosition => ({
  top: top + height / 2 - modalHeight / 2,
  left: left + width / 2 + 20,
});

class RescaleGridModal extends Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  private readonly focusRef: React.RefObject<HTMLInputElement>;

  private readonly modalRef: React.RefObject<HTMLInputElement>;

  public constructor(props: Props) {
    super(props);
    this.focusRef = createRef();
    this.modalRef = createRef();

    this.state = {
      size: '',
      position: this.getModalPosition(),
    };
  }

  public componentDidMount(): void {
    const position = this.getModalPosition();
    this.setState({ position });
    this.focusRef.current!.focus();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { selectedWall, canRescaleGrid } = this.props;
    if (prevProps.selectedWall !== selectedWall && selectedWall) {
      const position = this.getModalPosition();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ position });
    }
    if (prevProps.canRescaleGrid && !canRescaleGrid) {
      this.close();
    }
  }

  private getModalPosition(): ResizePosition {
    const { selectedWall } = this.props;
    const wallNode = document.getElementById(selectedWall!.wall.wallId);

    if (wallNode) {
      const modalNode = this.modalRef.current;
      const position = computeModalPosition(
        wallNode.getBoundingClientRect(),
        modalNode ? modalNode.getBoundingClientRect().height : 0,
      );
      return position;
    }
    return {};
  }

  private save = (event?: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>): void => {
    if (event) {
      event.preventDefault();
    }

    const {
      rescaleGrid,
      selectedWall,
      close,
      gridSizeInFeet,
      unitOfMeasure,
      toggleDrawing,
      switchToEdit
    } = this.props;
    if (!selectedWall) {
      return;
    }

    const currentLength = compose(
      unitOfMeasure === 'meters' ? pixelDistanceInMeters : pixelDistanceInFeet,
      multiplyBy(gridSizeInFeet),
      ([a, b]: CoordinatePoint[]) => distance(a, b),
    )([selectedWall.points.get(0)!, selectedWall.points.get(1)!]);

    const { size } = this.state;

    rescaleGrid(selectedWall.wall.wallId, `${(+size) / currentLength}`);
    close();
    toggleDrawing(true);
    switchToEdit();
  };

  private close = (): void => {
    const { close } = this.props;
    close();
  };

  private handleSizeChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const size = event.target.value;
    this.setState(() => ({ size }));
  };

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.save();
    }
    if (event.key === 'Escape') {
      this.close();
    }
  };

  public render = (): JSX.Element | null => {
    const {
      canRescaleGrid, selectedWall, gridSizeInFeet, unitOfMeasure, precision,
    } = this.props;
    const { position, size } = this.state;

    if (!selectedWall || !canRescaleGrid) {
      return null;
    }

    const cancelEvent = (event: React.PointerEvent | React.WheelEvent | React.DragEvent): void => {
      event.preventDefault();
      event.stopPropagation();
    };

    const wallStart: CoordinatePoint = selectedWall.points.get(0)!;
    const wallEnd: CoordinatePoint = selectedWall.points.get(1)!;

    const measurement = compose(
      formatLinearLabel(unitOfMeasure, precision),
      multiplyBy(gridSizeInFeet),
      ([a, b]: CoordinatePoint[]) => distance(a, b),
    )([wallStart, wallEnd]);

    const unitOfMeasurementText = () => {
      if (unitOfMeasure === 'decimalFeet') return ' Feet/Decimal';
      if (unitOfMeasure === 'feet') return ' Feet/Inches';

      return 'Meters';
    };

    return (
      <BackdropAnimated
        onPointerDown={cancelEvent}
        onPointerMove={cancelEvent}
        onPointerUp={cancelEvent}
        onPointerLeave={cancelEvent}
        onWheel={cancelEvent}
        onDragOver={cancelEvent}
        onDrop={cancelEvent}
        pose="enter"
        initialPose="exit"
      >
        <ModalAnimated
          pose="enter"
          initialPose="exit"
          position={window.screen.width > 400 ? position : { top: '15rem', left: '1rem' }}
          poseKey={position}
          ref={this.modalRef}
        >
          <h2>
            <CloseButton onClick={this.close} title={messages.closeModal}>
              <IconClose />
            </CloseButton>
            <span>{messages.rescaleGridTitle}</span>
          </h2>
          <p>
            Current wall size is
            <b>{` ${measurement}`}</b>
            <br />
          </p>
          <p>New wall size</p>
          <form onSubmit={this.save} autoComplete="off">
            <label htmlFor="resizeInput">
              <input
                style={{ pointerEvents: 'none' }}
                id="resizeInput"
                ref={this.focusRef}
                type="text"
                placeholder={unitOfMeasure === 'meters' ? '0.0' : '0,0 or 0.0'}
                required
                value={size}
                onChange={this.handleSizeChanged}
                onKeyDown={this.handleKeyDown}
              />
              {unitOfMeasurementText()}
            </label>
            <ButtonBig type="submit">Apply</ButtonBig>
          </form>
        </ModalAnimated>
      </BackdropAnimated>
    );
  };
}

export default connect(
  (state: RootState): StateProps => ({
    selectedWall: selectionSelectors.getSelectedLine(state),
    canRescaleGrid: selectionSelectors.isLineWallSelected(state),
    unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
    precision: settingsSelectors.getPrecision(state),
    gridSizeInFeet: settingsSelectors.getGridSizeInFeet(state),
  }),
  {
    close: resizeSketchModalActions.hideRescale,
    rescaleGrid: resizeSketchActions.rescaleGrid,
    toggleDrawing: drawActions.toggleDrawingStraightWalls,
    switchToEdit: editModeActions.switchToEdit,
  },
)(RescaleGridModal);
