/** @jsx jsx */
import React from 'react';
import { connect } from 'react-redux';
import { jsx } from '@emotion/react';
import { RootState } from 'reducers/rootReducer';

import { isTouchDevice } from 'helpers/browserDetect';
import { ApiSketch } from 'types/api/apiSketch';

import {
  sidebarItemStyles,
  sidebarItemSelectedStyles,
  sidebarItemMedatdataStyles,
  metaDataTooltipStyles,
  sketchItemTextStyles,
  labelItemStyles,
  sketchDataContainerStyles,
  sketchDataTitleStyles,
  sketchDataRightTitleStyles,
  sketchDataContentStyles
} from 'components/sidebar/styles';
import { ListItem } from 'components/sidebar/LabelSidebar';

import { SketchDataToolTipContent } from 'components/elements/Tooltip';

import { selectors as sketchesPersistenceSelectors } from 'ducks/persistence/sketchesPersistence';
import { actions as sketchPersistenceActions } from 'ducks/persistence/sketchPersistence';
import { MetaDataTooltip } from 'components/modal/GlaBreakdownModal';

interface StateProps {
  // eslint-disable-next-line react/no-unused-prop-types
  readonly sketches: ApiSketch[];
}

interface ActionProps {
  readonly loadSketch: (id: string) => void;
}

interface LabelItemProps {
  readonly isSelected: boolean;
  readonly id: string;
  readonly orderId: string;
  readonly sketchName: string;
  readonly propertyAddress: string;
  readonly lastModifiedDate: string;
}

type Props = LabelItemProps & ActionProps & StateProps;

const SearchSketchItem: React.FC<Props> = (props) => {
  const {
    sketchName, isSelected, id, orderId, propertyAddress, lastModifiedDate, loadSketch
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    loadSketch(id);
  };

  return (
    <ListItem
      css={[isSelected && sidebarItemSelectedStyles, sketchName && sidebarItemMedatdataStyles, metaDataTooltipStyles]}
      tabIndex={0}
      withParent={false}
      initialPose="show"
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <div css={[sidebarItemStyles, labelItemStyles]}>
        <div css={sketchDataContainerStyles}>
          <div>
            <MetaDataTooltip tooltip={<SketchDataToolTipContent title={sketchName} />} tooltipPosition="bottom" css={metaDataTooltipStyles}>
              <div css={sketchDataTitleStyles}>
                Sketch Name
              </div>
              <div css={[sketchItemTextStyles, sketchDataContentStyles]} style={{ textAlign: 'justify' }}>
                {sketchName}
              </div>
            </MetaDataTooltip>
          </div>
          <div>
            <MetaDataTooltip tooltip={<SketchDataToolTipContent title={orderId} />} tooltipPosition="bottom" css={metaDataTooltipStyles}>
              <div css={sketchDataRightTitleStyles}>
                Order Number
              </div>
              <div css={[sketchItemTextStyles, sketchDataContentStyles]}>
                {orderId}
              </div>
            </MetaDataTooltip>
          </div>
        </div>

        <div css={sketchDataContainerStyles}>
          <div>
            <MetaDataTooltip tooltip={<SketchDataToolTipContent title={propertyAddress} />} tooltipPosition="bottom" css={metaDataTooltipStyles}>
              <div css={sketchDataTitleStyles}>
                Property Address
              </div>
              <div css={[sketchItemTextStyles, sketchDataContentStyles]} style={{ textAlign: 'justify' }}>
                {propertyAddress}
              </div>
            </MetaDataTooltip>
          </div>
          <div>
            <MetaDataTooltip tooltip={<SketchDataToolTipContent title={lastModifiedDate} />} tooltipPosition="bottom" css={metaDataTooltipStyles}>
              <div css={sketchDataRightTitleStyles}>
                Last Modified Date
              </div>
              <div css={[sketchItemTextStyles, sketchDataContentStyles]} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {lastModifiedDate}
              </div>
            </MetaDataTooltip>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    sketches: sketchesPersistenceSelectors.getSketches(state),
  }),
  {
    loadSketch: sketchPersistenceActions.loadSketch,
  }
)(SearchSketchItem);
