import { LogLevel } from 'types/log';
import { Log, FromSketchMessageType } from 'types/sketch';
import { getWebView } from './inspection';

export default function log(level: LogLevel, ...args: Parameters<typeof console.log>): void {
  const webView = getWebView();
  if (webView) {
    let logMessage: Log;
    try {
      logMessage = {
        type: FromSketchMessageType.Log,
        level,
        message: JSON.stringify(args),
      };
    } catch (err) {
      logMessage = {
        type: FromSketchMessageType.Log,
        level: LogLevel.error,
        message: `Error while logging message: ${err.message}`,
      };
    }
    webView.postMessage(JSON.stringify(logMessage));
  } else {
    console[level](...args); // eslint-disable-line no-console
  }
}
