import styled from '@emotion/styled';
import posed from 'react-pose';

import { modalStyles } from 'components/modal/styles';
import { appearFromLeft } from 'components/animations';
import { sketchConfig } from 'config/sketchConfig';

interface SidebarProps {
  readonly top?: number;
  readonly left?: number;
}

export const Sidebar = styled.div<SidebarProps>(modalStyles, ({ top = 0, left }) => ({
  top,
  left,
  bottom: 0,
  width: sketchConfig.sidebarWidth,
}));

export default posed(Sidebar)(appearFromLeft);
