
import { scaleConfig } from './scaleConfig';

interface GeometryConfig {
  getExteriorLineWidth: (zoomInPercent: number) => number;
  getInteriorLineWidth: (zoomInPercent: number) => number;
  movePointer: any;
  origin: any;
}

export const geometryConfig: GeometryConfig = {
  /** Line Width of Exterior walls */
  /**
 * Size of point
 * @param zoomInPercent the current zoom in %
 * @returns size
 */
  getExteriorLineWidth: (zoomInPercent: number): number => scaleConfig.getScale(10, 2, zoomInPercent),

  /** Thickness of interior walls */
  getInteriorLineWidth: (zoomInPercent: number): number => scaleConfig.getScale(4, 1, zoomInPercent),

  movePointer: {
    offset: 13, // size + (thickness / 2)
    size: 8,
  },

  /** Origin of coordinate system */
  origin: { x: 0, y: 0 },
};
