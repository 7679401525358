import Immutable from 'immutable';

import { CoordinatePoint } from 'types/point';
import { LabelOrientationType, LabelBaselineType } from 'types/labelOrientationType';
import { polygonBound } from 'helpers/polygonBound';
import { Angle, addCircular } from 'helpers/rotation';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';

export const getLabelOrientation = (angle: number) => (directionFromCenter: CoordinatePoint): LabelOrientationType => {
  // we can collapse negative angles (keeping same slope) to simplify things
  const halfAngle = angle < Angle.HALF ? angle + Math.PI : angle;
  if (halfAngle === Angle.HALF || halfAngle === Angle.FULL) {
    return directionFromCenter.y < 0
      ? LabelOrientationType.HORIZONTAL_ABOVE
      : LabelOrientationType.HORIZONTAL_BELOW;
  }
  if (halfAngle === Angle.THREE_QUARTER) {
    return directionFromCenter.x < 0
      ? LabelOrientationType.VERTICAL_LEFT
      : LabelOrientationType.VERTICAL_RIGHT;
  }
  if (halfAngle > Angle.THREE_QUARTER) {
    return directionFromCenter.x > 0 || directionFromCenter.y > 0
      ? LabelOrientationType.UP_DIAGONAL_BELOW_RIGHT
      : LabelOrientationType.UP_DIAGONAL_ABOVE_LEFT;
  }
  /* (halfAngle < THREE_QUARTER) */
  return directionFromCenter.x > 0 || directionFromCenter.y < 0
    ? LabelOrientationType.DOWN_DIAGONAL_ABOVE_RIGHT
    : LabelOrientationType.DOWN_DIAGONAL_BELOW_LEFT;
};

const getPossiblePositions = (orientation: LabelOrientationType): CoordinatePoint[] => {
  switch (orientation) {
    case LabelOrientationType.HORIZONTAL_BELOW:
      return [{ x: 0, y: 1 }, { x: 0, y: -1 }];
    case LabelOrientationType.VERTICAL_LEFT:
      return [{ x: -1, y: 0 }, { x: 1, y: 0 }];
    case LabelOrientationType.VERTICAL_RIGHT:
      return [{ x: 1, y: 0 }, { x: -1, y: 0 }];
    case LabelOrientationType.UP_DIAGONAL_ABOVE_LEFT:
      return [{ x: -1, y: -1 }, { x: 1, y: 1 }];
    case LabelOrientationType.UP_DIAGONAL_BELOW_RIGHT:
      return [{ x: 1, y: 1 }, { x: -1, y: -1 }];
    case LabelOrientationType.DOWN_DIAGONAL_ABOVE_RIGHT:
      return [{ x: 1, y: -1 }, { x: -1, y: 1 }];
    case LabelOrientationType.DOWN_DIAGONAL_BELOW_LEFT:
      return [{ x: -1, y: 1 }, { x: 1, y: -1 }];
    case LabelOrientationType.HORIZONTAL_ABOVE:
      return [{ x: 0, y: -1 }, { x: 0, y: 1 }];
    default: throw new UnreachableCaseError(orientation);
  }
};

export const getBestPosition = (
  orientation: LabelOrientationType, centerOfEdge: CoordinatePoint, polygon: Immutable.List<CoordinatePoint>,
): CoordinatePoint => {
  const position = getPossiblePositions(orientation);
  const defaultPosition = position[0];
  const inside = polygonBound(polygon, centerOfEdge.x + defaultPosition.x, centerOfEdge.y + defaultPosition.y);
  return (inside)
    ? position[1]
    : defaultPosition;
};

export const getLabelBaseline = (orientation: LabelOrientationType): LabelBaselineType => {
  switch (orientation) {
    case LabelOrientationType.HORIZONTAL_ABOVE:
    case LabelOrientationType.VERTICAL_LEFT:
    case LabelOrientationType.UP_DIAGONAL_ABOVE_LEFT:
    case LabelOrientationType.DOWN_DIAGONAL_ABOVE_RIGHT:
      return LabelBaselineType.ABOVE;
    case LabelOrientationType.HORIZONTAL_BELOW:
    case LabelOrientationType.VERTICAL_RIGHT:
    case LabelOrientationType.UP_DIAGONAL_BELOW_RIGHT:
    case LabelOrientationType.DOWN_DIAGONAL_BELOW_LEFT:
      return LabelBaselineType.BELOW;
    default: throw new UnreachableCaseError(orientation);
  }
};

const EIGHTH = Math.PI / 4;
export const getBestRotation = (orientation: LabelOrientationType, angle: number): number => {
  switch (orientation) {
    case LabelOrientationType.HORIZONTAL_ABOVE:
    case LabelOrientationType.HORIZONTAL_BELOW:
      return Angle.HALF;
    case LabelOrientationType.VERTICAL_LEFT:
    case LabelOrientationType.VERTICAL_RIGHT:
      return Angle.QUARTER;
    case LabelOrientationType.UP_DIAGONAL_BELOW_RIGHT:
    case LabelOrientationType.UP_DIAGONAL_ABOVE_LEFT:
      return angle > Angle.HALF ? addCircular(angle, Math.PI) : angle;
    case LabelOrientationType.DOWN_DIAGONAL_ABOVE_RIGHT:
    case LabelOrientationType.DOWN_DIAGONAL_BELOW_LEFT:
      return (
        (angle > Angle.NONE && angle < Angle.NONE + EIGHTH)
        || (angle < Angle.THREE_QUARTER && angle > Angle.THREE_QUARTER - EIGHTH)
      )
        ? addCircular(angle, Math.PI)
        : angle;
    default: throw new UnreachableCaseError(orientation);
  }
};
