import { transparentize } from 'helpers/colorTransforms';
import { aciColors } from 'figma/styles/acistylecolors';

export const colors = {
  none: 'transparent',
  white: '#FFF',
  black: '#000',
  base: '#04104C',
  red: '#ED3870',
  blue: '#0870FD',
  blueDark: '#305E9D',
  bluishGray: '#FAFCFE',
  yellow: '#FFCE00',
  green: '#11A34D',
  pink: '#FF29CF',
  text: '#1A244F',
  hovered: '#848EA8',
  highlighted: '#D5DEFB',
  shadow: 'rgba(26, 36, 79, 0.16)',
};

export const grid = {
  small: '#EBF0FD',
  light: '#D9E2FB',
  dark: '#AAAAAA',
  darkSmall: '#CCCCCC',
};

export const walls = {
  base: colors.base,
  interior: colors.base,
  hovered: colors.hovered,
  selected: colors.highlighted,
};

export const points = {
  base: colors.white,
  hovered: '#F5DEFF',
  selected: colors.highlighted,
};

export const area = {
  base: colors.white,
  hovered: '#F7FAFF',
  selected: '#F6FAFF',
};

export const positionedLabels = {
  base: colors.base,
  hovered: colors.blueDark,
  selected: '#009AFF',
};

export const lengthLabels = {
  base: colors.text,
};

export const arrows = {
  base: colors.highlighted,
};

export const autoFinishButton = {
  base: transparentize(colors.blue, 0.1),
  stroke: colors.highlighted,
};

export const drawHead = {
  base: transparentize(colors.green, 0.15),
  stroke: colors.highlighted,
};

export const selection = {
  base: '#949AA3',
  background: colors.bluishGray,
  transparent: colors.none,
  white: colors.white,
  active: colors.blue,
};

export const toolbar = {
  background: colors.white,
  border: '#DBDDE1',
  button: {
    background: colors.none,
    backgroundHover: '#EFF1FC',
    text: colors.text,
    textActive: aciColors.red500,
  },
};

export const numpad = {
  base: colors.white,
  border: transparentize('#396AE6', 0.46),
  button: {
    base: '#F2F3F5',
    text: '#010957',
    active: transparentize('#F2F3F5', 0.5),
    numPadActive: transparentize('#CCCCCC', 0.7),
  },
};

export const sidebar = {
  itemButton: {
    base: colors.none,
    hovered: transparentize(colors.white, 0.18),
    active: transparentize(colors.white, 0.36),
  },
  headerButton: {
    base: colors.none,
    hovered: transparentize(colors.white, 0.18),
    active: transparentize(colors.white, 0.36),
  },
  item: {
    base: colors.white,
    hovered: colors.bluishGray,
    color: colors.text,
    colorActive: colors.blue,
    metaHighlight: '#f3f6fa',
  },
};

export const menu = {
  listItem: {
    hover: colors.shadow,
    border: colors.bluishGray,
    text: colors.text,
  },
};

export const modal = {
  header: {
    bg: colors.blueDark,
    color: colors.white,
  },
  headerButton: {
    base: colors.none,
    hovered: transparentize(colors.white, 0.18),
    active: transparentize(colors.white, 0.36),
    selected: transparentize(colors.white, 0.85),
    colorSelected: colors.blueDark,
    selectedHovered: colors.white,
  },
  button: {
    base: '#3461DE',
    hovered: '#3A6CF8',
    color: colors.white,
  },
  table: {
    base: colors.white,
  },
  base: colors.bluishGray,
  shadow: colors.shadow,
  backdrop: transparentize('#141C32', 0.5),
};
