import Immutable from 'immutable';
import {
  all, takeEvery, put, select, call,
} from 'redux-saga/effects';
import { createAction } from 'typesafe-actions';
import { toast } from 'react-toastify';

import {
  ToSketchMessage, FromSketchMessage, ToSketchMessageType, FromSketchMessageType,
} from 'types/sketch';
import { getWebView } from 'helpers/inspection';
import { AreaType } from 'types/areaType';
import { actions as sketchPersistenceActions } from 'ducks/persistence/sketchPersistence';
import { selectors as areaTypesSelectors } from 'ducks/model/areaTypes';
import { actions as editModeActions } from 'ducks/editMode';
import { loadSketch } from 'helpers/save/loadSketch';
import { ApiSketch } from 'types/api/apiSketch';
import { saveSketch } from 'helpers/save/saveSketch';

// Action Creators
export const actions = {
  fromInspectionMessage: createAction('inspection/fromInspectionMessage')<ToSketchMessage>(),
  toInspectionMessage: createAction('inspection/toInspectionMessage')<FromSketchMessage>(),
  saveSketch: createAction('inspection/saveSketch')<string>(),
};

const unreachableCaseSwallow = (arg: never): never => arg;

// Sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* fromInspectionMessage({ payload }: ReturnType<typeof actions.fromInspectionMessage>) {
    switch (payload.type) {
      case ToSketchMessageType.Pong: {
        toast(`Pong Message from Inspection ${JSON.stringify(payload)}`, {
          type: toast.TYPE.INFO,
        });
        return;
      }
      case ToSketchMessageType.SetSketchData: {
        const rootState = yield select();
        const areaTypes: Immutable.Map<string, AreaType> = areaTypesSelectors.getAllAreaTypes(rootState);
        const model = loadSketch(payload.modelJson, areaTypes);

        yield put(sketchPersistenceActions.replaceModel(model));
        yield put(sketchPersistenceActions.setChanged(false));
        yield put(editModeActions.switchToEdit());
        return;
      }
      default: unreachableCaseSwallow(payload);
    }
  }

  // eslint-disable-next-line require-yield
  function* toInspectionMessage({ payload }: ReturnType<typeof actions.toInspectionMessage>) {
    const webView = getWebView();
    if (webView) {
      webView.postMessage(JSON.stringify(payload));
    }
  }

  function* doSaveSketch({ payload }: ReturnType<typeof actions.saveSketch>) {
    const rootState = yield select();
    const modelJson: ApiSketch = yield call(saveSketch, rootState);
    yield put(actions.toInspectionMessage({
      type: FromSketchMessageType.SaveSketchData,
      modelJson,
      sketchId: payload,
    }));
  }

  return function* saga() {
    yield all([
      takeEvery(actions.fromInspectionMessage, fromInspectionMessage),
      takeEvery(actions.toInspectionMessage, toInspectionMessage),
      takeEvery(actions.saveSketch, doSaveSketch),
    ]);
  };
};
