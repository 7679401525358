import { RootState } from 'reducers/rootReducer';
import { CoordinatePoint, Point } from 'types/point';
import { PayloadAction } from 'types/payloadAction';
import { geometryConfig } from 'config/geometryConfig';

// Actions
const NAME = 'boxSelect';
const START_BOX_SELECT = `${NAME}/START_BOX_SELECT`;
const SET_BOX_SELECT_OBJECT_IDS = `${NAME}/SET_BOX_SELECT_OBJECT_IDS`;
export const SET_BOX_SELECT_END_POINT = `${NAME}/SET_END_POINT`;

// Action Creators
export const actions = {
  startBoxSelect: (startPoint: CoordinatePoint) => ({
    type: START_BOX_SELECT,
    payload: startPoint,
  }),
  setBoxSelectEndPoint: (endPoint: CoordinatePoint) => ({
    type: SET_BOX_SELECT_END_POINT,
    payload: endPoint,
  }),
  setBoxSelectObjectIds: (objectIds: string[]) => ({
    type: SET_BOX_SELECT_OBJECT_IDS,
    payload: objectIds,
  }),
};

export interface BoxSelectState {
  readonly startPoint: Point | CoordinatePoint;
  readonly endPoint: Point | CoordinatePoint;
  readonly objectIds: string[];
}

// Initial State
const initialState: BoxSelectState = {
  startPoint: { ...geometryConfig.origin },
  endPoint: { ...geometryConfig.origin },
  objectIds: [],
};

const startBoxSelectReducer = (state: BoxSelectState, startPoint: Point): BoxSelectState => ({
  ...state,
  startPoint,
  endPoint: startPoint,
});

const setBoxSelectEndPointReducer = (state: BoxSelectState, endPoint: Point): BoxSelectState => ({
  ...state,
  endPoint,
});

const setBoxSelectObjectIdsReducer = (state: BoxSelectState, objectIds: string[]): BoxSelectState => ({
  ...state,
  objectIds,
});

export const reducer = (state: BoxSelectState = initialState, action: PayloadAction): BoxSelectState => {
  switch (action.type) {
    case START_BOX_SELECT:
      return startBoxSelectReducer(state, action.payload);

    case SET_BOX_SELECT_END_POINT:
      return setBoxSelectEndPointReducer(state, action.payload);

    case SET_BOX_SELECT_OBJECT_IDS:
      return setBoxSelectObjectIdsReducer(state, action.payload);

    default:
      return state;
  }
};

// Selectors
const getBoxSelectState = (rootState: RootState): BoxSelectState => rootState.boxSelect;

const getStartPoint = (rootState: RootState): CoordinatePoint => getBoxSelectState(rootState).startPoint;

const getEndPoint = (rootState: RootState): CoordinatePoint => getBoxSelectState(rootState).endPoint;

const getBoxSelectObjectIds = (rootState: RootState): string[] => getBoxSelectState(rootState).objectIds;

export const selectors = {
  getBoxSelectState,
  getStartPoint,
  getEndPoint,
  getBoxSelectObjectIds,
};
