import {
  put, select,
} from 'redux-saga/effects';

import { PositionedSymbol } from 'types/positionedSymbol';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';
import {
  selectors as positionedSymbolsSelectors, actions as positionedSymbolsActions,
} from 'ducks/model/positionedSymbols';
import { actions as pointsActions } from 'ducks/model/points';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* removePositionedSymbol(positionedSymbolId: string) {
  const positionedSymbol: PositionedSymbol = positionedSymbolsSelectors
    .getPositionedSymbolById(yield select(), positionedSymbolId);
  const pageWithSymbolId: string = pagesSelectors.getPageIdWithObject(yield select(), positionedSymbolId)!;

  yield put(pagesActions.removeObject(pageWithSymbolId, positionedSymbolId));

  yield put(pointsActions.remove(positionedSymbol.pointId));
  yield put(positionedSymbolsActions.remove(positionedSymbolId));
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
