import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { gridConfig } from 'config/gridConfig';
import { Box } from 'types/box';
import { selectors as viewportSelectors } from 'ducks/viewport';
import { selectors as settingsSelectors } from 'ducks/settings';

import { isNaN } from 'helpers/utils';
import gridStyles from './styles';

export const GRID_CONTAINER = 'gridContainer';

const GridContainer = styled.rect(
  gridStyles.container,
);

const SmallGridLine = styled.path(
  gridStyles.small,
);

const DarkSmallGridLine = styled.path(
  gridStyles.darkSmall,
);

const LightBigGridLine = styled.path(
  gridStyles.bigLight,
);

const DarkBigGridLine = styled.path(
  gridStyles.bigDark,
);

const smallGridPath = `M ${gridConfig.gridSize} 0 L 0 0 0 ${gridConfig.gridSize}`;
const gridPath = `M ${gridConfig.bigGridSize} 0 L 0 0 0 ${gridConfig.bigGridSize}`;

const GridLineStyles = (setting: string) => {
  switch (setting) {
    case 'off':
      return 'bigGridHide';

    case 'light':
      return 'lightBigGrid';

    case 'dark':
      return 'darkBigGrid';

    default:
      return 'lightBigGrid';
  }
};

interface StateProps {
  readonly viewBox: Box;
  readonly gridLines: string;
}

type Props = StateProps;

const Grid = ({ viewBox, gridLines }: Props): JSX.Element | null => {
  if (!viewBox || isNaN(viewBox.x)) {
    return null;
  }

  return (
    <>
      <defs>
        <pattern
          id="smallGrid"
          width={gridConfig.gridSize}
          height={gridConfig.gridSize}
          patternUnits="userSpaceOnUse"
        >
          <SmallGridLine d={smallGridPath} />
        </pattern>
        <pattern
          id="darkSmallGrid"
          width={gridConfig.gridSize}
          height={gridConfig.gridSize}
          patternUnits="userSpaceOnUse"
        >
          <DarkSmallGridLine d={smallGridPath} />
        </pattern>
        <pattern
          id="lightBigGrid"
          width={gridConfig.bigGridSize}
          height={gridConfig.bigGridSize}
          patternUnits="userSpaceOnUse"
        >
          <rect width={gridConfig.bigGridSize} height={gridConfig.bigGridSize} fill="url(#smallGrid)" />
          <LightBigGridLine d={gridPath} />
        </pattern>
        <pattern
          id="darkBigGrid"
          width={gridConfig.bigGridSize}
          height={gridConfig.bigGridSize}
          patternUnits="userSpaceOnUse"
        >
          <rect width={gridConfig.bigGridSize} height={gridConfig.bigGridSize} fill="url(#darkSmallGrid)" />
          <DarkBigGridLine d={gridPath} />
        </pattern>
        <pattern
          id="bigGridHide"
          width={gridConfig.bigGridSize}
          height={gridConfig.bigGridSize}
          patternUnits="userSpaceOnUse"
        >
          <rect width={gridConfig.bigGridSize} height={gridConfig.bigGridSize} fill="white" />
        </pattern>
      </defs>
      <GridContainer
        id={GRID_CONTAINER}
        x={viewBox.x}
        y={viewBox.y}
        width={viewBox.width}
        height={viewBox.height}
        fill={`url(#${GridLineStyles(gridLines)})`}
      />
    </>
  );
};

export default connect((state: RootState): StateProps => ({
  viewBox: viewportSelectors.getViewBox(state),
  gridLines: settingsSelectors.gridLines(state),
}))(Grid);
