import {
  all, takeLatest, select, put,
} from 'redux-saga/effects';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { CoordinatePoint } from 'types/point';
import { parseDrawInput } from 'helpers/draw/parseDrawInput';
import {
  START_DRAW, FINISH_SEGMENT,
  selectors as drawSelectors,
  actions as drawActions,
  DrawState,
} from 'ducks/draw/draw';
import { isTooShort } from 'helpers/model/wallPoints';
import { selectors as settingsSelectors } from 'ducks/settings';
import { divideBy } from 'helpers/utils';
import { Direction } from 'types/direction';
import { selectors as viewportSelectors } from 'ducks/viewport';

const NAME = 'drawWithKeypad';

const ADD_DRAW_INPUT = `${NAME}/ADD_DRAW_INPUT`;
const FINISH_DRAW_INPUT = `${NAME}/FINISH_DRAW_INPUT`;
const RESET_DRAW_INPUT = `${NAME}/RESET_DRAW_INPUT`;
const RESET_CURRENT_VALUE = `${NAME}/RESET_CURRENT_VALUE`;
const RESET_CURRENT_ANGLE_VALUE = `${NAME}/RESET_CURRENT_ANGLE_VALUE`;
const RESET_CURRENT_DIRECTION = `${NAME}/RESET_CURRENT_DIRECTION`;
const REMOVE_LAST_INPUT = `${NAME}/REMOVE_LAST_INPUT`;
const SET_INPUT_SEQUENCE = `${NAME}/SET_INPUT_SEQUENCE`;
const SET_ANGLE_INPUT = `${NAME}/SET_ANGLE_INPUT`;
const SET_CURRENT_VALUE = `${NAME}/SET_CURRENT_VALUE`;
const SET_CURRENT_ANGLE_VALUE = `${NAME}/SET_CURRENT_ANGLE_VALUE`;
const SET_CURRENT_DIRECTION = `${NAME}/SET_CURRENT_DIRECTION`;

// Action Creators
export const actions = {
  addDrawInput: (inputKey: string) => ({
    type: ADD_DRAW_INPUT,
    payload: inputKey,
  }),
  finishDrawInput: () => ({
    type: FINISH_DRAW_INPUT,
  }),
  resetDrawInput: () => ({
    type: RESET_DRAW_INPUT,
  }),
  resetCurrentValue: () => ({
    type: RESET_CURRENT_VALUE,
  }),
  resetCurrentAngleValue: () => ({
    type: RESET_CURRENT_ANGLE_VALUE,
  }),
  resetCurrentDirection: () => ({
    type: RESET_CURRENT_DIRECTION,
  }),
  removeLastInput: () => ({
    type: REMOVE_LAST_INPUT,
  }),
  setInputSequence: (inputKeySeqeunce: string[]) => ({
    type: SET_INPUT_SEQUENCE,
    payload: inputKeySeqeunce
  }),
  setAngleInput: () => ({
    type: SET_ANGLE_INPUT,
  }),
  setCurrentValue: (inputKey: string) => ({
    type: SET_CURRENT_VALUE,
    payload: inputKey,
  }),
  setCurrentAngleValue: (inputKey: string) => ({
    type: SET_CURRENT_ANGLE_VALUE,
    payload: inputKey,
  }),
  setCurrentDirection: (inputKey: string) => ({
    type: SET_CURRENT_DIRECTION,
    payload: inputKey,
  }),
};

export interface DrawKeypadState {
  readonly inputKeySequence: string[];
  readonly currentValue: string;
  readonly currentAngleValue: string;
  readonly currentDirection: Direction | undefined;
  readonly isAngleInputEnabled: boolean;
}

// Initial State
const initialState: DrawKeypadState = {
  inputKeySequence: [],
  currentValue: '',
  currentAngleValue: '0',
  currentDirection: undefined,
  isAngleInputEnabled: false
};

// Reducers
const addDrawInputReducer = (state: DrawKeypadState, inputKey: Direction): DrawKeypadState => {
  const isLastInputDirectional = !+state.inputKeySequence[state.inputKeySequence.length - 1] && +inputKey === 0;
  if (!+inputKey && isLastInputDirectional) {
    state.inputKeySequence.splice(-1, 1, inputKey);
    return {
      ...state,
      inputKeySequence: [
        ...state.inputKeySequence,
      ],
    };
  }

  return {
    ...state,
    inputKeySequence: [
      ...state.inputKeySequence,
      inputKey,
    ],
  };
};

const resetDrawInputReducer = (state: DrawKeypadState): DrawKeypadState => ({
  ...state,
  inputKeySequence: [],
  currentValue: '',
  currentDirection: undefined,
  currentAngleValue: '0',
  isAngleInputEnabled: false
});

const resetCurrentValueReducer = (state: DrawKeypadState): DrawKeypadState => ({
  ...state,
  currentValue: '',
});

const resetCurrentAngleValueReducer = (state: DrawKeypadState): DrawKeypadState => ({
  ...state,
  currentAngleValue: '',
});

const resetCurrentDirectionReducer = (state: DrawKeypadState): DrawKeypadState => ({
  ...state,
  currentDirection: undefined,
});

const setInputSequenceReducer = (state: DrawKeypadState, inputKeySequence: string[]): DrawKeypadState => ({
  ...state,
  inputKeySequence
});

const setAngleInputReducer = (state: DrawKeypadState): DrawKeypadState => ({
  ...state,
  isAngleInputEnabled: !state.isAngleInputEnabled,
});

const setCurrentValueReducer = (state: DrawKeypadState, inputKey: string): DrawKeypadState => ({
  ...state,
  currentValue: inputKey
});

const setCurrentAngleValueReducer = (state: DrawKeypadState, inputKey: string): DrawKeypadState => ({
  ...state,
  currentAngleValue: inputKey
});

const setCurrentDirectionReducer = (state: DrawKeypadState, inputKey: Direction): DrawKeypadState => {
  const isLastInputDirectional = !+state.inputKeySequence[state.inputKeySequence.length - 1];
  if (inputKey && !+inputKey && isLastInputDirectional) {
    state.inputKeySequence.splice(-1, 1, inputKey);
    return {
      ...state,
      inputKeySequence: [
        ...state.inputKeySequence,
      ],
      currentDirection: inputKey,
    };
  }

  return {
    ...state,
    inputKeySequence: [
      ...state.inputKeySequence,
      inputKey
    ],
    currentDirection: inputKey,
  };
};

export const reducer = (state: DrawKeypadState = initialState, action: PayloadAction): DrawKeypadState => {
  switch (action.type) {
    case ADD_DRAW_INPUT:
      return addDrawInputReducer(state, action.payload);

    case RESET_DRAW_INPUT:
      return resetDrawInputReducer(state);

    case RESET_CURRENT_VALUE:
      return resetCurrentValueReducer(state);

    case RESET_CURRENT_ANGLE_VALUE:
      return resetCurrentAngleValueReducer(state);

    case RESET_CURRENT_DIRECTION:
      return resetCurrentDirectionReducer(state);

    case SET_INPUT_SEQUENCE:
      return setInputSequenceReducer(state, action.payload);

    case SET_ANGLE_INPUT:
      return setAngleInputReducer(state);

    case SET_CURRENT_VALUE:
      return setCurrentValueReducer(state, action.payload);

    case SET_CURRENT_ANGLE_VALUE:
      return setCurrentAngleValueReducer(state, action.payload);

    case SET_CURRENT_DIRECTION:
      return setCurrentDirectionReducer(state, action.payload);

    default:
      return state;
  }
};

// Selectors
const getDrawInputState = (rootState: RootState): DrawKeypadState => rootState.drawKeypad;

const getDrawInputSequence = (rootState: RootState): string[] => rootState.drawKeypad.inputKeySequence;

const getCurrentValue = (rootState: RootState): string => rootState.drawKeypad.currentValue;

const getCurrentDirection = (rootState: RootState): string | undefined => rootState.drawKeypad.currentDirection;

const getIsAngleInputEnabled = (rootState: RootState): boolean => rootState.drawKeypad.isAngleInputEnabled;

const getCurrentAngleValue = (rootState: RootState): string => rootState.drawKeypad.currentAngleValue;

export const selectors = {
  getDrawInputState,
  getDrawInputSequence,
  getCurrentValue,
  getCurrentDirection,
  getIsAngleInputEnabled,
  getCurrentAngleValue,
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doAddDrawInput(action: any) {
    const { payload } = action;
    const drawState: DrawState = drawSelectors.getDrawState(yield select());
    const drawInputState: DrawKeypadState = selectors.getDrawInputState(yield select());

    if (drawInputState.inputKeySequence && Number.isNaN(parseInt(drawInputState.inputKeySequence[0]))) {
      drawInputState.inputKeySequence.pop();
      return;
    }

    const { isAngleInputEnabled } = drawInputState;
    const uom = settingsSelectors.getUnitOfMeasure(yield select());
    const currentGridSize = settingsSelectors.getGridSizeInFeet(yield select());
    const deltaPoint = parseDrawInput(drawInputState.inputKeySequence, uom);
    const endPoint: CoordinatePoint = {
      x: drawState.startPoint.x + divideBy(currentGridSize)(deltaPoint.x),
      y: drawState.startPoint.y + divideBy(currentGridSize)(deltaPoint.y),
    };

    if (!isAngleInputEnabled) {
      yield put(drawActions.setEndPointWithoutSnapping(endPoint, false));
    }

    if (isAngleInputEnabled && (+payload || +payload === 0)) {
      const { currentAngleValue } = drawInputState;
      if (currentAngleValue.length > 2) {
        const newCurrentAngleValue = currentAngleValue.substring(1) + payload;
        yield put(actions.setCurrentAngleValue(newCurrentAngleValue));
      } else {
        const newCurrentAngleValue = drawInputState.currentAngleValue + payload;
        yield put(actions.setCurrentAngleValue(newCurrentAngleValue));
      }
    }

    if (((+payload || +payload === 0) || payload === '.' || payload === ',') && !isAngleInputEnabled) {
      const currentValue = drawInputState.currentValue + payload;
      yield put(actions.setCurrentValue(currentValue));
    }

    if (!+payload && +payload !== 0 && payload !== '.' && payload !== ',') {
      yield put(actions.setCurrentDirection(payload));
    }

    if (payload !== '.' && drawInputState.inputKeySequence && drawInputState.inputKeySequence.length >
     1 && Number.isNaN(parseInt(drawInputState.inputKeySequence[drawInputState.inputKeySequence.length - 1], 10))) {
      // while(drawInputState.inputKeySequence.length) drawInputState.inputKeySequence.pop();
      yield put(actions.setCurrentValue(''));
      yield put(actions.setCurrentDirection(undefined));
    }

    if (payload === '.' && drawInputState.currentValue !== null && (drawInputState.currentValue.toString().match(/\./g) || []).length > 0) {
      yield put(actions.setCurrentValue(''));
      yield put(actions.setCurrentDirection(undefined));
    }
  }

  function* doFinishDrawInput() {
    const drawState: DrawState = drawSelectors.getDrawState(yield select());
    if (!isTooShort(drawState.startPoint, drawState.endPoint, viewportSelectors.getZoomInPercent(yield select()))) {
      yield put(drawActions.finishSegment());
    }
  }

  function* doResetDrawInput() {
    yield put(actions.resetDrawInput());
  }

  function* doRemoveLastInput() {
    const rootState: RootState = yield select();
    const drawInputState: DrawKeypadState = selectors.getDrawInputState(rootState);
    const {
      isAngleInputEnabled, currentAngleValue, currentValue, inputKeySequence
    } = drawInputState;

    const newCurrentAngleValue = currentAngleValue.slice(0, -1);
    if (newCurrentAngleValue === '' && isAngleInputEnabled) {
      yield put(actions.setAngleInput());
      yield put(drawActions.resetDrawPoints());
      return;
    }

    const poppedKeyInput = inputKeySequence.pop();

    if (isAngleInputEnabled) {
      yield put(actions.resetCurrentAngleValue());
      yield put(actions.addDrawInput(newCurrentAngleValue));
    } else if (!isAngleInputEnabled && poppedKeyInput && (+poppedKeyInput! || +poppedKeyInput === 0)) {
      const newCurrentValue = currentValue.slice(0, -1);
      yield put(actions.resetCurrentValue());
      yield put(actions.addDrawInput(newCurrentValue));
    } else {
      yield put(actions.resetCurrentDirection());
    }

    if (poppedKeyInput !== '') {
      yield put(actions.setInputSequence(inputKeySequence));
    }

    yield put(drawActions.resetDrawPoints());
  }

  return function* saga() {
    yield all([
      takeLatest(START_DRAW, doResetDrawInput),
      takeLatest(FINISH_SEGMENT, doResetDrawInput),
      takeLatest(ADD_DRAW_INPUT, doAddDrawInput),
      takeLatest(FINISH_DRAW_INPUT, doFinishDrawInput),
      takeLatest(REMOVE_LAST_INPUT, doRemoveLastInput),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
