import { geometryConfig } from 'config/geometryConfig';
import { zoomConfig } from 'config/zoomConfig';
import { Box } from 'types/box';
import { CoordinatePoint } from 'types/point';
import { Dimensions } from 'types/dimensions';

export const zoomViewBox = (
  oldViewBox: Box, boxSize: Dimensions, location: CoordinatePoint, zoomIncrement: number,
): Box => {
  const scale = 1 + zoomConfig.zoomIncrementSize * zoomIncrement;

  const newViewBoxSize = {
    width: boxSize.width * scale,
    height: boxSize.height * scale,
  };

  const resizeRatio = newViewBoxSize.width / oldViewBox.width;

  return {
    x: location.x + (oldViewBox.x - location.x) * resizeRatio,
    y: location.y + (oldViewBox.y - location.y) * resizeRatio,
    ...newViewBoxSize,
  };
};

const getLimitedZoomIncrements = (zoomIncrements: number): number => {
  if (zoomIncrements > zoomConfig.maxZoomIncrements) {
    return zoomConfig.maxZoomIncrements;
  }
  if (zoomIncrements < zoomConfig.minZoomIncrements) {
    return zoomConfig.minZoomIncrements;
  }
  return zoomIncrements;
};

export const getZoomIncrementsByBox = (boxSize: Dimensions, dimensionsToFit: Dimensions): number => {
  const scaleWidth = dimensionsToFit.width / boxSize.width;
  const scaleHeight = dimensionsToFit.height / boxSize.height;
  const scale = Math.max(scaleWidth, scaleHeight);
  const newIncrements = Math.round((scale - 1) / zoomConfig.zoomIncrementSize);
  return getLimitedZoomIncrements(newIncrements);
};

export const panViewBox = (
  viewBox: Box, boxSize: Dimensions, origin: CoordinatePoint, toLocation: CoordinatePoint,
): Box => {
  const ratio = viewBox.width / boxSize.width;

  return {
    ...viewBox,
    x: viewBox.x - (toLocation.x - origin.x) * ratio,
    y: viewBox.y - (toLocation.y - origin.y) * ratio,
  };
};

export const setViewBox = (viewBox: Box, toCenter: CoordinatePoint): Box => ({
  ...viewBox,
  x: toCenter.x - viewBox.width / 2,
  y: toCenter.y - viewBox.height / 2,
});

export const initialViewBox = (boxSize: Dimensions): Box => {
  const viewBox = {
    ...geometryConfig.origin,
    ...boxSize,
  };

  return zoomViewBox(viewBox, boxSize, { ...geometryConfig.origin }, zoomConfig.initialZoom);
};

export const updateViewBox = (newBoxSize: Dimensions, oldBoxSize: Dimensions, oldViewBox: Box): Box => {
  const widthRatio = newBoxSize.width / oldBoxSize.width;
  const heightRatio = newBoxSize.height / oldBoxSize.height;
  return {
    ...oldViewBox,
    width: oldViewBox.width * widthRatio,
    height: oldViewBox.height * heightRatio,
  };
};

export const zoomInPercent = (currentZoom: number): number => {
  if (currentZoom <= zoomConfig.initialZoom) {
    const minZoom = Math.abs(zoomConfig.minZoomIncrements);
    return (currentZoom + minZoom) * 100 / (zoomConfig.initialZoom + minZoom);
  }

  return 100
    + (currentZoom - zoomConfig.initialZoom) * 100
    / (zoomConfig.maxZoomIncrements - zoomConfig.initialZoom);
};
