import { Figure, isClosedFigure } from 'types/figure';
import { SelectableObjects } from 'types/selection';
import { getWallsWithPoint, getCenterWall } from 'helpers/model/wallPoints';
import { getFiguresWithWall, compareFiguresByWalls } from 'helpers/model/figureWalls';
import { getSharedPoints, getFigurePoints } from 'helpers/model/figurePoints';
import { findPath } from 'helpers/path/findPath';
import { polygonArea } from 'helpers/polygonArea';
import { isPointToFigureCollision } from 'helpers/collision/figureCollision';
import { WallType } from 'types/wallType';

/**
 * when figure closed it is possible that new walls split some bigger figure to two parts
 * we need to remove from that bigger figure shared walls
 * one way to do it: remove one wall and see if it is exist shortest path, which close figure again.
 * If that path exists - it is new list of wall for that figure.
 * @param selectableObjects
 * @param figureId - figure id which is closed figure
 * @param lastWallId
 */
export const getFiguresForUpdate = (
  selectableObjects: SelectableObjects,
  figureId: string, lastWallId: string,
): Figure[] => {
  // so we closed some new polygon,
  // but potentially we split bigger polygon to two
  // first we want to find that bigger closed polygon, and remove shared walls from bigger polygon

  const updatedFigures: Figure[] = [];
  const { points, walls, figures } = selectableObjects;

  const figure = figures.get(figureId)!;

  // we add additional check
  const c = walls.get(lastWallId)!.wallType !== WallType.CIRCLE ? getCenterWall(points, walls, lastWallId) : { x: 0, y: 0 };

  const sharedPoints = getSharedPoints(walls, points, figures, figureId);

  sharedPoints.forEach((point) => {
    const wallsWithPoint = getWallsWithPoint(walls, point.pointId)
      .filter(wId => !figure.walls.includes(wId));
    wallsWithPoint.forEach((otherWallId) => {
      const otherWall = walls.get(otherWallId)!;
      const otherPointId = otherWall.points
        .find((pId, index) => (pId !== point.pointId) && (index < 2))!;
      let otherFigureIds = getFiguresWithWall(figures, otherWallId);
      otherFigureIds = otherFigureIds.filter(fId => fId !== figureId);
      otherFigureIds.forEach((otherFigureId) => {
        let otherFigure = figures.get(otherFigureId)!;

        if (!isClosedFigure(otherFigure)) {
          // nothing to do it is just non-closed path
          return;
        }

        const figurePoints = getFigurePoints(walls, points, otherFigure);
        if (!isPointToFigureCollision(figurePoints, true, c)) {
          return;
        }

        figure.walls.forEach((figureWallId) => {
          const updatedFigureIndex = updatedFigures.findIndex(fig => fig.figureId === otherFigureId);
          if (updatedFigureIndex !== -1) {
            otherFigure = updatedFigures[updatedFigureIndex];
          }

          const otherFigurePoints = getFigurePoints(walls, points, otherFigure);
          const otherFigureArea = polygonArea(otherFigurePoints);

          // we should find path, but using all that new-figure walls or updating-figure walls
          // because path by other walls could be shorter, but it will be completely different figure
          // we need to find sub-cycle in the updating-figure
          const wallsForPath = walls.filter((wall) => {
            const wId = wall.wallId;
            if (wId === figureWallId) {
              return false;
            }

            return figure.walls.includes(wId) || otherFigure.walls.includes(wId);
          });
          const otherPath = findPath(wallsForPath, point.pointId, otherPointId, [otherWallId]);
          otherPath.push(otherWallId);
          if (otherPath.length > 2
            && !compareFiguresByWalls(otherFigure.walls, otherPath)
            && !compareFiguresByWalls(figure.walls, otherPath)) {
            const updatedFigure = { ...otherFigure, walls: otherPath };
            const otherPathPoints = getFigurePoints(walls, points, updatedFigure);
            const otherPathArea = polygonArea(otherPathPoints);
            if (otherPathArea < otherFigureArea) {
              if (updatedFigureIndex !== -1) {
                updatedFigures[updatedFigureIndex] = updatedFigure;
              } else {
                updatedFigures.push(updatedFigure);
              }
            }
          }
        });
      });
    });
  });

  return updatedFigures;
};
