import { CoordinatePoint } from 'types/point';
import { Keys } from 'helpers/keys';
import { isFinite } from 'helpers/utils';
import { feetToPixels, metersToPixels, UsSize } from 'helpers/pixelsToDistance';

export const parseIntegerNumber = (valueStr: string): number => {
  const valueNumber = parseInt(valueStr, 10);
  if (!isFinite(valueNumber)) {
    return 0;
  }

  return valueNumber;
};

export const parseFloatNumber = (valueStr: string): number => {
  const valueNumber = parseFloat(valueStr);
  if (!isFinite(valueNumber)) {
    return 0;
  }

  return valueNumber;
};

const containsCommaBeforeDot = (value: string): boolean => {
  const commaIndex = value.indexOf(Keys.comma);
  const dotIndex = value.indexOf(Keys.dot);
  return commaIndex >= 0 && (dotIndex < 0 || commaIndex < dotIndex);
};

export const getFeetAndInches = (value: string): UsSize => {
  let feetStr = value;
  let inchesStr = '';
  const index = value.indexOf(Keys.comma);
  if (index !== -1) {
    feetStr = value.substring(0, index);
    inchesStr = value.substring(index + 1);
  }

  return {
    feet: parseIntegerNumber(feetStr),
    inches: parseIntegerNumber(inchesStr),
  };
};

export const parseString = (numberStr: string): UsSize => {
  let feet = parseFloatNumber(numberStr);
  let inches = 0;
  // alternatively user can type 10,8 which means 10 foot 8 inches
  // so we need to convert those numbers separately:
  // i.e. foot to pixels + inches to pixels
  if (containsCommaBeforeDot(numberStr)) {
    ({ feet, inches } = getFeetAndInches(numberStr));
  }

  return { feet, inches };
};

export const parseDrawInput = (inputKeySequence: string[], uom = 'feet'): CoordinatePoint => {
  const d: CoordinatePoint = {
    x: 0,
    y: 0,
  };

  let numberStr = '';
  for (let i = 0; i < inputKeySequence.length; i++) {
    const key = inputKeySequence[i];
    if (Keys.digitKeys.includes(key)) {
      numberStr += key;
    }

    if (Keys.arrowKeys.includes(key)) {
      let pixels;
      if (uom === 'meters') {
        pixels = metersToPixels(+numberStr);
      } else {
        const { feet, inches } = parseString(numberStr);
        pixels = feetToPixels(feet, inches);
      }
      switch (key) {
        case Keys.arrowDown:
          d.y += pixels;
          break;

        case Keys.arrowUp:
          d.y -= pixels;
          break;

        case Keys.arrowLeft:
          d.x -= pixels;
          break;

        case Keys.arrowRight:
          d.x += pixels;
          break;

        default:
          break;
      }

      numberStr = '';
    }
  }
  return d;
};
