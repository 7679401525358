import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { CoordinatePoint } from 'types/point';
import { MeasurementLabelType } from 'types/measurementLabel';
import { distance } from 'helpers/geometry';
import { createMeasurementLabel } from 'helpers/label/measurementLabel';
import { selectors as settingsSelectors } from 'ducks/settings';
import MeasurementLabel from 'components/sketch/MeasurementLabel/MeasurementLabel';
import wallStyles from 'components/sketch/Wall/styles';
import { selectors as viewPortSelectors } from 'ducks/viewport';

interface StateProps {
  readonly unitOfMeasurement: string;
  readonly precision: number;
  readonly gridSize: number;
  readonly zoomInPercent: number;
}

interface InputProps {
  readonly wallPoints: Immutable.List<CoordinatePoint>;
  readonly isAlignmentLine?: boolean;
  readonly hideLine?: boolean;
  readonly hideLabel?: boolean;
}

type Props = InputProps & StateProps;

const SelectedSegmentLine = ({
  wallPoints,
  isAlignmentLine,
  hideLine = false,
  hideLabel = false,
  unitOfMeasurement,
  precision,
  gridSize,
  zoomInPercent,
}: Props): JSX.Element | null => {
  const p1 = wallPoints.get(0)!;
  const p2 = wallPoints.get(1)!;

  if (distance(p1, p2) === 0) {
    return null;
  }

  const styles = wallStyles.get(zoomInPercent);
  const SegmentLine = styled.path(styles.drawSegmentLine);
  const AlignmentLine = styled.path(styles.alignmentLine);

  const Path = isAlignmentLine ? AlignmentLine : SegmentLine;
  const pathData = `M ${wallPoints.map(p => `${p.x} ${p.y}`).join(' L ')}`;

  const measurementLabel: MeasurementLabelType = createMeasurementLabel(
    wallPoints,
    false,
    wallPoints,
    false,
    unitOfMeasurement,
    precision,
    gridSize,
  );

  return (
    <>
      {!hideLine && (<Path d={pathData} />)}
      {!hideLabel && (
        <MeasurementLabel
          text={measurementLabel.text}
          position={measurementLabel.position}
          rotation={measurementLabel.rotation}
        />
      )}
    </>
  );
};

export default connect((state: RootState): StateProps => ({
  unitOfMeasurement: settingsSelectors.getUnitOfMeasure(state),
  precision: settingsSelectors.getPrecision(state),
  gridSize: settingsSelectors.getGridSizeInFeet(state),
  zoomInPercent: viewPortSelectors.getZoomInPercent(state),
}))(SelectedSegmentLine);
