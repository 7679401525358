import {
  all, takeLatest, select, put,
} from 'redux-saga/effects';

import { RootState } from 'reducers/rootReducer';
import { geometryConfig } from 'config/geometryConfig';
import { zoomConfig } from 'config/zoomConfig';
import { Box } from 'types/box';
import { Point, CoordinatePoint } from 'types/point';
import { Dimensions } from 'types/dimensions';
import { PayloadAction } from 'types/payloadAction';
import {
  zoomViewBox,
  panViewBox,
  initialViewBox,
  zoomInPercent,
  setViewBox,
  updateViewBox,
  getZoomIncrementsByBox,
} from 'helpers/viewBox';
import { getMinimalDimensions, getCenterPoint, convertSvgPointCoordinates } from 'helpers/viewport';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { createSelector } from 'reselect';
import { objectEquals } from 'helpers/utils';
import { selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';

// Action Types
const NAME = 'viewport';

const SET_SVG_ELEMENT = `${NAME}/SET_SVG_ELEMENT`;
const RESET_VIEW_BOX = `${NAME}/RESET_VIEW_BOX`;
const ZOOM_VIEW_BOX_BY_INCREMENT = `${NAME}/ZOOM_VIEW_BOX_BY_INCREMENT`;
const ZOOM_VIEW_BOX_BY_DIMENSIONS = `${NAME}/ZOOM_VIEW_BOX_BY_DIMENSIONS`;
const START_PAN = `${NAME}/START_PAN`;
const PAN_VIEW_BOX = `${NAME}/PAN_VIEW_BOX`;
const END_PAN = `${NAME}/END_PAN_BOX`;
const SET_VIEW_BOX = `${NAME}/SET_VIEWBOX`;
const CENTER_ALL = `${NAME}/CENTER_ALL`;
const PAN_TO_CENTER = `${NAME}/PAN_TO_CENTER`;

// Action Creators
export interface ZoomByIncrementPayload {
  readonly location: CoordinatePoint;
  readonly increments: number;
}

export interface ZoomByDimensionsPayload {
  readonly location: CoordinatePoint;
  readonly dimensions: Dimensions;
}

export const actions = {
  setSvgElement: (payload: SVGSVGElement) => ({
    type: SET_SVG_ELEMENT,
    payload,
  }),

  resetViewBox: () => ({
    type: RESET_VIEW_BOX,
  }),

  zoomViewBoxByIncrement: (payload: ZoomByIncrementPayload) => ({
    type: ZOOM_VIEW_BOX_BY_INCREMENT,
    payload,
  }),

  zoomViewBoxByDimension: (payload: ZoomByDimensionsPayload) => ({
    type: ZOOM_VIEW_BOX_BY_DIMENSIONS,
    payload,
  }),

  startPan: (payload: CoordinatePoint) => ({
    type: START_PAN,
    payload,
  }),

  panViewBox: (payload: CoordinatePoint) => ({
    type: PAN_VIEW_BOX,
    payload,
  }),

  setViewBox: (payload: CoordinatePoint) => ({
    type: SET_VIEW_BOX,
    payload,
  }),

  endPan: () => ({
    type: END_PAN,
  }),

  panToCenter: (payload: CoordinatePoint) => ({
    type: PAN_TO_CENTER,
    payload,
  }),

  centerAll: () => ({
    type: CENTER_ALL,
  }),
};

export interface ViewportState {
  readonly viewBox: Box;
  readonly svgSize: Dimensions;
  readonly svgElement: SVGSVGElement | null;
  readonly panOrigin: CoordinatePoint | null;
  readonly viewBoxOrigin: Box | null;
  readonly zoomIncrements: number;
}

const DEFAULT_SVG_SIZE = { width: 0, height: 0 };

// Initial State
const initialState: ViewportState = {
  viewBox: { ...geometryConfig.origin, ...DEFAULT_SVG_SIZE },
  svgSize: DEFAULT_SVG_SIZE,
  svgElement: null,
  panOrigin: null,
  viewBoxOrigin: null,
  zoomIncrements: zoomConfig.initialZoom,
};

// Selectors
const getViewport = (rootState: RootState): ViewportState => rootState.viewport;

const getViewBoxOrigin = (): CoordinatePoint => geometryConfig.origin;

const getSvgSize = createSelector(
  getViewport,
  ({ svgSize }) => svgSize,
);

const getSvgElement = (
  rootState: RootState,
): SVGSVGElement | null => getViewport(rootState).svgElement;

const getViewBox = (rootState: RootState): Box => getViewport(rootState).viewBox;

const isPanning = (state: RootState): boolean => getViewport(state).panOrigin !== null;

const getZoom = (state: RootState): number => getViewport(state).zoomIncrements;

/**
 * return zoom 0% - very small zoom 100% - normal zoom, over 100% - big zoom
 */
const getZoomInPercent = createSelector(
  getZoom, zoomInPercent,
);

export type MousePointToSVGPointFunction = (mousePoint: { clientX: number; clientY: number }) => CoordinatePoint;
type PointToSVGPointFunction = (mousePoint: CoordinatePoint) => CoordinatePoint;

const getPointToSvgPoint = (rootState: RootState): PointToSVGPointFunction =>
  (mousePoint: CoordinatePoint): CoordinatePoint => { // eslint-disable-line implicit-arrow-linebreak
    const svgElement = getSvgElement(rootState)!;
    return convertSvgPointCoordinates(svgElement, mousePoint);
  };

const getMousePointToSvgPoint = (rootState: RootState): MousePointToSVGPointFunction =>
  // eslint-disable-next-line implicit-arrow-linebreak
  (mousePoint) => getPointToSvgPoint(rootState)({ x: mousePoint.clientX, y: mousePoint.clientY });

const getViewCenterPoint = createSelector(
  getViewBox,
  ({
    x, y, width, height,
  }) => ({
    x: x + width / 2,
    y: y + height / 2,
  }),
);

export const selectors = {
  getViewBoxOrigin,
  getSvgSize,
  getViewBox,
  isPanning,
  getMousePointToSvgPoint,
  getPointToSvgPoint,
  getZoomInPercent,
  getSvgElement,
  getViewCenterPoint,
};

// Reducers
const setSvgElementReducer = (state: ViewportState, svgElement: SVGSVGElement): ViewportState => {
  const boundingRect = svgElement.getBoundingClientRect();
  const svgSize = { width: boundingRect.width, height: boundingRect.height };
  const viewBox = state.svgElement ? updateViewBox(svgSize, state.svgSize, state.viewBox) : initialViewBox(svgSize);

  return {
    ...state,
    svgElement,
    svgSize: objectEquals(state.svgSize)(svgSize) ? state.svgSize : svgSize,
    viewBox: objectEquals(state.viewBox)(viewBox) ? state.viewBox : viewBox,
  };
};

const resetViewBoxReducer = (state: ViewportState): ViewportState => ({
  ...state,
  viewBox: initialViewBox(state.svgSize),
  zoomIncrements: zoomConfig.initialZoom,
});

const zoomViewBoxByIncrementReducer = (
  state: ViewportState, { location, increments }: ZoomByIncrementPayload,
): ViewportState => {
  const { viewBox, svgSize, zoomIncrements } = state;
  const newIncrements = zoomIncrements + increments;
  if (newIncrements > zoomConfig.maxZoomIncrements || newIncrements < zoomConfig.minZoomIncrements) {
    return state;
  }
  const newViewBox = zoomViewBox(viewBox, svgSize, location, newIncrements);
  return {
    ...state,
    zoomIncrements: newIncrements,
    viewBox: newViewBox,
  };
};

const zoomViewBoxByDimensionsReducer = (
  state: ViewportState, { location, dimensions }: ZoomByDimensionsPayload,
): ViewportState => {
  const { viewBox, svgSize } = state;

  const newIncrements = getZoomIncrementsByBox(svgSize, dimensions);
  const newViewBox = zoomViewBox(viewBox, svgSize, location, newIncrements);
  return {
    ...state,
    zoomIncrements: newIncrements,
    viewBox: newViewBox,
  };
};

const startPanReducer = (state: ViewportState, panOrigin: CoordinatePoint): ViewportState => ({
  ...state,
  panOrigin,
  viewBoxOrigin: state.viewBox,
});

const panViewBoxReducer = (state: ViewportState, toLocation: CoordinatePoint): ViewportState => {
  const { panOrigin, svgSize, viewBoxOrigin } = state;

  if (viewBoxOrigin === null || panOrigin === null) return state;

  return {
    ...state,
    viewBox: panViewBox(viewBoxOrigin, svgSize, panOrigin, toLocation),
  };
};

const endPanReducer = (state: ViewportState): ViewportState => ({
  ...state,
  panOrigin: null,
  viewBoxOrigin: null,
});

const setViewBoxReducer = (state: ViewportState, toLocation: CoordinatePoint): ViewportState => ({
  ...state,
  viewBox: setViewBox(state.viewBox, toLocation),
});

export const reducer = (state: ViewportState = initialState, action: PayloadAction): ViewportState => {
  switch (action.type) {
    case SET_SVG_ELEMENT:
      return setSvgElementReducer(state, action.payload);

    case RESET_VIEW_BOX:
      return resetViewBoxReducer(state);

    case ZOOM_VIEW_BOX_BY_INCREMENT:
      return zoomViewBoxByIncrementReducer(state, action.payload);

    case ZOOM_VIEW_BOX_BY_DIMENSIONS:
      return zoomViewBoxByDimensionsReducer(state, action.payload);

    case START_PAN:
      return startPanReducer(state, action.payload);

    case PAN_VIEW_BOX:
      return panViewBoxReducer(state, action.payload);

    case SET_VIEW_BOX:
      return setViewBoxReducer(state, action.payload);

    case END_PAN:
      return endPanReducer(state);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doPanToCenter({ payload }: PayloadAction) {
    yield put(actions.setViewBox(payload as CoordinatePoint));
  }

  function* doCenterAll() {
    const points: Point[] = (pointsSelectors.getAllPointsList(yield select())).toArray();
    const numPadOffSet = numpadModalSelectors.isShowing(yield select()) ? 100 : 0;
    console.log(numPadOffSet);
    const centerPoint = getCenterPoint(points);
    centerPoint.y += numPadOffSet;
    yield put(actions.setViewBox(centerPoint));
    const minDimensions = getMinimalDimensions(points);
    minDimensions.height -= 100;
    yield put(actions.zoomViewBoxByDimension({ location: centerPoint, dimensions: minDimensions }));
  }

  return function* saga() {
    yield all([
      takeLatest(PAN_TO_CENTER, doPanToCenter),
      takeLatest(CENTER_ALL, doCenterAll),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
