/* eslint-disable react/jsx-one-expression-per-line */
import { isTouchDevice } from 'helpers/browserDetect';
import React from 'react';

import { sketchConfig } from './sketchConfig';

const canNotGoBackText = (
  <>
    <p>This function has been disabled in our application.</p>
    <hr />
    <p>
      To <b>undo</b> - use the the undo function in the top menu.
    </p>
    {sketchConfig.showLogoutButton && (
      <p>
        {' '}
        To <b>logout</b> - use the logout function at the top.{' '}
      </p>
    )}
  </>
);

const createNewSketchDialogText = (
  <>
    <p>
      Creating a new sketch will erase your progress. Are you sure you want to proceed? This action is <b>not reversible</b>.
    </p>
    <p>
      Any un-saved changes will be <b>erased</b>.
    </p>
  </>
);

export const messages = {
  shortWallDetected: 'Wall is too short',
  wallsCollisionDetected: 'Invalid wall intersection. No exterior wall intersections are permitted.',
  arcCollisionDetected: 'Wall overlaps existing arc-wall, cannot be placed here',
  cannotCloseInTheMiddle: 'Cannot create room, select first point',

  cannotRemoveSharedPoint: 'Cannot remove point shared by more than two walls',
  cannotRemovePointRoomCollapse: 'Cannot remove point, room would collapse',

  cannotAddBluePrintImage: 'Cannot add blue print image',
  cannotResizeSketch: 'Cannot resize sketch',

  sketchLoaded: 'Sketch loaded OK',
  sketchLoadFailed: 'Sketch is not loaded',
  sketchCreated: 'Sketch created OK',
  sketchSaved: 'Sketch saved OK',
  sketchSaveFailed: 'Sketch is not saved',
  loadingAreaTypes: 'Loading Area Types',
  cannotLoadAreaTypes: 'Cannot load Area Types',
  loadingLabels: 'Loading Labels',
  cannotLoadLabels: 'Cannot load Labels',
  cannotLoadSettings: 'Failed load settings from server',
  cannotLoadSketches: 'Failed load sketches from server',
  cannotUpdateSettings: 'Failed to save settings to server',
  loadingSymbols: 'Loading Symbols',
  cannotLoadSymbols: 'Cannot load Symbols',
  cannotLoadSymbolGroups: 'Cannot load Symbol Groups',
  cannotCreateNewSketch: 'Unable to create new sketch',
  cannotExitCreateSketchModal: 'Cannot proceed without creating a new sketch',
  cannotNotUpdateLabel: 'Cannot update label',
  cannotDeleteLabel: 'Cannot delete label',
  cannotPersistLabelOrder: 'Cannot persist label order',

  closeModal: 'Close',
  save: 'Save',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',

  areaBreakdownTitle: 'Area Breakdown',
  areaBreakdownName: 'Structure',
  areaBreakdownCalculation: 'Area',
  areaBreakdownInclude: 'Include in GLA',
  areaBreakdownTotal: 'Total',
  areaBreakdownTotalGLA: 'Total GLA',
  areaBreakdownTotalGBA: 'Total GBA',

  onScreenNumpadTitle: 'Numberpad',
  angleTriggerWithoutDistance: 'Must enter a distance before entering an angle',
  angleTriggerDirectionalRestriction: 'Must enter a clockwise or counter-clockwise direction',
  // angleTriggerAngleValueFirst: 'Must enter angle value before entering the direction',
  angleTriggerWithoutDrawMode: 'Must be in draw mode in order to set angle value',
  angleTriggerWithoutWalls: 'Must be at least one wall in order to create an angle',

  addPage: 'Add',

  resizeSketchTitle: 'Define wall length',
  rescaleGridTitle: 'Define new grid scale',

  areaTypeTitle: 'Add Structure',
  areaTypeToggleGla: 'GLA',
  areaTypeToggleNonGla: isTouchDevice ? 'GLA' : 'non-GLA', // shorter on mobile device to save space

  addLabelTitle: 'Add Label',
  addLabelCreate: 'Create New Label',
  addSubAreaCreate: 'Create New Sub Area',
  editLabelTitle: 'Edit label',
  deleteLabelTitle: 'Delete label',
  deleteLabelDesc: 'Remove label from the list.',
  stampMode: 'Stamp Mode',

  addSymbolTitle: 'Add Symbol',
  addSymbolBack: 'Back',

  addPolygonAreaTitle: 'Add Shape Area',

  createLabelTitle: 'CREATE Label',
  createLabelLabelText: 'Label Text',
  createLabelLabelSave: 'Save to Re-use',

  unableToSaveSketch: 'Unable to save sketch (unavailable in IE11)',
  unableToPreviewSketch: 'Unable to preview sketch (unavailable in IE11)',

  maxPages: 'Maximum number of sketch pages is 6',

  browserWarningTitle: 'We are sorry!',
  browserWarningButton: 'Ok, I understand',

  onScreenPagesTitle: 'Pages',
};

export const printPreview = {
  exitCopy: 'Exit preview',
  printCopy: 'Save Sketch PDF',
  separateGlaTableCopy: 'Separate GLA Table',
};

export const toolbarTopTextConfig = {
  toggleToolbarLeft: 'Toggle Toolbar Left',
  toggleMobileMenu: 'Toggle Mobile Menu',
  undo: 'Undo',
  redo: 'Redo',
  pages: 'Pages',
  calculations: 'Show calculations',
  addImage: 'Add an image to trace',
  createSketch: 'Create new sketch',
  upload: 'Upload',
  quickSave: 'Save to Report',
  search: 'Cloud',
  logout: 'Logout',
  numpad: 'Show Numberpad',
  touchLabels: {
    calculations: 'Calculations',
    addImage: 'Trace image',
    createSketch: 'New',
    numpad: 'Numpad',
  },
};

export const toolbarLeftTextConfig = {
  select: {
    ariaLabel: 'Select Tool',
    touchLabel: 'Select',
    title: 'Select',
    description: 'Use the left mouse button to select a structure, wall or corner.',
    shortcut: 'V',
  },
  multiselect: {
    ariaLabel: 'Multiselect tool',
    touchLabel: 'Multi Select',
    title: 'Multiselect',
    description: 'Use the left mouse button to select or deselect a structure, wall or corner.',
    shortcut: 'Shift',
  },
  boxSelect: {
    ariaLabel: 'Box Select tool',
    touchLabel: 'Box Select',
    title: 'Box Select',
    description: 'Use the left mouse button to create a selection box on the sketch canvas.',
    shortcut: 'Alt',
  },
  sketchTool: {
    ariaLabel: 'Sketch tool',
    touchLabel: 'Exterior',
    title: 'Sketch',
    description: 'Use this tool to sketch the primary floor plan.',
    shortcut: 'D',
  },
  interiorWallsTool: {
    ariaLabel: 'Sketch interior walls tool',
    touchLabel: 'Interior',
    title: 'Sketch Interior Walls',
    description: 'Use this tool to sketch interior walls. These walls do not affect GLA/NGLA.',
    shortcut: 'I',
  },
  label: {
    ariaLabel: 'Add label',
    touchLabel: 'Labels',
    title: 'Add label',
    description: 'Drag a label into the floor plan.',
    shortcut: 'L',
  },
  symbol: {
    ariaLabel: 'Add symbol',
    touchLabel: 'Symbols',
    title: 'Add symbol',
    description: 'Drag a symbol into the floor plan.',
    shortcut: 'S',
  },
  point: {
    ariaLabel: 'Add point',
    touchLabel: 'Point',
    title: 'Add point',
    description: 'Use the mouse to aim and click on a wall to create a new point.',
    shortcut: 'P',
  },
  curveWall: {
    ariaLabel: 'Curve wall',
    touchLabel: 'Curve',
    title: 'Curve wall',
    description: 'Turn a currently selected wall into a curve. Drag the center point to define the curve inclination.',
    shortcut: 'U',
  },
  duplicate: {
    ariaLabel: 'Duplicate',
    touchLabel: 'Duplicate',
    title: 'Duplicate',
    description: 'Duplicates selected figures, symbols or labels.',
    shortcut: 'CMD + D',
  },
  resize: {
    ariaLabel: 'Resize wall',
    touchLabel: 'Resize',
    title: 'Resize wall',
    description: 'Select a wall to resize. All structures will be scaled accordingly.',
  },
  rescale: {
    ariaLabel: 'Rescale Grid',
    touchLabel: 'Rescale',
    title: 'Rescale Grid',
    description: 'Select a wall to rescale the grid by. All structures will be scaled accordingly.',
  },
  center: {
    ariaLabel: 'Center all objects on screen',
    touchLabel: 'Center',
    title: 'Center',
    description: 'Center all objects on screen.',
    shortcut: 'C',
  },
  removeSelected: {
    ariaLabel: 'Remove selected object',
    touchLabel: 'Remove',
    title: 'Remove',
    description: 'Remove currently selected object.',
    shortcut: 'Del',
  },
  removeAll: {
    ariaLabel: 'Remove all',
    touchLabel: 'Rem. all',
    title: 'Reset',
    description: 'Remove everything from sketch area.',
    shortcut: 'Ctrl (⌘) + Del',
  },
  polygon: {
    ariaLabel: 'Draw a shape area',
    touchLabel: 'Shapes',
    title: 'Shapes',
    description: 'Draw a regular shape by defining the radius.',
    shortcut: 'P',
  },
};

export const createNewSketchDialog = {
  title: 'caution',
  text: createNewSketchDialogText,
  submitText: 'create new sketch',
};

export const cannotGoBackDialog = {
  title: 'caution',
  text: canNotGoBackText,
};

export const settingsTextConfig = {
  heading: 'Settings',
  grid: {
    label: 'Grid',
    subLabelGridLines: 'Grid Lines',
    subLabelGridScale: 'Grid Scale',
  },
  measurement: {
    label: 'Measurements',
    subLabelPrecision: 'Precision',
    subLabelUnit: 'Unit',
  },
  numPad: {
    label: 'On-Screen Numpad',
    subLabelShowNumpad: 'Show Numpad',
  },
};

export const sketchSearchModalTextConfig = {
  heading: 'Sketch Files',
  search: 'Search',
  mostRecent: 'Most Recent',
  login: 'Login',
  newSketch: 'Create New Sketch',
  save: 'Save',
  saveAs: 'Save As',
};
