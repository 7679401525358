import {
  takeLatest, all, put,
} from 'redux-saga/effects';

import { RootState } from 'reducers/rootReducer';

import { PayloadAction } from 'types/payloadAction';
import { Page } from 'types/page';

import { getStringTable } from 'helpers/print/stringTable';
import { selectors as settingsSelectors } from 'ducks/settings';

import { actions as editModeActions, } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import { actions as pagesActions } from 'ducks/model/pages';
import { selectors as areaTypesSelectors } from 'ducks/model/areaTypes';

// Action Types
const NAME = 'printPreviewModal';

const SHOW = `${NAME}/SHOW`;
const SHOW_SUCCESS = `${NAME}/SHOW_SUCCESS`;
const HIDE = `${NAME}/HIDE`;
const SET_SEPARATE_AREA_TABLE = `${NAME}/SET_SEPARATE_AREA_TABLE`;
const SET_CURRENT_PREVIEW_PAGE = `${NAME}/SET_CURRENT_PREVIEW_PAGE`;

// State
export interface PrintPreviewModalState {
  readonly isShowing: boolean;
  readonly isSeparateAreaTable: boolean;
  readonly currentPreviewPage?: Page;
}

const initialState: PrintPreviewModalState = {
  isShowing: false,
  isSeparateAreaTable: false,
};

// Action Creators
export const actions = {
  hide: () => ({ type: HIDE }),
  show: () => ({ type: SHOW }),
  showSuccess: () => ({ type: SHOW_SUCCESS }),
  setCurrentPreviewPage: (page: Page) => ({
    type: SET_CURRENT_PREVIEW_PAGE,
    payload: page,
  }),
  setSeparateAreaTable: (isSeparateAreaTable: boolean) => ({
    type: SET_SEPARATE_AREA_TABLE,
    payload: isSeparateAreaTable,
  }),
};

// Selectors
const getState = (rootState: RootState): PrintPreviewModalState => rootState.printPreviewModal;

const isShowing = (rootState: RootState): boolean => getState(rootState).isShowing;

const isSeparateAreaTable = (rootState: RootState): boolean => getState(rootState).isSeparateAreaTable;

const getCurrentPreviewPage = (rootState: RootState): Page | undefined => getState(rootState).currentPreviewPage;

const getStringTableSelector = (rootState: RootState): string[][] | undefined => {
  const selectableObjects = modelSelectors.getSelectableObjects(rootState);
  const areaTypes = areaTypesSelectors.getAreaTypesForDisplay(rootState);
  const precision = settingsSelectors.getPrecision(rootState);
  const unitOfMeasure = settingsSelectors.getUnitOfMeasure(rootState);
  const gridSizeInFeet = settingsSelectors.getGridSizeInFeet(rootState);

  return getStringTable(selectableObjects, areaTypes, precision, unitOfMeasure, gridSizeInFeet);
};

export const selectors = {
  getCurrentPreviewPage,
  isShowing,
  isSeparateAreaTable,
  getStringTable: getStringTableSelector,
};

// Reducers
const showReducer = (state: PrintPreviewModalState): PrintPreviewModalState => ({
  ...state,
  isShowing: true,
});

const hideReducer = (state: PrintPreviewModalState): PrintPreviewModalState => ({
  ...state,
  isShowing: false,
});

// eslint-disable-next-line no-shadow
const setSeparateAreaTableReducer = (state: PrintPreviewModalState, isSeparateAreaTable: boolean): PrintPreviewModalState => ({
  ...state,
  isSeparateAreaTable,
});

const setCurrentPreviewPageReducer = (state: PrintPreviewModalState, page: Page): PrintPreviewModalState => ({
  ...state,
  currentPreviewPage: page,
});

export const reducer = (
  state: PrintPreviewModalState = initialState, action: PayloadAction,
): PrintPreviewModalState => {
  switch (action.type) {
    case HIDE:
      return hideReducer(state);

    case SHOW_SUCCESS:
      return showReducer(state);

    case SET_SEPARATE_AREA_TABLE:
      return setSeparateAreaTableReducer(state, action.payload);

    case SET_CURRENT_PREVIEW_PAGE:
      return setCurrentPreviewPageReducer(state, action.payload);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doShowPrintPreview() {
    yield put(pagesActions.upsertCurrentPageObjects());
    yield put(editModeActions.clearSelection());
    yield put(pagesActions.upsertCurrentPageObjects());
    yield put(actions.showSuccess());
  }

  return function* saga() {
    yield all([
      takeLatest(SHOW, doShowPrintPreview),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
