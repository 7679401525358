import {
  put, select,
} from 'redux-saga/effects';

import { PositionedLabel } from 'types/positionedLabel';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';
import {
  selectors as positionedLabelsSelectors, actions as positionedLabelsActions,
} from 'ducks/model/positionedLabels';
import { actions as pointsActions } from 'ducks/model/points';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* removePositionedLabel(positionedLabelId: string) {
  const positionedLabel: PositionedLabel = positionedLabelsSelectors
    .getPositionedLabelById(yield select(), positionedLabelId);

  const pageWithLabel: string = pagesSelectors.getPageIdWithObject(yield select(), positionedLabelId)!;
  yield put(pagesActions.removeObject(pageWithLabel, positionedLabelId));

  yield put(pointsActions.remove(positionedLabel.pointId));
  yield put(positionedLabelsActions.remove(positionedLabelId));
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
