export const Corners = Object.freeze({
  TOP_LEFT: Object.freeze({ x: -1, y: -1 }) as Corner,
  TOP_RIGHT: Object.freeze({ x: 1, y: -1 }) as Corner,
  BOTTOM_LEFT: Object.freeze({ x: -1, y: 1 }) as Corner,
  BOTTOM_RIGHT: Object.freeze({ x: 1, y: 1 }) as Corner,
});

export interface Corner {
  x: -1 | 1;
  y: -1 | 1;
}
