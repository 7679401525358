import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';

// Action Types
const NAME = 'settingsModal';

const SHOW = `${NAME}/SHOW`;
const HIDE = `${NAME}/HIDE`;

export interface SettingsModalState {
  readonly isShowing: boolean;
}

// Initial State
const initialState: SettingsModalState = {
  isShowing: false,
};

// Action Creators
export const actions = {
  hide: () => ({ type: HIDE }),
  show: () => ({ type: SHOW }),
};

// Selectors
const getSettingsModal = (rootState: RootState): SettingsModalState => rootState.settingsModal;

const isShowing = (rootState: RootState): boolean => getSettingsModal(rootState).isShowing;

export const selectors = {
  isShowing,
};

// Reducers
const showReducer = (state: SettingsModalState): SettingsModalState => ({
  ...state,
  isShowing: true,
});

const hideReducer = (state: SettingsModalState): SettingsModalState => ({
  ...state,
  isShowing: false,
});

export const reducer = (
  state: SettingsModalState = initialState,
  action: PayloadAction,
): SettingsModalState => {
  switch (action.type) {
    case HIDE:
      return hideReducer(state);

    case SHOW:
      return showReducer(state);

    default:
      return state;
  }
};
