import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import posed from 'react-pose';

import { drawHead as colors } from 'config/paletteConfig';
import { pointConfig } from 'config/pointConfig';
import { RootState } from 'reducers/rootReducer';
import { CoordinatePoint } from 'types/point';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as drawSelectors } from 'ducks/draw/draw';
import { circleGlow } from 'components/animations';
import { selectors as viewPointSelectors } from 'ducks/viewport';

const RADIUS_FACTOR = 2;

interface StateProps {
  readonly isDrawingMode: boolean;
  readonly drawEndPoint: CoordinatePoint;
  readonly zoomInPercent: number;
}

type Props = StateProps;

const Circle = styled.circle({
  strokeWidth: 2,
  stroke: colors.stroke,
  fill: colors.base,
  pointerEvents: 'none',
});

const GlowingCircle = posed(Circle)(circleGlow);

const AutoFinishButton: React.FC<Props> = ({ isDrawingMode, drawEndPoint, zoomInPercent }) => isDrawingMode ? (
  <GlowingCircle
    r={pointConfig.getRadiusAutoFinish(zoomInPercent) / RADIUS_FACTOR}
    cx={drawEndPoint.x}
    cy={drawEndPoint.y}
    initialPose="hide"
    pose="show"
  />
) : null;

export default connect(
  (state: RootState): StateProps => ({
    isDrawingMode: editModeSelectors.isDrawingMode(state),
    drawEndPoint: drawSelectors.getEndPoint(state),
    zoomInPercent: viewPointSelectors.getZoomInPercent(state),
  }),
)(AutoFinishButton);
