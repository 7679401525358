import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { pointConfig } from 'config/pointConfig';
import { Point, CoordinatePoint, isPoint } from 'types/point';
import { GeometryType } from 'types/geometryType';
import { MeasurementLabelType } from 'types/measurementLabel';
import { SelectableObjects, CollidedObject } from 'types/selection';
import { getStrongCollisionsWithWall } from 'helpers/collision/getCollidedObject';
import { createMeasurementLabel } from 'helpers/label/measurementLabel';
import { getAdditionalLine, isAdditionalLinesDisplayed } from 'helpers/draw/getHelperLine';
import { isTooShort } from 'helpers/model/wallPoints';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as drawSelectors, DrawState } from 'ducks/draw/draw';
import { selectors as settingsSelectors } from 'ducks/settings';
import MeasurementLabel from 'components/sketch/MeasurementLabel/MeasurementLabel';
import SelectedSegmentAngle from 'components/sketch/SelectedSegment/SelectedSegmentAngle';
import SelectedSegmentLine from 'components/sketch/SelectedSegment/SelectedSegmentLine';
import wallStyles from 'components/sketch/Wall/styles';
import pointStyles from 'components/sketch/FigurePoint/styles';
import { compose } from 'helpers/utils';
import uuid from 'uuid/v4';
import { selectors as viewPortSelectors } from 'ducks/viewport';

interface StateProps {
  readonly isDrawing: boolean;
  readonly selectableObjects: SelectableObjects;
  readonly drawState: DrawState;
  readonly unitOfMeasurement: string;
  readonly precision: number;
  readonly gridSize: number;
  readonly zoomInPercent: number;
}

type Props = StateProps;

interface PathProps {
  readonly error: boolean;
  readonly isInterior: boolean;
}

interface PolygonProps {
  readonly error?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composedCollisionCheck = (selectableObjects: SelectableObjects, zoomInPercent: number): any => compose(
  (collided: CollidedObject) => collided.objectType !== GeometryType.UNKNOWN,
  (lastLine: Immutable.List<Point | CoordinatePoint>) => getStrongCollisionsWithWall(
    selectableObjects, lastLine, [], zoomInPercent
  ),
  (points: (Point | CoordinatePoint)[]) => Immutable.List<Point | CoordinatePoint>(points),
);

const SelectedSegment = ({
  isDrawing, selectableObjects, drawState, unitOfMeasurement, precision, gridSize, zoomInPercent
}: Props): JSX.Element | null => {
  if (!isDrawing) {
    return null;
  }
  const {
    startPoint, endPoint, alignedPoints, drawingInteriorWalls, drawObjectId, drawingPreshapes, preshapePoints,
  } = drawState;

  const lastLine = Immutable.List<Point | CoordinatePoint>([startPoint, endPoint]);
  const checkForCollision = composedCollisionCheck(selectableObjects, zoomInPercent);

  const hasCollision = drawingPreshapes && preshapePoints
    ? preshapePoints.some(
      (p, i) => checkForCollision([p, preshapePoints[i + 1] || preshapePoints[0]]),
    )
    : checkForCollision([startPoint, endPoint]);

  // Interior wall segments can collide with any other other wall segments, exterior or interior
  const intersection = !drawingInteriorWalls && hasCollision;
  const tooShort = isTooShort(startPoint, endPoint, zoomInPercent);

  const pathData = `M ${lastLine.map(p => `${p.x} ${p.y}`).join(' L ')}`;
  const isHelperLinesDisplayed = isAdditionalLinesDisplayed(startPoint, endPoint);

  const measurementLabel: MeasurementLabelType = createMeasurementLabel(
    lastLine,
    false,
    lastLine,
    true,
    unitOfMeasurement,
    precision,
    gridSize,
  );

  const alignedPointsLines = alignedPoints.map(alignedPoint => (
    Immutable.List<Point | CoordinatePoint>([alignedPoint, endPoint])
  ));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Circle = styled.circle<any>(pointStyles.get(zoomInPercent).base);

  const InitialPoint = (p: Point | CoordinatePoint): JSX.Element | null => {
    const { x, y } = p;
    return x && y && !isPoint(p)
      ? <Circle cx={x} cy={y} r={pointConfig.getRadius(zoomInPercent)} />
      : null;
  };

  const styles = wallStyles.get(zoomInPercent);

  const Path = styled.path<PathProps>(
    styles.base,
    styles.drawingBase,
    ({ isInterior }) => isInterior && styles.interior,
    ({ error }) => error && styles.error,
    styles.edit,
  );

  const Polygon = styled.polygon<PolygonProps>(
    styles.base,
    styles.drawingBase,
    ({ error }) => error && styles.error,
    styles.edit,
  );

  return !drawingPreshapes ? (
    <>
      <SelectedSegmentAngle />
      <Path error={intersection || tooShort} d={pathData} isInterior={drawingInteriorWalls} />
      {(isHelperLinesDisplayed || !drawObjectId) && (
        <MeasurementLabel {...measurementLabel} />
      )}
      {isHelperLinesDisplayed && (
        <>
          <SelectedSegmentLine
            wallPoints={getAdditionalLine(startPoint, startPoint.x, endPoint.y)}
          />
          <SelectedSegmentLine
            wallPoints={getAdditionalLine(endPoint, startPoint.x, endPoint.y)}
          />
        </>
      )}
      {
        alignedPointsLines.map(alignedPointLine => {
          const first = alignedPointLine.get(0)!;
          return (
            <SelectedSegmentLine
              key={isPoint(first) ? first.pointId : undefined}
              wallPoints={alignedPointLine}
              isAlignmentLine
            />
          );
        })
      }
      <InitialPoint {...startPoint} />
    </>
  ) : (
    <>
      {preshapePoints?.length && (
        <>
          <SelectedSegmentLine
            wallPoints={getAdditionalLine(
              startPoint,
              preshapePoints[preshapePoints.length - 1].x,
              preshapePoints[preshapePoints.length - 1].y,
            )}
          />
          {preshapePoints?.length > 1 ? (
            <>
              <SelectedSegmentLine
                wallPoints={getAdditionalLine(
                  startPoint,
                  (preshapePoints[preshapePoints.length - 1].x + preshapePoints[preshapePoints.length - 2].x) / 2,
                  (preshapePoints[preshapePoints.length - 1].y + preshapePoints[preshapePoints.length - 2].y) / 2,
                )}
              />
              {preshapePoints.length === 4 && (
                <SelectedSegmentLine
                  wallPoints={getAdditionalLine(
                    startPoint,
                    (preshapePoints[preshapePoints.length - 2].x + preshapePoints[preshapePoints.length - 3].x) / 2,
                    (preshapePoints[preshapePoints.length - 2].y + preshapePoints[preshapePoints.length - 3].y) / 2,
                  )}
                />
              )}
              <g stroke="currentColor" strokeWidth={10}>
                <Polygon error={intersection} fill="none" points={`${preshapePoints.map(p => `${p.x}, ${p.y}`).join(' ')}`} />
              </g>
              {preshapePoints.map((point, i) => (
                <SelectedSegmentLine
                  key={`point-${uuid()}`}
                  wallPoints={getAdditionalLine(
                    point,
                    (preshapePoints[i + 1] || preshapePoints[0]).x,
                    (preshapePoints[i + 1] || preshapePoints[0]).y,
                  )}
                  hideLine
                />
              ))}
            </>
          ) : (
            <g stroke="currentColor" strokeWidth={10}>
              <circle fill="none" strokeWidth={10} cx={startPoint.x} cy={startPoint.y} r={Math.abs(preshapePoints[0].x - startPoint.x)} />
            </g>
          )}
        </>
      )}
    </>
  );
};

export default connect((state: RootState): StateProps => ({
  isDrawing: editModeSelectors.isDrawingMode(state),
  selectableObjects: modelSelectors.getSelectableObjects(state),
  drawState: drawSelectors.getDrawState(state),
  unitOfMeasurement: settingsSelectors.getUnitOfMeasure(state),
  precision: settingsSelectors.getPrecision(state),
  gridSize: settingsSelectors.getGridSizeInFeet(state),
  zoomInPercent: viewPortSelectors.getZoomInPercent(state),
}))(SelectedSegment);
