/** @jsx jsx */
import React, {
  useRef, useEffect, useState, useImperativeHandle, Fragment
} from 'react';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';
import { useDrop, DropTargetMonitor } from 'react-dnd';

import { RootState } from 'reducers/rootReducer';
import { sketchConfig } from 'config/sketchConfig';
import { CoordinatePoint, Point } from 'types/point';
import { Dimensions } from 'types/dimensions';
import { Box } from 'types/box';
import {
  ItemTypes, LabelDragItem, SymbolDragItem, LabelOrSymbolDropResult
} from 'types/dnd';
import { StampId } from 'types/stamp';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as pagesSelectors } from 'ducks/model/pages';
import { actions as viewportActions, selectors as viewportSelectors } from 'ducks/viewport';
import { selectors as bluePrintImageSelectors } from 'ducks/bluePrintImage/bluePrintImage';
import { actions as sidebarActions, selectors as sidebarSelectors } from 'ducks/sidebar/sidebar';
import { selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { actions as drawActions, selectors as drawSelectors, DrawState } from 'ducks/draw/draw';
import SelectedSegment from 'components/sketch/SelectedSegment/SelectedSegment';
import BoxSelect from 'components/sketch/BoxSelect/BoxSelect';
import SelectGroup from 'components/sketch/SelectGroup/SelectGroup';
import Grid from 'components/sketch/Grid/Grid';
import Page from 'components/sketch/Page/Page';
import EditCursor from 'components/sketch/EditCursor/EditCursor';
import BluePrintImage from 'components/sketch/BluePrintImage/BluePrintImage';
import LockImageButton from 'components/sketch/BluePrintImage/LockImageButton';
import AutoFinishButton from 'components/sketch/AutoFinishButton/AutoFinishButton';
import PointDragHandle from 'components/sketch/PointDragHandle/PointDragHandle';
import DrawHead from 'components/sketch/DrawHead/DrawHead';
import StampPreview from 'components/elements/StampPreview';
import { convertSvgPointCoordinates } from 'helpers/viewport';
import { isTouchDevice } from 'helpers/browserDetect';
import { usePrevious } from 'helpers/hooks';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import { selectors as figureSelectors } from 'ducks/model/figures';
import { ClosedFigure } from 'types/figure';

const { minFontZoom, maxFontZoom } = sketchConfig;

const style = css`
  display: block; /* Necessary to render properly in Safari */
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;

  text {
    user-select: none;
  }
`;

const stampModeCursor = css`
  cursor: none;
`;

const editModeCursor = css`
  cursor: crosshair;
`;

const moveModeCursor = css`
  cursor: move;
`;

interface StateProps {
  readonly points: Immutable.Map<string, Point>;
  readonly isDrawing: boolean;
  readonly isSelectMode: boolean;
  readonly isEditMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isSplittingMode: boolean;
  readonly isMovingMode: boolean;
  readonly isBoxSelectEditMode: boolean;
  readonly isRotatingMode: boolean;
  readonly pageIds: string[];
  readonly viewBox: Box;
  readonly defaultOrigin: CoordinatePoint;
  readonly svgSize: Dimensions;
  readonly zoomInPercent: number;
  readonly isBluePrintImageLocked: boolean;
  readonly isShowingNumpadModal: boolean;
  readonly selectedObjects: Immutable.List<string>;
  readonly stampId?: StampId;
  readonly isDrawingPreshapes: boolean;
  readonly isShowingLabelSidebar: boolean;
  readonly drawState: DrawState;
  readonly figures: Immutable.Map<string, ClosedFigure>;
  readonly isDrawingInteriorWalls: boolean;
}

interface ActionProps {
  readonly setSvgElement: (svgElement: SVGSVGElement) => void;
  readonly drawPositionedLabel: (labelId: string, at: CoordinatePoint) => void;
  readonly drawPositionedSymbol: (symbolId: string, at: CoordinatePoint) => void;
  readonly closeSidebar: typeof sidebarActions.hide;
}

type Props = StateProps & ActionProps;

const getFontSize = (zoomInPercent: number): React.CSSProperties => {
  // eslint-disable-next-line no-nested-ternary
  const fs = zoomInPercent < minFontZoom ? minFontZoom : zoomInPercent > maxFontZoom ? maxFontZoom : zoomInPercent;

  return { fontSize: `${fs}%` };
};

// eslint-disable-next-line max-len
const formatViewBox = (viewBox: Box, defaultOrigin: CoordinatePoint, svgSize: Dimensions): string => `${(viewBox && viewBox.x) || defaultOrigin.x} ${(viewBox && viewBox.y) || defaultOrigin.y} ${(viewBox && viewBox.width) || svgSize.width} ${(viewBox && viewBox.height) || svgSize.height
}`;

const onWheel = (event: WheelEvent): void => {
  if (event.ctrlKey) {
    event.preventDefault();
  }
};

export interface SketchRef {
  getPointToSvgPoint: (point: CoordinatePoint) => CoordinatePoint | undefined;
}

const Sketch = React.forwardRef<SketchRef, Props>((props, ref): JSX.Element => {
  const {
    points,
    isEditMode,
    isMovingMode,
    pageIds,
    isSplittingMode,
    isDrawing,
    isBluePrintImageLocked,
    isShowingNumpadModal,
    isBoxSelectEditMode,
    selectedObjects,
    zoomInPercent,
    viewBox,
    defaultOrigin,
    svgSize,
    setSvgElement,
    stampId,
    drawPositionedLabel,
    drawPositionedSymbol,
    isSelectedMode,
    isDrawingPreshapes,
    drawState,
    figures,
    isDrawingInteriorWalls
  } = props;

  const sketchRef = useRef<SVGSVGElement>(null);
  const lastSketchRefValue = usePrevious(sketchRef.current);
  if (lastSketchRefValue !== sketchRef.current && sketchRef.current) {
    setSvgElement(sketchRef.current);
  }

  useEffect(() => {
    const updateSvgElement = (): void => {
      if (sketchRef.current) {
        setSvgElement(sketchRef.current);
      }
    };

    // on resize we have to redraw whole sketch due to some elements
    // for example grids don't render correctly (i.e. viewBox should be updated)
    window.addEventListener('resize', updateSvgElement);

    // https://github.com/facebook/react/issues/14856#issuecomment-478144231
    // fix for mac's gesture events
    window.addEventListener('wheel', onWheel, { passive: false });
    return () => {
      window.removeEventListener('resize', updateSvgElement);
      window.removeEventListener('wheel', onWheel);
    };
  }, [setSvgElement]);

  useImperativeHandle(ref, () => ({
    getPointToSvgPoint(point: CoordinatePoint): CoordinatePoint | undefined {
      return sketchRef.current ? convertSvgPointCoordinates(sketchRef.current, point) : undefined;
    },
  }));

  const [cursorPosition, setCursorPosition] = useState<CoordinatePoint>();

  const handleTouchMove = (event: React.TouchEvent<SVGSVGElement>): void => {
    // if this is not a single touch
    if (event.touches.length !== 1) {
      return;
    }

    const point: CoordinatePoint = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    // if we don't have a point
    if (!point) {
      return;
    }

    onPointerMove(point);
  };

  const handleMouseMove = (event: React.MouseEvent<SVGSVGElement>): void => {
    const point: CoordinatePoint = { x: event.clientX, y: event.clientY };
    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !point) {
      return;
    }

    onPointerMove(point);
  };

  const onPointerMove = (point: CoordinatePoint): void => {
    if (sketchRef.current && (isDrawing || isMovingMode || stampId)) {
      setCursorPosition(convertSvgPointCoordinates(sketchRef.current, point));
    }
  };

  const handleTouchStart = (event: React.TouchEvent<SVGSVGElement>): void => {
    if (sketchRef.current && stampId) {
      event.stopPropagation();
    }

    // if this is not a single touch
    if (event.touches.length !== 1) {
      return;
    }

    const point: CoordinatePoint = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    // if we don't have a point
    if (!point) {
      return;
    }

    onPointerDown(point);
  };

  const handleMouseDown = (event: React.MouseEvent<SVGSVGElement>): void => {
    if (sketchRef.current && stampId) {
      event.stopPropagation();
    }

    const point: CoordinatePoint = { x: event.clientX, y: event.clientY };
    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !point) {
      return;
    }

    onPointerDown(point);
  };

  // eslint-disable-next-line consistent-return
  const onPointerDown = (point: CoordinatePoint): void => {
    if (sketchRef.current && stampId) {
      const at = convertSvgPointCoordinates(sketchRef.current, point);
      switch (stampId.objectType) {
        case 'label':
          return drawPositionedLabel(stampId.objectId, at);
        case 'symbol':
          return drawPositionedSymbol(stampId.objectId, at);
        default:
          throw new UnreachableCaseError(stampId.objectType);
      }
    }
  };

  const [, drop] = useDrop({
    accept: [ItemTypes.Label, ItemTypes.Symbol],
    drop: (_: LabelDragItem | SymbolDragItem, monitor: DropTargetMonitor): LabelOrSymbolDropResult | undefined => {
      const dropAt = monitor.getClientOffset();
      return dropAt && sketchRef.current
        ? {
          position: convertSvgPointCoordinates(sketchRef.current, { x: dropAt.x - window.pageXOffset, y: dropAt.y - window.pageYOffset }),
        }
        : undefined;
    },
  });

  // we have to change z-order for blueprint image if isBluePrintImageLocked
  // otherwise user can't select blueprint image

  // get a figure that is currently being drawn (if there is one)
  const figure = figures.valueSeq().filter(f => f.figureId === drawState.drawObjectId)?.get(0);

  // show the point drag handle if
  let showPointDragHandle =
    isTouchDevice && // this is a touch device
    points.size && // we have points
    !isDrawingPreshapes && // we are NOT drawing pre-shapes
    !isDrawingInteriorWalls && // we are NOT drawing interior walls
    (isDrawing || isMovingMode); // we are either drawing or moving

  // if there is more than one selectedobject then do not show
  if (selectedObjects.size > 1) {
    showPointDragHandle = false;
  }

  // if there is a single item selected, and it's not a point then do not show point drag handle..
  const selectedPoint = points.find((obj) => obj.pointId === selectedObjects.get(0));
  if (showPointDragHandle && selectedObjects.size === 1 && !selectedPoint) {
    showPointDragHandle = false;
  }

  // if this is a label then do not show the point drag handle..
  if (showPointDragHandle && selectedPoint && selectedPoint.pointType && selectedPoint.pointType === 'label') {
    showPointDragHandle = false;
  }

  if (showPointDragHandle && figure && figure.glaType?.length) {
    showPointDragHandle = false;
  }

  // only show auto finish if this is a touch device or the numpad is open
  let showAutoFinishButton = (isTouchDevice || isShowingNumpadModal);
  if (showAutoFinishButton) {
  // do not show the AutoFinishButton unless the user is drawing a figure and it has at least 2 walls already
    if (!figure || figure.walls?.length < 2) {
      showAutoFinishButton = false;
    }
  }

  return (
    <Fragment>
      <svg
        css={[
          style,
          // eslint-disable-next-line no-nested-ternary
          stampId
            ? stampModeCursor // eslint-disable-line no-nested-ternary
            : // eslint-disable-next-line no-nested-ternary
            isEditMode || isSplittingMode || isDrawing
              ? editModeCursor // eslint-disable-line no-nested-ternary
              : isMovingMode
                ? moveModeCursor
                : undefined,
        ]}
        style={{ ...getFontSize(zoomInPercent), backgroundColor: 'white' }}
        viewBox={formatViewBox(viewBox, defaultOrigin, svgSize)}
        ref={(node) => {
          drop(node);
          // @ts-ignore
          sketchRef.current = node;
        }}
        onTouchStart={handleTouchStart}
        onMouseDown={handleMouseDown}
        onTouchMove={handleTouchMove}
        onMouseMove={handleMouseMove}
      >
        {isBluePrintImageLocked && <BluePrintImage />}
        <Grid />
        {pageIds.map((pageId) => (
          <Page key={pageId} pageId={pageId} />
        ))}
        {showAutoFinishButton && <AutoFinishButton />}
        {showPointDragHandle && <PointDragHandle />}
        {!isTouchDevice && <DrawHead />}
        <SelectedSegment />
        {isBoxSelectEditMode && <BoxSelect />}
        {isSelectedMode && selectedObjects.size > 1 && <SelectGroup />}
        {!isBluePrintImageLocked && <BluePrintImage />}
        {cursorPosition &&
          (stampId ? (
            <StampPreview cursorPosition={cursorPosition} objectType={stampId.objectType} objectId={stampId.objectId} />
          ) : (
            <EditCursor cursorPosition={cursorPosition} />
          ))}
        <LockImageButton />
      </svg>
      <ContextMenu type="sketch" parentRef={sketchRef} />
    </Fragment>
  );
});

export default connect(
  (state: RootState): StateProps => ({
    points: pointsSelectors.getAllPoints(state),
    isDrawing: editModeSelectors.isDrawingMode(state),
    isSelectMode: editModeSelectors.isSelectMode(state),
    isEditMode: editModeSelectors.isEditMode(state),
    isSelectedMode: editModeSelectors.isSelectedMode(state),
    isSplittingMode: editModeSelectors.isSplittingMode(state),
    isMovingMode: editModeSelectors.isMovingMode(state),
    isShowingNumpadModal: numpadModalSelectors.isShowing(state),
    isBoxSelectEditMode: editModeSelectors.isBoxSelectEditMode(state),
    isRotatingMode: editModeSelectors.isRotatingMode(state),
    pageIds: pagesSelectors.getPageIds(state),
    viewBox: viewportSelectors.getViewBox(state),
    defaultOrigin: viewportSelectors.getViewBoxOrigin(),
    svgSize: viewportSelectors.getSvgSize(state),
    zoomInPercent: viewportSelectors.getZoomInPercent(state),
    isBluePrintImageLocked: bluePrintImageSelectors.isImageLocked(state),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
    stampId: sidebarSelectors.getStampId(state),
    isDrawingPreshapes: drawSelectors.isDrawingPreshapes(state),
    isShowingLabelSidebar: sidebarSelectors.isShowingLabels(state),
    drawState: drawSelectors.getDrawState(state),
    figures: figureSelectors.getAllFigures(state),
    isDrawingInteriorWalls: drawSelectors.isDrawingInteriorWalls(state),
  }),
  {
    setSvgElement: viewportActions.setSvgElement,
    drawPositionedLabel: drawActions.drawPositionedLabel,
    drawPositionedSymbol: drawActions.drawPositionedSymbol,
    closeSidebar: sidebarActions.hide,
  },
  null,
  { forwardRef: true },
)(Sketch);
