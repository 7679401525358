import { CoordinatePoint } from 'types/point';
import { PointType } from 'types/pointType';
import { DEGREES_PER_RADIAN } from 'helpers/rotation';
import { distance } from 'helpers/geometry';

interface CurveParameters {
  r: number;
  angle: number;
  bigArc: number;
  arcHeight: number;
  center: CoordinatePoint;
}

/**
 * get "control" point of the arc
 */
export const getArcPoint = (p1: CoordinatePoint, p2: CoordinatePoint, arcHeight: number): CoordinatePoint => {
  const c = {
    pointType: PointType.ARC,
    x: (p2.x + p1.x) / 2,
    y: (p2.y + p1.y) / 2,
  };

  const d = {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };

  const dist = Math.sqrt(d.x * d.x + d.y * d.y);
  if (dist === 0) {
    return c;
  }
  const sinF = d.x / dist;
  const cosF = d.y / dist;

  const rotated = {
    pointType: PointType.ARC,
    x: c.x - cosF * arcHeight,
    y: c.y + sinF * arcHeight,
  };

  return rotated;
};

/**
 * Create circle by 3 points and get center of the circle
 * https://stackoverflow.com/questions/32861804/how-to-calculate-the-centre-point-of-a-circle-given-three-points
 */
export const getCircleCenter = (
  p1: CoordinatePoint, p2: CoordinatePoint, arcPoint: CoordinatePoint,
): CoordinatePoint => {
  const x1 = p1.x - arcPoint.x;
  const y1 = p1.y - arcPoint.y;

  const x2 = p2.x - arcPoint.x;
  const y2 = p2.y - arcPoint.y;

  const z1 = x1 * x1 + y1 * y1;
  const z2 = x2 * x2 + y2 * y2;
  const d = 2 * (x1 * y2 - x2 * y1);
  if (d === 0) {
    return { ...arcPoint };
  }

  const xC = (z1 * y2 - z2 * y1) / d + arcPoint.x;
  const yC = (x1 * z2 - x2 * z1) / d + arcPoint.y;

  const center = {
    x: xC,
    y: yC,
  };

  return center;
};

/**
 * get parameters to draw curve
 */
export const getCurveParameters = (p1: CoordinatePoint, p2: CoordinatePoint, arcHeight: number): CurveParameters => {
  const c = {
    x: (p2.x + p1.x) / 2,
    y: (p2.y + p1.y) / 2,
  };

  const d = {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };

  const angleRad = Math.atan2(d.y, d.x);
  const angleDegree = angleRad * DEGREES_PER_RADIAN;

  const arcPoint = getArcPoint(p1, p2, arcHeight);
  const center = getCircleCenter(p1, p2, arcPoint);

  const r = distance(center, arcPoint);
  const distanceToMiddlePoint = distance(c, arcPoint);

  return {
    r,
    angle: angleDegree,
    bigArc: (distanceToMiddlePoint > r) ? 1 : 0,
    arcHeight,
    center,
  };
};

/**
 * get parameters to draw curve
 */
export const getCurveByArcPoint = (
  p1: CoordinatePoint, p2: CoordinatePoint, arcPoint: CoordinatePoint,
): CurveParameters => {
  const c = {
    x: (p2.x + p1.x) / 2,
    y: (p2.y + p1.y) / 2,
  };

  const s = {
    x: arcPoint.x - c.x,
    y: arcPoint.y - c.y,
  };

  const d = {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };

  // sign of sin angle between two vectors x1*y2 - x2*y1
  const sign = Math.sign(d.x * s.y - d.y * s.x);
  const arcHeight = sign * Math.sqrt(s.x * s.x + s.y * s.y);
  return getCurveParameters(p1, p2, arcHeight);
};
