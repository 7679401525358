import Immutable from 'immutable';
import { createAction } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { SketchSymbol } from 'types/sketchSymbol';
import { compose } from 'helpers/utils';

// Action Types
const NAME = 'symbols';

const ADD = `${NAME}/ADD`;
const SET = `${NAME}/SET`;
const UPDATE = `${NAME}/UPDATE`;
const REMOVE = `${NAME}/REMOVE`;

export interface SymbolsState {
  readonly symbols: Immutable.Map<string, SketchSymbol>;
}
interface SetSymbolsPayload {
  clear: boolean;
  symbols: SketchSymbol[];
}

// Initial State
const initialState: SymbolsState = {
  symbols: Immutable.Map<string, SketchSymbol>(),
};

// Action Creators
export const actions = {
  add: (symbol: SketchSymbol) => ({
    type: ADD,
    payload: symbol,
  }),
  set: createAction(SET)<SetSymbolsPayload>(),

  update: (symbol: SketchSymbol) => ({
    type: UPDATE,
    payload: symbol,
  }),

  remove: (symbolId: string) => ({
    type: REMOVE,
    payload: symbolId,
  }),
};

// Selectors
const getSymbolsState = (rootState: RootState): SymbolsState => rootState.symbols;

const getAllSymbols = (
  rootState: RootState,
): Immutable.Map<string, SketchSymbol> => getSymbolsState(rootState).symbols;

const getSymbolById = (
  rootState: RootState,
  symbolId: string,
): SketchSymbol => getAllSymbols(rootState).get(symbolId)!;

export const selectors = {
  getAllSymbols,
  getSymbolById,
};

// Reducers
const addSymbolReducer = (state: SymbolsState, symbol: SketchSymbol): SymbolsState => ({
  ...state,
  symbols: state.symbols.set(symbol.symbolId, symbol),
});

const removeSymbolReducer = (state: SymbolsState, symbolId: string): SymbolsState => ({
  ...state,
  symbols: state.symbols.remove(symbolId),
});

const setSymbolsReducer = (state: SymbolsState, { clear, symbols }: SetSymbolsPayload): SymbolsState => ({
  ...state,
  symbols: compose(
    (newMap: Map<string, SketchSymbol>) => clear ? newMap : state.symbols.merge(newMap),
  )(Immutable.Map<string, SketchSymbol>(symbols.map((symbol) => [symbol.symbolId, symbol]))),
});

export const reducer = (state: SymbolsState = initialState, action: PayloadAction): SymbolsState => {
  switch (action.type) {
    case ADD:
    case UPDATE:
      return addSymbolReducer(state, action.payload);
    case SET:
      return setSymbolsReducer(state, action.payload);

    case REMOVE:
      return removeSymbolReducer(state, action.payload);

    default:
      return state;
  }
};
