import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import posed from 'react-pose';
import styled from '@emotion/styled';

import { isTouchDevice, isIE11 } from 'helpers/browserDetect';
import { RootState } from 'reducers/rootReducer';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';
import { actions as printPreviewModalActions } from 'ducks/modal/printPreviewModal';
import { Modal, ButtonModalHeader as Button } from 'components/modal/Modal';
import { appearFromTop } from 'components/animations';
import { scrollbar } from 'components/globalStyles';
import { messages } from 'config/messages';
import PageGroup, { PageGroupAdd, ShowPrintPreview } from 'components/modal/PageGroup';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { actions as pageGroupsModalActions } from 'ducks/modal/pageGroupsModal';
import IEWarning from './IEWarning';

const ModalWithScroll = styled(Modal)(scrollbar, {
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: `calc(100% - ${51 + 10}px)`,
  right: isTouchDevice ? 0 : '4rem',
});
const ModalAnimated = posed(ModalWithScroll)(appearFromTop);

interface InputProps {
  readonly top: number;
  readonly pose?: string;
}

interface StateProps {
  readonly pageIds: string[];
}

interface ActionProps {
  readonly close: () => void;
  readonly createPage: typeof pagesActions.add;
  readonly showPrintPreview: () => void;
}

type Props = InputProps & StateProps & ActionProps;

class PageGroupsModal extends Component<Props, { showIEWarning: boolean }> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      showIEWarning: false,
    };
  }

  private onAddGroup = (): void => {
    const { createPage } = this.props;
    createPage();
  };

  private onShowPrintPreview = (): void => {
    const { showPrintPreview } = this.props;
    if (!isIE11) {
      showPrintPreview();
    } else {
      this.toggleIEWarning();
    }
  };

  private toggleIEWarning = (): void => {
    this.setState((prevState) => ({ showIEWarning: !prevState.showIEWarning }));
  };

  public render = (): JSX.Element => {
    const { pageIds, top, pose, close } = this.props;
    const { showIEWarning } = this.state;
    return (
      <ModalAnimated top={top} right={200} pose={pose}>
        <h2 style={{ marginBottom: '10px' }}>
          <Button onClick={close}>
            <IconClose />
          </Button>
          <span>{messages.onScreenPagesTitle}</span>
          <div />
          <br />
        </h2>
        {pageIds.map((pageId, index) => (
          <PageGroup key={pageId} pageId={pageId} pageTitle={`Page ${index + 1}`} />
        ))}
        <PageGroupAdd onClick={this.onAddGroup} />
        <ShowPrintPreview onClick={this.onShowPrintPreview} />
        {showIEWarning && <IEWarning action={this.toggleIEWarning} />}
      </ModalAnimated>
    );
  };
}

export default connect(
  (state: RootState): StateProps => ({
    pageIds: pagesSelectors.getPageIds(state),
  }),
  (dispatch: Dispatch): ActionProps => ({
    close: async () => {
      dispatch(pageGroupsModalActions.hide());
    },
    showPrintPreview: async () => {
      // await dispatch(editModeActions.clearSelection()); // hack to update svg before trying to use svg element
      // dispatch(pagesActions.setFirstPage());
      dispatch(printPreviewModalActions.show());
    },
    ...bindActionCreators(
      {
        createPage: pagesActions.add,
      },
      dispatch,
    ),
  }),
)(PageGroupsModal);
