import { transparentize } from 'helpers/colorTransforms';
import { colors, area as areaColors } from 'config/paletteConfig';

export const OPACITY = 0.75;

export const areaStyles = {
  base: {
    fill: colors.none,
    pointerEvents: 'none',
  },
  hover: {
    '&:hover': {
      fill: transparentize(areaColors.hovered, OPACITY),
    },
  },
  closed: {
    fill: transparentize(areaColors.base, OPACITY),
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  active: {
    fill: transparentize(areaColors.hovered, OPACITY),
    '&:hover': {
      cursor: 'move',
    },
  },
  interior: {
    fill: colors.none,
  },
  nonGla: {
    fill: transparentize('#F3F3F3', OPACITY),
  },
} as const;

export default areaStyles;
