/** @jsx jsx */
/** @jsxFrag */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { modal as colors } from 'config/paletteConfig';
import { AreaType } from 'types/areaType';
import { isClosedFigure, Figure } from 'types/figure';
import { GlaType } from 'types/glaType';
import { getFigureGlaType, getFigureAreaType } from 'helpers/label/areaTypeLabel';
import { selectors as areaTypeModalSelectors, actions as areaTypeModalActions } from 'ducks/modal/areaTypeModal';
import { actions as subAreaModalActions } from 'ducks/modal/subAreaModal';
import { selectors as areaTypesSelectors } from 'ducks/model/areaTypes';
import { selectors as areaTypePersistenceSelectors, actions as areaTypePersistenceActions } from 'ducks/persistence/areaTypePersistence';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import ToggleSwitch from 'components/elements/ToggleSwitch';
import { TooltipContent } from 'components/elements/Tooltip';
import { scrollbar } from 'components/globalStyles';
import { appearFromTop } from 'components/animations';
import { Modal } from 'components/modal/Modal';
import { sidebarItemStyles, scrollWrapperStyles } from 'components/sidebar/styles';
import { percentageFromInt } from 'helpers/utils';
import { isTouchDevice } from 'helpers/browserDetect';
import ToolbarButton from '../Toolbar/ToolbarButton';

const background = css`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(20, 28, 50, 0.18);
  z-index: 1000;
`;

const ButtonAreaType = styled.button(sidebarItemStyles);
const ModalAnimated = posed(Modal)(appearFromTop);

const ListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3px;

  &:hover,
  &:focus {
    background-color: ${colors.base};

    & .glaModifierContainer {
      opacity: 1;
    }
  }
`;

const ModifierInput = styled.input`
  width: 24px;
  margin-right: 4px;
  height: 20px;
`;

const GlaModifierContainer = isTouchDevice ? styled.div`
  display: flex;
  opacity: 1;
  width: 120px;
` : styled.div`
display: flex;
opacity: 0;
`;

const buttonStyle = isTouchDevice ? css`
  &:focus + div > label {
    opacity: 1;
  }
` : css`
padding-right: 105px;

&:focus + div > label {
  opacity: 1;
}
`;

const buttonNegativeStyle = css`
  color: #ffaabe;
`;

const toggleSwitch = css`
  transform: translateY(-50%);
  transition: opacity 0.12s linear 0.1s;

  input:focus + & {
    opacity: 1;
  }

  margin-right: 6px;
  margin-top: 12px;
`;

const selected = css`
  background-color: ${colors.base};
`;

const ScrollArea = styled.ul(scrollWrapperStyles, scrollbar, { maxHeight: `calc(100vh - ${75 + 46 + 15}px)` });

const LoadingMessage = styled.div`
  text-align: center;
  padding: 10px;
`;

interface StateProps {
  readonly isLoading: boolean;
  readonly figure: Figure;
  readonly areaTypes: Immutable.Map<string, AreaType>;
  readonly figures: Immutable.Map<string, Figure>;
  readonly isMultiFamily: boolean;
}

interface ActionProps {
  readonly loadAreaTypes: typeof areaTypePersistenceActions.loadAreaTypes;
  readonly addAreaTypeForFigure: typeof areaTypeModalActions.addAreaTypeForFigure;
  readonly changeAreaTypeForFigure: typeof areaTypeModalActions.changeAreaTypeForFigure;
  readonly changeGlaMultiplierForFigure: typeof areaTypeModalActions.changeGlaMultiplierForFigure;
  readonly close: typeof areaTypeModalActions.hide;
  readonly setMultiFamily: typeof areaTypeModalActions.setMultiFamily;
  readonly showForFigure: typeof subAreaModalActions.showForFigure;
}

type Props = StateProps & ActionProps;

interface State {
  readonly glaType?: GlaType;
  glaModifier?: string;
}

class AreaTypeModal extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      glaModifier: '100',
    };
  }

  public componentDidMount = (): void => {
    const { loadAreaTypes, changeGlaMultiplierForFigure, figure } = this.props;
    const { glaModifier } = this.state;
    changeGlaMultiplierForFigure(figure.figureId, percentageFromInt(glaModifier));
    loadAreaTypes();
  };

  private makeAddLabel = (areaType: AreaType) => () => {
    const {
      figure, addAreaTypeForFigure, changeAreaTypeForFigure, close, isMultiFamily, showForFigure,
    } = this.props;
    let { glaType } = this.state;
    if (areaType.glaType === GlaType.NEGATIVE) {
      glaType = GlaType.NEGATIVE;
    }

    const meta = areaType?.meta || {};

    if (!glaType) {
      // eslint-disable-next-line prefer-destructuring
      glaType = areaType.glaType;
    }

    if (!isClosedFigure(figure)) {
      addAreaTypeForFigure(figure.figureId, areaType.areaTypeId, glaType, meta, isMultiFamily);
      if (isMultiFamily) showForFigure(figure.figureId);
    } else {
      changeAreaTypeForFigure(figure.figureId, areaType.areaTypeId, glaType, meta, isMultiFamily);
    }

    close();
  };

  private toggleGla = (newValue: boolean, changedAreaTypeId?: string): void => {
    const { changeAreaTypeForFigure, figure } = this.props;

    if (isClosedFigure(figure) && figure.areaTypeId === changedAreaTypeId) {
      const glaType = newValue ? GlaType.GLA : GlaType.NON_GLA;
      this.setState(() => ({ glaType }));

      changeAreaTypeForFigure(figure.figureId, figure.areaTypeId, glaType);
    }
  };

  private setGlaModifier = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target: { value } } = event;
    const { changeGlaMultiplierForFigure, figure } = this.props;
    if (value === '' || (/^[0-9\b]+$/.test(value) && parseInt(value, 10) <= 100)) {
      this.setState({ glaModifier: value });
      changeGlaMultiplierForFigure(figure.figureId, percentageFromInt(value));
    }
  }

  private handleKeyPress = (e: any): void => {
    e.stopPropagation();
  }

  private handleMultiFamilyClick = (e: any): void => {
    const { setMultiFamily } = this.props;
    e.preventDefault();
    setMultiFamily();
  }

  public render = (): JSX.Element => {
    const {
      isLoading, figure, areaTypes, isMultiFamily,
    } = this.props;
    const { glaModifier } = this.state;
    const figureGlaType = getFigureGlaType(figure);

    return (
      <div css={background}>
        <ModalAnimated
          top={isTouchDevice ? 55 : 75}
          left={isTouchDevice ? 55 : undefined}
          bottom={isTouchDevice ? 0 : undefined}
          pose="enter"
          initialPose="exit"
        >
          <h2>
            <span>{messages.areaTypeTitle}</span>
            <ToolbarButton
              style={{ marginTop: 0, marginBottom: 0, marginRight: 0 }}
              onClick={this.handleMultiFamilyClick}
              aria-label="Make MultiFamily Structure"
              touchLabel="Make MultiFamily Structure"
              active={isMultiFamily}
              icon="M"
              tooltip={<TooltipContent title="Make MultiFamily Structure" />}
            />
          </h2>
          {isLoading ? (
            <LoadingMessage>
              {messages.loadingAreaTypes}
              ...
            </LoadingMessage>
          ) : (
            <ScrollArea>
              {areaTypes.valueSeq().map((areaType) => (
                <ListItem key={areaType.areaTypeId}>
                  <ButtonAreaType
                    css={[
                      buttonStyle,
                      areaType.glaType === GlaType.NEGATIVE && buttonNegativeStyle,
                      areaType.areaTypeId === getFigureAreaType(figure) && selected,
                    ]}
                    onClick={this.makeAddLabel(areaType)}
                  >
                    {areaType.text}
                  </ButtonAreaType>
                  <GlaModifierContainer className="glaModifierContainer">
                    {(areaType.glaType !== GlaType.NEGATIVE)
                      && (
                        <>
                          <ToggleSwitch
                            id={areaType.areaTypeId}
                            css={toggleSwitch}
                            defaultToggled={(isClosedFigure(figure) && figure.areaTypeId === areaType.areaTypeId)
                              ? (figureGlaType === GlaType.GLA) : (areaType.glaType === GlaType.GLA)}
                            onValueChanged={this.toggleGla}
                            toggledText={messages.areaTypeToggleGla}
                            unToggledText={messages.areaTypeToggleNonGla}
                          />
                          <ModifierInput
                            type="text"
                            value={glaModifier}
                            onChange={this.setGlaModifier}
                            onKeyDown={this.handleKeyPress}
                            onKeyUp={this.handleKeyPress}
                          />
                          <span css={{ marginTop: '4px', marginRight: '6px' }}>%</span>
                        </>
                      )
                    }
                  </GlaModifierContainer>
                </ListItem>
              ))}
            </ScrollArea>
          )}
        </ModalAnimated>
      </div>
    );
  };
}

export default connect(
  (state: RootState): StateProps => {
    const figureId = areaTypeModalSelectors.getSelectedFigureId(state)!;
    return {
      isLoading: areaTypePersistenceSelectors.isLoading(state),
      figure: figuresSelectors.getFigureById(state, figureId),
      areaTypes: areaTypesSelectors.getAreaTypesForDisplay(state),
      figures: figuresSelectors.getAllFigures(state),
      isMultiFamily: areaTypeModalSelectors.isMultiFamily(state),
    };
  },
  {
    loadAreaTypes: areaTypePersistenceActions.loadAreaTypes,
    addAreaTypeForFigure: areaTypeModalActions.addAreaTypeForFigure,
    changeAreaTypeForFigure: areaTypeModalActions.changeAreaTypeForFigure,
    changeGlaMultiplierForFigure: areaTypeModalActions.changeGlaMultiplierForFigure,
    close: areaTypeModalActions.hide,
    setMultiFamily: areaTypeModalActions.setMultiFamily,
    showForFigure: subAreaModalActions.showForFigure,
  },
)(AreaTypeModal);
