export enum LineOrientationType {
  // vertical like "|"
  VERTICAL,

  // horizontal like "-"
  HORIZONTAL,

  // diagonal like "\"
  DIAGONAL_DOWN,

  // diagonal like "/"
  DIAGONAL_UP,
}
