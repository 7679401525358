/* eslint-disable global-require */
/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';

import dotenv from 'dotenv';
import Routes from './components/routes/Routes';
import { RootState } from './reducers/rootReducer';




dotenv.config();

const fonts = css`
  @font-face {
    font-family: 'Montserrat';
    src: font-url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
         font-url('./assets/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: font-url('./assets/fonts/Montserrat-Light.woff2') format('woff2'),
         font-url('./assets/fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: font-url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
         font-url('./assets/fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
`;

const style = css`
  html,
  #root {
    height: 100%;
    -ms-content-zooming: none;
  }
  body {
    font: 14px 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  button {
    font-family: 'Montserrat', 'Helvetica', -apple-system, sans-serif;
  }
  button:focus,
  input:focus {
    outline: none;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  #tooltip-root {
    position: relative;
    z-index: 1000;
  }
  #modal-root {
    position: relative;
    z-index: 1001;
  }
`;

interface Props {
  store: Store<RootState, AnyAction>;
}
const App = ({ store }: Props): JSX.Element => (
  <Provider store={store}>
    <Global styles={[fonts, style]} />
    <Routes />
  </Provider>
);


export default App;
