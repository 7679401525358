import React, { Component } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';

import { RootState } from 'reducers/rootReducer';
import { Label } from 'types/label';
import { PositionedLabel } from 'types/positionedLabel';
import { selectors as labelsSelectors, actions as labelsActions } from 'ducks/model/labels';
import {
  selectors as positionedLabelsSelectors,
  actions as positionedLabelsActions,
} from 'ducks/model/positionedLabels';
import { scaleYIn } from 'components/animations';

const LabelAnimated = posed.label(scaleYIn);

interface InputProps {
  readonly labelId?: string;
  readonly positionedLabelId?: string;
  readonly className?: string;

  readonly onEditFinished: (value: string) => void;
}

interface StateProps {
  readonly label?: Label;
  readonly positionedLabel?: PositionedLabel;
}

interface ActionProps {
  readonly updateLabel: typeof labelsActions.update;
  readonly createLabel: typeof labelsActions.create;
  readonly createLabelAndUpdate: typeof positionedLabelsActions.createLabelAndUpdate;
}

type Props = InputProps & StateProps & ActionProps;

interface State {
  readonly text: string;
}

class LabelTextEdit extends Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  private readonly focusRef: React.RefObject<HTMLInputElement>;

  // due too setState is async operation we can't put isSaved to state
  // otherwise save called twice: on enter and on component un-mount
  private isSaved: boolean;

  public constructor(props: Props) {
    super(props);
    this.focusRef = React.createRef();
    this.state = {
      text: props.label ? props.label.text : '',
    };

    this.isSaved = false;
  }

  public componentDidMount(): void {
    this.focusRef.current!.focus();
  }

  public componentWillUnmount(): void {
    this.save();
  }

  private editFinished = (): void => {
    const { onEditFinished } = this.props;
    const { text } = this.state;
    this.isSaved = true;
    onEditFinished(text);
  };

  private handleTextChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const text = event.target.value;
    this.setState(() => ({ text }));
    this.isSaved = false;
  };

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.save(event);
    }
    if (event.key === 'Escape') {
      this.editFinished();
    }
  };

  private save = (event?: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>): void => {
    if (event) {
      event.preventDefault();
    }

    if (this.isSaved) {
      return;
    }

    const {
      label, positionedLabel, updateLabel, createLabel, createLabelAndUpdate, onEditFinished,
    } = this.props;
    const { text } = this.state;
    if (!text) {
      return;
    }

    // if this component is being used to create a label that shouldnt be added to Labels
    if (onEditFinished.length) {
      this.editFinished();
      return;
    }

    if (positionedLabel && !label?.label_type) {
      createLabelAndUpdate(text, positionedLabel);
    } else if (label) {
      updateLabel({ ...label, text });
    } else {
      createLabel(text);
    }

    this.editFinished();
  };

  public render = (): JSX.Element => {
    const { className } = this.props;
    const { text } = this.state;
    return (
      <form onSubmit={this.save}>
        <LabelAnimated htmlFor="textInput" className={className} initialPose="hide" pose="show">
          <input
            id="textInput"
            ref={this.focusRef}
            type="text"
            placeholder="New label..."
            required
            value={text}
            onChange={this.handleTextChanged}
            onKeyDown={this.handleKeyDown}
            onBlur={this.editFinished}
          />
        </LabelAnimated>
      </form>
    );
  };
}

export default connect(
  (state: RootState, props: InputProps): StateProps => {
    let positionedLabel;
    if (props.positionedLabelId) {
      positionedLabel = positionedLabelsSelectors.getPositionedLabelById(state, props.positionedLabelId);
    }
    const labelId = props.labelId || (positionedLabel && positionedLabel.labelId);
    let label;
    if (labelId) {
      label = labelsSelectors.getLabelById(state, labelId);
    }
    return ({
      label,
      positionedLabel,
    });
  },
  {
    updateLabel: labelsActions.update,
    createLabel: labelsActions.create,
    createLabelAndUpdate: positionedLabelsActions.createLabelAndUpdate,
  },
)(LabelTextEdit);
