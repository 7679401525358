import React from 'react';

import { CoordinatePoint } from 'types/point';

type withChildren<T> =
  T & { children?: React.ReactNode };

type RotateProps = withChildren<{
  degrees?: number;
  position: CoordinatePoint;
}>

const Rotate = ({ degrees, position, children }: RotateProps): JSX.Element => degrees
  ? (
    <g transform={`rotate(${degrees},${position.x},${position.y})`}>
      {children}
    </g>
  )
  : <>{children}</>;

export default Rotate;
