import Color from 'color';

export const transparentize = (
  color: string,
  opacity: number,
): string => Color(color).alpha(opacity).rgb().string();

export const lighten = (
  color: string,
  value: number,
): string => Color(color).lighten(value).string();
