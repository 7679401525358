// eslint-disable-next-line dot-notation,@typescript-eslint/no-explicit-any
export const isIE11 = !!(window as any)['MSInputMethodContext'] && !!(document as any)['documentMode'];

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// https://stackoverflow.com/questions/57765958/how-to-detect-ipad-and-ipad-os-version-in-ios-13-and-up
export const isTouchDevice = !!navigator.maxTouchPoints || navigator.userAgent.match(/(iPad)/); /* iOS pre 13 */

export const isMac = /^.*(Macintosh)/i.test(navigator.userAgent);

export const isAppleTouchDevice = isTouchDevice && [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod'
].includes(navigator.platform);
