import { arrayEquals, compose } from 'helpers/utils';
import Immutable from 'immutable';

import { Figure, isClosedFigure } from 'types/figure';

/**
 * get all figures, which contains specific wall
 * @param figures - all figures
 * @param wallId - specific wall
 * @returns array of figuresIds
 */
export const getFiguresWithWall = (
  figures: Immutable.Map<string, Figure>,
  wallId: string,
): string[] => figures.filter(figure => figure.walls.includes(wallId)).keySeq().toArray();

/**
 * get all shared walls for specific figure
 * @param figures - all figures
 * @param figureId - specific figure
 * @returns array of wallsIds
 */
export const getSharedWalls = (
  figures: Immutable.Map<string, Figure>,
  figureId: string,
): string[] => {
  const figure: Figure = figures.get(figureId)!;
  if (!figure) {
    return [];
  }

  return figure.walls.filter(wallId => getFiguresWithWall(figures, wallId).length > 1);
};

/**
 * get all shared walls for specific figure
 * @param figures - all figures
 * @param figureId - specific figure
 * @returns array of wallsIds
 */
export const getNonSharedWalls = (
  figures: Immutable.Map<string, Figure>,
  figureId: string,
): string[] => {
  const figure: Figure = figures.get(figureId)!;
  if (!figure) {
    return [];
  }

  return figure.walls.filter(wallId => getFiguresWithWall(figures, wallId).length <= 1);
};

/**
 * if wall is shared between closed figures
 */
export const isSharedWallForClosedFigures = (
  figures: Immutable.Map<string, Figure>,
  figuresWithWall: string[],
): boolean => {
  if (figuresWithWall.length !== 2) {
    return false;
  }

  const figure1 = figures.get(figuresWithWall[0])!;
  const figure2 = figures.get(figuresWithWall[1])!;

  return isClosedFigure(figure1) && isClosedFigure(figure2);
};

/**
 * rotate cycle path such that it begins with the smallest node
 * we need that to compare walls of two figures
 * it is ok, since our graph is not oriented
 * @param walls - walls of figure
 */
export const rotateWallsToSmallest = (walls: string[]): string[] => {
  if (!walls.length) {
    return walls;
  }

  const minWall = walls.reduce((min, b, indexWall) => ((min.wallId > b) ? { wallId: b, indexWall } : min), { wallId: walls[0], indexWall: 0 });

  return walls.slice(minWall.indexWall).concat(walls.slice(0, minWall.indexWall));
};
/**
 * invert walls to backward order and rotate to smallest
 * @param walls - walls of figure
 */
export const invertWalls = (walls: string[]): string[] => rotateWallsToSmallest([...walls].reverse());

/**
 * Compare two closed figures by walls
 * we need that to close polygons
 * @param walls1 - walls of figure1
 * @param walls2 - walls of figure2
 */
export const compareFiguresByWalls = (walls1: string[], walls2: string[]): boolean => {
  if (walls1.length !== walls2.length) {
    return false;
  }

  const rotatedWalls1 = rotateWallsToSmallest(walls1);
  const rotatedWalls2 = rotateWallsToSmallest(walls2);
  const isEqualToRotatedWalls2 = arrayEquals(rotatedWalls2);

  return isEqualToRotatedWalls2(rotatedWalls1)
    || compose(
      isEqualToRotatedWalls2,
      invertWalls,
    )(walls1);
};

/**
 * if figure1 is sub-figure for figure2
 * i.e. we test if all walls for smallFigure belongs to bigFigure
 */
export const isSubPath = (figure1Walls: string[], figure2Walls: string[]): boolean => {
  if (!figure1Walls.length) {
    // we don't want to consider empty figures
    return false;
  }

  if (figure1Walls.length > figure2Walls.length) {
    return false;
  }

  const nonSharedWall = figure1Walls.find(wallId => !figure2Walls.includes(wallId));
  return !nonSharedWall;
};
