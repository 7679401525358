/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Hammer from 'react-hammerjs';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';

import { RootState } from 'reducers/rootReducer';
import { Point, CoordinatePoint } from 'types/point';
import { GeometryType } from 'types/geometryType';
import { HammerEvent } from 'types/hammerjs';
import { SelectableObjects, CollidedObject } from 'types/selection';
import { getCollidedObjectWithPoint } from 'helpers/collision/getCollidedObject';
import { getIdsInSelectBox } from 'helpers/getIdsInSelectBox';
import { actions as boxSelectActions, selectors as boxSelectSelectors, BoxSelectState } from 'ducks/boxSelect';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { actions as moveObjectsActions, selectors as moveObjectsSelectors } from 'ducks/moveObjects';
import { actions as resizeObjectsActions } from 'ducks/resizeObjects';
import { actions as rotateObjectsActions } from 'ducks/rotateObjects';
import { actions as resizeSketchModalActions, selectors as resizeSketchModalSelectors } from 'ducks/modal/resizeSketchModal';
import { actions as drawActions, selectors as drawSelectors } from 'ducks/draw/draw';
import { actions as viewportActions, selectors as viewportSelectors, ZoomByIncrementPayload } from 'ducks/viewport';
import { actions as sidebarActions, selectors as sidebarSelectors } from 'ducks/sidebar/sidebar';
import { selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { selectors as groupSelectors } from 'ducks/groupObjects';
import { Group } from 'types/group';
import Sketch, { SketchRef } from 'components/sketch/Sketch/Sketch';
import Throbber from 'components/sketch/SketchArea/Throbber';
import { isTooShort } from 'helpers/model/wallPoints';
import { listToArray, isFromGroup } from 'helpers/utils';
import { isTouchDevice, isAppleTouchDevice } from 'helpers/browserDetect';

const style = css`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 18px;
`;

const throbberStyle = css`
  position: absolute;
  top: 50%;
  left: calc(50% + 27px);
  width: 120px;
  height: 120px;
  transform: translate(-50%, -50%);
`;

interface StateProps {
  readonly isDrawing: boolean;
  readonly isEditMode: boolean;
  readonly isLabelEditMode: boolean;
  readonly isSelectMode: boolean;
  readonly isBoxSelectMode: boolean;
  readonly isBoxSelectEditMode: boolean;
  readonly isMultiselectMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isMovingMode: boolean;
  readonly isResizingMode: boolean;
  readonly isRotatingMode: boolean;
  readonly isLoading: boolean;
  readonly isPanning: boolean;
  readonly isAdjustingDrawPoint: boolean;
  readonly hasObjectMoved: boolean;
  readonly isShowingNumpadModal: boolean;
  readonly selectableObjects: SelectableObjects;
  readonly selectedObjects: Immutable.List<string>;
  readonly selectedFigures: string[];
  readonly startPoint: CoordinatePoint;
  readonly endPoint: CoordinatePoint;
  readonly isDrawingInteriorWalls: boolean;
  readonly isDrawingPreshapes: boolean;
  readonly boxSelectState: BoxSelectState;
  readonly isTracingFirstWall: boolean;
  readonly groups: Immutable.Map<string, Group>;
  readonly currentSelectedGroupId: string | undefined;
  readonly isShowingMobileMenu: boolean;
  readonly zoomInPercent: number;
}

interface ActionProps {
  readonly startLine: (point: CoordinatePoint) => void;
  readonly startPreshape: (point: CoordinatePoint) => void;
  readonly updateLine: (point: CoordinatePoint) => void;
  readonly updatePreshape: (point: CoordinatePoint) => void;
  readonly endLine: () => void;
  readonly endShape: () => void;
  readonly updateMove: (point: CoordinatePoint) => void;
  readonly endMove: (point: CoordinatePoint) => void;
  readonly updateResize: (point: CoordinatePoint) => void;
  readonly endResize: (point: CoordinatePoint) => void;
  readonly rotate: (point: CoordinatePoint) => void;
  readonly endRotate: (point: CoordinatePoint) => void;
  readonly zoomViewBox: (zoom: ZoomByIncrementPayload) => void;
  readonly startPan: (point: CoordinatePoint) => void;
  readonly panViewBox: (point: CoordinatePoint) => void;
  readonly endPan: () => void;
  readonly setGroupId: typeof editModeActions.setCurrentSelectedGroupId;
  readonly startDraw: typeof editModeActions.switchToDrawing;
  readonly switchToSelection: typeof editModeActions.switchToSelection;
  readonly switchToBoxSelectEditMode: typeof editModeActions.switchToBoxSelectEditMode;
  readonly switchToEdit: typeof editModeActions.switchToEdit;
  readonly endDraw: typeof editModeActions.switchToSelection;
  readonly startMove: typeof editModeActions.switchToMoving;
  readonly selectObjects: typeof editModeActions.selectObjects;
  readonly toggleDrawingInteriorWalls: (drawInteriorWalls: boolean) => void;
  readonly startBoxSelect: typeof boxSelectActions.startBoxSelect;
  readonly setBoxSelectEndPoint: typeof boxSelectActions.setBoxSelectEndPoint;
  readonly setBoxSelectObjectIds: typeof boxSelectActions.setBoxSelectObjectIds;
  readonly toggleIsTracingFirstWall: typeof resizeSketchModalActions.toggleIsTracingFirstWall;
  readonly showRescaleGridModal: typeof resizeSketchModalActions.showRescale;
  readonly startLineWithoutSnap: typeof drawActions.startDrawWithoutSnap;
  readonly toggleMobileMenu: () =>void;
}

type Props = StateProps & ActionProps;

class SketchArea extends Component<Props> {
  private panStarted: boolean;

  // we can use this to catch a rough touch start
  // an error on the ui thread seems to cause touch
  // start to fire again- even a non-fatal error
  private isTouching: boolean;

  private sketchRef = React.createRef<SketchRef>();

  private lastTouchDate?: number;

  // we have to keep up with pinching scale
  private lastPinchingScale: number;

  public constructor(props: Props) {
    super(props);

    this.panStarted = false;
    this.isTouching = false;
    this.lastTouchDate = undefined;
    this.lastPinchingScale = 1;
  }

  private handleTouchStart = (event: React.TouchEvent<HTMLElement>): void => {
    const {
      isShowingMobileMenu,
      toggleMobileMenu
    } = this.props;

    if (this.isTouching) {
      // if a touch was already started and has not ended, ignore this
      return;
    }

    // if the mobile menu is showing when the canvas is touched then close it
    if (isShowingMobileMenu) {
      // sidebar/TOGGLE_MOBILE_MENU
      toggleMobileMenu();
    }

    // a touch has started
    this.isTouching = true;

    // Unable to preventDefault inside passive event listener due to target being treated as passive. See https://www.chromestatus.com/feature/5093566007214080
    // event.preventDefault();

    const relativePoint = this.relativeCoordinatesForTouchEvent(event);
    // if this is not a single touch, or we don't have a relative point
    if (event.touches.length !== 1 || !relativePoint) {
      return;
    }

    // if this is an apple touch device (iOS)
    // set this on touch and reset on touch move, pan or zoom
    if (isAppleTouchDevice) {
      this.lastTouchDate = new Date().getTime();
    }

    this.handlePointerDown(relativePoint, event.touches[0].clientX, event.touches[0].clientY);
    event.stopPropagation();
  }

  private handleMouseDown = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();

    const relativePoint = this.relativeCoordinatesForEvent(event);
    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !relativePoint) {
      return;
    }

    this.handlePointerDown(relativePoint, event.clientX, event.clientY);
    event.stopPropagation();
  }

  private handlePointerDown = (relativePoint: CoordinatePoint, clientX: number, clientY: number): void => {
    const {
      isDrawing,
      startDraw,
      startLine,
      startPreshape,
      updateLine,
      updatePreshape,
      endLine,
      endShape,
      endDraw,
      isSelectMode,
      isEditMode,
      isLabelEditMode,
      isSelectedMode,
      isMultiselectMode,
      startMove,
      startPan,
      selectableObjects,
      switchToEdit,
      selectObjects,
      selectedFigures,
      startPoint,
      isDrawingInteriorWalls,
      isDrawingPreshapes,
      isBoxSelectMode,
      switchToBoxSelectEditMode,
      startBoxSelect,
      selectedObjects,
      isTracingFirstWall,
      toggleIsTracingFirstWall,
      showRescaleGridModal,
      isShowingNumpadModal,
      groups,
      setGroupId,
      isMovingMode,
      zoomInPercent
    } = this.props;

    if (isEditMode && !isDrawing) {
      startDraw();
      if (isDrawingPreshapes) {
        startPreshape(relativePoint);
      }
      startLine(relativePoint);
      return;
    }

    if (isSelectMode || isSelectedMode || isLabelEditMode) {
      const collidedObject: CollidedObject = getCollidedObjectWithPoint(selectableObjects, relativePoint, [], zoomInPercent, selectedFigures);

      if (groups.max()) {
        groups.entrySeq().forEach((group: any) => {
          const selected = listToArray(group[1].selectedObjects);
          if (selected.includes(collidedObject.objectId)) {
            selectObjects(selected);
            setGroupId(group[0]);
          }
        });
      }

      if (collidedObject.objectType !== GeometryType.UNKNOWN) {
        if (selectedObjects.includes(collidedObject.objectId)) {
          startMove(relativePoint);
          return;
        }

        if (isFromGroup(groups, collidedObject.objectId)) {
          groups.entrySeq().forEach((group: any) => {
            const selected = listToArray(group[1].selectedObjects);
            if (selected.includes(collidedObject.objectId)) {
              selectObjects([...selected, collidedObject.objectId]);
              setGroupId(group[0]);
            }
          });
        } else {
          selectObjects([collidedObject.objectId]);
          if (!isMultiselectMode) setGroupId(undefined);
        }

        startMove(relativePoint);
        return;
      }

      selectObjects([]);
      setGroupId(undefined);

      if (isBoxSelectMode) {
        switchToBoxSelectEditMode();
        startBoxSelect(relativePoint);
        return;
      }
    }



    if (isDrawing && !isTooShort(startPoint, relativePoint, zoomInPercent)) {
      if (!isTouchDevice && !isShowingNumpadModal && (!isDrawingPreshapes || isDrawingInteriorWalls)) {
        updateLine(relativePoint);
        endLine();
        if (isTracingFirstWall) {
          toggleIsTracingFirstWall(false);
          showRescaleGridModal();
        }
      } else if (isDrawingPreshapes) {
        if (isTouchDevice || isShowingNumpadModal) {
          updatePreshape(relativePoint);
          endShape();
        }
      }

      if (isTouchDevice || isShowingNumpadModal) {
        updateLine(relativePoint);
        return;
      }

      if (isDrawingInteriorWalls) {
        if (isTouchDevice || isShowingNumpadModal) {
          updateLine(relativePoint);
          endLine();
        }
        endDraw();
        switchToEdit();
        return;
      }
    }

    // for pan we have to use "raw" client coordinates instead of relative svg coordinates
    // reason is that during panning we change viewBox.
    // so position, calculated with previous viewBox, is invalid for current viewBox
    if (!isDrawing && !isMovingMode) startPan({ x: clientX, y: clientY });
  };

  private handleTouchMove = (event: React.TouchEvent<HTMLElement>): void => {
    event.preventDefault();

    const relativePoint = this.relativeCoordinatesForTouchEvent(event);
    // if this is not a single touch, or we don't have a relative point
    if (event.touches.length !== 1 || !relativePoint) {
      return;
    }

    // reset this on touch move
    this.lastTouchDate = undefined;

    this.handlePointerMove(relativePoint, event.touches[0].clientX, event.touches[0].clientY);
    event.stopPropagation();
  }

  private handleMouseMove = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();

    const relativePoint = this.relativeCoordinatesForEvent(event);
    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !relativePoint) {
      return;
    }

    this.handlePointerMove(relativePoint, event.clientX, event.clientY);
    event.stopPropagation();
  }

  private handlePointerMove = (relativePoint: CoordinatePoint, clientX: number, clientY: number): void => {
    const {
      isDrawing,
      isDrawingPreshapes,
      updateLine,
      updatePreshape,
      isMovingMode,
      isResizingMode,
      isRotatingMode,
      updateMove,
      updateResize,
      rotate,
      isPanning,
      panViewBox,
      isBoxSelectMode,
      isBoxSelectEditMode,
      setBoxSelectEndPoint,
      isShowingNumpadModal,
    } = this.props;

    if (isShowingNumpadModal) return;

    if (isBoxSelectMode && isBoxSelectEditMode) {
      setBoxSelectEndPoint(relativePoint);
      return;
    }

    if (isMovingMode && !isBoxSelectEditMode) {
      updateMove(relativePoint);
      return;
    }

    if (isResizingMode) {
      updateResize(relativePoint);
      return;
    }

    if (isRotatingMode) {
      rotate(relativePoint);
      return;
    }

    if (isResizingMode) {
      updateResize(relativePoint);
      return;
    }

    if (isDrawing) {
      if (isDrawingPreshapes) {
        updatePreshape(relativePoint);
      } else {
        updateLine(relativePoint);
      }

      return;
    }

    if (isPanning) {
      panViewBox({ x: clientX, y: clientY });
    }
  };

  private handleTouchEnd = (event: React.TouchEvent<HTMLElement>): void => {
    // any active touch ends here..
    this.isTouching = false;

    // don't do this here, it will stop the context menu from displaying
    // event.preventDefault();

    // note that on touch end we will use event.changedTouches instead of
    // event.touches- the later is not available here
    const relativePoint = this.relativeCoordinatesForTouchEvent(event);
    if (event.changedTouches.length !== 1 || !relativePoint) {
      return;
    }

    // if now is 1 full second after lastTouchDate then show the context menu and bail
    if (this.lastTouchDate && new Date().getTime() > (this.lastTouchDate + 1000)) {
      this.lastTouchDate = undefined;

      event.target.dispatchEvent.call(event.target, new MouseEvent('contextmenu', {
        bubbles: true,
        clientX: event.changedTouches[0].clientX,
        clientY: event.changedTouches[0].clientY
      }));

      event.stopPropagation();
      return;
    }

    this.handlePointerUp(relativePoint);
    event.stopPropagation();
  }

  private handleMouseUp = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();

    const relativePoint = this.relativeCoordinatesForEvent(event);
    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !relativePoint) {
      return;
    }

    this.handlePointerUp(relativePoint);
    event.stopPropagation();
  }

  private handlePointerUp = (relativePoint: CoordinatePoint): void => {
    const {
      endLine,
      isDrawing,
      isEditMode,
      startPreshape,
      startLine,
      startPoint,
      endPoint,
      isDrawingInteriorWalls,
      isDrawingPreshapes,
      updateLine,
      updatePreshape,
      endShape,
      endDraw,
      switchToEdit,
      isMovingMode,
      endMove,
      isResizingMode,
      endResize,
      isRotatingMode,
      endRotate,
      isPanning,
      endPan,
      hasObjectMoved,
      selectableObjects,
      selectObjects,
      selectedFigures,
      isBoxSelectEditMode,
      boxSelectState,
      setBoxSelectObjectIds,
      groups,
      setGroupId,
      zoomInPercent
    } = this.props;

    if (isMovingMode) {
      endMove(relativePoint);
      if (!hasObjectMoved) {
        // if an object hasn't been moved treat the event as a click
        const collidedObject: CollidedObject = getCollidedObjectWithPoint(selectableObjects, relativePoint, [], zoomInPercent, selectedFigures);

        if (groups.max()) {
          groups.entrySeq().forEach((group: any) => {
            const selectedObjects = listToArray(group[1].selectedObjects);
            if (selectedObjects.includes(collidedObject.objectId)) {
              selectObjects(selectedObjects);
            }
          });
        }

        if (collidedObject.objectType !== GeometryType.FIGURE) {
          selectObjects([collidedObject.objectId]);
        }
      }
    }

    if (isEditMode && !isDrawing) {
      if (isDrawingPreshapes) {
        startPreshape(relativePoint);
      } else {
        startLine(relativePoint);
      }
      return;
    }

    if (isResizingMode) {
      endResize(relativePoint);
    }

    if (isRotatingMode) {
      endRotate(relativePoint);
    }

    if (isPanning) {
      endPan();
    }

    if (isBoxSelectEditMode) {
      const { startPoint: boxSelectStartPoint, endPoint } = boxSelectState;

      const boxSelectedItems = getIdsInSelectBox(boxSelectStartPoint, endPoint, selectableObjects);

      setBoxSelectObjectIds(boxSelectedItems);

      if (groups.max()) {
        groups.entrySeq().some((group: any) => {
          const selectedObjects = listToArray(group[1].selectedObjects);
          const unGroupedObjectIds = boxSelectedItems.filter((objectId: string) => !selectedObjects.includes(objectId));
          const hasBoxSelectedFigureFromGroup = selectedObjects.some((objectId: string) => boxSelectedItems.includes(objectId));
          if (hasBoxSelectedFigureFromGroup) {
            if (unGroupedObjectIds.length > 0) {
              selectObjects([...unGroupedObjectIds, ...selectedObjects]);
            } else {
              selectObjects(selectedObjects);
            }
            setGroupId(group[0]);
            return hasBoxSelectedFigureFromGroup;
          }
          selectObjects(boxSelectedItems);
          setGroupId(undefined);
          return false;
        });
      } else {
        selectObjects(boxSelectedItems);
      }
    }

    if (isDrawing && !isTooShort(startPoint, relativePoint, zoomInPercent)) {
      if (isDrawingInteriorWalls || !isDrawingPreshapes) {
        if (startPoint.x === endPoint.x && startPoint.y === endPoint.y) return;
        updateLine(relativePoint);
        endLine();
      } else if (isDrawingPreshapes) {
        updatePreshape(relativePoint);
        endShape();
      }

      if (isDrawingInteriorWalls) {
        // do not call endLine here- it was already done in pointer down
        endDraw();
        switchToEdit();
      }
    }
  };

  private handleMouseWheel = (mouseEvent: React.WheelEvent<HTMLElement>): void => {
    const location = this.relativeCoordinatesForEvent(mouseEvent);
    if (location) {
      const { zoomViewBox } = this.props;
      const increments: number = Math.sign(mouseEvent.deltaY);
      zoomViewBox({ location, increments });
    }
  };

  private handlePan = (panEvent: HammerEvent): void => {
    const { isDrawing, isMovingMode, updateLine, updateMove } = this.props;
    const point = this.getPointToSvgPoint(panEvent.center);
    if (!this.panStarted || !point) {
      return;
    }

    panEvent.preventDefault();

    if (isMovingMode) {
      updateMove(point);
    } else if (isDrawing) {
      updateLine(point);
    }
  };

  private handlePanEnd = (panEvent: HammerEvent): void => {
    const { startPoint, endLine, endMove, isDrawing, isMovingMode, isDrawingPreshapes } = this.props;

    const point = this.getPointToSvgPoint(panEvent.center);
    if (!this.panStarted || !point) {
      return;
    }
    this.panStarted = false;

    panEvent.preventDefault();

    if (isMovingMode) {
      endMove(point);
    } else if (!isDrawingPreshapes && isDrawing && (startPoint as Point).pointId === (point as Point).pointId) {
      endLine();
    }
  };

  private handlePanStart = (panEvent: HammerEvent): void => {
    const {
      isAdjustingDrawPoint,
      isDrawing,
      startDraw,
      startLine,
      selectableObjects,
      isSelectMode,
      isEditMode,
      isLabelEditMode,
      isSelectedMode,
      startMove,
      selectedObjects,
      selectedFigures,
      isShowingNumpadModal,
      zoomInPercent
    } = this.props;

    const point = this.getPointToSvgPoint(panEvent.center);
    if (isAdjustingDrawPoint || !point) {
      return;
    }
    this.panStarted = true;

    panEvent.preventDefault();

    // reset this on pan
    this.lastTouchDate = undefined;

    if (isSelectMode || isSelectedMode || isLabelEditMode) {
      const collidedObject: CollidedObject = getCollidedObjectWithPoint(selectableObjects, point, [], zoomInPercent, selectedFigures);

      if (collidedObject.objectType !== GeometryType.UNKNOWN && selectedObjects.includes(collidedObject.objectId)) {
        startMove(point);
      }
    } else if (isEditMode && !isDrawing && !isShowingNumpadModal) {
      startDraw();
      startLine(point);
    }
  };

  private handlePinch = (pinchEvent: HammerInput): void => {
    const { endPan, isPanning: wasPanning, panViewBox, startPan, zoomViewBox, isDrawing } = this.props;
    const { center, scale } = pinchEvent;

    if (isDrawing) {
      return;
    }

    // 2 finger panning in hammerjs conflicts with pinching. Based on whether
    // the user is moving their fingers closer or further apart during
    // the gesture we determine if it's 2 finger panning or pinching
    const isPanningNow = Math.abs(1 - scale) < 0.2;

    // panning
    if (isPanningNow) {
      const { x, y } = center;
      if (wasPanning) {
        panViewBox({ x, y });
      } else {
        startPan({ x, y });
      }
    } else if (wasPanning) {
      // pinching
      endPan();
    }

    const location = this.getPointToSvgPoint(center);
    if (location) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const increments = 0.5 * ((pinchEvent as any).additionalEvent === 'pinchin' ? 1 : -1);
      // pinchin/pinchout is unreliable- use scale instead..
      // see https://github.com/hammerjs/hammer.js/issues/1141
      let increments = 0;
      if (scale < this.lastPinchingScale) {
        // zoom in
        increments = 0.5;
      } else if (scale > this.lastPinchingScale) {
        // zoom out
        increments = -0.5;
      }

      // persist the scale
      this.lastPinchingScale = scale;

      // zoom here
      zoomViewBox({ location, increments });
    }
  };

  private handlePinchStart = (pinchEvent: HammerEvent): void => {
    const { x, y } = pinchEvent.center;
    const { startPan, isDrawing } = this.props;

    // reset this on pinch
    this.lastTouchDate = undefined;

    if (isDrawing) {
      return;
    }

    startPan({ x, y });
  };

  private handlePinchEnd = (): void => {
    const { endPan, isDrawing } = this.props;

    if (isDrawing) {
      return;
    }

    // reset this here
    this.lastPinchingScale = 1;
    endPan();
  };

  private relativeCoordinatesForEvent = (mouseEvent: React.MouseEvent<HTMLElement>): CoordinatePoint | undefined => {
    const mousePoint: CoordinatePoint = { x: mouseEvent.clientX, y: mouseEvent.clientY };
    return this.getPointToSvgPoint(mousePoint);
  };

  private relativeCoordinatesForTouchEvent = (event: React.TouchEvent<HTMLElement>): CoordinatePoint | undefined => {
    if (event.touches.length === 0 && event.changedTouches.length !== 0) {
      const touchPoint: CoordinatePoint = { x: event.changedTouches[0]?.clientX, y: event.changedTouches[0]?.clientY };
      return this.getPointToSvgPoint(touchPoint);
    }

    const touchPoint: CoordinatePoint = { x: event.touches[0]?.clientX, y: event.touches[0]?.clientY };
    return this.getPointToSvgPoint(touchPoint);
  };

  private getPointToSvgPoint = (point: CoordinatePoint): CoordinatePoint | undefined =>
    this.sketchRef.current ? this.sketchRef.current.getPointToSvgPoint(point) : undefined;

  // we can set the mode that we start in here.
  public componentDidMount = (): void => {
    const { switchToSelection } = this.props;
    switchToSelection();
  };

  public render(): JSX.Element {
    const { isLoading } = this.props;
    if (isLoading) {
      return <Throbber css={throbberStyle} />;
    }

    return (
      // consider https://www.npmjs.com/package/react-use-gesture instead of Hammer
      <Hammer
        onPinch={this.handlePinch}
        onPinchStart={this.handlePinchStart}
        onPinchEnd={this.handlePinchEnd}
        onPan={this.handlePan}
        onPanStart={this.handlePanStart}
        onPanEnd={this.handlePanEnd}
        options={{
          recognizers: {
            pinch: {
              enable: true,
            },
            pan: {
              enable: true,
            },
          },
        }}
      >
        { isTouchDevice ? (
          <div
            className="sketchDiv"
            css={style}
            role="presentation"
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            onWheel={this.handleMouseWheel} // this is only for the chrome emulator
          >
            <Sketch ref={this.sketchRef} />
          </div>
        )
          : (
            <div
              className="sketchDiv"
              css={style}
              role="presentation"
              onMouseDown={this.handleMouseDown}
              onMouseMove={this.handleMouseMove}
              onMouseUp={this.handleMouseUp}
              onWheel={this.handleMouseWheel}
            >
              <Sketch ref={this.sketchRef} />
            </div>
          )}
      </Hammer>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    currentSelectedGroupId: editModeSelectors.getCurrentSelectedGroupId(state),
    isMultiselectMode: editModeSelectors.isMultiselectMode(state),
    isDrawing: editModeSelectors.isDrawingMode(state),
    isEditMode: editModeSelectors.isEditMode(state),
    isLabelEditMode: editModeSelectors.isLabelEditMode(state),
    isSelectMode: editModeSelectors.isSelectMode(state),
    isBoxSelectMode: editModeSelectors.isBoxSelectMode(state),
    isBoxSelectEditMode: editModeSelectors.isBoxSelectEditMode(state),
    isSelectedMode: editModeSelectors.isSelectedMode(state),
    isMovingMode: editModeSelectors.isMovingMode(state),
    isResizingMode: editModeSelectors.isResizingMode(state),
    isRotatingMode: editModeSelectors.isRotatingMode(state),
    isLoading: editModeSelectors.isLoading(state),
    isAdjustingDrawPoint: editModeSelectors.isAdjustingDrawPoint(state),
    isPanning: viewportSelectors.isPanning(state),
    hasObjectMoved: moveObjectsSelectors.hasMoved(state),
    selectableObjects: modelSelectors.getSelectableObjects(state),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
    selectedFigures: selectionSelectors.getSelectedFigures(state).map((selectedFigure) => selectedFigure.figureId),
    startPoint: drawSelectors.getStartPoint(state),
    endPoint: drawSelectors.getEndPoint(state),
    isDrawingInteriorWalls: drawSelectors.isDrawingInteriorWalls(state),
    isDrawingPreshapes: drawSelectors.isDrawingPreshapes(state),
    boxSelectState: boxSelectSelectors.getBoxSelectState(state),
    isTracingFirstWall: resizeSketchModalSelectors.isTracingFirstWall(state),
    groups: groupSelectors.getAllGroups(state),
    isShowingNumpadModal: numpadModalSelectors.isShowing(state),
    isShowingMobileMenu: sidebarSelectors.isShowingMobileMenu(state),
    zoomInPercent: viewportSelectors.getZoomInPercent(state),
  }),
  {
    startLine: drawActions.startDraw,
    startLineWithoutSnap: drawActions.startDrawWithoutSnap,
    startPreshape: drawActions.startPreshapeDraw,
    updateLine: drawActions.setEndPoint,
    updatePreshape: drawActions.setPreshapePoints,
    endLine: drawActions.finishSegment,
    endShape: drawActions.finishShape,
    setGroupId: editModeActions.setCurrentSelectedGroupId,
    startDraw: editModeActions.switchToDrawing,
    switchToSelection: editModeActions.switchToSelection,
    switchToEdit: editModeActions.switchToEdit,
    switchToBoxSelectEditMode: editModeActions.switchToBoxSelectEditMode,
    endDraw: editModeActions.switchToSelection,
    startMove: editModeActions.switchToMoving,
    selectObjects: editModeActions.selectObjects,
    startBoxSelect: boxSelectActions.startBoxSelect,
    setBoxSelectEndPoint: boxSelectActions.setBoxSelectEndPoint,
    setBoxSelectObjectIds: boxSelectActions.setBoxSelectObjectIds,
    updateMove: moveObjectsActions.updateMove,
    endMove: moveObjectsActions.endMove,
    updateResize: resizeObjectsActions.updateResize,
    endResize: resizeObjectsActions.endResize,
    rotate: rotateObjectsActions.rotate,
    endRotate: rotateObjectsActions.endRotate,
    zoomViewBox: viewportActions.zoomViewBoxByIncrement,
    startPan: viewportActions.startPan,
    panViewBox: viewportActions.panViewBox,
    endPan: viewportActions.endPan,
    toggleDrawingInteriorWalls: drawActions.toggleDrawingInteriorWalls,
    toggleIsTracingFirstWall: resizeSketchModalActions.toggleIsTracingFirstWall,
    showRescaleGridModal: resizeSketchModalActions.showRescale,
    toggleMobileMenu: sidebarActions.toggleMobileMenu,
  },
)(SketchArea);
