/** @jsx jsx */
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';

import { RootState } from 'reducers/rootReducer';
import { selectors as settingsSelectors } from 'ducks/settings';
import { positionedLabels as colors } from 'config/paletteConfig';
import { CoordinatePoint } from 'types/point';
import { formatAreaLabel } from 'helpers/pixelsToDistance';

const style = css`
  fill: ${colors.base};
  text-anchor: middle;
`;

interface StateProps {
  readonly unitOfMeasure: string;
  readonly precision: number;
  readonly gridSize: number;
}

interface InputProps {
  readonly area: number;
  readonly position: CoordinatePoint;
}

type Props = InputProps & StateProps;

const AreaTypeLabelArea = ({
  area,
  position,
  unitOfMeasure,
  precision,
  gridSize,
}: Props): JSX.Element => (
  <text
    css={style}
    x={position.x}
    y={position.y}
    dy="1em"
  >
    {formatAreaLabel(unitOfMeasure, precision, gridSize)(area)}
  </text>
);

export default connect((state: RootState): StateProps => ({
  unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
  precision: settingsSelectors.getPrecision(state),
  gridSize: settingsSelectors.getGridSizeInFeet(state),
}))(AreaTypeLabelArea);
