import Immutable from 'immutable';

import { Wall } from 'types/wall';

export interface BfsNode {
  nodeId: string;
  parentId: string;
  wallId: string;
}

export const backTrace = (
  parents: { [nodeId: string]: BfsNode },
  startNode: string,
  targetNode: string,
): string[] => {
  const path = [];
  let currentNode = targetNode;
  while (parents[currentNode] && parents[currentNode].nodeId !== startNode) {
    path.push(parents[currentNode].wallId);
    currentNode = parents[currentNode].parentId;
  }
  return path.reverse();
};

export const getAdjacentNodes = (
  walls: Immutable.Map<string, Wall>,
  nodeId: string,
  visited: { [nodeId: string]: boolean },
  exceptWalls: string[],
): BfsNode[] => {
  const adjacentNodes: BfsNode[] = [];
  walls.forEach((wall) => {
    if (exceptWalls.includes(wall.wallId)) {
      return;
    }
    const p1 = wall.points[0];
    const p2 = wall.points[1];

    if (p1 === nodeId && !visited[p2]) {
      adjacentNodes.push({
        nodeId: p2,
        parentId: nodeId,
        wallId: wall.wallId,
      });
    }

    if (p2 === nodeId && !visited[p1]) {
      adjacentNodes.push({
        nodeId: p1,
        parentId: nodeId,
        wallId: wall.wallId,
      });
    }
  });

  return adjacentNodes;
};
