/** @jsx jsx */
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';

import { RootState } from 'reducers/rootReducer';
import { gridConfig } from 'config/gridConfig';
import { CoordinatePoint } from 'types/point';
import { getSnappedCoordinatePointToGrid } from 'helpers/snap/snapToGrid';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { isTouchDevice } from 'helpers/browserDetect';

export const EDIT_CURSOR_CONTAINER = 'editCursorContainer';

const style = css`
  stroke: blue;
`;

interface InputProps {
  readonly cursorPosition: CoordinatePoint;
}

interface StateProps {
  readonly isEditMode: boolean;
  readonly isDrawing: boolean;
  readonly snapDivision: number;
  readonly isShowingNumpadModal: boolean;
}

type Props = InputProps & StateProps;

const EditCursor = ({
  cursorPosition, isEditMode, isDrawing, snapDivision, isShowingNumpadModal
}: Props): JSX.Element | null => {
  if (!isEditMode || isDrawing) {
    return null;
  }

  // do not show this if we are on a touch device and the numpad is not open
  if (isTouchDevice && !isShowingNumpadModal) {
    return null;
  }

  const point = getSnappedCoordinatePointToGrid(cursorPosition, snapDivision);
  const size = gridConfig.cursorSize;

  return (
    <g id={EDIT_CURSOR_CONTAINER}>
      <line css={style} x1={point.x - size} y1={point.y} x2={point.x + size} y2={point.y} />
      <line css={style} x1={point.x} y1={point.y - size} x2={point.x} y2={point.y + size} />
    </g>
  );
};

export default connect((state: RootState): StateProps => ({
  isDrawing: editModeSelectors.isDrawingMode(state),
  isEditMode: editModeSelectors.isEditMode(state),
  snapDivision: settingsSelectors.getPrecision(state),
  isShowingNumpadModal: numpadModalSelectors.isShowing(state),
}))(EditCursor);
