/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { isTouchDevice } from 'helpers/browserDetect';
import { useDrag, DragSourceMonitor, DragPreviewImage } from 'react-dnd';

import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { sidebar } from 'config/paletteConfig';
import { CoordinatePoint, Point } from 'types/point';
import { SketchSymbol } from 'types/sketchSymbol';
import { SketchSymbolGroup } from 'types/sketchSymbolGroup';
import { ItemTypes, SymbolDragItem, LabelOrSymbolDropResult } from 'types/dnd';
import { StampObjectType, StampId } from 'types/stamp';
import { actions as drawActions } from 'ducks/draw/draw';
import { selectors as sidebarSelectors, actions as sidebarActions } from 'ducks/sidebar/sidebar';
import { selectors as symbolSelectors } from 'ducks/model/symbols';
import { selectors as symbolGroupsSelectors } from 'ducks/model/symbolGroups';
import { selectors as symbolPersistenceSelectors, actions as symbolPersistenceActions } from 'ducks/persistence/symbolPersistence';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { ReactComponent as IconUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as IconStamp } from 'assets/icons/stamp.svg';
import { scrollbar } from 'components/globalStyles';
import { withTooltip, TooltipContent } from 'components/elements/Tooltip';
import { ButtonModalHeader } from 'components/modal/Modal';
import { emptyImageSrc } from 'helpers/image';
import {
  sidebarItemStyles, scrollWrapperStyles, headerButtonSelected, sidebarItemSelectedStyles, labelItemTextStyles,
} from './styles';
import Sidebar from './Sidebar';

const symbolItemStyles = css`
  margin-bottom: 3px;
  padding: 7px 25px;
  color: #1a244f;
  background-color: ${sidebar.item.base};
  font-size: 14;
  font-weight: 600;
  text-align: left;
  transition: ${isTouchDevice ? 'background-color 0s linear' : 'background-color 0.15s ease'};
  white-space: nowrap;
  user-select: none;

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    background-color: ${sidebar.item.hovered};
  }

  img {
    max-height: 44px;
    width: 44px;
    margin-right: 25px;
    vertical-align: middle;
    cursor: move;
    cursor: grab;
  }
`;

const ScrollArea = styled.ul(scrollWrapperStyles, scrollbar);

const Button = withTooltip(ButtonModalHeader);
const FolderButton = styled.button(sidebarItemStyles, { marginBottom: 3 });

interface SymbolItemProps {
  readonly symbol: SketchSymbol;
  readonly isSelected: boolean;
  readonly drawPositionedSymbol: (symbolId: string, at: Point | CoordinatePoint) => void;
  readonly setStampId: (symbolId: string) => void;
  readonly close: () => void;
}

const SymbolItem: React.FC<SymbolItemProps> = (props) => {
  const {
    symbol, drawPositionedSymbol, setStampId, isSelected, close,
  } = props;

  const image = <img alt={symbol.name} src={symbol.data} />;

  const item: SymbolDragItem = {
    type: ItemTypes.Symbol,
    symbolId: symbol.symbolId,
    preview: symbol.data,
  };
  const [, drag, preview] = useDrag({
    item,
    isDragging: (monitor: DragSourceMonitor) => {
      if (window.screen.width < 500 && monitor.getItem().symbolId === item.symbolId) close();
      return monitor.getItem().symbolId === item.symbolId;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (draggedItem: SymbolDragItem | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult() as LabelOrSymbolDropResult | undefined;
      monitor.getItemType();
      if (draggedItem && dropResult) {
        drawPositionedSymbol(draggedItem.symbolId, dropResult.position);
      }
    },
  });

  const handleClick = () => setStampId(symbol.symbolId);

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <>
      <DragPreviewImage connect={preview} src={emptyImageSrc} />
      <li css={[symbolItemStyles, isSelected && sidebarItemSelectedStyles]} onClick={handleClick}>
        <div ref={drag} css={labelItemTextStyles}>
          {image}
          {symbol.name}
        </div>
      </li>
    </>
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  );
};

interface InputProps {
  readonly pose: string;
  readonly left?: number;
}

interface StateProps {
  readonly isLoading: boolean;
  readonly symbols: Immutable.Map<string, SketchSymbol>;
  readonly symbolGroups: Immutable.Map<string, SketchSymbolGroup>;
  readonly symbolGroupId: string | null;
  readonly isInStampMode: boolean;
  readonly stampId?: StampId;
}

interface ActionProps {
  readonly loadSymbols: typeof symbolPersistenceActions.loadSymbols;
  readonly loadSymbolGroups: typeof symbolPersistenceActions.loadSymbolGroups;
  readonly drawPositionedSymbol: (symbolId: string, at: Point | CoordinatePoint) => void;
  readonly selectGroup: (categoryId: string | null) => void;
  readonly close: () => void;
  readonly toggleStampMode: () => void;
  readonly setStampId: (objectType: StampObjectType, objectId: string) => void;
}

type Props = InputProps & StateProps & ActionProps;

class SymbolSidebar extends Component<Props> {
  public componentDidMount = (): void => {
    const { loadSymbols, loadSymbolGroups } = this.props;
    loadSymbols();
    loadSymbolGroups();
  };

  private onClose = (): void => {
    const { close } = this.props;
    close();
  };

  private onBack = (): void => {
    const { selectGroup } = this.props;
    selectGroup(null);
  };

  private selectGroup = (symbolGroupId: string): void => {
    const { selectGroup } = this.props;
    selectGroup(symbolGroupId);
  };

  private setStampId = (symbolId: string): void => {
    const { isInStampMode, setStampId } = this.props;
    if (isInStampMode) {
      setStampId('symbol', symbolId);
    }
  };

  public render = (): JSX.Element => {
    const {
      isLoading, symbols, symbolGroupId, symbolGroups, left, pose, drawPositionedSymbol, isInStampMode, toggleStampMode, stampId,
    } = this.props;

    return (
      <Sidebar left={left} pose={pose}>
        <h2>
          <Button onClick={this.onClose} tooltip={messages.closeModal} tooltipPosition="bottom">
            <IconClose />
          </Button>
          <span>{messages.addSymbolTitle}</span>
          {symbolGroupId && (
            <Button onClick={this.onBack} title={messages.addSymbolBack}>
              <IconUp />
            </Button>
          )}
          <Button
            css={isInStampMode && headerButtonSelected}
            onClick={toggleStampMode}
            aria-label={messages.stampMode}
            tooltipPosition="bottom"
            tooltip={<TooltipContent title={messages.stampMode} description={isInStampMode ? 'Leave Stamp Mode' : 'Enter Stamp Mode'} />}
          >
            <IconStamp />
          </Button>
        </h2>
        {isLoading ? (
          messages.loadingSymbols
        ) : (
          <ScrollArea>
            {symbolGroupId
              ? symbols
                .valueSeq()
                .filter((symbol) => symbol.symbolGroupId === symbolGroupId)
                .map((symbol: SketchSymbol) => (
                  <SymbolItem
                    key={symbol.symbolId}
                    symbol={symbol}
                    drawPositionedSymbol={drawPositionedSymbol}
                    close={this.onClose}
                    setStampId={this.setStampId}
                    isSelected={stampId?.objectType === 'symbol' && symbol.symbolId === stampId?.objectId}
                  />
                ))
              : symbolGroups.valueSeq().map((group) => (
                <li key={group.symbolGroupId}>
                  <FolderButton onClick={() => this.selectGroup(group.symbolGroupId)}>
                    {group.name}
                  </FolderButton>
                </li>
              ))}
          </ScrollArea>
        )}
      </Sidebar>
    );
  };
}

export default connect(
  (state: RootState): StateProps => ({
    isLoading: symbolPersistenceSelectors.isLoading(state),
    symbols: symbolSelectors.getAllSymbols(state),
    symbolGroups: symbolGroupsSelectors.getAllSymbolGroups(state),
    symbolGroupId: sidebarSelectors.getSymbolCategory(state),
    isInStampMode: sidebarSelectors.isInStampMode(state),
    stampId: sidebarSelectors.getStampId(state),
  }),
  {
    loadSymbols: symbolPersistenceActions.loadSymbols,
    loadSymbolGroups: symbolPersistenceActions.loadSymbolGroups,
    drawPositionedSymbol: drawActions.drawPositionedSymbol,
    close: sidebarActions.hide,
    selectGroup: sidebarActions.selectSymbolCategory,
    toggleStampMode: sidebarActions.toggleStampMode,
    setStampId: sidebarActions.setStampId,
  },
)(SymbolSidebar);
