/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';

import { positionedLabels as colors } from 'config/paletteConfig';
import { CoordinatePoint } from 'types/point';

const style = css`
  font-weight: bold;
  fill: ${colors.base};
  text-anchor: middle;

  &:hover {
    fill: ${colors.hovered};
  }
`;

const selectedLabel = css`
  fill: ${colors.selected};
`;

interface Props {
  readonly text: string;
  readonly position: CoordinatePoint;
  readonly labelRef: React.RefObject<SVGTextElement>;
  readonly selected: boolean;
}

const AreaTypeLabelText = ({
  text, position, labelRef, selected,
}: Props): JSX.Element => (
  <text
    ref={labelRef}
    css={[
      style,
      selected && selectedLabel,
    ]}
    x={position.x}
    y={position.y}
  >
    {text}
  </text>
);

export default AreaTypeLabelText;
