import { Figure } from 'types/figure';
import { FigureType } from 'types/figureType';
import { SelectableObjects } from 'types/selection';
import { findPath } from 'helpers/path/findPath';
import { getFigurePoints } from 'helpers/model/figurePoints';
import { polygonArea } from 'helpers/polygonArea';

/**
 * find path with respect of area size and uniqueness
 */
export const minimizeFigureArea = (
  selectableObjects: SelectableObjects,
  startNode: string,
  targetNode: string,
  originalFigureWalls: string[],
  somePath: string[],
): string[] => {
  const { walls, points } = selectableObjects;
  let figureWalls = [...originalFigureWalls, ...somePath];

  const figure: Figure = {
    figureId: '',
    walls: figureWalls,
    type: FigureType.BASE,
  };

  let figureArea = polygonArea(
    getFigurePoints(walls, points, figure),
  );
  const exceptWalls = [...originalFigureWalls];
  let path = somePath;
  while (path.length) {
    const newFigureWalls = [...originalFigureWalls, ...path];
    const newFigureArea = polygonArea(
      getFigurePoints(walls, points, { ...figure, walls: newFigureWalls }),
    );

    if (newFigureArea < figureArea) {
      figureArea = newFigureArea;
      figureWalls = newFigureWalls;
    }
    exceptWalls.push(path[path.length - 1]);
    path = findPath(walls, startNode, targetNode, exceptWalls);
  }

  return figureWalls;
};
