/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { toolbar } from 'config/paletteConfig';
import { withAuthEnabled } from 'components/auth/withAuthEnabled';
import { withTooltip, WithTooltipProps } from 'components/elements/Tooltip';
import { isTouchDevice } from 'helpers/browserDetect';


const ButtonStyled = styled.button`
  width: 36px;
  height: 36px;
  margin: 8px;
  padding: 0;
  border-radius: 3px;
  background-color: ${toolbar.button.background};
  color: ${toolbar.button.text};
  border: 0;

  &:hover,
  &:focus {
    background-color: ${toolbar.button.backgroundHover};
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.25;
    background-color: ${toolbar.button.background};
    cursor: default;
    pointer-events: none;

    &:hover {
      background-color: ${toolbar.button.background};
    }
  }

  &:active {
    color: ${toolbar.button.textActive};
  }

  > svg {
    width: ${({ iconSize = 18 }) => iconSize}px;
    height: ${({ iconSize = 21 }) => iconSize}px;
    vertical-align: middle;
  }
`;

const TouchLabel = styled.div`
  margin-top: 4px;
  color: inherit;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

const touchStyle = css`
  width: auto;
  min-width: 52px;
  min-height: 52px;
  height: auto;
  padding: 0 4px;
  text-align: center;
  overflow: hidden;
`;

const activeStyle = css`
  color: ${toolbar.button.textActive};
`;

const Button = withTooltip(ButtonStyled);

interface ToolbarButtonOwnProps {
  touchLabel?: string;
  active?: boolean;
  icon?: React.ReactNode;
  iconActive?: React.ReactNode;
}

type ToolbarButtonProps = ToolbarButtonOwnProps & WithTooltipProps & React.PropsWithoutRef<JSX.IntrinsicElements['button']>;

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  touchLabel, active, children, icon, iconActive, ...restProps
}) => (
  <Button css={[!isTouchDevice && touchStyle, active && activeStyle].filter(Boolean)} {...restProps}>
    {iconActive && active ? iconActive : icon}
    {children}
    {touchLabel && !isTouchDevice && <TouchLabel>{touchLabel}</TouchLabel>}
  </Button>
);

export default ToolbarButton;

export const ProtectedButton = withAuthEnabled(ToolbarButton);
