/** @jsx jsx */
import React from 'react';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';

import { colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import {
  actions as bluePrintImageActions,
  selectors as bluePrintImageSelectors,
} from 'ducks/bluePrintImage/bluePrintImage';
import { ReactComponent as RemoveIcon } from 'assets/icons/trash.svg';

interface InputProps {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
}

interface StateProps {
  readonly locked: boolean;
}

interface ActionProps {
  readonly removeImage: typeof bluePrintImageActions.removeImage;
}

type Props = InputProps & StateProps & ActionProps;

const style = css`
  cursor: pointer;
`;

const SIZE = 60;

class RemoveImageButton extends React.Component<Props> {
  private handleMouseDown = (): void => {
    const { removeImage } = this.props;

    removeImage();
  };

  public render(): JSX.Element | null {
    const {
      x, y, width, locked,
    } = this.props;

    if (locked) {
      return null;
    }

    return (
      <g
        css={style}
        transform={`translate(${x + width}, ${y})`}
        onClick={this.handleMouseDown}
      >
        <circle r={SIZE / 2} cx={0} cy={0} fill="#1A244F" opacity="0.16" filter="url(#dropShadow)" />
        <circle r={SIZE / 2} cx={0} cy={0} fill={colors.white} />
        <RemoveIcon x={-SIZE / 4} y={-SIZE / 4} width={SIZE / 2} height={SIZE / 2} stroke={colors.red} />
      </g>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    locked: bluePrintImageSelectors.isImageLocked(state),
  }),
  {
    removeImage: bluePrintImageActions.removeImage,
  },
)(RemoveImageButton);
