import React, { Component } from 'react';
import styled from '@emotion/styled';
import { isTouchDevice } from 'helpers/browserDetect';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface LabelProps {
  width: number;
}

const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
`;

const Label = styled.label<LabelProps>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width}px;
  height: 20px;

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 10px;
    line-height: 20px;
    font-size: 0.8em;
    text-align: right;
    background-color: #EDEEF5;
    color: #041243;
    transition: .4s;
    border-radius: 20px;
    user-select: none;
  }

  span:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + & > span {
    background-color: #112869;
    color: #FFF;
    text-align: left;
  }

  input:focus + & > span {
    box-shadow: 0 0 4px #0870fd;
  }

  input:checked + & > span:before {
    transform: ${({ width }) => `translateX(${width - (isTouchDevice ? 45 : 27)}px)`};
  }
`;

interface Props {
  readonly id?: string;
  readonly label?: string;
  readonly defaultToggled: boolean;
  readonly toggledText?: string;
  readonly unToggledText?: string;
  readonly width?: number;
  readonly className?: string;

  readonly onValueChanged: (newValue: boolean, changedAreaTypeId?: string) => void;
}

interface State {
  readonly toggled: boolean;
}

class ToggleSwitch extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      toggled: props.defaultToggled,
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    const { defaultToggled } = this.props;
    if (prevProps.defaultToggled !== defaultToggled) {
      this.onUpdate({ toggled: defaultToggled });
    }
  }

  public onUpdate(state: any): void {
    this.setState(state);
  }

  private handleToggle = (): void => {
    const { onValueChanged, id } = this.props;
    this.setState((state: State) => {
      const newValue = !state.toggled;
      onValueChanged(newValue, id);
      return ({ toggled: newValue });
    });
  };

  public render = (): JSX.Element => {
    const {
      label = '',
      toggledText = '',
      unToggledText = '',
      width = 80,
      id,
      className,
    } = this.props;
    const { toggled } = this.state;

    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <>
        <Input
          id={`toggleInput-${id}`}
          type="checkbox"
          checked={toggled}
          onChange={this.handleToggle}
        />
        <Label htmlFor={`toggleInput-${id}`} width={width} className={className}>
          {label}
          <span>{toggled ? toggledText : unToggledText}</span>
        </Label>
      </>
    );
    /* eslint-enable jsx-a11y/label-has-for */
  }
}

export default ToggleSwitch;
