import React from 'react';
import { makeStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import { aciColors } from '../../styles/acistylecolors';
import AciCoreTheme from 'figma/themes/acitheme';

const useStyles = makeStyles(theme => (
  {
 
  headerBase: {
    background: aciColors.grey900,    
    color: aciColors.white,
    minHeight: '0',
  },
  headerTitle: {
    font: AciCoreTheme.typography.useAciFont('font4', true, 'bold'),
    flexGrow: 1,
    textAlign: 'left'
  },
  headerItems: {
    color: aciColors.white,
  },
}));

function AciHeader(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Toolbar
      className={classes.headerBase}
      sx={{
        '@media (min-width:600px)': {
          paddingLeft: '12px',
          paddingRight: '12px',
        },
      }}
    >
      {props.children}
    </Toolbar>
  );
}

export default AciHeader;
