import {
  put, all, takeLatest, call
} from 'redux-saga/effects';

import { actions as pagesActions } from 'ducks/model/pages';
import { actions as areaTypeModalActions } from 'ducks/modal/areaTypeModal';
import { actions as editModeActions } from 'ducks/editMode';

// Action Types
const NAME = 'sketch';

const CLEAR = `${NAME}/CLEAR`;
const DELETE = `${NAME}/DELETE`;
const INITIALISE = `${NAME}/INITIALISE`;

// Action Creators
export const actions = {
  clear: () => ({ type: CLEAR }),
  delete: () => ({ type: DELETE }),
  initialise: () => ({ type: INITIALISE }),
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doInitialise() {
    yield put(pagesActions.add());
  }

  function* doDelete() {
    yield put(editModeActions.switchToLoading());

    // remove pages
    yield put(pagesActions.clear());
    yield put(editModeActions.switchToSelection());

    // hide area type selection modal
    yield put(areaTypeModalActions.hide());
  }

  function* doClearSketch() {
    yield call(doDelete);
    yield call(doInitialise);
  }

  return function* saga() {
    yield all([
      takeLatest(CLEAR, doClearSketch),
      takeLatest(DELETE, doDelete),
      takeLatest(INITIALISE, doInitialise),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
