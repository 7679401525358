import Immutable from 'immutable';

import { Figure, isClosedFigure } from 'types/figure';
import { Wall } from 'types/wall';
import { Point } from 'types/point';
import { WallType } from 'types/wallType';
import { getCommonPoint, getNonCommonPoint, getWallsWithPoint, } from 'helpers/model/wallPoints';
import { getFiguresWithWall, getSharedWalls } from 'helpers/model/figureWalls';
import { getWallPointIds } from 'ducks/copy/copy';

import {
  compose, mapBy,
} from 'helpers/utils';

/**
 * Check if figure is closed, i.e. last pointId === first pointId
 * Here is complication that wall is not oriented,
 * i.e. [ p0, p1 ] [ p2, p1 ] [ p2, p0 ] - is closed figure
 * @param walls - all walls
 * @param figure - testing figure
 * @returns true if figure is closed polygon
 */
export const isClosingFigure = (walls: Immutable.Map<string, Wall>, figure: Figure): boolean => {
  if (figure.walls.length < 3 && figure.walls.length !== 1) {
    return false;
  }

  const firstWallId = figure.walls[0];
  const lastWallId = figure.walls[figure.walls.length - 1];

  const firstWall = walls.get(firstWallId)!;
  const lastWall = walls.get(lastWallId)!;

  if (firstWallId === lastWallId && firstWall.wallType !== WallType.CIRCLE) {
    return false;
  }

  const [p1, p2] = firstWall.points;
  const lastWallPoints = lastWall.points;

  /*
  //TODO: [SSS-250] Look at changing to return Boolean(firstWall.points.filter(p => lastWallPoints.includes(p)));
  //need to figure why the start point is not rendering correctly when using this way though
  */
  // Checks to see if there is a point in common between the lastWall and the firstWall
  return lastWallPoints.includes(p1) || lastWallPoints.includes(p2);
};

export const getLastPoint = (
  walls: Immutable.Map<string, Wall>,
  points: Immutable.Map<string, Point>,
  figure: Figure,
): Point | undefined => {
  if (!figure || !figure.walls.length) {
    return undefined;
  }

  const lastWallId = figure.walls[figure.walls.length - 1];
  const lastWall = walls.get(lastWallId)!;
  let lastPointId = lastWall.points[1];
  if (figure.walls.length > 1) {
    const prevWallId = figure.walls[figure.walls.length - 2];
    lastPointId = getNonCommonPoint(walls, prevWallId, lastWallId);
  }

  const lastPoint = points.get(lastPointId)!;
  return lastPoint;
};

/**
 * Check if base figure is closed
 * @param walls - all walls
 * @param figure - testing figure
 * @returns true if figure is closed polygon from not-inner walls
 */
export const isClosingBaseFigure = (walls: Immutable.Map<string, Wall>, figure: Figure): boolean => {
  const figureWalls = figure.walls.filter(wallId => {
    const wall = walls.get(wallId)!;
    return wall.wallType !== WallType.INTERIOR;
  });
  const nonInteriorWalls = walls.filter(wall => wall.wallType !== WallType.INTERIOR);
  return isClosingFigure(nonInteriorWalls, { ...figure, walls: figureWalls });
};

export const getFigurePoints = (
  walls: Immutable.Map<string, Wall>,
  points: Immutable.Map<string, Point>,
  figure: Figure,
): Immutable.List<Point> => {
  if (!figure || !figure.walls.length) {
    return Immutable.List<Point>();
  }

  const isClosed = isClosedFigure(figure);

  const pointsIds: string[] = compose(
    getWallPointIds,
    mapBy((wallId: string) => walls.get(wallId)),
  )(figure.walls);

  if (!isClosed) {
    const lastPoint = getLastPoint(walls, points, figure);
    if (lastPoint) {
      pointsIds.push(lastPoint.pointId);
    }
  }

  const figuresPoints: Immutable.List<Point> = Immutable.List(pointsIds)
    .map((pointId: string) => points.get(pointId)!);
  return figuresPoints;
};

/**
 * get all figure's points i.e. middle arcPoint too
 */
export const getFigurePointsForDraw = (
  walls: Immutable.Map<string, Wall>,
  points: Immutable.Map<string, Point>,
  figure: Figure,
): Immutable.List<Point> => {
  let figurePoints = Immutable.List<Point>();
  if (!figure || !figure.walls.length) {
    return figurePoints;
  }

  const isClosed = isClosingFigure(walls, figure);

  figure.walls.forEach((wallId, index) => {
    const wall = walls.get(wallId)!;
    let arcPoint: Point | undefined;
    if (wall.wallType === WallType.ARC) {
      arcPoint = points.get(wall.points[2]);
    }
    // add only first point in the wall
    let pointId = wall.points[0];
    if (figure.walls.length > 1) {
      let prevWallId = wallId;
      if (index === 0) {
        if (!isClosed) {
          // figure is not closed return just first not shared point
          pointId = getNonCommonPoint(walls, figure.walls[1], wallId);
          figurePoints = figurePoints.push(points.get(pointId)!);
          if (arcPoint) {
            figurePoints = figurePoints.push(arcPoint);
          }
          return;
        }

        prevWallId = figure.walls[figure.walls.length - 1];
      } else {
        prevWallId = figure.walls[index - 1];
      }

      pointId = getCommonPoint(walls, prevWallId, wallId);
    }
    figurePoints = figurePoints.push(points.get(pointId)!);
    if (arcPoint) {
      figurePoints = figurePoints.push(arcPoint);
    }
  });

  if (!isClosed) {
    const lastPoint = getLastPoint(walls, points, figure);
    if (lastPoint) {
      figurePoints = figurePoints.push(lastPoint);
    }
  }

  return figurePoints;
};

/**
 * get all shared point for specific figure
 * @param walls all walls
 * @param points all points
 * @param figures
 * @param figureId
 */
export const getSharedPoints = (
  walls: Immutable.Map<string, Wall>,
  points: Immutable.Map<string, Point>,
  figures: Immutable.Map<string, Figure>,
  figureId: string,
): Immutable.List<Point> => {
  const figure = figures.get(figureId)!;
  const allFiguresPoints = getFigurePoints(walls, points, figure);
  const sharedWalls = getSharedWalls(figures, figureId);

  // if point belongs to other wall of other figure, it is shared point
  const sharedPoints = allFiguresPoints.filter((p) => {
    const { pointId } = p;
    const wallsWithPoint = getWallsWithPoint(walls, pointId);
    const nonFigureWall = wallsWithPoint.find(wallId => !figure.walls.includes(wallId));
    if (nonFigureWall) {
      return true;
    }

    const sharedWall = wallsWithPoint.find(wallId => sharedWalls.includes(wallId));
    return !!sharedWall;
  });

  return sharedPoints;
};

/**
 * get all non-shared point for specific figure
 * @param walls all walls
 * @param points all points
 * @param figures
 * @param figureId
 */
export const getNonSharedPoints = (
  walls: Immutable.Map<string, Wall>,
  points: Immutable.Map<string, Point>,
  figures: Immutable.Map<string, Figure>,
  figureId: string,
): Immutable.List<Point> => {
  const figure = figures.get(figureId)!;
  const allFiguresPoints = getFigurePoints(walls, points, figure);
  const sharedPointsIds = getSharedPoints(walls, points, figures, figureId).map(p => p.pointId);

  // if point belongs to other wall of other figure, it is shared point
  const nonSharedPoints = allFiguresPoints.filter(p => !sharedPointsIds.includes(p.pointId));

  return nonSharedPoints;
};

/**
 * get all figures which contain specific point
 */
export const getFiguresWithPoint = (
  walls: Immutable.Map<string, Wall>,
  figures: Immutable.Map<string, Figure>,
  pointId: string,
): string[] => {
  const figuresWithPoint: string[] = [];

  const wallsWithPoint = getWallsWithPoint(walls, pointId);
  wallsWithPoint.forEach((wallId) => {
    const allFiguresWithWall = getFiguresWithWall(figures, wallId);
    allFiguresWithWall.forEach((figureId) => {
      if (!figuresWithPoint.includes(figureId)) {
        figuresWithPoint.push(figureId);
      }
    });
  });

  return figuresWithPoint;
};
