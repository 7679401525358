import { toNumber } from 'helpers/environment';

const initialZoom = 15;

export const zoomConfig = {
  zoomIncrementSize: 0.05,
  initialZoom,
  minZoomIncrements: initialZoom - toNumber(process.env.REACT_APP_ZOOM_IN_LIMIT, 30)!,
  maxZoomIncrements: initialZoom + toNumber(process.env.REACT_APP_ZOOM_OUT_LIMIT, 15)!,
};
