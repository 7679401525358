import Immutable from 'immutable';
import {
  all, takeLatest, select, call, put, take
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { RootState } from 'reducers/rootReducer';
import { authConfig } from 'config/authConfig';
import { messages } from 'config/messages';
import { AreaType } from 'types/areaType';
import { PayloadAction } from 'types/payloadAction';
import { SketchModel } from 'types/sketchModel';
import { ApiSketch } from 'types/api/apiSketch';
import { LogLevel } from 'types/log';
import { saveSketch } from 'helpers/save/saveSketch';
import { loadSketch } from 'helpers/save/loadSketch';
import log from 'helpers/log';
import { getAccessToken } from 'effects/auth';
import { actions as areaTypePersistenceActions } from 'ducks/persistence/areaTypePersistence';
import { actions as areaTypesActions, selectors as areaTypesSelectors } from 'ducks/model/areaTypes';
import { actions as sketchSearchModalActions } from 'ducks/modal/sketchSearchModal';
import { actions as sketchActions } from 'ducks/model/sketch';
import { actions as editModeActions } from 'ducks/editMode';
import { actions as pagesActions } from 'ducks/model/pages';
import { actions as viewportActions } from 'ducks/viewport';
import { actions as figuresActions } from 'ducks/model/figures';
import { actions as pointsActions } from 'ducks/model/points';
import { actions as wallsActions } from 'ducks/model/walls';
import { actions as labelsActions } from 'ducks/model/labels';
import { actions as positionedLabelsActions } from 'ducks/model/positionedLabels';
import { actions as positionedSymbolsActions } from 'ducks/model/positionedSymbols';
import { actions as bluePrintImageActions } from 'ducks/bluePrintImage/bluePrintImage';
import * as ApiEffects from 'effects/api';
import { actions as drawActions } from 'ducks/draw/draw';

// Action Types
const NAME = 'sketchPersistence';

const LOAD_SKETCH = `${NAME}/LOAD_SKETCH`;
const SAVE_SKETCH = `${NAME}/SAVE_SKETCH`;
const SET_SKETCH = `${NAME}/SET_SKETCH`;
const SET_CURRENT_SKETCH = `${NAME}/SET_CURRENT_SKETCH`;
const RESET_CURRENT_SKETCH = `${NAME}/RESET_CURRENT_SKETCH`;
const SET_CREATED = `${NAME}/SET_CREATED`;
const REPLACE_MODEL = `${NAME}/REPLACE_MODEL`;
const SET_CHANGED = `${NAME}/SET_CHANGED`;
const LOAD_LAST_SKETCH = `${NAME}/LOAD_LAST_SKETCH`;
export const MODEL_REPLACED = `${NAME}/MODEL_REPLACED`;
export const SET_IS_APPLYING_MODEL = `${NAME}/SET_IS_APPLYING_MODEL`;

// Action Creators
export const actions = {
  loadLastSketch: () => ({
    type: LOAD_LAST_SKETCH,
  }),

  setSketch: () => ({
    type: SET_SKETCH,
  }),

  setCurrentSketch: (sketch: ApiSketch): PayloadAction => ({
    type: SET_CURRENT_SKETCH,
    payload: sketch
  }),

  resetCurrentSketch: () => ({
    type: RESET_CURRENT_SKETCH,
  }),

  setCreated: (isCreated: boolean): PayloadAction => ({
    type: SET_CREATED,
    payload: isCreated,
  }),

  setChanged: (isChanged: boolean): PayloadAction => ({
    type: SET_CHANGED,
    payload: isChanged,
  }),

  loadSketch: (id?: string) => ({
    type: LOAD_SKETCH,
    payload: id
  }),

  saveSketch: (payload?: any) => ({
    type: SAVE_SKETCH,
    payload
  }),

  replaceModel: (payload: SketchModel) => ({
    type: REPLACE_MODEL,
    payload,
  }),

  modelReplaced: () => ({
    type: MODEL_REPLACED,
  }),

  setIsApplyingModel: (payload: boolean) => ({
    type: SET_IS_APPLYING_MODEL,
    payload,
  }),
};

export interface SketchPersistenceState {
  readonly currentSketch: any;
  readonly isCreated: boolean; // model created on BE
  readonly isDirty: boolean; // model changed after load
  readonly isApplyingModel: boolean; // used to delay grabbing model until it has finished
}

// Initial State
const initialState: SketchPersistenceState = {
  /**
   * current sketch object
   */
  currentSketch: {},

  /**
   * If sketch already created on BE
   */
  isCreated: false,

  /**
   * If sketch changed after load/save
   */
  isDirty: false,
  /**
   * If sketch changed after load/save
   */
  isApplyingModel: false,
};

// Selectors
const getState = (rootState: RootState): SketchPersistenceState => rootState.sketchPersistence;

const getSketch = (rootState: RootState): any => rootState.sketchPersistence.currentSketch;

const isCreated = (rootState: RootState): any => rootState.sketchPersistence.isCreated;

const isApplyingModel = (rootState: RootState): any => rootState.sketchPersistence.isApplyingModel;

export const selectors = {
  getState,
  getSketch,
  isCreated,
  isApplyingModel
};

// Reducers
const setCurrentSketchReducer = (state: SketchPersistenceState, sketch: ApiSketch): SketchPersistenceState => ({
  ...state,
  currentSketch: sketch,
});
const resetCurrentSketchReducer = (state: SketchPersistenceState): SketchPersistenceState => ({
  ...state,
  currentSketch: {},
  isCreated: false,
  isDirty: false
});

const setCreatedReducer = (state: SketchPersistenceState, isCreated: boolean): SketchPersistenceState => ({
  ...state,
  isCreated,
});

const setChangedReducer = (state: SketchPersistenceState, isDirty: boolean): SketchPersistenceState => ({
  ...state,
  isDirty,
});

const setIsApplyingModelReducer = (state: SketchPersistenceState, isApplyingModel: boolean): SketchPersistenceState => ({
  ...state,
  isApplyingModel,
});

export const reducer = (
  state: SketchPersistenceState = initialState, action: PayloadAction,
): SketchPersistenceState => {
  switch (action.type) {
    case SET_CURRENT_SKETCH:
      return setCurrentSketchReducer(state, action.payload);

    case RESET_CURRENT_SKETCH:
      return resetCurrentSketchReducer(state);

    case SET_CREATED:
      return setCreatedReducer(state, action.payload);

    case SET_CHANGED:
      return setChangedReducer(state, action.payload);

    case SET_IS_APPLYING_MODEL:
      return setIsApplyingModelReducer(state, action.payload);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* applyModelFromHistory(model: SketchModel) {
    yield put(editModeActions.switchToLoading());
    yield put(actions.setIsApplyingModel(true));

    const pages = model.pages.valueSeq().toArray();
    const points = model.points.valueSeq().toArray();
    const walls = model.walls.valueSeq().toArray();
    const areaTypes = model.areaTypes.valueSeq().toArray();
    const labels = model.labels.valueSeq().toArray();
    const positionedLabels = model.positionedLabels.valueSeq().toArray();
    const positionedSymbols = model.positionedSymbols.valueSeq().toArray();
    const figures = model.figures.valueSeq().toArray();

    yield put(pagesActions.clear());

    yield all(pages.map(page => put(pagesActions.set(Immutable.List(pages)))));

    yield all(points.map(point => put(pointsActions.add(point))));

    yield all(walls.map(wall => put(wallsActions.add(wall))));

    yield put(areaTypesActions.set({ clear: false, areaTypes }));

    yield all(labels.map(label => put(labelsActions.add(label))));

    yield all(positionedLabels.map(positionedLabel => (
      put(positionedLabelsActions.add(positionedLabel)))));

    yield all(positionedSymbols.map(positionedSymbol => (
      put(positionedSymbolsActions.add(positionedSymbol)))));

    yield all(figures.map(figure => put(figuresActions.add(figure))));

    const { image } = model;
    if (image && image.imageUrl) {
      yield put(bluePrintImageActions.addImage(image.imageUrl, image.width, image.height));
    } else {
      yield put(bluePrintImageActions.removeImage());
    }

    yield put(drawActions.setSegmentStartPoint(model.drawState.segmentStartPoint));
    yield put(drawActions.setStartPointWithoutSnapping(model.drawState.startPoint));
    yield put(drawActions.setEndPointWithoutSnapping(model.drawState.endPoint, false));

    yield put(editModeActions.switchToSelection());
    yield put(actions.setIsApplyingModel(false));
  }

  function* applyModelFromSketchData(model: SketchModel) {
    yield put(editModeActions.switchToLoading());
    yield put(actions.setIsApplyingModel(true));

    const { pages, pagesOrder } = model;
    const pagesArray = pages.toIndexedSeq().toArray().sort((a, b) => pagesOrder.indexOf(a.pageId) - pagesOrder.indexOf(b.pageId));

    yield put(pagesActions.clear());
    yield put(pagesActions.setPagesAndPlaceFirst(Immutable.List(pagesArray)));

    const areaTypes = model.areaTypes.valueSeq().toArray();
    yield put(areaTypesActions.set({ clear: false, areaTypes }));

    const { image } = model;
    if (image && image.imageUrl) {
      yield put(bluePrintImageActions.addImage(image.imageUrl, image.width, image.height));
    } else {
      yield put(bluePrintImageActions.removeImage());
    }

    yield put(editModeActions.switchToSelection());

    yield put(actions.setIsApplyingModel(false));
  }

  function* replaceModelFromHistory(model: SketchModel) {
    yield put(pagesActions.clearSketchPad());
    yield put(editModeActions.switchToSelection());
    yield call(applyModelFromHistory, model);
  }

  function* replaceModelFromSketchData(model: SketchModel) {
    yield take(sketchActions.delete);
    yield put(editModeActions.switchToSelection());
    yield call(applyModelFromSketchData, model);
  }

  function* doReplaceModel({ payload }: PayloadAction) {
    const model = payload as SketchModel;
    yield call(replaceModelFromHistory, model);
    yield put(actions.modelReplaced());
  }

  function* doLoadSketch(action: any) {
    let response: ApiSketch | null = null;
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      yield put(editModeActions.switchToLoading());
      yield put(viewportActions.resetViewBox());

      try {
        const accessToken: string = yield call(getAccessToken);
        response = yield call(ApiEffects.getSketch, accessToken, action.payload);
        yield localStorage.setItem('currentSketch', JSON.stringify(response));
        yield call(doGetLocalStorageSketch);
        if (response) {
          yield put(actions.setCurrentSketch(response));
          yield put(actions.setCreated(true));
          yield put(sketchSearchModalActions.hide());
        }
        toast(messages.sketchLoaded, {
          type: toast.TYPE.INFO,
        });
      } catch (e) {
        log(LogLevel.error, e);
      }
    } else {
      toast(messages.sketchLoadFailed, {
        type: toast.TYPE.WARNING,
      });
    }
    return response;
  }

  function* doSaveSketch(action: any): any {
    if (authConfig.enabled || process.env.REACT_APP_USE_MOCKS) {
      const rootState = yield select();
      const { currentSketch, isCreated }: SketchPersistenceState = selectors.getState(rootState);

      yield put(editModeActions.switchToSelection());
      yield put(pagesActions.upsertCurrentPageObjects());
      // waits for pngs to be created before creating sketchData
      yield take(pagesActions.updatePreviewPngs);

      let sketchData: ApiSketch;
      if (action?.payload) {
        const { sketchName, propertyAddress, orderId } = action.payload;
        sketchData = yield call(doGenerateSketchData, { sketchName, propertyAddress, orderId });
      } else {
        const { sketch_name, property_address, order_id }: any = selectors.getSketch(rootState);
        sketchData = yield call(doGenerateSketchData, { sketchName: sketch_name, propertyAddress: property_address, orderId: order_id });
      }

      try {
        const accessToken = yield call(getAccessToken);
        if (!isCreated) {
          const response: ApiSketch | null = yield call(ApiEffects.createSketch, accessToken, sketchData);
          if (response) {
            yield put(actions.setCurrentSketch(response));
            yield put(actions.setCreated(true));
            yield doStoreSketch({ payload: response });
            toast(messages.sketchCreated, {
              type: toast.TYPE.INFO,
              toastId: 'saved'
            });
          }
        } else {
          yield call(ApiEffects.saveSketch, accessToken, currentSketch.id, sketchData);
          toast(messages.sketchSaved, {
            type: toast.TYPE.INFO,
            toastId: 'saved'
          });
          yield put(actions.setCurrentSketch(sketchData));
          yield doStoreSketch({ payload: sketchData });
        }
      } catch (e) {
        log(LogLevel.error, e);
        toast(messages.sketchSaveFailed, {
          type: toast.TYPE.ERROR,
        });
      }
    } else {
      toast(messages.sketchSaveFailed, {
        type: toast.TYPE.WARNING,
      });
    }
  }

  function* doGenerateSketchData(action?: any) {
    const { sketchName, propertyAddress, orderId } = action;
    const rootState: RootState = yield select();
    let sketchData: ApiSketch;

    if (action) {
      sketchData = yield call(saveSketch, rootState, sketchName, propertyAddress, orderId);
    } else {
      sketchData = yield call(saveSketch, rootState, '', '', '');
    }
    return sketchData;
  }

  function* doStoreSketch(action: any) {
    const { payload } = action;
    let sketchData: ApiSketch;

    yield put(editModeActions.switchToSelection());
    yield put(pagesActions.upsertCurrentPageObjects());
    // waits for pngs to be created before creating sketchData
    yield take(pagesActions.updatePreviewPngs);

    const { sketch_name, property_address, order_id } = getSketch(yield select());

    if (action?.payload) {
      sketchData = yield call(doGenerateSketchData, { sketchName: payload.sketch_name, propertyAddress: payload.property_address, orderId: payload.order_id });
    } else {
      sketchData = yield call(doGenerateSketchData, { sketchName: sketch_name, propertyAddress: property_address, orderId: order_id });
    }
    yield put(actions.setChanged(false));

    if (!action?.loadedSketch) {
      yield put(actions.setCurrentSketch(sketchData));
      yield put(actions.setCreated(true));
      yield localStorage.setItem('currentSketch', JSON.stringify(sketchData));
      toast(messages.sketchSaved, {
        type: toast.TYPE.INFO,
        toastId: 'saved'
      });
    }

    // STP SSS-420 I'm adding a callback for use by the mobile application
    if (action.callback) {
      action.callback();
    }
  }

  function* doStoreCreated() {
    const { isCreated }: { isCreated: boolean } = selectors.getState(yield select());
    yield localStorage.setItem('isCreated', isCreated.toString());
  }

  function* doSetChanged() {
    const { isDirty }: { isDirty: boolean } = selectors.getState(yield select());
    yield localStorage.setItem('isDirty', isDirty.toString());
  }

  function* doLoadLastSketch() {
    const { isCreated }: { isCreated: boolean } = selectors.getState(yield select());

    if (isCreated) {
      yield put(actions.loadSketch());
    } else {
      const localStorageSketch = localStorage.getItem('currentSketch');
      if (localStorageSketch) {
        yield doGetLocalStorageSketch();
      }
    }
  }

  function* doGetLocalStorageSketch() {
    const localStorageSketch = localStorage.getItem('currentSketch');
    const areaTypes: Immutable.Map<string, AreaType> = areaTypesSelectors.getAllAreaTypes(yield select());
    if (localStorageSketch) {
      const response = JSON.parse(localStorageSketch);
      yield put(actions.setCurrentSketch(response));
      yield put(actions.setCreated(true));
      const model = loadSketch(response, areaTypes);
      yield put(areaTypePersistenceActions.loadAreaTypes());
      yield call(replaceModelFromSketchData, model);
    }
    yield put(actions.setChanged(false));
  }

  function* doResetLocalSketch() {
    yield localStorage.removeItem('currentSketch');
  }

  return function* saga() {
    yield all([
      takeLatest(LOAD_LAST_SKETCH, doLoadLastSketch),
      takeLatest(LOAD_SKETCH, doLoadSketch),
      takeLatest(SAVE_SKETCH, doSaveSketch),
      takeLatest(REPLACE_MODEL, doReplaceModel),
      takeLatest(SET_SKETCH, doStoreSketch),
      takeLatest(SET_CREATED, doStoreCreated),
      takeLatest(SET_CHANGED, doSetChanged),
      takeLatest(RESET_CURRENT_SKETCH, doResetLocalSketch),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
