import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { reorderFigures } from 'helpers/draw/reorderFigures';
import { selectors as pagesSelectors } from 'ducks/model/pages';
import { selectors as editModeSelectors } from 'ducks/editMode';
import Figure from 'components/sketch/Figure/Figure';
import FigureWalls from 'components/sketch/Figure/FigureWalls';
import FigurePoints from 'components/sketch/Figure/FigurePoints';
import PositionedSymbol from 'components/sketch/PositionedSymbol/PositionedSymbol';
import PositionedLabel from 'components/sketch/PositionedLabel/PositionedLabel';

export const figureWallsId = (figureId: string): string => `${figureId}-FigureWalls`;
export const figurePointsId = (figureId: string): string => `${figureId}-FigurePoints`;

interface InputProps {
  /* eslint-disable-next-line react/no-unused-prop-types */
  readonly pageId: string;
}

interface StateProps {
  readonly figureIds: string[];
  readonly symbolIds: string[];
  readonly labelIds: string[];
  readonly selectedObjects: Immutable.List<string>;
}

type Props = InputProps & StateProps;

const PageComponent = ({ selectedObjects, figureIds, symbolIds, labelIds }: Props): JSX.Element => {
  // we have to draw selected walls and points over all figures due to z-order
  const reorderedFigureIds = reorderFigures(figureIds, selectedObjects.toArray());
  // A little cludgy, but need to wrap all top level elements in SVG group elements,
  // so that we can assign an id for showing/hding when "printing" to image.
  return (
    <>
      {reorderedFigureIds.map((figureId) => (
        <g id={figureId} key={figureId}>
          <Figure figureId={figureId} />
        </g>
      ))}
      {reorderedFigureIds.map((figureId) => (
        <g id={figureWallsId(figureId)} key={figureWallsId(figureId)}>
          <FigureWalls figureId={figureId} />
        </g>
      ))}
      {reorderedFigureIds.map((figureId) => (
        <g id={figurePointsId(figureId)} key={figurePointsId(figureId)}>
          <FigurePoints figureId={figureId} />
        </g>
      ))}
      {symbolIds.map((positionedSymbolId) => (
        <g id={positionedSymbolId} key={positionedSymbolId}>
          <PositionedSymbol positionedSymbolId={positionedSymbolId} />
        </g>
      ))}
      {labelIds.map((positionedLabelId) => (
        <g id={positionedLabelId} key={positionedLabelId}>
          <PositionedLabel positionedLabelId={positionedLabelId} />
        </g>
      ))}
    </>
  );
};

export default connect(
  (state: RootState, { pageId }: InputProps): StateProps => ({
    figureIds: pagesSelectors.getPageFiguresIds(state, pageId),
    symbolIds: pagesSelectors.getPagePositionedSymbolsIds(state, pageId),
    labelIds: pagesSelectors.getPagePositionedLabelsIds(state, pageId),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
  }),
)(PageComponent);
