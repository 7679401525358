import { PositionedAreaLabel } from 'types/positionedAreaLabel';
import { FigureType } from 'types/figureType';
import { GlaType } from 'types/glaType';

export interface OpenFigure {
  figureId: string;
  walls: string[]; // arrays of wall's ids
  type: FigureType;
  areaTypeId?: string;
  isMultiFamily?: boolean;
  subAreaText?: string;
  colorFill?: string;
}

export interface ClosedFigure extends OpenFigure {
  areaTypeId: string;
  glaType: GlaType;
  glaMultiplier: number;
  positionedAreaLabel?: PositionedAreaLabel;
  meta?: any;
  isMultiFamily?: boolean;
  subAreaText?: string;
  colorFill: string;
}

export type Figure = OpenFigure | ClosedFigure;

export const isClosedFigure = (figure: Figure): figure is ClosedFigure => 'areaTypeId' in figure;

export interface FigureChange {
  walls?: string[];
  type?: FigureType;
  areaTypeId?: string;
  glaType?: GlaType;
  positionedAreaLabel?: PositionedAreaLabel;
  glaMultiplier?: number;
  meta?: any;
  isMultiFamily?: boolean;
  subAreaText?: string;
  colorFill?: string;
}
