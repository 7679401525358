import React from 'react';
import styled from '@emotion/styled';

import { geometryConfig } from 'config/geometryConfig';
import { CoordinatePoint } from 'types/point';
import { LineOrientationType } from 'types/lineOrientationType';
import { getLineOrientation } from 'helpers/getLineOrientation';

import { arrowsStyles } from './styles';

const Arrow = styled.path(arrowsStyles);

const TRIANGLE_OFFSET = geometryConfig.movePointer.offset;
const TRIANGLE_SIZE = geometryConfig.movePointer.size;

interface Props {
  readonly p1: CoordinatePoint;
  readonly p2: CoordinatePoint;
}

const MoveWallPointer = ({ p1, p2 }: Props): JSX.Element | null => {
  const lineType = getLineOrientation(p1, p2);
  if (lineType !== LineOrientationType.VERTICAL && lineType !== LineOrientationType.HORIZONTAL) {
    return null;
  }

  const c: CoordinatePoint = {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };

  let path1;
  let path2;

  if (lineType === LineOrientationType.VERTICAL) {
    const c1 = {
      x: c.x + TRIANGLE_OFFSET,
      y: c.y,
    };
    // ">"
    path1 = `M
      ${c1.x - TRIANGLE_SIZE} ${c1.y - TRIANGLE_SIZE}
      ${c1.x - TRIANGLE_SIZE} ${c1.y + TRIANGLE_SIZE}
      ${c1.x + TRIANGLE_SIZE / 2} ${c1.y}
    Z`;

    const c2 = {
      x: c.x - TRIANGLE_OFFSET,
      y: c.y,
    };
    // "<"
    path2 = `M
      ${c2.x + TRIANGLE_SIZE} ${c2.y - TRIANGLE_SIZE}
      ${c2.x + TRIANGLE_SIZE} ${c2.y + TRIANGLE_SIZE}
      ${c2.x - TRIANGLE_SIZE / 2} ${c2.y}
    Z`;
  } else {
    const c1 = {
      x: c.x,
      y: c.y - TRIANGLE_OFFSET,
    };
    // "/\"
    path1 = `M
      ${c1.x - TRIANGLE_SIZE} ${c1.y + TRIANGLE_SIZE}
      ${c1.x + TRIANGLE_SIZE} ${c1.y + TRIANGLE_SIZE}
      ${c1.x} ${c1.y - TRIANGLE_SIZE / 2}
    Z`;

    const c2 = {
      x: c.x,
      y: c.y + TRIANGLE_OFFSET,
    };
    // "\/"
    path2 = `M
      ${c2.x - TRIANGLE_SIZE} ${c2.y - TRIANGLE_SIZE}
      ${c2.x + TRIANGLE_SIZE} ${c2.y - TRIANGLE_SIZE}
      ${c2.x} ${c2.y + TRIANGLE_SIZE / 2}
    Z`;
  }

  return (
    <>
      <Arrow d={path1} />
      <Arrow d={path2} />
    </>
  );
};

export default MoveWallPointer;
