import { default as label } from './label';
import { default as settings } from './settings';
import { default as sketches } from './sketches';
import { default as sketch } from './sketch';
import { default as symbol } from './symbol';
import { default as symbolGroup } from './symbolGroup';
import { default as area } from './area';
import { default as company } from './dev-aci-auth-lambda';

export default {
  label,
  settings,
  symbol,
  sketches,
  sketch,
  'symbol-group': symbolGroup,
  company,
  area,
};
