/** @jsx jsx */
import React from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { colors } from 'config/paletteConfig';
import { SubAreaType } from 'types/subArea';
import { Figure } from 'types/figure';
import { GlaType } from 'types/glaType';
import { getFigureAreaType } from 'helpers/label/areaTypeLabel';
import { selectors as subAreaModalSelectors, actions as subAreaModalActions } from 'ducks/modal/subAreaModal';
import { selectors as areaTypePersistenceSelectors, actions as areaTypePersistenceActions } from 'ducks/persistence/areaTypePersistence';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { withTooltip, TooltipContent } from 'components/elements/Tooltip';
import { scrollbar } from 'components/globalStyles';
import { appearFromTop } from 'components/animations';
import { Modal, ButtonModalHeader } from 'components/modal/Modal';
import { sidebarItemStyles, scrollWrapperStyles } from 'components/sidebar/styles';
import LabelTextEdit from 'components/elements/LabelTextEdit';

import { ReactComponent as IconAdd } from 'assets/icons/plus.svg';
import uuid from 'uuid/v4';

const background = css`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(20, 28, 50, 0.18);
  z-index: 1000;
`;

const ButtonAreaType = styled.button(sidebarItemStyles);
const ModalAnimated = posed(Modal)(appearFromTop);

const ListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 3px;

  &:hover,
  &:focus {
    background-color: ${colors.white};

    & .glaModifierContainer {
      opacity: 1;
    }
  }
`;

const buttonStyle = css`
  padding-right: 105px;

  &:focus + div > label {
    opacity: 1;
  }
`;

const selected = css`
  background-color: ${colors.white};
`;

const inputStyles = css`
  box-sizing: border-box;
  margin-bottom: 3px;

  &:hover {
    background-color: ${colors.white};
  }

  input {
    width: 100%;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    &::-webkit-input-placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
    &:-ms-input-placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
    &::placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
  }
`;

const ScrollArea = styled.ul(scrollWrapperStyles, scrollbar, { maxHeight: `calc(100vh - ${75 + 46 + 15}px)` });
const Button = withTooltip(ButtonModalHeader);

const LoadingMessage = styled.div`
  text-align: center;
  padding: 10px;
`;

interface StateProps {
  readonly isLoading: boolean;
  readonly figure: Figure;
  readonly figures: Immutable.Map<string, Figure>;
}

interface ActionProps {
  readonly loadAreaTypes: typeof areaTypePersistenceActions.loadAreaTypes;
  readonly changeSubAreaForFigure: typeof subAreaModalActions.changeSubAreaForFigure;
  readonly close: typeof subAreaModalActions.hide;
}

const mockSubAreas = [
  {
    subAreaLabelId: uuid(),
    text: 'Unit 1',
  },
  {
    subAreaLabelId: uuid(),
    text: 'Unit 2',
  },
];

type Props = StateProps & ActionProps;

interface State {
  readonly glaType?: GlaType;
  readonly isEditing: boolean;
  readonly editLabelId?: string;
}

class SubAreaModal extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      editLabelId: undefined,
    };
  }

  public componentDidMount = (): void => {
    const { loadAreaTypes } = this.props;
    loadAreaTypes();
  };

  private makeAddSubArea = (subAreaType: SubAreaType) => () => {
    const { figure, changeSubAreaForFigure, close } = this.props;

    const subAreaText = subAreaType?.text || 'Sub Area';

    changeSubAreaForFigure(figure.figureId, figure.areaTypeId || 'postiveArea', subAreaText);

    close();
  };

  private makeStartEditing = (subAreaId?: string): void => this.setState(() => ({
    isEditing: true,
    editLabelId: subAreaId,
  }));

  private finishEditing = (value: string): void => {
    mockSubAreas.push({ subAreaLabelId: uuid(), text: value });

    this.setState(() => ({
      isEditing: false,
      editLabelId: undefined,
    }));
  }

  public render = (): JSX.Element => {
    const { isLoading, figure } = this.props;
    const { isEditing, editLabelId } = this.state;

    return (
      <div css={background}>
        <ModalAnimated top={75} pose="enter" initialPose="exit">
          <h2>
            <span>Add Sub Area</span>
            <Button
              onClick={() => this.makeStartEditing()}
              aria-label={messages.addLabelCreate}
              tooltipPosition="bottom"
              tooltip={<TooltipContent title={messages.addLabelCreate} description="Add a new custom label to the list" />}
            >
              <IconAdd />
            </Button>
          </h2>
          {isLoading ? (
            <LoadingMessage>
              {messages.loadingAreaTypes}
              ...
            </LoadingMessage>
          ) : (
            <ScrollArea>
              {isEditing && <LabelTextEdit labelId={editLabelId} css={[sidebarItemStyles, inputStyles]} onEditFinished={this.finishEditing} />}
              {mockSubAreas
                .map((subAreaType) => (
                  <ListItem key={subAreaType.subAreaLabelId}>
                    <ButtonAreaType
                      css={[
                        buttonStyle,
                        subAreaType.subAreaLabelId === getFigureAreaType(figure) && selected,
                      ]}
                      onClick={this.makeAddSubArea(subAreaType)}
                    >
                      {subAreaType.text}
                    </ButtonAreaType>
                  </ListItem>
                ))}
            </ScrollArea>
          )}
        </ModalAnimated>
      </div>
    );
  };
}

export default connect(
  (state: RootState): StateProps => {
    const figureId = subAreaModalSelectors.getSelectedFigureId(state)!;
    return {
      isLoading: areaTypePersistenceSelectors.isLoading(state),
      figure: figuresSelectors.getFigureById(state, figureId),
      figures: figuresSelectors.getAllFigures(state),
    };
  },
  {
    loadAreaTypes: areaTypePersistenceActions.loadAreaTypes,
    changeSubAreaForFigure: subAreaModalActions.changeSubAreaForFigure,
    close: subAreaModalActions.hide,
  },
)(SubAreaModal);
