import { all, call, fork } from 'redux-saga/effects';

import { createSagas as mount } from 'ducks/mount';
import { createSagas as sketchSaga } from 'ducks/model/sketch';
import { createSagas as pagesSaga } from 'ducks/model/pages';
import { createSagas as groupsSaga } from 'ducks/groupObjects';
import { createSagas as labelsSaga } from 'ducks/model/labels';
import { createSagas as positionedLabelsSaga } from 'ducks/model/positionedLabels';
import { createSagas as labelPersistenceSaga } from 'ducks/persistence/labelPersistence';
import { createSagas as settingsPersistenceSaga } from 'ducks/persistence/settingsPersistence';
import { createSagas as sketchPersistenceSaga } from 'ducks/persistence/sketchPersistence';
import { createSagas as sketchesPersistenceSaga } from 'ducks/persistence/sketchesPersistence';
import { createSagas as areaTypePersistenceSaga } from 'ducks/persistence/areaTypePersistence';
import { createSagas as symbolPersistenceSaga } from 'ducks/persistence/symbolPersistence';
import { createSagas as networkPersistenceSaga } from 'ducks/persistence/networkPersistence';
import { createSagas as areaTypeModalSaga } from 'ducks/modal/areaTypeModal';
import { createSagas as subAreaModalSaga } from 'ducks/modal/subAreaModal';
import { createSagas as splitWallSaga } from 'ducks/draw/splitWall';
import { createSagas as moveObjectsSaga } from 'ducks/moveObjects';
import { createSagas as resizeObjectsSaga } from 'ducks/resizeObjects';
import { createSagas as rotateObjectsSaga } from 'ducks/rotateObjects';
import { createSagas as drawSaga } from 'ducks/draw/draw';
import { createSagas as drawCurveSaga } from 'ducks/draw/drawCurve';
import { createSagas as drawWithKeypadSaga } from 'ducks/draw/drawWithKeypad';
import { createSagas as bluePrintImageSaga } from 'ducks/bluePrintImage/bluePrintImage';
import { createSagas as resizeSketchSaga } from 'ducks/bluePrintImage/resizeSketch';
import { createSagas as removeObjectsSaga } from 'ducks/remove/removeObjects';
import { createSagas as pageGroupsModalSaga } from 'ducks/modal/pageGroupsModal';
import { createSagas as drawAndPanSaga } from 'ducks/draw/drawAndPan';
import { createSagas as historySaga } from 'ducks/history/history';
import { createSagas as viewportSaga } from 'ducks/viewport';
import { createSagas as printPreviewModalSaga } from 'ducks/modal/printPreviewModal';
import { createSagas as copySaga } from 'ducks/copy/copy';
import { createSagas as inspectionSaga } from 'ducks/inspection';
import { createSagas as editModeSaga } from 'ducks/editMode';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,func-names
export const rootSaga = function* () {
  yield all([
    fork(sketchSaga()),
    fork(pagesSaga()),
    fork(groupsSaga()),
    fork(labelsSaga()),
    fork(positionedLabelsSaga()),
    fork(labelPersistenceSaga()),
    fork(settingsPersistenceSaga()),
    fork(sketchPersistenceSaga()),
    fork(sketchesPersistenceSaga()),
    fork(areaTypePersistenceSaga()),
    fork(symbolPersistenceSaga()),
    fork(networkPersistenceSaga()),
    fork(areaTypeModalSaga()),
    fork(subAreaModalSaga()),
    fork(splitWallSaga()),
    fork(moveObjectsSaga()),
    fork(resizeObjectsSaga()),
    fork(rotateObjectsSaga()),
    fork(drawSaga()),
    fork(drawCurveSaga()),
    fork(drawWithKeypadSaga()),
    fork(bluePrintImageSaga()),
    fork(resizeSketchSaga()),
    fork(removeObjectsSaga()),
    fork(pageGroupsModalSaga()),
    fork(drawAndPanSaga()),
    fork(historySaga()),
    fork(viewportSaga()),
    fork(printPreviewModalSaga()),
    fork(copySaga()),
    fork(inspectionSaga()),
    fork(editModeSaga()),
  ]);

  yield call(mount());
};
