export const polygonSidebarItems = [
  {
    label: 'Rectangle',
    options: {
      sides: 4, rotation: 0, maintainAspectRatio: false,
    },
  },
  // {
  //   label: 'Triangle',
  //   options: { sides: 3 },
  // },
  // {
  //   label: 'Pentagon',
  //   options: { sides: 5 },
  // },
  // {
  //   label: 'Hexagon',
  //   options: { sides: 6, rotation: 0 },
  // },
  // {
  //   label: 'Heptagon',
  //   options: { sides: 7 },
  // },
  // {
  //   label: 'Octagon',
  //   options: { sides: 8, rotation: -22.5 },
  // },
  // {
  //   label: 'Nonagon',
  //   options: { sides: 9 },
  // },
  // {
  //   label: 'Decagon',
  //   options: { sides: 10, rotation: 0 },
  // },
  {
    label: 'Circle',
    options: { sides: 0, rotation: 0 },
  },
];
