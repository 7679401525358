import React from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { Figure } from 'types/figure';
import { Point, CoordinatePoint, isPoint } from 'types/point';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import FigurePoint from 'components/sketch/FigurePoint/FigurePoint';
import { PointType } from 'types/pointType';

interface InputProps {
  /* eslint-disable-next-line react/no-unused-prop-types */
  readonly figureId: string;
}

interface StateProps {
  readonly figure: Figure;
  readonly figurePoints: Immutable.List<Point | CoordinatePoint>;
  readonly isMovingMode: boolean;
  readonly movedPoints: Immutable.List<Point>;
}

type Props = InputProps & StateProps;

const FigurePoints = ({
  figure, figurePoints, isMovingMode, movedPoints,
}: Props): JSX.Element | null => {
  if (!figure || (!figurePoints || !figurePoints.size)) {
    return null;
  }

  const polygon = figurePoints.map(
    (p): Point | CoordinatePoint => (isMovingMode ? mapMovedPoint(movedPoints, p) : p),
  );

  return (
    <>
      {polygon.toArray().map(point => isPoint(point) && (point as Point).pointType !== PointType.CENTER
        ? <FigurePoint key={point.pointId} pointId={point.pointId} />
        : null)}
    </>
  );
};

export default connect((state: RootState, { figureId }: InputProps): StateProps => {
  const figure = figuresSelectors.getFigureById(state, figureId);
  return ({
    figure,
    figurePoints: figuresSelectors.getFigurePointsForDraw(state, figure.figureId),
    isMovingMode: editModeSelectors.isMovingMode(state),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
  });
})(FigurePoints);
