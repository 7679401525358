// Polyfills for IE11, including only what's necessary in order to save bundle size
/* eslint-disable import/first, @typescript-eslint/no-var-requires */
require('dotenv').config();

import 'core-js/features/promise';
import 'core-js/features/string';
import 'core-js/features/object';
import 'core-js/features/array';
import 'core-js/features/math';
import 'core-js/features/map';
import 'core-js/features/set';
import 'core-js/features/weak-set';
import 'core-js/features/function';
import 'core-js/features/number';
import 'core-js/web/url';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import configureStore from './configureStore';
import * as serviceWorker from './serviceWorker';

const store = configureStore();
ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
