import React from 'react';
import styled from '@emotion/styled';

import { colors } from 'config/paletteConfig';

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${colors.black};
  cursor: pointer;
  padding: 8px;
`;

const Check = styled.span`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #BBCEE6;
  border-radius: 50%;
  transition: border-color 0.18s ease-out, transform 0.12s ease-in;

  label:hover &::after,
  input:focus + &::after {
    transform: scale(0.75);
  }

  input:checked + & {
    border-color: ${colors.blue};
  }

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${colors.blue};
    transition: transform 0.18s ease-out, opacity 0.12s ease;
    opacity: 0.25;
    transform: scale(0);
  }

  input:checked + &::after {
    opacity: 1;
    transform: scale(1);
  }
`;

type RadioButtonProps = {
  id: string;
  label: string;
  value: string;
  checked: boolean;
}

const RadioButton = ({
  id,
  label,
  value,
  checked,
}: RadioButtonProps): JSX.Element => (
  <Label>
    <Input type="radio" name={id} value={value} defaultChecked={checked} />
    <Check />
    {label}
  </Label>
);

export default RadioButton;
