// /<summary>
// /The purpose of this class is to hold all Typographic Definitions we utilize in this library in a single place.
// /</summary>
//----------

// /<summary>
// /An Object with all the defined Font Families we support and use
// /</summary>
export const aciFontFamilies = {
  PublicSans: 'Public Sans',
};

// /<summary>
// /An Object with all the defined Font Weights we support and use
// /</summary>
export const aciFontWeights = {
  bold: '700', // Default Bold Font
  semiBold: '600',
  normal: '400', // Default Normal Font
};

// /<summary>
// /An Object with all the defined Font Sizes we support and use.
// /</summary>
export const aciFontSizes = {
  font1: '32px',
  font2: '24px',
  font3: '20px',
  font4: '16px',
  font5: '14px',
  font6: '12px',
  font7: '10px',
};

// /<summary>
// /An Object with all the defined Line Heights for the given font.
// /These were originally in the same object as aciFontSizes, but not everything worked nicely with the line heights
// /</summary>
export const aciLineHeights = {
  font1: '36px',
  font2: '28px',
  font3: '24px',
  font4: '20px',
  font5: '16px',
  font6: '16px',
  font7: '12px',
};


// /<summary>
// /Using these predefined and supported Typography values, create a font shorthand string so we do not need to redefine the fonts everywhere.
// /</summary>
// /</param name="size">A number or string indicating the font size. "font#" with # being 1-7 (1 being the biggest), or the numbers directly; Defaults to "font1"</param>
// /</param name="lineHeight">A boolean indicating if we wish to use the corresponding lineHeight to the font, or not. Defaults to "true"</param>
// /</param name="state">A string indicated if the font is "normal", "bold", or "semiBold"; Defaults to "normal"</param>
// /</param name="family">A string referring to one of the supported aciFontFamilies (No Spaces); Defaults to "PublicSans"</param>
// /<returns>A css font shorthand string to be included in a style under the "font:" attribute</returns>
export function useAciFont(size, lineHeight = true, state = 'normal', family = 'PublicSans') {
  let fontSize = '';
  let fontLine = '';
  let fontWeight = '';
  let fontFamily = '';

  // Handle Font Size:
  size = size.trim().toLowerCase();

  if (isNaN(size) === false) {
    size = `font${size}`;
  }

  if (size in aciFontSizes) {
    fontSize = aciFontSizes[size];
  } else {
    size = 'font1';
    fontSize = aciFontSizes.font1;
  }

  // Handle Line Height Size:
  if (lineHeight) {
    fontLine = aciLineHeights[size];
  }

  // Handle Font Weight
  state = state.trim().toLowerCase();
  switch (state) {
    case 'bold':
      fontWeight = aciFontWeights.bold;
      break;
    case 'semibold':
    case 'semi':
      fontWeight = aciFontWeights.semiBold;
      break;
    default:
      fontWeight = aciFontWeights.normal;
  }

  // Handle Font Family
  state = state.trim();
  if (family in aciFontFamilies) {
    fontFamily = aciFontFamilies[family];
  } else {
    fontFamily = aciFontFamilies.PublicSans;
  }

  const combinedSizeAndLine = (lineHeight)
    ? `${fontSize}/${fontLine}`
    : `${fontSize}/${fontSize}`;

  // Return Font ShortHand String: In Order (only size and family are required): style, variant, weight, size/height, font-family
  return `${fontWeight} ${combinedSizeAndLine} ${fontFamily}`;
}

// /<summary>
// /A single collection of all the Typography Definitions
// /</summary>
const AciStyleTypography = {
  useAciFont,
  aciFontFamilies,
  aciFontWeights,
  aciFontSizes,
};

export default AciStyleTypography;
