import React from 'react';
import {
  Page, Image, View, Document
} from '@react-pdf/renderer';

function SketchPrintPdf({ previewPages }: any): JSX.Element {
  // eslint-disable-next-line
  const constructedPdfEls = previewPages.map(previewPage => (<Page><View><Image src={previewPage} /></View></Page>));

  return <Document>{constructedPdfEls}</Document>;
}

export default SketchPrintPdf;
