import Immutable from 'immutable';
import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { toast } from 'react-toastify';
import uuid from 'uuid/v4';
import md5 from 'md5';

import { RootState } from 'reducers/rootReducer';
import { Label } from 'types/label';
import { LabelType } from 'types/labelType';
import { LogLevel } from 'types/log';
import { drawLabel } from 'ducks/draw/drawLabel';
import { removeLabel } from 'ducks/remove/removeLabel';
import log from 'helpers/log';
import { compose, defaultTo } from 'helpers/utils';
import { getAccessToken } from 'effects/auth';
import { messages } from 'config/messages';
import * as ApiEffects from 'effects/api';

import { selectors as networkPersistenceSelectors, actions as networkPersistenceActions } from 'ducks/persistence/networkPersistence';

interface SetLabelsPayload {
  clear: boolean;
  labels: Label[];
}

// Action Creators
export const actions = {
  add: createAction('labels/add')<Label>(),

  set: createAction('labels/set')<SetLabelsPayload>(),

  update: createAction('labels/update')<Label>(),

  remove: createAction('labels/remove')<string>(),

  create: createAction('labels/create')<string>(),

  delete: createAction('labels/delete')<string>(),
};

export type Actions = ActionType<typeof actions>

export interface LabelsState {
  readonly labels: Immutable.Map<string, Label>;
  readonly currentCustomLabelId: string | null;
}

// Initial State
const initialState: LabelsState = {
  labels: Immutable.Map<string, Label>(),
  currentCustomLabelId: null,
};

// Selectors
const getLabelsState = (rootState: RootState): LabelsState => rootState.labels;

const getAllLabels = (
  rootState: RootState,
): Immutable.Map<string, Label> => getLabelsState(rootState).labels;

const getNonPresetTextLabelsIds = (
  rootState: RootState,
): string[] => getAllLabels(rootState).valueSeq().reduce(
  (labelIds: string[], label: Label) => (label.type !== LabelType.PRESET_TEXT)
    ? [...labelIds, label.labelId]
    : labelIds,
  [],
);

const labelsMemo: any = {};

// eslint-disable-next-line arrow-body-style
const getTextLabels = (rootState: RootState): Immutable.Map<string, Label> => {
  const labels = getAllLabels(rootState)
    .filter((label: Label) => (label.type === LabelType.CUSTOM_TEXT) || (label.type === LabelType.PRESET_TEXT))
    // eslint-disable-next-line no-nested-ternary
    .sort((l1: Label, l2: Label) => defaultTo(0)(l1.index) === defaultTo(0)(l2.index)
      // if indexes are equal then sort by alpha
      ? l1.text.localeCompare(l2.text)
      : defaultTo(0)(l1.index!) < defaultTo(0)(l2.index!)
        ? -1
        : 1);

  const labelOrderHash = md5(labels.reduce((acc, { labelId, text }) => `${acc}${labelId}${text}`, ''));

  if (labelsMemo[labelOrderHash]) {
    return labelsMemo[labelOrderHash];
  }
  labelsMemo[labelOrderHash] = labels;
  return labels;
};

const getPresetLabels = (rootState: RootState): Immutable.Map<string, Label> => getAllLabels(rootState)
  .filter((label: Label) => label.type === LabelType.PRESET_TEXT);

const getCustomLabels = (rootState: RootState): Immutable.Map<string, Label> => getAllLabels(rootState)
  .filter((label: Label) => label.type === LabelType.CUSTOM_TEXT);

const getLabelById = (
  rootState: RootState,
  labelId: string,
): Label | undefined => getAllLabels(rootState).get(labelId);

const getCurrentCustomLabel = (rootState: RootState): Label | undefined => {
  const { currentCustomLabelId }: LabelsState = getLabelsState(rootState);
  return currentCustomLabelId !== null ? getLabelById(rootState, currentCustomLabelId) : undefined;
};

export const selectors = {
  getAllLabels,
  getNonPresetTextLabelsIds,
  getTextLabels,
  getPresetLabels,
  getCustomLabels,
  getCurrentCustomLabel,
  getLabelById,
};

// Reducers
const addLabelReducer = (state: LabelsState, label: Label): LabelsState => ({
  ...state,
  labels: state.labels.set(label.labelId, label),
  currentCustomLabelId: label.type === LabelType.CUSTOM_TEXT ? label.labelId : state.currentCustomLabelId,
});

const updateLabelReducer = (state: LabelsState, label: Label): LabelsState => {
  const { labels } = state;
  const oldValues: Label = labels.get(label.labelId)!;
  const newLabel = {
    ...oldValues,
    ...label,
  };
  return {
    ...state,
    labels: labels.set(label.labelId, newLabel),
    currentCustomLabelId: label.type === LabelType.CUSTOM_TEXT ? label.labelId : state.currentCustomLabelId,
  };
};

const setLabelsReducer = (state: LabelsState, { clear, labels }: SetLabelsPayload): LabelsState => ({
  ...state,
  labels: compose(
    (newMap: Map<string, Label>) => clear ? newMap : state.labels.merge(newMap),
  )(Immutable.Map<string, Label>(labels.map((label) => [label.labelId, label]))),
});

const removeLabelReducer = (state: LabelsState, labelId: string): LabelsState => ({
  ...state,
  labels: state.labels.remove(labelId),
  currentCustomLabelId: state.currentCustomLabelId === labelId ? null : state.currentCustomLabelId,
});

export const reducer = (state: LabelsState = initialState, action: Actions): LabelsState => {
  switch (action.type) {
    case getType(actions.add):
      return addLabelReducer(state, action.payload);
    case getType(actions.update):
      return updateLabelReducer(state, action.payload);
    case getType(actions.set):
      return setLabelsReducer(state, action.payload);
    case getType(actions.remove):
      return removeLabelReducer(state, action.payload);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doCreateLabel({ payload }: ReturnType<typeof actions.create>) {
    const accessToken: string = yield call(getAccessToken);
    const labelObj = {
      id: uuid(),
      text: payload,
      // @ts-ignore
      label_type: 'General',
      isDefault: false,
    };
    let response: any;

    try {
      response = yield call(ApiEffects.createLabel, accessToken, labelObj);
    } catch (e) {
      toast(messages.cannotDeleteLabel, {
        type: toast.TYPE.WARNING,
      });
    }

    // if offline, store the action in a log to send when connection is established again
    const isOffline = networkPersistenceSelectors.getIsOffline(yield select());
    if (isOffline) {
      const offlineModeObject = {
        ...labelObj,
        requestType: 'POST',
        type: 'label'
      };
      yield put(networkPersistenceActions.updateOfflineModeActions(offlineModeObject));
      yield call(drawLabel, labelObj);
    } else {
      yield call(drawLabel, response);
    }
  }

  function* doUpdateLabel({ payload }: ReturnType<typeof actions.update>) {
    const accessToken: string = yield call(getAccessToken);
    const labelObj = {
      id: payload.labelId,
      text: payload.text,
      index: payload.index,
      label_type: payload.type,
      isDefault: payload.isDefault,
      meta: payload.meta
    };
    // if the user is not logged in, accessToken is an object
    if (typeof (accessToken) === 'string') {
      try {
        yield call(ApiEffects.updateLabel, accessToken, labelObj);
      } catch (e) {
        toast(messages.cannotNotUpdateLabel, {
          type: toast.TYPE.WARNING,
        });
        log(LogLevel.error, e);
      }
    }

    // if offline, store the action in a log to send when connection is established again
    const isOffline = networkPersistenceSelectors.getIsOffline(yield select());
    if (isOffline) {
      const offlineModeObject = {
        ...payload,
        id: payload.labelId,
        label_type: payload.label_type,
        requestType: 'PUT',
        type: 'label'
      };
      yield put(networkPersistenceActions.updateOfflineModeActions(offlineModeObject));
    }

    yield doUpdateLocalStorageLabels({ labelObj, type: 'PUT' });
  }

  function* doDeleteLabel({ payload }: ReturnType<typeof actions.delete>) {
    const accessToken: string = yield call(getAccessToken);
    // if the user is not logged in, accessToken is an object
    if (typeof (accessToken) === 'string') {
      try {
        yield call(ApiEffects.deleteLabel, accessToken, payload);
      } catch (e) {
        toast(messages.cannotNotUpdateLabel, {
          type: toast.TYPE.ERROR,
        });
        log(LogLevel.error, e);
      }
    }

    // if offline, store the action in a log to send when connection is established again
    const isOffline = networkPersistenceSelectors.getIsOffline(yield select());
    if (isOffline) {
      const deletedLabel = getLabelById(yield select(), payload);
      const offlineModeObject = {
        ...deletedLabel,
        requestType: 'DELETE',
        type: 'label'
      };
      yield call(removeLabel, payload);
      yield put(networkPersistenceActions.updateOfflineModeActions(offlineModeObject));
    } else {
      yield call(removeLabel, payload);
      yield doUpdateLocalStorageLabels({ payload, type: 'DELETE' });
    }
  }

  function* doUpdateLocalStorageLabels(action: any) {
    const labelsArray = JSON.parse(localStorage.getItem('label')! || '[]');
    if (labelsArray.length) {
      // POST is handled by request.ts
      // as unique uuid's are generated offline, a response from the API when online again replaces local storage id with the correct database id

      // PUT
      if (action.type === 'PUT') {
        const newLabelsArray = labelsArray.map((label: any) => label.id !== action.labelObj.id ? label : action.labelObj);
        localStorage.setItem('label', JSON.stringify(newLabelsArray));
      }
      // DELETE
      if (action.type === 'DELETE') {
        const filteredArray = labelsArray.filter((label: any) => label.id !== action.payload);
        localStorage.setItem('label', JSON.stringify(filteredArray));
      }
    }
    yield;
  }

  return function* saga() {
    yield all([
      takeLatest(actions.create, doCreateLabel),
      takeLatest(actions.update, doUpdateLabel),
      takeLatest(actions.delete, doDeleteLabel),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
