interface ScaleConfig {
  getScale: (base: number, min: number, zoomInPercent: number) => number;
}

export const scaleConfig: ScaleConfig = {
/**
     * simple scale function
     * @param startingSize the base or starting size (with zoom at 100%)
     * @param minSize the min size
     * @param zoomInPercent the current zoom in %
     * @returns base * zoomInPercent / 100 or min
     */
  getScale: (startingSize: number, minSize: number, zoomInPercent: number): number => {
    if (!zoomInPercent) {
      return minSize;
    }

    return Math.max(minSize, startingSize * zoomInPercent / 100);
  },
};
