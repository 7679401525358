import { Precision } from 'types/precision';
import { toNumber } from 'helpers/environment';

const gridSize: number = toNumber(process.env.REACT_APP_GRID_SIZE_PIXELS, 20)!;
const gridSizeInFeet: number = toNumber(process.env.REACT_APP_GRID_SIZE_FEET, 1)!;

const SMALLGRID_STROKE_WIDTH = 1;

export const gridConfig = {
  /** snapping grid (small cell's-grid) grid size for snapping in pixels */
  gridSize,
  /** number of feet per grid unit */
  gridSizeInFeet,

  /**
   * big grid (strong lines) added only for better visual orientation
   * NB: for now, not configurable via environment
   */
  bigGridSize: gridSize * 5,

  /**
   * Size of grid cursor pointer
   */
  cursorSize: gridSize / 4.0,

  smallGridStrokeWidth: `${SMALLGRID_STROKE_WIDTH}px`,
  bigGridStrokeWidth: `${SMALLGRID_STROKE_WIDTH * 2}px`,

  /**
   * Default grid snapping precision
   */
  defaultGridLines: 'light',

  /**
   * Default grid snapping precision
   */
  defaultPrecision: Precision.whole,

  /**
   * Default unit of measurement
   */
  defaultUnitOfMeasurement: 'decimalFeet',
};
