import { put, select } from 'redux-saga/effects';
import uuid from 'uuid/v4';

import { Page } from 'types/page';
import { Point, CoordinatePoint, isPoint } from 'types/point';
import { PositionedSymbol } from 'types/positionedSymbol';
import { actions as pointsActions } from 'ducks/model/points';
import { actions as positionedSymbolActions } from 'ducks/model/positionedSymbols';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* drawPositionedSymbol(symbolId: string, point: Point | CoordinatePoint) {
  let pointId = isPoint(point) ? point.pointId : undefined;
  if (!pointId) {
    pointId = uuid();
    yield put(pointsActions.add({ pointId, ...point }));
  }
  const positionedSymbolId = uuid();
  const positionedSymbol: PositionedSymbol = {
    positionedSymbolId,
    symbolId,
    pointId,
    rotation: 0,
  };
  yield put(positionedSymbolActions.add(positionedSymbol));

  const page: Page | undefined = pagesSelectors.getCurrentPage(yield select());
  if (page) yield put(pagesActions.upsertObject(page.pageId, positionedSymbolId, positionedSymbol));

  return positionedSymbolId;
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
