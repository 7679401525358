import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';

// Action Types
const NAME = 'resizeSketchModal';

const SHOW_RESIZE = `${NAME}/SHOW_RESIZE`;
const HIDE_RESIZE = `${NAME}/HIDE_RESIZE`;
const SHOW_RESCALE = `${NAME}/SHOW_RESCALE`;
const HIDE_RESCALE = `${NAME}/HIDE_RESCALE`;
const SHOW_KNOWN_LENGTH = `${NAME}/SHOW_KNOWN_LENGTH`;
const TOGGLE_IS_TRACING_FIRST_WALL = `${NAME}/TOGGLE_IS_TRACING_FIRST_WALL`;

export interface ResizeSketchModalState {
  readonly isResizeShowing: boolean;
  readonly isRescaleShowing: boolean;
  readonly isKnownLengthPromptShowing: boolean;
  readonly isTracingFirstWall: boolean;
}

// Initial State
const initialState: ResizeSketchModalState = {
  isResizeShowing: false,
  isRescaleShowing: false,
  isKnownLengthPromptShowing: false,
  isTracingFirstWall: false,
};

// Action Creators
export const actions = {
  hideResize: () => ({ type: HIDE_RESIZE }),
  showResize: () => ({ type: SHOW_RESIZE }),
  hideRescale: () => ({ type: HIDE_RESCALE }),
  showRescale: () => ({ type: SHOW_RESCALE }),
  showKnownLengthPrompt: () => ({ type: SHOW_KNOWN_LENGTH }),
  toggleIsTracingFirstWall: (isTracing: boolean) => ({ type: TOGGLE_IS_TRACING_FIRST_WALL, payload: isTracing }),
};

// Selectors
const getResizeSketchModal = (rootState: RootState): ResizeSketchModalState => rootState.resizeSketchModal;

const isResizeShowing = (rootState: RootState): boolean => getResizeSketchModal(rootState).isResizeShowing;

const isRescaleShowing = (rootState: RootState): boolean => getResizeSketchModal(rootState).isRescaleShowing;

const isKnownLengthPromptShowing = (rootState: RootState): boolean => getResizeSketchModal(rootState).isKnownLengthPromptShowing;

const isTracingFirstWall = (rootState: RootState): boolean => getResizeSketchModal(rootState).isTracingFirstWall;

export const selectors = {
  isResizeShowing,
  isRescaleShowing,
  isKnownLengthPromptShowing,
  isTracingFirstWall,
};

// Reducers
const showResizeReducer = (state: ResizeSketchModalState): ResizeSketchModalState => ({
  ...state,
  isResizeShowing: true,
  isRescaleShowing: false,
  isKnownLengthPromptShowing: false,
});

const hideResizeReducer = (state: ResizeSketchModalState): ResizeSketchModalState => ({
  ...state,
  isResizeShowing: false,
  isRescaleShowing: false,
  isKnownLengthPromptShowing: false,
});

const showRescaleReducer = (state: ResizeSketchModalState): ResizeSketchModalState => ({
  ...state,
  isResizeShowing: false,
  isRescaleShowing: true,
  isKnownLengthPromptShowing: false,
});

const showKnownLengthPromptReducer = (state: ResizeSketchModalState): ResizeSketchModalState => ({
  ...state,
  isResizeShowing: false,
  isRescaleShowing: false,
  isKnownLengthPromptShowing: true,
});

const hideRescaleReducer = (state: ResizeSketchModalState): ResizeSketchModalState => ({
  ...state,
  isResizeShowing: false,
  isRescaleShowing: false,
  isKnownLengthPromptShowing: false,
});

const toggleIsTracingFirstWallReducer = (state: ResizeSketchModalState, isTracing: boolean): ResizeSketchModalState => ({
  ...state,
  isTracingFirstWall: isTracing,
});

export const reducer = (
  state: ResizeSketchModalState = initialState,
  action: PayloadAction,
): ResizeSketchModalState => {
  switch (action.type) {
    case HIDE_RESIZE:
      return hideResizeReducer(state);

    case SHOW_RESIZE:
      return showResizeReducer(state);

    case HIDE_RESCALE:
      return hideRescaleReducer(state);

    case SHOW_RESCALE:
      return showRescaleReducer(state);

    case SHOW_KNOWN_LENGTH:
      return showKnownLengthPromptReducer(state);

    case TOGGLE_IS_TRACING_FIRST_WALL:
      return toggleIsTracingFirstWallReducer(state, action.payload);

    default:
      return state;
  }
};
