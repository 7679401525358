/** @jsx jsx */
import React from 'react';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';
import { useDrag, DragSourceMonitor, DragPreviewImage } from 'react-dnd';

import { colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { GeometryType } from 'types/geometryType';
import { Figure } from 'types/figure';
import { FigureType } from 'types/figureType';
import { AreaType } from 'types/areaType';
import { Label } from 'types/label';
import { SketchSymbol } from 'types/sketchSymbol';
import { SketchSymbolGroup } from 'types/sketchSymbolGroup';
import { PositionedSymbol } from 'types/positionedSymbol';
import { PositionedLabel } from 'types/positionedLabel';
import { lighten } from 'helpers/colorTransforms';
import { getFigureAreaType } from 'helpers/label/areaTypeLabel';
import { ItemTypes, PageDragItem, PageDropResult } from 'types/dnd';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as modelSelectors } from 'ducks/model/model';
import { actions as pageGroupsModalActions } from 'ducks/modal/pageGroupsModal';
import { selectors as figuresSelectors } from 'ducks/model/figures';
import { selectors as areaTypesSelectors } from 'ducks/model/areaTypes';
import { selectors as symbolsSelectors } from 'ducks/model/symbols';
import { selectors as symbolGroupsSelectors } from 'ducks/model/symbolGroups';
import { selectors as labelsSelectors } from 'ducks/model/labels';
import { emptyImageSrc } from 'helpers/image';

export const listItemStyle = css`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  height: 24px;
  border: 0;
  padding: 10px 15px;
  background-color: ${colors.white};
  text-align: left;
  user-select: none;
  box-shadow: 4px 0 0 0 transparent inset;
  transition: box-shadow 0.15s linear;

  span {
    margin-left: 28px;
  }

  svg + span {
    margin-left: 4px;
  }

  span + svg {
    margin-left: auto;
  }
`;

const style = css`
  border-top: 1px solid #F5F6F8;
  background-color: ${colors.bluishGray};
`;

const selected = css`
  box-shadow: 4px 0 0 0 ${colors.blue} inset !important;
`;

const hover = css`
  cursor: pointer;
  &:hover {
    box-shadow: 4px 0 0 0 ${lighten(colors.blue, 0.75)} inset;
  }
`;

export const PageGroupItemEmpty: React.FC = () => (
  <li css={[listItemStyle, style]}>
    <span>–</span>
  </li>
);

interface InputProps {
  readonly objectId: string;
}

interface StateProps {
  readonly objectType: GeometryType;
  readonly figure?: Figure;
  readonly areaType?: AreaType;
  readonly label?: Label;
  readonly symbol?: SketchSymbol;
  readonly symbolGroup?: SketchSymbolGroup;
  readonly selectedObjects: ReturnType<typeof editModeSelectors.getSelectedObjects>;
}

interface ActionProps {
  readonly move: (dropPageId: string, dragItems: string[]) => void;
  readonly selectFigure: (figureId: string) => void;
  readonly selectPositionable: (objectId: string) => void;
}

type Props = InputProps & StateProps & ActionProps;

const PageGroupItem: React.FC<Props> = (props) => {
  const {
    objectId, objectType, selectedObjects,
    selectFigure, selectPositionable, figure, areaType, label, symbol, symbolGroup, move,
  } = props;

  let text = 'Wall';
  let clickHandler = (): void => selectFigure(objectId);

  if (objectType === GeometryType.FIGURE) {
    if (figure!.type === FigureType.INTERIOR) {
      text = 'Interior Wall';
    } else if (areaType) {
      text = areaType.text; // eslint-disable-line prefer-destructuring
    }
  } else if (objectType === GeometryType.POSITIONED_SYMBOL) {
    if (symbol) {
      text = `${symbolGroup ? `${symbolGroup.name} - ` : ''}${symbol.name}`;
    }
    clickHandler = () => selectPositionable(objectId);
  } else if (objectType === GeometryType.POSITIONED_LABEL) {
    if (label) {
      text = `Label - ${label.text}`;
    }
    clickHandler = () => selectPositionable(objectId);
  }

  const isObjectSelected = selectedObjects.includes(objectId);

  const item: PageDragItem = {
    type: ItemTypes.Page,
    items: selectedObjects.includes(objectId) ? selectedObjects.toArray() : [...selectedObjects.toArray(), objectId],
    preview: text,
  };
  const [, drag, preview] = useDrag({
    item,
    end: (draggedItem: PageDragItem | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult() as PageDropResult | undefined;
      monitor.getItemType();
      if (draggedItem && dropResult) {
        move(dropResult.pageId, draggedItem.items);
      }
    },
  });

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <>
      <DragPreviewImage connect={preview} src={emptyImageSrc} />
      <li
        ref={drag}
        onClick={clickHandler}
        css={[
          listItemStyle,
          style,
          hover,
          isObjectSelected && selected,
        ]}
      >
        <span>{text}</span>
      </li>
    </>
  );
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-enable jsx-a11y/click-events-have-key-events */
};

export default connect(
  (state: RootState, { objectId }: InputProps): StateProps => {
    const sketchObject = modelSelectors.getModelObject(state, objectId);
    const objectType = modelSelectors.getGeometryType(state, objectId);
    const symbol = objectType === GeometryType.POSITIONED_SYMBOL
      ? symbolsSelectors.getSymbolById(state, (sketchObject as PositionedSymbol).symbolId)
      : undefined;
    const selectedObjects = editModeSelectors.getSelectedObjects(state);
    return {
      objectType,
      figure: objectType === GeometryType.FIGURE
        ? figuresSelectors.getFigureById(state, objectId)
        : undefined,
      areaType: objectType === GeometryType.FIGURE
        ? areaTypesSelectors.getAreaTypeByMaybeId(
          state,
          getFigureAreaType(sketchObject as Figure),
        )
        : undefined,
      symbol,
      symbolGroup: (objectType === GeometryType.POSITIONED_SYMBOL && symbol)
        ? symbolGroupsSelectors.getSymbolGroupById(state, symbol.symbolGroupId)
        : undefined,
      label: objectType === GeometryType.POSITIONED_LABEL
        ? labelsSelectors.getLabelById(state, (sketchObject as PositionedLabel).labelId)
        : undefined,
      selectedObjects,
    };
  },
  {
    move: pageGroupsModalActions.move,
    selectFigure: pageGroupsModalActions.selectFigure,
    selectPositionable: pageGroupsModalActions.selectPositionable,
  },
)(PageGroupItem);
