const area = [
  {
    id: 'd408c937-ca53-4ded-9e38-3545001cf7ba',
    gla_type: 'gla',
    name: 'First Floor',
    index: 1,
    meta: {
      include_in_gba: true,
      enable_gla_toggle: true,
    },
  },
  {
    id: '3eb7414c-d7fa-4bde-a47c-170639599b43',
    gla_type: 'gla',
    name: 'Second Floor',
    index: 2,
    meta: {
      include_in_gba: true,
      enable_gla_toggle: true,
    },
  },
  {
    id: '84d0090e-a9ce-411b-b66f-2c76a434c4cb',
    gla_type: 'gla',
    name: 'Third Floor',
    index: 3,
    meta: {
      include_in_gba: true,
      enable_gla_toggle: true,
    },
  },
  {
    id: '060dfc8c-f8e6-4abf-823d-1aff98853bd1',
    gla_type: 'gla',
    name: 'Fourth Floor',
    index: 4,
    meta: {
      include_in_gba: true,
      enable_gla_toggle: true,
    },
  },
  {
    id: '909dcf43-350e-4d4a-885a-83b5e63c2a77',
    gla_type: 'non_gla',
    name: 'Basement',
    index: 5,
    meta: {
      include_in_gba: true,
      enable_gla_toggle: false,
    },
  },
  {
    id: '4e295571-289d-42cd-b382-17d07f2f31e2',
    gla_type: 'non_gla',
    name: 'Attic',
    index: 6,
    meta: {
      include_in_gba: false,
      enable_gla_toggle: false,
    },
  },
  {
    id: 'c0a36548-7fbe-4c81-ad71-f254ed085ab7',
    gla_type: 'negative_gla',
    name: 'Open to Below',
    index: 7,
    meta: {
      include_in_gba: false,
      enable_gla_toggle: false,
    },
  },
  {
    id: '44766d74-d54a-44c6-8e9f-400d6136a8a5',
    gla_type: 'non_gla',
    name: 'Deck',
    index: 8,
    meta: {
      include_in_gba: false,
      enable_gla_toggle: false,
    },
  },
  {
    id: 'c4d3e09d-0af5-4ecd-b43f-ac11079e8e5c',
    gla_type: 'non_gla',
    name: 'Garage',
    index: 9,
    meta: {
      include_in_gba: true,
      enable_gla_toggle: false,
    },
  },
  {
    id: 'f204a869-5e53-44c9-aea6-4f455f51dbbc',
    gla_type: 'non_gla',
    name: 'Patio',
    index: 10,
    meta: {
      include_in_gba: false,
      enable_gla_toggle: false,
    },
  },
  {
    id: '45373cea-14d7-4e8f-adbd-455aca9dcef9',
    gla_type: 'non_gla',
    name: 'Porch',
    index: 11,
    meta: {
      include_in_gba: false,
      enable_gla_toggle: false,
    },
  },
  {
    id: 'f631c6fe-0f10-44d2-88f0-57bbfaf32156',
    gla_type: 'non_gla',
    name: 'Covered Patio',
    index: 14,
    meta: {
      include_in_gba: false,
      enable_gla_toggle: false,
    },
  },
];

export default area;
