/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { useDrop } from 'react-dnd';

import { colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { Page } from 'types/page';
import { lighten } from 'helpers/colorTransforms';
import { ItemTypes, PageDropResult } from 'types/dnd';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';
import { ReactComponent as IconChevron } from 'assets/icons/chevron.svg';
import { ReactComponent as IconDelete } from 'assets/icons/delete.svg';
import { ReactComponent as IconPage } from 'assets/icons/page.svg';
import { ReactComponent as IconAdd } from 'assets/icons/plus.svg';
import { ReactComponent as IconPrint } from 'assets/icons/printer.svg';
import { listItemStyle } from 'components/modal/PageGroupItem';

const List = styled.ul`
  background-color: ${colors.bluishGray};
  padding: 0 3px;
  overflow: hidden;
  flex: 1 0 auto;

  & + & {
    margin-top: 3px;
  }
`;

const buttonStyle = css`
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: transform 0.2s ease;
`;

const selected = css`
  box-shadow: 4px 0 0 0 ${colors.yellow} inset !important;
`;

const hover = css`
  &:hover {
    box-shadow: 4px 0 0 0 ${lighten(colors.yellow, 0.75)} inset;
  }
`;

const chevron = css`
  padding: 6px;
  cursor: pointer;
`;

const opened = css`
  transform: rotate(90deg);
`;

const disabled = css`
  color: gray;
`;

const add = css`
  margin: 3px;
  color: ${colors.blue};
`;

const preview = css`
  margin: 3px;
  color: white;
  background: ${colors.blue};
`;

interface PageGroupAddProps {
  readonly onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
export const PageGroupAdd = ({ onClick }: PageGroupAddProps): React.ReactElement => (
  <button onClick={onClick} css={[listItemStyle, add]}>
    <IconAdd />
    <span>New page</span>
  </button>
);

interface ShowPrintPreviewProps {
  readonly onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
export const ShowPrintPreview = ({ onClick }: ShowPrintPreviewProps): React.ReactElement => (
  <button onClick={onClick} css={[listItemStyle, preview]}>
    <IconPrint />
    <span>Print preview</span>
  </button>
);

interface ListDropTargetProps extends React.ComponentProps<typeof List> {
  pageId: string;
}
const ListDropTarget: React.FC<ListDropTargetProps> = ({ pageId, ...props }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.Page,
    drop: (): PageDropResult => ({ pageId }),
  });

  return <List ref={drop} {...props} />;
};

interface InputProps {
  readonly pageId: string;
  readonly pageTitle: string;
}

interface StateProps {
  readonly page: Page;
  readonly currentPage: Page | undefined;
  readonly canDelete: boolean;
}

interface ActionProps {
  readonly deletePage: typeof pagesActions.delete;
  readonly setCurrentPage: typeof pagesActions.setCurrentPage;
}

type Props = InputProps & StateProps & ActionProps;

interface State {
  readonly showPage: boolean;
}

class PageGroup extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      showPage: true,
    };
  }

  private togglePage = (): void => {
    this.setState(({ showPage }) => ({ showPage: !showPage }));
  };

  private selectPage = (): void => {
    const { setCurrentPage, page } = this.props;
    setCurrentPage(page);
  };

  private deletePage = (e: any): void => {
    e.stopPropagation();
    const { deletePage, page, canDelete } = this.props;
    if (canDelete) {
      deletePage(page);
    }
  };

  public render = (): JSX.Element => {
    const {
      page, pageTitle, currentPage, canDelete
    } = this.props;
    const { showPage } = this.state;

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return (
      <ListDropTarget pageId={page.pageId}>
        <li css={[listItemStyle, hover, page?.pageId === currentPage?.pageId && selected]} onClick={this.selectPage}>
          <IconChevron css={[buttonStyle, chevron, showPage ? opened : null]} onClick={this.togglePage} />
          <IconPage />
          <span>{pageTitle}</span>
          <IconDelete css={[buttonStyle, canDelete ? null : disabled]} onClick={this.deletePage} />
        </li>
      </ListDropTarget>
    );
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  };
}

export default connect(
  (state: RootState, { pageId }: InputProps): StateProps => ({
    page: pagesSelectors.getPageById(state, pageId),
    currentPage: pagesSelectors.getCurrentPage(state),
    canDelete: pagesSelectors.getNumberOfPages(state) > 1,
  }),
  {
    deletePage: pagesActions.delete,
    setCurrentPage: pagesActions.setCurrentPage,
  },
)(PageGroup);
