import styled from '@emotion/styled';

import { colors, modal } from 'config/paletteConfig';
import { scrollbar } from 'components/globalStyles';
import {
  modalStyles, modalWallResizeStyles, modalBackdropStyles, buttonStyles, headerButtonStyles,
} from './styles';

const HEIGHT_TOP_BAR = 51;

const printPreviewStyles = {
  position: 'absolute',
  zIndex: 1,
  top: HEIGHT_TOP_BAR,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  flexFlow: 'column nowrap',
  minWidth: 600,
  width: '80%',
  maxHeight: `calc(100% - ${HEIGHT_TOP_BAR + 10}px)`,
  backgroundColor: colors.white,
  boxShadow: `0 3px 6px 0 ${modal.shadow}`,

  header: {
    textAlign: 'center',
  },

  p: {
    margin: '0 10px 10px',
    color: colors.text,
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'center',
  },

  span: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 20,
    textAlign: 'center',
  },

  button: {
    width: 40,
    height: 40,
    border: 0,
    borderRadius: '50%',
    backgroundColor: colors.white,
    boxShadow: '0 0 3px 0 rgba(26, 36, 79, 0.1)',
  },
} as const;

export const ButtonModalHeader = styled.button(headerButtonStyles);
export const ButtonBig = styled.button(buttonStyles);
export const ModalWallResize = styled.div(modalWallResizeStyles);
export const ModalBackdrop = styled.div(modalBackdropStyles);
export const ModalPrintPreview = styled.div(scrollbar, printPreviewStyles);

export interface ModalProps {
  readonly top?: number;
  readonly left?: number;
  readonly right?: number;
  readonly bottom?: number;
}

export const Modal = styled.div<ModalProps>(modalStyles, ({
  top = 0,
  left = 80,
  right,
  bottom
}) => ({
  top,
  left: right !== undefined ? 'initial' : left,
  right: right !== undefined ? right : 'initial',
  bottom: bottom !== undefined ? bottom : 'initial',
}));
