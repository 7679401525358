import { SelectableObjects } from 'types/selection';
import { Point } from 'types/point';
import { Wall } from 'types/wall';
import { Figure } from 'types/figure';
import { WallType } from 'types/wallType';
import { getWallPoints, getWallsWithPoint } from 'helpers/model/wallPoints';
import { getAngleWalls } from 'helpers/angle/getAngleArc';
import { getFiguresWithWall } from 'helpers/model/figureWalls';
import { distance } from 'helpers/geometry';

export const canExtrudeWall = (selectableObjects: SelectableObjects, wallId: string): boolean => {
  const { walls, points } = selectableObjects;
  const wall = walls.get(wallId)!;
  if (wall.wallType === WallType.ARC) {
    return false;
  }

  const wallPoints = getWallPoints(points, wall);
  const badPoint = wallPoints.find((p) => {
    const wallsWithPoint = getWallsWithPoint(walls, p.pointId);
    if (wallsWithPoint.length < 2) {
      return true;
    }

    const nonRightAngleWall = wallsWithPoint.find((wId) => {
      if (wId === wallId) {
        return false;
      }

      const otherWall = walls.get(wId)!;
      if (otherWall.wallType === WallType.ARC) {
        return true;
      }

      const otherWallPoints = getWallPoints(points, otherWall);
      if (distance(otherWallPoints.get(0)!, otherWallPoints.get(1)!) === 0) {
        return true;
      }

      const angle = getAngleWalls(wallPoints, otherWallPoints);
      return (angle % 90) !== 0;
    });

    return !!nonRightAngleWall;
  });

  return !badPoint;
};

export interface ExtrudeWallResult {
  updatedWalls: Wall[];
  updatedFigures: Figure[];
  updatedPoints: Point[];
}

export const extrudeWall = (
  selectableObjects: SelectableObjects, wallId: string, newIds: string[],
): ExtrudeWallResult => {
  const { walls, points, figures } = selectableObjects;

  const result: ExtrudeWallResult = {
    updatedWalls: [],
    updatedFigures: [],
    updatedPoints: [],
  };

  const wall = { ...walls.get(wallId)! };
  const wallPoints = getWallPoints(points, wall);
  const newPath: string[] = [];
  const newWallPoints = [...wall.points];

  wallPoints.forEach((p, index) => {
    if (index === 1) {
      newPath.push(wallId);
    }

    const wallsWithPoint = getWallsWithPoint(walls, p.pointId);
    const zeroAngleWall = wallsWithPoint.find((wId) => {
      if (wId === wallId) {
        return false;
      }
      const otherWall = walls.get(wId)!;
      const otherWallPoints = getWallPoints(points, otherWall);
      const angle = getAngleWalls(wallPoints, otherWallPoints);
      return (angle === 0 || angle === 180);
    });

    if (!zeroAngleWall) {
      return;
    }

    const newPoint: Point = {
      ...p,
      pointId: newIds[index + 2],
    };
    if (index === 0) {
      wall.points = [newPoint.pointId, p.pointId];
    } else {
      wall.points = [p.pointId, newPoint.pointId];
    }
    result.updatedPoints.push(newPoint);

    const newWall: Wall = {
      wallId: newIds[index],
      wallType: WallType.LINE,
      points: (index === 0) ? [p.pointId, newPoint.pointId] : [newPoint.pointId, p.pointId],
    };

    result.updatedWalls.push(newWall);
    newPath.push(newWall.wallId);
    newWallPoints[index] = newPoint.pointId;
  });

  result.updatedWalls.push({
    ...wall,
    points: newWallPoints,
  });

  const figuresWithWall = getFiguresWithWall(figures, wallId);
  figuresWithWall.forEach((figureId) => {
    const figure = figures.get(figureId)!;
    const figureWalls = [...figure.walls];
    const indexForInsert = figureWalls.findIndex(wId => wId === wallId);
    figureWalls.splice(indexForInsert, 1, ...newPath);
    result.updatedFigures.push({ ...figure, walls: figureWalls });
  });

  return result;
};
