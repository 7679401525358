import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';

// Action Types
const NAME = 'message';
export const SHOW = `${NAME}/SHOW`;
export const HIDE = `${NAME}/HIDE`;

export type onSubmitEventType = () => void;

// Action Creators
export const actions = {
  show: (title: string, message: string | JSX.Element, onSubmit?: onSubmitEventType, onSubmitText?: string, dissmisable = true) => ({
    type: SHOW,
    payload: {
      title,
      message,
      onSubmit,
      onSubmitText,
      dissmisable
    },
  }),

  hide: () => ({
    type: HIDE,
  }),
};

export interface MessageContainerState {
  readonly title: string | undefined;
  readonly message: string | JSX.Element | undefined;
  readonly onSubmit: onSubmitEventType | undefined;
  readonly onSubmitText: string | undefined;
  readonly dissmisable: boolean;
}

const initialState: MessageContainerState = {
  title: undefined,
  message: undefined,
  onSubmit: undefined,
  onSubmitText: undefined,
  dissmisable: true
};

const getMessageContainerState = (rootState: RootState): MessageContainerState => rootState.messageContainer;

const getTitle = (rootState: RootState): string | undefined => getMessageContainerState(rootState).title;

const getMessage = (
  rootState: RootState,
): string | JSX.Element | undefined => getMessageContainerState(rootState).message;

const getOnSubmit = (
  rootState: RootState,
): onSubmitEventType | undefined => getMessageContainerState(rootState).onSubmit;

const getOnSubmitText = (
  rootState: RootState,
): string | undefined => getMessageContainerState(rootState).onSubmitText;

const getIsDissmisable = (
  rootState: RootState,
): boolean => getMessageContainerState(rootState).dissmisable;

export const selectors = {
  getTitle,
  getMessage,
  getOnSubmit,
  getOnSubmitText,
  getIsDissmisable
};

// Reducers
const hideMessageReducer = (): MessageContainerState => initialState;

const showMessageReducer = (state: MessageContainerState, {
  title, message, onSubmit, onSubmitText, dissmisable = true
}: {
  title: string; message: string | JSX.Element; onSubmit?: onSubmitEventType; onSubmitText?: string; dissmisable: boolean
}): MessageContainerState => ({
  ...state,
  title,
  message,
  onSubmit,
  onSubmitText,
  dissmisable
});

export const reducer = (state: MessageContainerState = initialState, action: PayloadAction): MessageContainerState => {
  switch (action.type) {
    case SHOW:
      return showMessageReducer(state, action.payload);

    case HIDE:
      return hideMessageReducer();

    default:
      return state;
  }
};
