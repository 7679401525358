import Immutable from 'immutable';
import {
  select, put, call, all,
} from 'redux-saga/effects';
import uuid from 'uuid/v4';
import { toast } from 'react-toastify';
import { toastConfig } from 'config/toastConfig';
import { messages } from 'config/messages';
import { Point, CoordinatePoint, isPoint } from 'types/point';
import { Wall } from 'types/wall';
import { Figure } from 'types/figure';
import { Page } from 'types/page';
import { WallType } from 'types/wallType';
import { PointType } from 'types/pointType';
import { FigureType } from 'types/figureType';
import { GeometryType } from 'types/geometryType';
import { CollidedObject } from 'types/selection';
import { distance } from 'helpers/geometry';
import { selectors as collisionSelectors } from 'ducks/collision/collision';
import { selectors as numpadSelectors } from 'ducks/modal/numpadModal';
import { actions as editModeActions } from 'ducks/editMode';
import { closeFigure } from 'ducks/draw/closeFigure';
import { actions as drawActions } from 'ducks/draw/draw';
import { splitWall, SplitWallResult } from 'ducks/draw/splitWall';
import { selectors as pointsSelectors, actions as pointsActions } from 'ducks/model/points';
import { selectors as wallsSelectors, actions as wallsActions } from 'ducks/model/walls';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';
import { selectors as pagesSelectors, actions as pagesActions } from 'ducks/model/pages';
import { selectors as resizeSketchModalSelectors } from 'ducks/modal/resizeSketchModal';
import { selectors as viewportSelectors } from 'ducks/viewport';
import { compose } from 'helpers/utils';
import { RootState } from 'reducers/rootReducer';
import md5 from 'md5';

const toastId = md5(messages.wallsCollisionDetected);

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/**
 * User possible click on some point/wall
 * in case of wall, it seems user want to split wall
 * in case of point, user wants to continue to draw old figure or wants to close polygon
 */
export function* startDrawFigure(startPoint: Point | CoordinatePoint): any {
  const state: RootState = yield select();
  const collidedObject: CollidedObject = collisionSelectors.collidesWith(state, startPoint);
  let point: Point | CoordinatePoint;
  point = startPoint;

  if (collidedObject.objectType === GeometryType.WALL) {
    const wall: Wall = wallsSelectors.getWallById(state, collidedObject.objectId);
    if (wall.wallType === WallType.ARC) {
      if (toastConfig.drawMessagesEnabled) {
        toast(messages.arcCollisionDetected, {
          type: toast.TYPE.WARNING,
        });
      }
      yield put(editModeActions.switchToEdit());
      return;
    }
    // split wall
    const splitWallResult: SplitWallResult = yield call(splitWall, collidedObject.objectId, point);
    point = splitWallResult.point; // eslint-disable-line
  } else if (collidedObject.objectType === GeometryType.POINT) {
    const typedPoint: Point = pointsSelectors.getPointById(state, collidedObject.objectId);
    if (typedPoint.pointType === PointType.ARC) {
      if (toastConfig.drawMessagesEnabled) {
        toast(messages.arcCollisionDetected, {
          type: toast.TYPE.WARNING,
        });
      }
      yield put(editModeActions.switchToEdit());
      return;
    }
    point = typedPoint;
  }

  yield put(drawActions.setStartPoint(point));
  yield put(drawActions.setSegmentStartPoint(point));
}

export function* createNewFigure(isInteriorFigure: boolean) {
  const figureId = uuid();
  yield put(
    figuresActions.add({
      figureId,
      walls: [],
      type: isInteriorFigure ? FigureType.INTERIOR : FigureType.BASE,
    }),
  );

  const figure = {
    figureId,
    walls: [],
    type: isInteriorFigure ? FigureType.INTERIOR : FigureType.BASE,
  };
  const page: Page | undefined = pagesSelectors.getCurrentPage(yield select());
  if (page) yield put(pagesActions.upsertObject(page.pageId, figureId, figure));

  return figureId;
}

/**
 * User want to add new wall to the figure
 */
export function* addWallToFigure(
  drawFigureId: string, startPoint: Point | CoordinatePoint, endPoint: Point | CoordinatePoint, isInteriorWall: boolean,
): any {
  if (distance(startPoint, endPoint) === 0) {
    return;
  }

  const lastLine = Immutable.List<Point | CoordinatePoint>([startPoint, endPoint]);

  const isIntersection: boolean = collisionSelectors.isCollidedWithWall(yield select(),
    lastLine, isInteriorWall, viewportSelectors.getZoomInPercent(yield select()));

  if (isIntersection) {
    if (toastConfig.drawMessagesEnabled) {
      toast(messages.wallsCollisionDetected, {
        toastId,
        type: toast.TYPE.WARNING,
      });
    }
    return;
  }

  let figureId = drawFigureId;
  if (!drawFigureId) {
    figureId = yield call(createNewFigure, isInteriorWall);
  }
  let endWallPoint: Point | undefined;

  const collidedObject: CollidedObject = collisionSelectors.collidesWith(yield select(), endPoint);
  if (collidedObject.objectType === GeometryType.WALL) {
    const wall: Wall = wallsSelectors.getWallById(yield select(), collidedObject.objectId);
    if (wall.wallType === WallType.ARC) {
      if (toastConfig.drawMessagesEnabled) {
        toast(messages.arcCollisionDetected, {
          type: toast.TYPE.WARNING,
        });
      }
      return;
    }
    const figure: Figure = figuresSelectors.getFigureById(yield select(), figureId);
    if (figure.walls.includes(collidedObject.objectId)) {
      if (toastConfig.drawMessagesEnabled) {
        toast(messages.cannotCloseInTheMiddle, {
          type: toast.TYPE.WARNING,
        });
      }
      return;
    }

    // split wall
    const splitWallResult: SplitWallResult = yield call(splitWall, collidedObject.objectId, endPoint);
    endWallPoint = splitWallResult.point;
  } else if (collidedObject.objectType === GeometryType.POINT) {
    const typedPoint: Point = pointsSelectors.getPointById(yield select(), collidedObject.objectId);
    endWallPoint = typedPoint;
    if ('pointType' in endWallPoint && endWallPoint.pointType === PointType.ARC) {
      if (toastConfig.drawMessagesEnabled) {
        toast(messages.arcCollisionDetected, {
          type: toast.TYPE.WARNING,
        });
      }
      return;
    }

    const figurePoints: Immutable.List<Point> = figuresSelectors.getFigurePoints(yield select(), figureId);
    const firstPoint: Point = figurePoints.first();
    const figurePoint = figurePoints.find(p => (
      p.pointId === endWallPoint!.pointId && p.pointId !== firstPoint.pointId));
    if (figurePoint) {
      if (toastConfig.drawMessagesEnabled) {
        toast(messages.cannotCloseInTheMiddle, {
          type: toast.TYPE.WARNING,
        });
      }
      return;
    }
  } else {
    endWallPoint = {
      ...endPoint,
      pointId: uuid(),
    };
    yield put(
      pointsActions.add(endWallPoint),
    );
  }

  let startPointId = isPoint(startPoint) ? startPoint.pointId : undefined;
  if (!startPointId) {
    startPointId = uuid();
    yield put(
      pointsActions.add({
        pointId: startPointId,
        ...startPoint,
      }),
    );
  }

  const wallId = uuid();
  const wallType = isInteriorWall ? WallType.INTERIOR : WallType.LINE;
  const points: string[] = [startPointId, endWallPoint.pointId];
  yield put(
    wallsActions.add({
      wallId,
      wallType,
      points,
    }),
  );
  yield put(figuresActions.addWall(figureId, wallId));

  const isDrawingWithNumpad = numpadSelectors.isShowing(yield select());
  if (collidedObject.objectType === GeometryType.WALL
    || collidedObject.objectType === GeometryType.POINT) {
    yield put(editModeActions.switchToEdit());
    yield call(closeFigure, endWallPoint, figureId);

    // this will remove the pointdraghandle after an area is closed
    // yield put(drawActions.toggleDrawingPreshapes(true));
  } else if (isDrawingWithNumpad) {
    yield put(drawActions.setStartPointWithoutSnapping(endWallPoint));
    yield put(drawActions.setEndPointWithoutSnapping(endWallPoint, false));
    yield put(drawActions.setDrawObject(figureId));
  } else {
    yield put(drawActions.setStartPoint(endWallPoint));
    yield put(drawActions.setEndPoint(endWallPoint));
    yield put(drawActions.setDrawObject(figureId));
  }

  if (resizeSketchModalSelectors.isTracingFirstWall(yield select())) {
    yield put(editModeActions.selectObjects([wallId]));
  }
}

const composedCollisionCheck = (rootState: RootState): any => compose(
  (points: Immutable.List<Point | CoordinatePoint>) => collisionSelectors.isCollidedWithWall(rootState, points, false,
    viewportSelectors.getZoomInPercent(rootState)),
  (points: (Point | CoordinatePoint)[]) => Immutable.List<Point | CoordinatePoint>(points),
);

export function* addPreshapeWallsToFigure(
  drawFigureId: string,
  preshapePoints: any,
): any {
  const rootState: RootState = yield select();
  const checkForCollision = composedCollisionCheck(rootState);
  const points: Point[] = preshapePoints
    .map((p: CoordinatePoint) => ({ ...p, pointId: uuid() }));

  const hasIntersection = points.some((p, i) => checkForCollision([p, points[i + 1] || points[0]]));
  if (hasIntersection) {
    if (toastConfig.drawMessagesEnabled) {
      toast(`${messages.wallsCollisionDetected}`, {
        toastId,
        type: toast.TYPE.WARNING,
      });
    }
    return null;
  }

  const figureId = drawFigureId || (yield call(createNewFigure, false));
  yield all(points.map((point: Point) => put(pointsActions.add(point))));
  yield all(points.map((p: Point, i: number) => {
    const [startPoint, endPoint] = [
      p,
      points[i + 1] || points[0],
    ];
    return call(
      addWallToFigure,
      figureId,
      startPoint,
      endPoint,
      false,
    );
  }));
  return null;
}

export function* addCircleWallToFigure(
  drawFigureId: string,
  cp: any,
  radius: number,
): any {
  const figureId = drawFigureId || (yield call(createNewFigure, false));

  const wallId = uuid();
  const wallType = WallType.CIRCLE;

  const centerPoint = ({ ...cp, pointId: uuid(), pointType: PointType.CENTER });

  yield put(pointsActions.add(centerPoint));

  yield put(
    wallsActions.addCircle({
      wallId,
      wallType,
      centerPoint,
      radius,
      points: [centerPoint.pointId],
    }),
  );

  yield put(figuresActions.addWall(figureId, wallId));

  const collidedObject: CollidedObject = collisionSelectors.collidesWith(yield select(), centerPoint);

  if (collidedObject.objectType === GeometryType.WALL
    || collidedObject.objectType === GeometryType.POINT) {
    yield put(editModeActions.switchToEdit());
    yield call(closeFigure, centerPoint, figureId);
  }
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
