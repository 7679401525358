import { CoordinatePoint } from 'types/point';
import { Direction } from 'types/direction';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';

export const movePoint = <T extends CoordinatePoint>(p: T, d: CoordinatePoint): T => ({
  ...p,
  x: p.x + d.x,
  y: p.y + d.y,
});

export const movePointInDirection = (point: CoordinatePoint, direction: Direction, value: number): CoordinatePoint => {
  const xy = Math.sqrt(value * value / 2);
  switch (direction) {
    case Direction.Up: return { ...point, y: point.y - value };
    case Direction.Down: return { ...point, y: point.y + value };
    case Direction.Left: return { ...point, x: point.x - value };
    case Direction.Right: return { ...point, x: point.x + value };
    case Direction.UpLeft: return { ...point, y: point.y - xy, x: point.x - xy };
    case Direction.UpRight: return { ...point, y: point.y - xy, x: point.x + xy };
    case Direction.DownLeft: return { ...point, y: point.y + xy, x: point.x - xy };
    case Direction.DownRight: return { ...point, y: point.y + xy, x: point.x + xy };
    default: throw new UnreachableCaseError(direction);
  }
};
