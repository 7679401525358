import Immutable from 'immutable';

import { angleConfig } from 'config/angleConfig';
import { Point, CoordinatePoint } from 'types/point';
import { PointType } from 'types/pointType';
import { distance } from 'helpers/geometry';
import { DEGREES_PER_RADIAN } from 'helpers/rotation';
import { polygonBound } from 'helpers/polygonBound';
import { getSequentialPoints } from 'helpers/model/wallPoints';

export interface WallsArc {
  labelPosition: CoordinatePoint;
  angle: number;
  pathData: string;
}

/**
 * svg polygon for arc
 */
export const getArcPath = (
  c1: CoordinatePoint, c2: CoordinatePoint, circleRadius: number, largeArcFlag: number,
): string => `M ${c1.x} ${c1.y} A ${circleRadius} ${circleRadius} 0 ${largeArcFlag} 0 ${c2.x} ${c2.y}`;

/**
 * construct arc from points of two lines
 */
export const getAllAngleArcs = (
  p1: CoordinatePoint, p2: CoordinatePoint, p3: CoordinatePoint,
): WallsArc[] | null => {
  const v1 = {
    x: p1.x - p2.x,
    y: p1.y - p2.y,
  };

  const v2 = {
    x: p3.x - p2.x,
    y: p3.y - p2.y,
  };

  const d1 = distance(p1, p2);
  const d2 = distance(p2, p3);
  if (d1 === 0 || d2 === 0) {
    return null;
  }

  const dot = v1.x * v2.x + v1.y * v2.y;
  const det = v1.x * v2.y - v1.y * v2.x;

  if (dot === 0 || det === 0) {
    return null;
  }
  const angleRad = Math.atan2(det, dot);
  const angle = Math.abs(Math.round(angleRad * DEGREES_PER_RADIAN));
  if (angle % 90 === 0) {
    return null;
  }

  const circleRadius = Math.min(angleConfig.arcRadius, d1, d2);

  // arc start point
  const c1 = {
    x: p2.x + v1.x / d1 * circleRadius,
    y: p2.y + v1.y / d1 * circleRadius,
  };

  // arc end point
  const c2 = {
    x: p2.x + v2.x / d2 * circleRadius,
    y: p2.y + v2.y / d2 * circleRadius,
  };
  let sign = Math.sign(det);

  const largeArcFlag = sign <= 0 ? 0 : 1;

  const c3 = {
    x: (c1.x + c2.x) / 2,
    y: (c1.y + c2.y) / 2,
  };

  // orthogonal vector, crossed middle point of the arc
  let v3 = {
    x: c3.x - p2.x,
    y: c3.y - p2.y,
  };

  let d3 = distance(c3, p2);
  if (d3 === 0) {
    v3 = {
      x: v2.y,
      y: -v2.x,
    };
    d3 = d2;
    sign = 1;
  }

  const v4 = {
    x: sign * v3.x / d3 * (angleConfig.labelPosition + circleRadius),
    y: sign * v3.y / d3 * (angleConfig.labelPosition + circleRadius),
  };

  const arcs: WallsArc[] = [
    // internal arc
    {
      labelPosition: {
        x: p2.x - v4.x,
        y: p2.y - v4.y,
      },
      angle: largeArcFlag ? 360 - angle : angle,
      pathData: getArcPath(c1, c2, circleRadius, largeArcFlag),
    },
    // external arc
    {
      labelPosition: {
        x: p2.x + v4.x,
        y: p2.y + v4.y,
      },
      angle: largeArcFlag ? angle : 360 - angle,
      pathData: getArcPath(c2, c1, circleRadius, largeArcFlag ? 0 : 1),
    },
  ];

  return arcs;
};

/**
 * return best arc with angle: "internal" or "external"
 */
export const getAngleArc = (
  wallsPoints: Immutable.List<Point | CoordinatePoint>, figurePoints?: Immutable.List<Point | CoordinatePoint>,
): WallsArc | null => {
  const p1 = wallsPoints.get(0)!;
  const p2 = wallsPoints.get(1)!;
  const p3 = wallsPoints.get(2)!;
  if (('pointType' in p1 && p1.pointType === PointType.ARC)
    || ('pointType' in p2 && p2.pointType === PointType.ARC)
    || ('pointType' in p3 && p3.pointType === PointType.ARC)) {
    return null;
  }

  const arcs = getAllAngleArcs(p1, p2, p3);
  if (!arcs) {
    return null;
  }

  if (figurePoints) {
    if (polygonBound(figurePoints, arcs[0].labelPosition.x, arcs[0].labelPosition.y)) {
      return arcs[0];
    }
    return arcs[1];
  }

  return arcs[0];
};

/**
 * return angle between two walls in degrees
 */
export const getAngleWalls = (
  wall1Points: Immutable.List<Point>,
  wall2Points: Immutable.List<Point>,
): number => {
  const points = getSequentialPoints(wall1Points, wall2Points);

  const p1 = points.get(0)!;
  const p2 = points.get(1)!;
  const p3 = points.get(2)!;

  const v1 = {
    x: p1.x - p2.x,
    y: p1.y - p2.y,
  };

  const v2 = {
    x: p3.x - p2.x,
    y: p3.y - p2.y,
  };

  const dot = v1.x * v2.x + v1.y * v2.y;
  const det = v1.x * v2.y - v1.y * v2.x;

  const angleRad = Math.atan2(det, dot);
  const angle = Math.abs(Math.round(angleRad * DEGREES_PER_RADIAN));

  return angle;
};
