import { put } from 'redux-saga/effects';

import { LabelType } from 'types/labelType';
import { PositionedLabel } from 'types/positionedLabel';
import { actions as labelsActions } from 'ducks/model/labels';
import { actions as positionedLabelsActions } from 'ducks/model/positionedLabels';

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function* drawLabel(response: any) {
  yield put(labelsActions.add({
    labelId: response.id, text: response.text, type: LabelType.CUSTOM_TEXT, labelCategory: response.labelCategory, label_type: 'General', isDefault: false
  }));
  return response.id;
}

export function* drawLabelAndUpdatePositionedLabel(
  text: string, positionedLabel: PositionedLabel,
) {
  const labelId: string = yield drawLabel(text);
  const newPositionedLabel: PositionedLabel = { ...positionedLabel, labelId };
  yield put(positionedLabelsActions.update(newPositionedLabel));
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
