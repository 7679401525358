import React from 'react';
import ReactDOM from 'react-dom';
import posed from 'react-pose';
import styled from '@emotion/styled';

import { colors } from 'config/paletteConfig';
import { fade, appearModalDialog } from 'components/animations';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { ModalBackdrop } from 'components/modal/Modal';

const ModalWindowAnimated = posed.div(appearModalDialog);

const Backdrop = styled(ModalBackdrop)({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
});

const BackdropAnimated = posed(Backdrop)(fade);

const ModalWindowStyled = styled(ModalWindowAnimated)({
  position: 'relative',

  maxWidth: window.screen.width > 400 ? 600 : '85%',
  minWidth: 300,
  margin: '0 auto',
  padding: '10px 20px',
  backgroundColor: colors.white,
  borderLeftWidth: 3,
  borderLeftStyle: 'solid',
  borderLeftColor: colors.yellow,
  boxShadow: '0 5px 30px -5px rgba(0, 0, 0, 0.1)',

  h5: {
    margin: '10px 40 10px 0',
    fontWeight: 600,
    color: '#1A244F',
    textTransform: 'uppercase',
  },

  '> div': {
    paddingBottom: 10,
    borderBottom: '1px solid #E5E6EC',
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -10px',
    paddingTop: 10,
  },
});

const Button = styled.button<{ primary?: boolean }>(({ primary }) => ({
  minWidth: 80,
  margin: '0 10px',
  padding: 10,
  border: 0,
  backgroundColor: colors.white,
  color: primary ? colors.blue : colors.black,
  borderRadius: 2,
  fontWeight: 600,
  textTransform: 'uppercase',
  textAlign: 'center',

  '&:hover, &:focus': {
    backgroundColor: '#EFF1FC',
  },
}));

const ButtonClose = styled.button({
  position: 'absolute',
  top: 10,
  right: 10,
  width: 40,
  height: 40,
  paddingTop: 3,
  border: 0,
  borderRadius: 20,
  color: colors.black,
  backgroundColor: '#FAFAFA',
  textAlign: 'center',
});

function cancelEvent(): void {
  // event.preventDefault();
  // event.stopPropagation();
}

export type OnClick = (event: React.MouseEvent) => void;

interface ModalDialogProps {
  readonly title?: string;
  readonly confirmText?: string;
  readonly dismissText?: string;
  readonly confirmAction?: OnClick;
  readonly dismissAction?: OnClick;
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  children, title, confirmText, dismissText, confirmAction, dismissAction
}) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  ReactDOM.createPortal(
    <BackdropAnimated
      onPointerDown={cancelEvent}
      onPointerMove={cancelEvent}
      onPointerUp={cancelEvent}
      onPointerLeave={cancelEvent}
      onWheel={cancelEvent}
      onDragOver={cancelEvent}
      onDrop={cancelEvent}
      pose="enter"
      initialPose="exit"
    >
      <ModalWindowStyled pose="enter" initialPose="exit">
        {dismissAction && (
          <ButtonClose onClick={dismissAction}>
            <IconClose />
          </ButtonClose>
        )}
        {title && <h5>{title}</h5>}
        <div>{children}</div>
        <footer>
          {dismissAction && <Button onClick={dismissAction}>{dismissText || 'Cancel'}</Button>}
          {confirmAction && (
            <Button onClick={confirmAction} primary>
              {confirmText || 'Ok'}
            </Button>
          )}
        </footer>
      </ModalWindowStyled>
    </BackdropAnimated>,
    document.getElementById('modal-root') || document.body,
  );
