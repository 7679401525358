import React from 'react';

import { getUserIsValid } from 'effects/auth';

interface WrappedProps {
  readonly disabled?: boolean;
}
interface State {
  readonly userIsValid: boolean;
}

export const withAuthEnabled = <P extends object>(
  WrappedComponent: React.ComponentType<P & WrappedProps>,
): React.ComponentType<P> => (
  class Wrapper extends React.Component<P & WrappedProps, State> {
    constructor(props: P & WrappedProps) {
      super(props);
      this.state = {
        userIsValid: false,
      };
    }

    componentDidMount = async (): Promise<void> => {
      this.setState({
        userIsValid: await getUserIsValid(),
      });
    };

    render = (): JSX.Element => {
      const { userIsValid } = this.state;
      const { disabled, ...rest } = this.props;
      return (
        <WrappedComponent
          disabled={!userIsValid || disabled}
          {...rest as P}
        />
      );
    };
  }
);
