import Immutable from 'immutable';

import { Point, CoordinatePoint, isPoint } from 'types/point';
import { PointType } from 'types/pointType';
import { degreesToRadians } from '@turf/helpers';
import { addTo, compose } from './utils/index';

/**
 * L2 distance between two points
 */
export const distance = (a: CoordinatePoint, b: CoordinatePoint): number => {
  const dx = a.x - b.x;
  const dy = a.y - b.y;
  return Math.sqrt(dx * dx + dy * dy);
};

/**
 * get last segment from figure
 */
export const getLastSegment = <T extends CoordinatePoint>(line: Immutable.List<T>): Immutable.List<T> => {
  if (line.size < 2) {
    return line;
  }

  const prevPoint: T = line.get(line.size - 2)!;
  const lastPoint: T = line.last();

  return Immutable.List([prevPoint, lastPoint]);
};

/**
 * get center point of a polygon
 */
export const getCenter = (polygon: Immutable.List<CoordinatePoint>): CoordinatePoint => {
  const zeroPoint: CoordinatePoint = { x: 0, y: 0 };

  const c: CoordinatePoint = polygon.reduce(
    (sum, p) => ({
      x: sum.x + p.x,
      y: sum.y + p.y,
    }),
    zeroPoint,
  );

  c.x /= polygon.size;
  c.y /= polygon.size;

  return c;
};

const isSame = (p1: Point | CoordinatePoint, p2: Point | CoordinatePoint): boolean => {
  if (isPoint(p1) && isPoint(p2) && p1.pointId === p2.pointId) {
    return true;
  }
  return (p1.x === p2.x && p1.y === p2.y);
};

// centroid, aka centre of mass, is different from the centre point of the points that compose it. See:
// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/9692448/how-can-you-find-the-centroid-of-a-concave-irregular-polygon-in-javascript
export const getCentroid = (polygon: Immutable.List<CoordinatePoint>): CoordinatePoint => {
  const first: CoordinatePoint = polygon.first();
  if (!isSame(first, polygon.last())) {
    polygon.push(first);
  }
  const numPoints = polygon.size;

  if (numPoints === 1 && (first as Point).pointType === PointType.CENTER) {
    return first;
  }

  let twiceArea = 0;
  let x = 0;
  let y = 0;
  let p1;
  let p2;
  let f;
  for (let i = 0, j = numPoints - 1; i < numPoints; j = i++) {
    p1 = polygon.get(i)!;
    p2 = polygon.get(j)!;
    f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
    twiceArea += f;
    x += (p1.x + p2.x - 2 * first.x) * f;
    y += (p1.y + p2.y - 2 * first.y) * f;
  }
  f = twiceArea * 3;
  return { x: x / f + first.x, y: y / f + first.y };
};

export const circleArea = (radius: number): number => Math.PI * radius ** 2;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const slope = ({ x, y }: CoordinatePoint): any => compose(
  ({ dx, dy }: Record<string, number>) => Math.atan2(dy, dx),
  (p2: CoordinatePoint) => ({ dx: p2.x - x, dy: p2.y - y })
)

export const coordinatesByAngle = (startPoint: CoordinatePoint, endPoint: CoordinatePoint, pixels: number, angle: number, direction: string): any => compose(
  (calculatedAngle: number) => ({
    x: endPoint.x + Math.cos(calculatedAngle) * pixels,
    y: endPoint.y + Math.sin(calculatedAngle) * pixels
  }),
  addTo(degreesToRadians(direction === 'ArrowRight' ? 180 - angle : 180 + angle)),
  slope(startPoint)
)(endPoint);
