import uuid from 'uuid/v4';

const settings = [
  {
    uuid: '05f244e1-e29a-4f83-a878-e340a5e21619',
    name: 'precision',
    label: 'Precision',
    type: 'radio',
    value: '1',
    id: '1',
    options: [
      {
        label: 'Whole',
        value: '1',
        id: '1',
      },
      {
        label: 'Half',
        value: '2',
        id: '2',
      },
      {
        label: 'Quarter',
        value: '4',
        id: '3',
      },
    ],
  },
  {
    uuid: '47556ec1-860b-43ac-a54f-1b720116a78d',
    name: 'unitOfMeasurement',
    label: 'Unit of Measurement',
    type: 'radio',
    value: 'decimalFeet',
    id: '3',
    options: [
      {
        label: 'Meters',
        value: 'meters',
        id: '5',
      },
      {
        label: 'Feet/Decimal',
        value: 'decimalFeet',
        id: '4',
      },
      {
        label: 'Feet/Inches',
        value: 'feet',
        id: '6',
      },
    ],
  },
  {
    uuid: 'e848ce82-fd85-4b72-930d-fb6d160a1ba0',
    name: 'gridLines',
    label: 'Grid Lines',
    type: 'radio',
    value: 'dark',
    id: '2',
    options: [
      {
        label: 'Off',
        value: 'off',
        id: '7',
      },
      {
        label: 'Light',
        value: 'light',
        id: '8',
      },
      {
        label: 'Dark',
        value: 'dark',
        id: '9',
      },
    ],
  },
  {
    uuid: '85b14019-b127-4a9b-a478-b0a630acc9f7',
    name: 'gridSizeInFeet',
    label: 'Grid Scale',
    type: 'static',
    value: '1',
    id: '4',
    options: [],
  },
];

export default settings;
