import Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import {
  ClosedFigure, Figure, FigureChange, isClosedFigure, OpenFigure,
} from 'types/figure';
import { CircleWall, Wall } from 'types/wall';
import { Point } from 'types/point';
import { FigureType } from 'types/figureType';
import { PositionedAreaLabel } from 'types/positionedAreaLabel';
import { WallType } from 'types/wallType';
import * as figurePointsHelper from 'helpers/model/figurePoints';
import { isPointToFigureCollision } from 'helpers/collision/figureCollision';
import { selectors as wallsSelectors } from 'ducks/model/walls';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as pagesSelectors } from 'ducks/model/pages';

// Action Types
const NAME = 'figures';

export const ADD = `${NAME}/ADD`;
const REMOVE = `${NAME}/REMOVE`;
const CLEAR = `${NAME}/CLEAR`;
const REMOVE_WALL = `${NAME}/REMOVE_WALL`;
const OPEN_FIGURE = `${NAME}/OPEN_FIGURE`;
export const UPDATE = `${NAME}/UPDATE`;
export const ADD_WALL = `${NAME}/ADD_WALL`;

export interface FiguresState {
  readonly figures: Immutable.Map<string, Figure>;
}

// Initial State
const initialState: FiguresState = {
  figures: Immutable.Map<string, Figure>(),
};

// Action Creators
export const actions = {
  add: (figure: Figure) => ({
    type: ADD,
    payload: figure,
  }),

  update: (figureId: string, newValues: FigureChange) => ({
    type: UPDATE,
    payload: { figureId, newValues },
  }),

  openFigure: (figureId: string, newValues: FigureChange) => ({
    type: OPEN_FIGURE,
    payload: { figureId, newValues },
  }),

  remove: (figureId: string) => ({
    type: REMOVE,
    payload: figureId,
  }),

  addWall: (figureId: string, wallId: string) => ({
    type: ADD_WALL,
    payload: { figureId, wallId },
  }),

  removeWall: (figureId: string, wallId: string) => ({
    type: REMOVE_WALL,
    payload: { figureId, wallId },
  }),

  clear: () => ({
    type: CLEAR,
  }),
};

// Selectors
const getFiguresState = (rootState: RootState): FiguresState => rootState.figures;

const getAllFigures = (
  rootState: RootState,
): Immutable.Map<string, Figure> => getFiguresState(rootState).figures;

const getAllClosedFigures = (rootState: RootState): Immutable.Map<string, ClosedFigure> => getAllFigures(rootState)
  .valueSeq()
  .reduce((closedFigures: Immutable.Map<string, ClosedFigure>, figure: Figure) => {
    if (isClosedFigure(figure)) return closedFigures.set(figure.figureId, figure);
    return closedFigures;
  }, Immutable.Map<string, ClosedFigure>());

const getFiguresIds = (
  rootState: RootState,
): string[] => getAllFigures(rootState).keySeq().toArray();

const getNonInteriorFigures = (
  rootState: RootState,
): Immutable.Map<string, Figure> => getAllFigures(rootState)
  .filter(figure => figure.type !== FigureType.INTERIOR);

const getInteriorFigures = (
  rootState: RootState,
): Immutable.Map<string, Figure> => getAllFigures(rootState)
  .filter(figure => figure.type === FigureType.INTERIOR);

const getFigureById = (
  rootState: RootState,
  figureId: string,
): Figure => getAllFigures(rootState).get(figureId)!;

const getClosedFigureById = (
  rootState: RootState,
  figureId: string,
): ClosedFigure | undefined => {
  const figure = getAllFigures(rootState).get(figureId);
  if (figure && isClosedFigure(figure)) {
    return figure;
  }
  return undefined;
};

const getFigureByWallId = (
  rootState: RootState,
  wallId: string,
): Figure => getAllFigures(rootState).valueSeq().find(f => f.walls.includes(wallId))!;


export const getObjectFromPages = (rootState: RootState, objectId: string) => {
  let result = null;

  pagesSelectors.getAllPagesAsMap(rootState).forEach((page) => {
    if(page.objects) {
      Immutable.Map(page.objects).forEach((objectvalue) => {
        if(objectvalue.objectId === objectId) {
          result = objectvalue.object;
        }
      });
    }
  });

  return result;
}

export const getWallsFromPages = (rootState: RootState, existingfigure: Figure) => {
  let walls = Immutable.Map<string, Wall | CircleWall>();
  existingfigure.walls.forEach((wall) => {
    const wallObject = getObjectFromPages(rootState, wall);
    if(wallObject) {
      walls = walls.set(wall, wallObject);
    }
  });

  return walls;
}

export const getPointsFromPages = (rootState: RootState, walls: Immutable.Map<string, Wall | CircleWall>) => {
  let points = Immutable.Map<string, Wall | CircleWall>();

  walls.forEach((wall) => {
    wall.points.forEach((point) => {
        const pointObject = getObjectFromPages(rootState, point);
        if(pointObject) {
          points = points.set(point, pointObject);
        }
    });
  });

  return points;
}

const getFigurePoints = (rootState: RootState, figureId: string, existingfigure?: Figure): Immutable.List<Point> => {

  if(existingfigure) {
    const walls = getWallsFromPages(rootState, existingfigure);
    const points = getPointsFromPages(rootState, walls);

    return figurePointsHelper.getFigurePoints(walls, points, existingfigure);
  }

  const walls = wallsSelectors.getAllWalls(rootState);
  const points = pointsSelectors.getAllPoints(rootState);
  const figure = getFigureById(rootState, figureId);

  return figurePointsHelper.getFigurePoints(walls, points, figure);
};

const getFigureWalls = (rootState: RootState, figureId: string): Wall[] => {
  const figure = getFigureById(rootState, figureId);
  const walls = wallsSelectors.getAllWalls(rootState);
  return figure.walls.map(wallId => walls.get(wallId)!);
};

const getFigureArcWalls = (rootState: RootState, figureId: string): Wall[] => {
  const figureWalls = getFigureWalls(rootState, figureId);
  return figureWalls.filter(wall => wall.wallType === WallType.ARC);
};

const getFigureArcPoints = (rootState: RootState, figureId: string): Immutable.List<Point> => {
  const points = pointsSelectors.getAllPoints(rootState);
  const figureArcWalls = getFigureArcWalls(rootState, figureId);
  const arcPoints = figureArcWalls.map(wall => points.get(wall.points[2])!);
  return Immutable.List(arcPoints);
};

const getFigurePointsForDraw = (rootState: RootState, figureId: string): Immutable.List<Point> => {
  const figure = getFigureById(rootState, figureId);
  const walls = wallsSelectors.getAllWalls(rootState);
  const points = pointsSelectors.getAllPoints(rootState);

  return figurePointsHelper.getFigurePointsForDraw(walls, points, figure);
};

const getLastPoint = (rootState: RootState, figureId: string): Point | undefined => {
  const figure = getFigureById(rootState, figureId);
  const walls = wallsSelectors.getAllWalls(rootState);
  const points = pointsSelectors.getAllPoints(rootState);

  return figurePointsHelper.getLastPoint(walls, points, figure);
};

const getLastWall = (rootState: RootState, figureId: string): Wall => {
  const figure = getFigureById(rootState, figureId);
  const walls = wallsSelectors.getAllWalls(rootState);

  const wallId = figure.walls[figure.walls.length - 1];
  return walls.get(wallId)!;
};

const isClosingBaseFigureOnId = (rootState: RootState, figureId: string): boolean => {
  const figure = getFigureById(rootState, figureId);
  if (!figure) {
    return false;
  }
  const walls = wallsSelectors.getAllWalls(rootState);
  return figurePointsHelper.isClosingBaseFigure(walls, figure);
};

const getAreaLabel = (rootState: RootState, figureId: string): PositionedAreaLabel | undefined => {
  const figure = getClosedFigureById(rootState, figureId);

  return figure && figure.positionedAreaLabel;
};

const getAreaLabelPosition = (rootState: RootState, figureId: string): Point | undefined => {
  const areaLabel = getAreaLabel(rootState, figureId);
  if (!areaLabel) {
    return undefined;
  }
  const points = pointsSelectors.getAllPoints(rootState);
  return points.get(areaLabel!.pointId)!;
};

const getFigureByAreaLabelPosition = (rootState: RootState, pointId: string): ClosedFigure => {
  const figures = getAllClosedFigures(rootState);
  return figures.find((figure) => {
    if (!figure.positionedAreaLabel) {
      return false;
    }
    const areaLabel = figure.positionedAreaLabel;
    return areaLabel.pointId === pointId;
  })!;
};

const getInnerFigurePoints = (
  rootState: RootState,
  figureId: string,
  exceptPointIds: string[],
): Immutable.List<Point> => {
  const points: Immutable.List<Point> = pointsSelectors.getAllPointsList(rootState);
  const figure = getFigureById(rootState, figureId);
  const isClosed = isClosedFigure(figure);
  const figurePointsArray: Immutable.List<Point> = getFigurePoints(rootState, figureId);

  return points.filter((p: Point) => !exceptPointIds.includes(p.pointId)
    && isPointToFigureCollision(figurePointsArray, isClosed, p));
};

export const selectors = {
  getAllFigures,
  getAllClosedFigures,
  getFiguresIds,
  getInteriorFigures,
  getNonInteriorFigures,
  getFigureById,
  getClosedFigureById,
  getFigureByWallId,
  getFigurePoints,
  getFigureWalls,
  getFigureArcWalls,
  getFigureArcPoints,
  getFigurePointsForDraw,
  getLastPoint,
  getLastWall,
  isClosingBaseFigureOnId,
  getAreaLabel,
  getAreaLabelPosition,
  getFigureByAreaLabelPosition,
  getInnerFigurePoints,
};

interface FigureUpdatePayload {
  readonly figureId: string;
  readonly newValues: FigureChange;
}

interface WallPayload {
  readonly wallId: string;
  readonly figureId: string;
}

// Reducers
const addFigureReducer = (state: FiguresState, figure: Figure): FiguresState => ({
  ...state,
  figures: state.figures.set(figure.figureId, figure),
});

const updateFigureReducer = (state: FiguresState, payload: FigureUpdatePayload): FiguresState => {
  const { figureId, newValues } = payload;
  const oldValues: Figure = state.figures.get(figureId)!;
  const newFigure = {
    ...oldValues,
    ...newValues,
  };
  return {
    ...state,
    figures: state.figures.set(figureId, newFigure),
  };
};

const openFigureReducer = (state: FiguresState, payload: FigureUpdatePayload): FiguresState => {
  const { figureId, newValues } = payload;
  const oldValues: Figure = state.figures.get(figureId)!;
  const newFigure: OpenFigure = {
    figureId,
    type: newValues.type || oldValues.type,
    walls: newValues.walls || oldValues.walls,
  };
  return {
    ...state,
    figures: state.figures.set(figureId, newFigure),
  };
};

const removeFigureReducer = (state: FiguresState, figureId: string): FiguresState => ({
  ...state,
  figures: state.figures.remove(figureId),
});

const clearFiguresReducer = (state: FiguresState): FiguresState => ({
  ...state,
  figures: Immutable.Map<string, Figure>(),
});

const addWallReducer = (state: FiguresState, payload: WallPayload): FiguresState => {
  const { figureId, wallId } = payload;
  const figure = state.figures.get(figureId)!;

  return {
    ...state,
    figures: state.figures.set(figure.figureId, {
      ...figure,
      walls: [...figure.walls, wallId],
    }),
  };
};

const removeWallReducer = (state: FiguresState, payload: WallPayload): FiguresState => {
  const { figureId, wallId } = payload;
  const figure = state.figures.get(figureId)!;
  return {
    ...state,
    figures: state.figures.set(figure.figureId, {
      ...figure,
      walls: figure.walls.filter(wId => wId !== wallId),
    }),
  };
};

export const reducer = (state: FiguresState = initialState, action: PayloadAction): FiguresState => {
  switch (action.type) {
    case ADD:
      return addFigureReducer(state, action.payload);

    case UPDATE:
      return updateFigureReducer(state, action.payload);

    case OPEN_FIGURE:
      return openFigureReducer(state, action.payload);

    case REMOVE:
      return removeFigureReducer(state, action.payload);

    case CLEAR:
      return clearFiguresReducer(state);

    case ADD_WALL:
      return addWallReducer(state, action.payload);

    case REMOVE_WALL:
      return removeWallReducer(state, action.payload);

    default:
      return state;
  }
};
