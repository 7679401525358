import { geometryConfig } from 'config/geometryConfig';
import { colors, walls as wallsColors } from 'config/paletteConfig';

interface WallStyles {
  get: (zoomInPercent: number) => any;
}


export const wallStyles: WallStyles = {
  get: (zoomInPercent: number) => (
    {
      base: {
        stroke: wallsColors.base,
        fill: colors.none,
      },
      drawingBase: {
        // when actively drawing a wall; before an endpoint is set
        strokeWidth: `${geometryConfig.getExteriorLineWidth(zoomInPercent)}px`,
      },
      wallSVGSegment: {
        strokeWidth: `${geometryConfig.getExteriorLineWidth(zoomInPercent)}`,
        fill: 'none',
        fillOpacity: '0.5',
      },
      wallSVGSegmentTouchHandle: {
        strokeWidth: 10,
        fill: 'none',
        strokeOpacity: '0',
      },
      hover: {
        '&:hover': {
          cursor: 'pointer',
          stroke: wallsColors.hovered,
        },
      },
      interior: {
        stroke: wallsColors.interior,
        strokeWidth: `${geometryConfig.getInteriorLineWidth(zoomInPercent)}px`,
      },
      interiorSVGSegment: {
        strokeWidth: `${geometryConfig.getInteriorLineWidth(zoomInPercent)}`,
        fill: 'none',
        fillOpacity: '0.5',
      },
      drawSegmentLine: {
        stroke: wallsColors.base,
        strokeWidth: `${geometryConfig.getExteriorLineWidth(zoomInPercent) / 5}px`,
        fill: colors.none,
        strokeDasharray: '6 4',
      },
      alignmentLine: {
        stroke: colors.pink,
        strokeWidth: `${geometryConfig.getExteriorLineWidth(zoomInPercent) / 5}px`,
        fill: colors.none,
      },
      active: {
        stroke: wallsColors.selected,
        fill: colors.none,
        cursor: 'move',
      },
      edit: {
        '&:hover': {
          cursor: 'crosshair',
        },
      },
      error: {
        stroke: colors.red,
      },
    }
  )
};
export const arrowsStyles = {
  fill: wallsColors.base,
};


export const arcAngleStyles: WallStyles = {
  get: (zoomInPercent: number) => (
    {
      stroke: wallsColors.base,
      strokeWidth: `${geometryConfig.getExteriorLineWidth(zoomInPercent) / 4}px`,
      fill: colors.none,
    }
  )

};

export default wallStyles;
