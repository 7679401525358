import { Dimensions } from 'types/dimensions';
import { Corner } from 'types/corner';
import { CoordinatePoint } from 'types/point';
import { SizingInfo } from 'types/sizingInfo';
import { DEGREES_PER_RADIAN } from 'helpers/rotation';

const minSize = 10;

export const resizeObject = (
  currentSize: Dimensions, rotation: number, position: CoordinatePoint, d: CoordinatePoint, corner: Corner,
): SizingInfo => {
  let newWidth;
  let newHeight;

  const { width = 0, height = 0 } = currentSize;
  const { x: dx, y: dy } = d;

  // calculate rotation vector
  const rotationRad = rotation / DEGREES_PER_RADIAN;
  const sinF = Math.sin(rotationRad);
  const cosF = Math.cos(rotationRad);

  // rotated delta - modified formula for non-cartesian coordinates plus flip to left bottom corner ([1, 1])
  const rotated = {
    x: (dx * cosF + dy * sinF) * corner.x,
    y: (-dx * sinF + dy * cosF) * corner.y,
  };

  // get resized dimension and calculate the other to preserve aspect ratio
  const w1 = Math.max(width + 2 * rotated.x, minSize);
  const h1 = height * (w1 / width);

  const h2 = Math.max(height + 2 * rotated.y, minSize);
  const w2 = width * (h2 / height);

  // only resize the dimension that user "changed" more
  if (Math.abs(w1 - currentSize.width) > Math.abs(w2 - currentSize.width)) {
    newWidth = w1;
    newHeight = h1;
  } else {
    newWidth = w2;
    newHeight = h2;
  }

  return {
    position: {
      ...position,
    },
    size: {
      width: newWidth,
      height: newHeight,
    },
  };
};
