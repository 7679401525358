import React, { Component } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';

import { ReactComponent as IconBackspace } from 'assets/icons/backspace.svg';
import { BackspaceButton } from 'components/modal/NumpadMenuModal';
import SearchIcon from '@mui/icons-material/Search';

import { scaleYIn } from 'components/animations';

const LabelAnimated = posed.label(scaleYIn);

interface InputProps {
  readonly className?: string;
  readonly onEditFinished: (value: string) => void;
}

type Props = InputProps

interface State {
  readonly text: string;
}

class SketchSearchInput extends Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  private readonly focusRef: React.RefObject<HTMLInputElement>;

  // due too setState is async operation we can't put isSaved to state
  // otherwise save called twice: on enter and on component un-mount
  private isSaved: boolean;

  public constructor(props: Props) {
    super(props);
    this.focusRef = React.createRef();
    this.state = {
      text: '',
    };

    this.isSaved = false;
  }

  public componentDidMount(): void {
    this.focusRef.current!.focus();
  }

  public componentWillUnmount(): void {
    this.save();
  }

  private editFinished = (): void => {
    const { onEditFinished } = this.props;
    const { text } = this.state;
    this.isSaved = true;
    onEditFinished(text);
  };

  private handleTextChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const text = event.target.value;
    this.setState(() => ({ text }));
    this.isSaved = false;
  };

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.save(event);
    }
    if (event.key === 'Escape') {
      this.editFinished();
    }
  };

  private save = (event?: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>): void => {
    if (event) {
      event.preventDefault();
    }

    if (this.isSaved) {
      return;
    }

    const { text } = this.state;
    if (!text) {
      return;
    }

    this.editFinished();
  };

  private preventDefault = (ev: React.MouseEvent | React.TouchEvent): void => ev.preventDefault();

  private onClearInput = (): void => {
    this.setState(() => ({ text: '' }));
  };

  public render = (): JSX.Element => {
    const { className } = this.props;
    const { text } = this.state;

    return (
      <>
        <form onSubmit={this.save} style={{ width: '100%' }}>
          <LabelAnimated htmlFor="textInput" className={className} initialPose="hide" pose="show" style={{ textOverflow: 'unset' }}>
            <input
              id="textInput"
              ref={this.focusRef}
              type="text"
              placeholder="Sketch Name / Order Number / Property Address / Date"
              required
              value={text}
              onChange={this.handleTextChanged}
              onKeyDown={this.handleKeyDown}
            />
          </LabelAnimated>
        </form>
        <BackspaceButton
          onPointerDown={this.onClearInput}
          onPointerUp={this.onClearInput}
          onTouchEnd={this.preventDefault}
        >
          <IconBackspace />
        </BackspaceButton>
        <BackspaceButton
          style={{ marginLeft: 8 }}
          onPointerUp={this.editFinished}
          onTouchEnd={this.preventDefault}
        >
          <SearchIcon />
        </BackspaceButton>
      </>
    );
  };
}

export default connect()(SketchSearchInput);
