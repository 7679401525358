import React, { Component } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';

import { messages } from 'config/messages';
import { numpad as colors, modal } from 'config/paletteConfig';

import Immutable from 'immutable';
import { CoordinatePoint } from 'types/point';
import { Direction } from 'types/direction';
import { Wall, CircleWall } from 'types/wall';

import { RootState } from 'reducers/rootReducer';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';
import { parseString } from 'helpers/draw/parseDrawInput';
import { movePointInDirection } from 'helpers/move/movePoint';
import { feetToPixels, metersToPixels } from 'helpers/pixelsToDistance';

import { selectors as drawsSelectors, actions as drawActions } from 'ducks/draw/draw';
import { actions as editModeActions } from 'ducks/editMode';
import { selectors as wallSelectors } from 'ducks/model/walls';
import { actions as numpadModalActions } from 'ducks/modal/numpadModal';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as drawWithKeypadSelectors, actions as drawWithKeypadActions } from 'ducks/draw/drawWithKeypad';

import { Modal, ButtonModalHeader as Button } from 'components/modal/Modal';
import { appearFromTop } from 'components/animations';
import { scrollbar } from 'components/globalStyles';
import { ReactComponent as IconBackspace } from 'assets/icons/backspace.svg';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { coordinatesByAngle } from 'helpers/geometry';
import { isMobileApplication } from 'effects/auth';

const MAX_LENGTH = 8;

const NumpadContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 10px;
`;

const NumbersContainer = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const ArrowsContainer = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

interface StyledNumpadButtonProps {
  readonly isActive?: boolean;
}

const NumpadButton = styled.button<StyledNumpadButtonProps>(({ isActive }) => ({
  flexBasis: '26%',
  height: '32px',
  margin: '4px',
  padding: 0,
  border: 0,
  backgroundColor: `${isActive ? colors.button.numPadActive : colors.button.base}`,
  color: ` ${colors.button.text}`,
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: '500px',
  transition: 'backgroundColor .1s linear, opacity 0.17s linear',
  userSelect: 'none',

  '&:active': {
    backgroundColor: `${colors.button.active}`
  },

  '&:disabled': {
    opacity: '0.4'
  },
}));

export const BackspaceButton = styled.button`
  user-select: none;
  width: 32px;
  height: 32px;
  margin: 0 0 0 auto;
  border: 0;

  svg {
    position: relative;
    top: -2px;
    left: -1px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
`;

const EnterButton = styled(NumpadButton)`
  background-color: ${modal.button.base};
  color: ${modal.button.color};
  font-size: 8px;
  font-weight: bold;
  text-transform: uppercase;

  &:active {
    background-color: ${modal.button.hovered};
  }
`;

const NumField = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  padding: 6px 0;
  margin: 0 20px;
  border-bottom: 1px solid #A4B9F4;
  font-size: 18px;

  span {
    max-width: 140px;
    overflow: hidden;
  }
`;

const ModalWithScroll = (left: number) => styled(Modal)(
  scrollbar,
  {
    minWidth: 0,
    maxWidth: '100%',
    left: `${left}px`,
    width: `calc(100% - ${left}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: colors.base,
    zIndex: 1,
  },
);

const modalStyle = css`
  width: ${window.screen.width > 400 ? '100%' : '100%'};
  z-index: 1;
  h2 {
    display: flex;
    justify-content: space-between;
  }

  span {
    text-transform: capitalize;
  }
`;


interface InputProps {
  readonly top: number;
  readonly left: number;
  readonly height: number;
  readonly pose?: string;
}

interface StateProps {
  readonly startPoint: CoordinatePoint;
  readonly endPoint: CoordinatePoint;
  readonly previousStartPoint: CoordinatePoint;
  readonly unitOfMeasure: string;
  readonly currentGridSize: number;
  readonly inputSequence: string[];
  readonly currentValue: string;
  readonly currentAngleValue: string;
  readonly currentDirection: Direction | string | undefined;
  readonly isAngleInputEnabled: boolean;
  readonly walls: Immutable.Map<string, Wall | CircleWall>;
}

interface ActionProps {
  readonly setCurrentDirection: typeof drawWithKeypadActions.setCurrentDirection;
  readonly setAngleInput: typeof drawWithKeypadActions.setAngleInput;
  readonly addDrawInput: typeof drawWithKeypadActions.addDrawInput;
  readonly resetCurrentValue: typeof drawWithKeypadActions.resetCurrentValue;
  readonly resetCurrentDirection: typeof drawWithKeypadActions.resetCurrentDirection;
  readonly resetDrawInput: typeof drawWithKeypadActions.resetDrawInput;
  readonly hideNumpadModal: () => void;
  readonly setEndPoint: (endPoint: CoordinatePoint, center: boolean) => void;
  readonly finishSegment: () => void;
  readonly toggleDrawingInteriorWalls: (drawInteriorWalls: boolean) => void;
  readonly toggleDrawingPreshapes: (drawPreshapes: boolean) => void;
  readonly switchToEdit: typeof editModeActions.switchToEdit;
}

type Props = InputProps & StateProps & ActionProps;

type NumpadValue = string | '.' | ','

interface State {
  readonly value: string;
  readonly angleValue?: string;
  readonly direction?: Direction | string;
}

const isValid = (string: string): boolean => /[1-9]/.test(string);

const Arrow: React.FC<{ direction: Direction | string }> = ({ direction }) => {
  switch (direction) {
    case Direction.Up: return <>↑</>;
    case Direction.Down: return <>↓</>;
    case Direction.Left: return <>←</>;
    case Direction.Right: return <>→</>;
    case Direction.UpLeft: return <>↖</>;
    case Direction.UpRight: return <>↗</>;
    case Direction.DownLeft: return <>↙</>;
    case Direction.DownRight: return <>↘</>;
    default: throw new UnreachableCaseError(direction);
  }
};

interface NumpadValueProps {
  value: NumpadValue;
  disabled?: boolean;
  isActive?: boolean;
  onClick: (value: string) => void;
}

const NumpadValue: React.FC<NumpadValueProps> = ({
  isActive, value, onClick, disabled,
}) => (
  <NumpadButton isActive={isActive} onClick={() => onClick(value)} disabled={disabled}>{value}</NumpadButton>
);

interface ArrowDirectionProps {
  direction: Direction;
  isActive?: boolean;
  disabled: boolean;
  onClick: (direction: Direction) => void;
}
const ArrowDirection: React.FC<ArrowDirectionProps> = ({
  direction, disabled, onClick, isActive
}) => (
  <NumpadButton isActive={isActive} onClick={() => onClick(direction)} disabled={disabled}>
    <Arrow direction={direction} />
  </NumpadButton>
);

const preventDefault = (ev: React.MouseEvent | React.TouchEvent): void => ev.preventDefault();

class NumpadMenuModal extends Component<Props, State> {
  private onClearStart = (): void => {
    const { resetDrawInput } = this.props;
    resetDrawInput();
  };

  private onClearEnd = (): void => {
    const { resetDrawInput } = this.props;
    resetDrawInput();
  };

  private handleValueChange = (newValue: string): void => {
    const { addDrawInput } = this.props;
    addDrawInput(newValue);
  };

  private handleDirectionChange = (direction: Direction): void => {
    const {
      setEndPoint,
      unitOfMeasure,
      currentValue,
      currentGridSize,
      addDrawInput,
      isAngleInputEnabled,
      currentAngleValue,
      setCurrentDirection,
      endPoint,
      previousStartPoint
    } = this.props;

    setCurrentDirection(direction);

    if (isValid(currentValue)) {
      const { feet, inches } = parseString(currentValue);
      const pixels = unitOfMeasure === 'meters' ? metersToPixels(parseFloat(currentValue), currentGridSize) : feetToPixels(feet, inches);

      if (isAngleInputEnabled && currentAngleValue !== '0') {
        const newCoordinates = coordinatesByAngle(previousStartPoint, endPoint, pixels, +currentAngleValue, direction);
        setEndPoint({ ...newCoordinates }, false);
      } else if (!isAngleInputEnabled) {
        addDrawInput(direction);
        setEndPoint(movePointInDirection({ ...endPoint }, direction, pixels), false);
      } else {
        addDrawInput(direction);
      }
    }
  };

  private canFinish = (): boolean => {
    const {
      startPoint, endPoint, currentValue, currentDirection, currentAngleValue, isAngleInputEnabled
    } = this.props;
    if (isAngleInputEnabled && currentDirection) return currentValue !== '' && currentAngleValue !== '0' && currentDirection !== '';
    return startPoint.x !== endPoint.x || startPoint.y !== endPoint.y;
  };

  private handleEnter = (): void => {
    if (this.canFinish()) {
      const { finishSegment, resetDrawInput } = this.props;
      finishSegment();
      resetDrawInput();
    }
  };

  private switchToInteriorWallsMode = (): void => {
    const { switchToEdit, toggleDrawingInteriorWalls, toggleDrawingPreshapes } = this.props;
    toggleDrawingInteriorWalls(true);
    toggleDrawingPreshapes(false);
    switchToEdit();
  };

  private switchToEditMode = (): void => {
    const { switchToEdit, toggleDrawingInteriorWalls, toggleDrawingPreshapes } = this.props;
    toggleDrawingInteriorWalls(false);
    toggleDrawingPreshapes(false);
    switchToEdit();
  };

  private handleAngleModeClick = () => {
    const { setAngleInput, currentValue, walls } = this.props;
    if (!walls.size) {
      toast(messages.angleTriggerWithoutWalls, {
        type: toast.TYPE.WARNING,
      });
      return;
    }

    if (!+currentValue) {
      toast(messages.angleTriggerWithoutDistance, {
        type: toast.TYPE.WARNING,
      });
      return;
    }
    setAngleInput();
  };

  public handleCloseClick = () => {
    const { hideNumpadModal } = this.props;
    hideNumpadModal();
  };

  public render = (): JSX.Element => {
    const {
      top, left, height, pose, currentValue, currentDirection, currentAngleValue, isAngleInputEnabled, inputSequence
    } = this.props;
    const ModalAnimated = posed(ModalWithScroll(left))(appearFromTop);

    const directionDisabled = !isValid(currentValue);
    const finishDisabled = !this.canFinish();
    const lastInput = inputSequence[inputSequence.length - 1];
    const numbersDisabled = currentValue.length >= MAX_LENGTH;
    const styledCurrentAngleValue = ` ${currentAngleValue} °`;

    const numPadTop = height - 230 + top - (isMobileApplication() ? 70 : 0); // - 50;

    return (
      <ModalAnimated top={numPadTop} pose={pose} css={modalStyle}>
        <NumField>
          <span>
            {currentValue}
            {currentDirection && <Arrow direction={currentDirection} />}
            {isAngleInputEnabled && currentAngleValue !== '' && styledCurrentAngleValue}
          </span>
          <BackspaceButton
            onPointerDown={this.onClearStart}
            onPointerUp={this.onClearEnd}
            onTouchEnd={preventDefault}
          >
            <IconBackspace />
          </BackspaceButton>
          <Button onClick={this.handleCloseClick}>
            <IconClose />
          </Button>
        </NumField>

        <NumpadContainer>
          <NumbersContainer>
            <NumpadValue isActive={lastInput === '7'} onClick={this.handleValueChange} value="7" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '8'} onClick={this.handleValueChange} value="8" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '9'} onClick={this.handleValueChange} value="9" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '4'} onClick={this.handleValueChange} value="4" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '5'} onClick={this.handleValueChange} value="5" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '6'} onClick={this.handleValueChange} value="6" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '1'} onClick={this.handleValueChange} value="1" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '2'} onClick={this.handleValueChange} value="2" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '3'} onClick={this.handleValueChange} value="3" disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '.'} onClick={this.handleValueChange} value="." disabled={numbersDisabled} />
            <NumpadValue isActive={lastInput === '0'} onClick={this.handleValueChange} value="0" disabled={numbersDisabled} />
            <NumpadValue isActive={isAngleInputEnabled} onClick={this.handleAngleModeClick} value="A" disabled={numbersDisabled} />
          </NumbersContainer>

          <ArrowsContainer>
            <EnterButton onClick={this.switchToEditMode}>Exterior</EnterButton>
            <EnterButton onClick={this.switchToInteriorWallsMode}>Interior</EnterButton>
            <Break />
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowUpLeft'}
              direction={Direction.UpLeft}
              disabled={directionDisabled || isAngleInputEnabled}
            />
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowUp'}
              direction={Direction.Up}
              disabled={directionDisabled || isAngleInputEnabled}
            />
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowUpRight'}
              direction={Direction.UpRight}
              disabled={directionDisabled || isAngleInputEnabled}
            />
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowLeft'}
              direction={Direction.Left}
              disabled={directionDisabled}
            />
            <EnterButton onClick={this.handleEnter} disabled={finishDisabled}>Enter</EnterButton>
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowRight'}
              direction={Direction.Right}
              disabled={directionDisabled}
            />
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowDownLeft'}
              direction={Direction.DownLeft}
              disabled={directionDisabled || isAngleInputEnabled}
            />
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowDown'}
              direction={Direction.Down}
              disabled={directionDisabled || isAngleInputEnabled}
            />
            <ArrowDirection
              onClick={this.handleDirectionChange}
              isActive={lastInput === 'ArrowDownRight'}
              direction={Direction.DownRight}
              disabled={directionDisabled || isAngleInputEnabled}
            />
          </ArrowsContainer>
        </NumpadContainer>
      </ModalAnimated>
    );
  };
}

export default connect(
  (state: RootState): StateProps => ({
    isAngleInputEnabled: drawWithKeypadSelectors.getIsAngleInputEnabled(state),
    currentAngleValue: drawWithKeypadSelectors.getCurrentAngleValue(state),
    currentDirection: drawWithKeypadSelectors.getCurrentDirection(state),
    currentValue: drawWithKeypadSelectors.getCurrentValue(state),
    inputSequence: drawWithKeypadSelectors.getDrawInputSequence(state),
    startPoint: drawsSelectors.getStartPoint(state),
    endPoint: drawsSelectors.getEndPoint(state),
    previousStartPoint: drawsSelectors.getPreviousStartPoint(state),
    unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
    currentGridSize: settingsSelectors.getGridSizeInFeet(state),
    walls: wallSelectors.getAllWalls(state)
  }),
  {
    addDrawInput: drawWithKeypadActions.addDrawInput,
    setCurrentDirection: drawWithKeypadActions.setCurrentDirection,
    setAngleInput: drawWithKeypadActions.setAngleInput,
    resetDrawInput: drawWithKeypadActions.resetDrawInput,
    resetCurrentValue: drawWithKeypadActions.resetCurrentValue,
    resetCurrentDirection: drawWithKeypadActions.resetCurrentDirection,
    hideNumpadModal: numpadModalActions.hide,
    setEndPoint: drawActions.setEndPointWithoutSnapping,
    finishSegment: drawActions.finishSegment,
    toggleDrawingInteriorWalls: drawActions.toggleDrawingInteriorWalls,
    toggleDrawingPreshapes: drawActions.toggleDrawingPreshapes,
    switchToEdit: editModeActions.switchToEdit,
  },
)(NumpadMenuModal);
