import { gridConfig } from 'config/gridConfig';
import { CoordinatePoint } from 'types/point';

/**
 * calculate snap point (if the point near grid point)
 * we snap independently by x and y
 */
export const getSnappedCoordinatePointToGrid = (p: CoordinatePoint, snapDivision: number): CoordinatePoint => {
  const snapSize = gridConfig.gridSize / (snapDivision * gridConfig.gridSizeInFeet);
  const snapTolerance = snapSize / 2;

  // reminder
  const r = {
    x: p.x % snapSize,
    y: p.y % snapSize,
  };

  // reminder's absolute values
  const absR = {
    x: Math.abs(r.x),
    y: Math.abs(r.y),
  };

  // sign of the reminder
  const signR = {
    x: Math.sign(r.x),
    y: Math.sign(r.y),
  };

  // previous point on the grid
  // i.e. if p is {0.1, 1.1}, grid point will be {0.0, 1.0}
  const gridPoint = {
    x: p.x - r.x,
    y: p.y - r.y,
  };

  // we return point itself if we don't snap
  const snapped = { ...p };

  if (absR.x < snapTolerance) {
    snapped.x = gridPoint.x;
  } else if (snapSize - absR.x <= snapTolerance) {
    snapped.x = gridPoint.x + snapSize * signR.x;
  }

  if (absR.y < snapTolerance) {
    snapped.y = gridPoint.y;
  } else if (snapSize - absR.y <= snapTolerance) {
    snapped.y = gridPoint.y + snapSize * signR.y;
  }

  return snapped;
};

export const getSnappedToGrid = <T extends CoordinatePoint>(p: T, snapDivision: number): T => {
  const snappedPosition = getSnappedCoordinatePointToGrid({ x: p.x, y: p.y }, snapDivision);
  return {
    ...p,
    x: snappedPosition.x,
    y: snappedPosition.y,
  };
};
