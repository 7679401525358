export enum Direction {
  Up = 'ArrowUp',
  Down = 'ArrowDown',
  Left = 'ArrowLeft',
  Right = 'ArrowRight',
  UpLeft = 'ArrowUpLeft',
  UpRight = 'ArrowUpRight',
  DownLeft = 'ArrowDownLeft',
  DownRight = 'ArrowDownRight',
}
