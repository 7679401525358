/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';

import { sketchConfig } from 'config/sketchConfig';
import { lengthLabels as colors } from 'config/paletteConfig';
import { CoordinatePoint } from 'types/point';
import { LabelBaselineType } from 'types/labelOrientationType';
import Rotate from 'components/sketch/Rotate/Rotate';
import SelectionBorder from '../SelectionBorder/SelectionBorder';

const measurementLabelStyle = css`
  pointer-events: bounding-box;
  fill: ${colors.base};
  cursor: pointer;
`;

interface Props {
  readonly text: string;
  readonly position: CoordinatePoint;
  readonly rotation?: number;
  readonly baseline?: LabelBaselineType;
  readonly size?: number;
  readonly selected?: boolean | false;
}
const { labelLineSpace: line } = sketchConfig;

const getDY = (baseline: LabelBaselineType | undefined): string =>
  // eslint-disable-next-line no-nested-ternary
  baseline === LabelBaselineType.ABOVE ? `-${line}px` : baseline === LabelBaselineType.BELOW ? `${line}px` : '0';

const getDominantBaseline = (baseline: LabelBaselineType | undefined): string => {
  if (baseline === LabelBaselineType.ABOVE) {
    return 'baseline';
  }

  if (baseline === LabelBaselineType.BELOW) {
    return 'hanging';
  }

  return 'middle';
};

const MeasurementLabel = ({ text, position, rotation, baseline, size, selected }: Props): JSX.Element => {
  const fontStyle = css`
    font-size: ${size || 100}%
  `;

  return position.x && position.y ? (
    <Rotate degrees={rotation} position={position}>
      <g>
        {selected && <SelectionBorder x={position.x - 10} y={position.y - 10} width={100} height={100} />}
        <text
          css={[measurementLabelStyle, fontStyle]}
          className={baseline}
          x={position.x}
          y={position.y}
          dy={getDY(baseline)}
          dominantBaseline={getDominantBaseline(baseline)}
          textAnchor="middle"
        >
          {text}
        </text>
      </g>
    </Rotate>
  ) : (
    <React.Fragment />
  );
};

export default MeasurementLabel;
