import { geometryConfig } from 'config/geometryConfig';
import { scaleConfig } from 'config/scaleConfig';

const pointSizeTouchMultiplier = 2; // make selected point twice the size for touch?
const radiusAutoFinish = 50; // Tablet auto-finish point

interface PointConfig {
  getRadius: (zoomInPercent: number) => number;
  getRadiusTouch: (zoomInPercent: number) => number;
  getRadiusSelected: (zoomInPercent: number) => number;
  getRadiusTouchSelected: (zoomInPercent: number) => number;
  getRadiusAutoFinish: (zoomInPercent: number) => number;
}

export const pointConfig: PointConfig = {
  /**
 * Size of point
 * @param zoomInPercent the current zoom in %
 * @returns size
 */
  getRadius: (zoomInPercent: number): number => scaleConfig.getScale(geometryConfig.getExteriorLineWidth(zoomInPercent) / 2, 3, zoomInPercent),

  /**
 * Size of point on a touch device
 * @param zoomInPercent the current zoom in %
 * @returns size
 */
  getRadiusTouch: (zoomInPercent: number): number => scaleConfig.getScale((geometryConfig.getExteriorLineWidth(zoomInPercent) / 2)
   * pointSizeTouchMultiplier, 7 /* 10 */, zoomInPercent),

  /**
 * Size of selected point
 * @param zoomInPercent the current zoom in %
 * @returns size
 */
  getRadiusSelected: (zoomInPercent: number): number => scaleConfig.getScale(geometryConfig.getExteriorLineWidth(zoomInPercent),
    geometryConfig.getExteriorLineWidth(zoomInPercent), zoomInPercent),

  /**
 * Size of selected point on a touch device.  Note this was set to 40, which seems way too large when zoomed in
 * @param zoomInPercent the current zoom in %
 * @returns size
 */
  getRadiusTouchSelected: (zoomInPercent: number): number => scaleConfig.getScale(geometryConfig.getExteriorLineWidth(zoomInPercent) * pointSizeTouchMultiplier,
    8 /* pointSizeSelected + 3 */, zoomInPercent),

  /* geometryConfig.thickness is used to determine a point collision (required to close a figure)
     the point collision point must be small enough to allow a user to draw a 1 foot line (20 units or 1 grid block side)
     the auto finish buttom must also be small enough to allow a 1 foot line (smaller than 20 units)
     we should scale this so that it is large when zoomed out, smaller when zoomed in
   */

  /**
 * Size of the auto finish circle
 * @param zoomInPercent the current zoom in %
 * @returns size
 */
  getRadiusAutoFinish: (zoomInPercent: number): number => scaleConfig.getScale(radiusAutoFinish, geometryConfig.getExteriorLineWidth(zoomInPercent),
    zoomInPercent),
};
