/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react';
import posed from 'react-pose';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { RootState } from 'reducers/rootReducer';
import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';

import { sketchSearchModalTextConfig, createNewSketchDialog } from 'config/messages';
import { colors, sidebar } from 'config/paletteConfig';

import { isTouchDevice } from 'helpers/browserDetect';
import { FromSketchMessageType } from 'types/sketch';
import { ApiSketch } from 'types/api/apiSketch';
import { selectors as sketchSearchModalSelectors, actions as sketchSearchModalActions } from 'ducks/modal/sketchSearchModal';
import { selectors as sketchPersistenceSelectors, actions as sketchPersistenceActions } from 'ducks/persistence/sketchPersistence';
import { actions as settingsActions } from 'ducks/settings';
import { selectors as sketchesPersistenceSelectors, actions as sketchesPersistenceActions } from 'ducks/persistence/sketchesPersistence';
import { selectors as networkPersistenceSelectors } from 'ducks/persistence/networkPersistence';
import { actions as messageContainerActions, onSubmitEventType } from 'ducks/messageContainer';
import { actions as sketchActions } from 'ducks/model/sketch';
import { actions as inspectionActions } from 'ducks/inspection';
import { actions as historyActions } from 'ducks/history/history';
import { actions as settingsPersistenceActions } from 'ducks/persistence/settingsPersistence';
import { actions as saveSketchModalActions } from 'ducks/modal/saveSketchModal';

import { appearFromTop, DisplayPose } from 'components/animations';
import { Modal, ButtonModalHeader as Button } from 'components/modal/Modal';
import { sidebarItemStyles, verticalSpacing } from 'components/sidebar/styles';
import { inputStyles } from 'components/sidebar/LabelSidebar';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';

import SearchSketchItem from 'components/elements/SearchSketchItem';
import SketchSearchInput from 'components/elements/SketchSearchInput';

import { getUserIsValid } from 'effects/auth';
import { authConfig } from 'config/authConfig';

// eslint-disable-next-line no-return-await

const flexRow = {
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
} as const;

const settingsStyles = {
  'z-index': 1,
  right: window.screen.width > 400 ? 0 : 'unset',
  width: window.screen.width > 400 ? 500 : '100%',
  h2: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    textTransform: 'none',

    span: {
      marginLeft: 0,
    },
  },
} as const;

const itemStyles = {
  width: 'auto',
  minHeight: 30,
} as const;

const ModalStyled = styled(Modal)(settingsStyles);
const ModalAnimated = posed(ModalStyled)(appearFromTop);

const Item = styled.div(sidebarItemStyles, itemStyles, flexRow, verticalSpacing);

const Label = styled.span({
  color: sidebar.item.color,
  fontWeight: 'bold',
  width: '100%',
  paddingBottom: 8,
});

const preview = css`
  margin: 3px;
  color: white;
  background: ${colors.blue};
`;

const disabledStyle = css`
  background: ${colors.shadow};
  cursor: unset;
`;

const sketchSearchButtonStyle = css`
  cursor: pointer;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  height: 24px;
  border: 0;
  padding: 10px 15px;
  background-color: ${colors.white};
  text-align: left;
  user-select: none;
  box-shadow: 4px 0 0 0 transparent inset;
  transition: box-shadow 0.15s linear;
`;

interface ButtonActionProps {
  readonly onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  readonly title: string;
  readonly disabled?: boolean;
}

const SketchSearchButton = ({ onClick, title, disabled }: ButtonActionProps): React.ReactElement => (
  <button onClick={onClick} css={disabled ? [sketchSearchButtonStyle, preview, disabledStyle] : [sketchSearchButtonStyle, preview]} disabled={disabled}>
    <span>{title}</span>
  </button>
);

interface InputProps {
  readonly top: number;
  readonly pose?: DisplayPose;
}

interface StateProps {
  readonly sketches: ApiSketch[];
  readonly isShowing: boolean;
  readonly isCreated: boolean;
  readonly isOffline: boolean;
}

interface ActionProps {
  readonly close: () => void;
  readonly showSaveSketchModal: () => void;
  readonly clearSketch: typeof sketchActions.clear;
  readonly clearHistory: typeof historyActions.clear;
  readonly showMessage: (title: string, message: string | JSX.Element, onSubmit: onSubmitEventType, onSubmitText: string) => void;
  readonly toInspectionMessage: typeof inspectionActions.toInspectionMessage;
  readonly resetCurrentSketch: typeof sketchPersistenceActions.resetCurrentSketch;
  readonly loadSketches: typeof sketchesPersistenceActions.loadSketches;
  readonly setSearchTerm: typeof sketchesPersistenceActions.setSearchTerm;
  readonly setSketch: typeof sketchPersistenceActions.setSketch;
}

type Props = InputProps & StateProps & ActionProps;

const SketchSearchModal: React.FC<Props> = ({
  isOffline,
  close,
  top,
  pose,
  loadSketches,
  showSaveSketchModal,
  sketches,
  showMessage,
  clearSketch,
  clearHistory,
  resetCurrentSketch,
  toInspectionMessage,
  setSearchTerm,
  isShowing,
  isCreated,
  setSketch,
}) => {
  // eslint-disable-next-line no-return-await
  const [userIsValid, setUserIsValid] = useState(false);

  const fetchUserIsValid = async () => {
    const result = await getUserIsValid();
    setUserIsValid(result);
  };

  useEffect(() => {
    fetchUserIsValid();
  }, []);

  useEffect(() => {
    if (isShowing) loadSketches();
  }, [isShowing]);

  const searchMostRecentSketches = (value: string) => {
    setSearchTerm(value);
  };

  const sendNotification = (): void => {
    toInspectionMessage({ type: FromSketchMessageType.Ping });
  };

  const openCreateSketchWindow = (event: React.MouseEvent<HTMLElement>): void => {
    if (!isTouchDevice) event.preventDefault();
    showMessage(createNewSketchDialog.title, createNewSketchDialog.text, createNewSketch, createNewSketchDialog.submitText);

    // we added here that test send message from Sketch to Inspection app
    // it will be removed later
    sendNotification();
  };

  const createNewSketch = (): void => {
    clearSketch();
    clearHistory();
    resetCurrentSketch();
    close();
  };

  const formatDate = (date: string): string => dayjs(date).format('MM/DD/YYYY');

  const saveAndLogin = async () => {
    authConfig.enabled = true;
    await setSketch();
    window.history.replaceState({}, '', '/');
    window.location.reload();
  };

  return (
    <ModalAnimated top={top} right={28} pose={pose}>
      <h2>
        <Button onClick={close}>
          <IconClose />
        </Button>
        <span>{sketchSearchModalTextConfig.heading}</span>
        <div />
      </h2>
      {(userIsValid && !isOffline) &&
        (
          <Fragment>
            <Item>
              <Label>{sketchSearchModalTextConfig.search}</Label>
              <div style={{ display: 'flex', width: '100%' }}>
                <SketchSearchInput css={[sidebarItemStyles, inputStyles]} onEditFinished={(value: string) => searchMostRecentSketches(value)} />
              </div>
            </Item>
            <Item style={{ maxHeight: 250, overflow: 'scroll' }}>
              <Label>{sketchSearchModalTextConfig.mostRecent}</Label>
              {sketches.length > 0 ? (
                sketches.map((sketchItem: any) => (
                  <SearchSketchItem
                    key={sketchItem.id}
                    isSelected={false}
                    id={sketchItem.id}
                    sketchName={sketchItem.sketch_name}
                    orderId={sketchItem.order_id}
                    propertyAddress={sketchItem.property_address}
                    lastModifiedDate={formatDate(sketchItem.last_modified_date)}
                  />
                ))
              ) : (
                <span> No recent sketches to display </span>
              )}
            </Item>
          </Fragment>
        )}
      <Item style={{ justifyContent: 'end' }}>
        {userIsValid ? (
          <SketchSearchButton
            title={isCreated ? sketchSearchModalTextConfig.save : sketchSearchModalTextConfig.saveAs}
            onClick={showSaveSketchModal}
            disabled={isOffline}
          />
        ) : (
          <SketchSearchButton title={sketchSearchModalTextConfig.login} onClick={saveAndLogin} disabled={isOffline} />
        )}
        <SketchSearchButton title={sketchSearchModalTextConfig.newSketch} onClick={openCreateSketchWindow} />
      </Item>
    </ModalAnimated>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    isOffline: networkPersistenceSelectors.getIsOffline(state),
    sketches: sketchesPersistenceSelectors.getSketches(state),
    isShowing: sketchSearchModalSelectors.isShowing(state),
    isCreated: sketchPersistenceSelectors.isCreated(state),
  }),
  {
    resetCurrentSketch: sketchPersistenceActions.resetCurrentSketch,
    loadSketch: sketchPersistenceActions.loadSketch,
    loadSketches: sketchesPersistenceActions.loadSketches,
    setSearchTerm: sketchesPersistenceActions.setSearchTerm,
    showSaveSketchModal: saveSketchModalActions.show,
    updateSetting: settingsPersistenceActions.updateSetting,
    close: sketchSearchModalActions.hide,
    switchGridShow: settingsActions.switchGridShow,
    switchPrecision: settingsActions.switchPrecision,
    switchUnitOfMeasurement: settingsActions.switchUnitOfMeasurement,
    updateLastSetting: settingsActions.updateLastSetting,
    clearSketch: sketchActions.clear,
    clearHistory: historyActions.clear,
    showMessage: messageContainerActions.show,
    toInspectionMessage: inspectionActions.toInspectionMessage,
    setSketch: sketchPersistenceActions.setSketch,
  },
)(SketchSearchModal);
