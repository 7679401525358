import React from 'react';
import { connect } from 'react-redux';

import { RootState } from 'reducers/rootReducer';
import { getAdditionalLine } from 'helpers/draw/getHelperLine';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as boxSelectSelectors, BoxSelectState } from 'ducks/boxSelect';
import { selectors as settingsSelectors } from 'ducks/settings';
import SelectedSegmentLine from 'components/sketch/SelectedSegment/SelectedSegmentLine';

interface StateProps {
  readonly isBoxSelectEditMode: boolean;
  readonly boxSelectState: BoxSelectState;
}

type Props = StateProps;

const BoxSelect = ({
  isBoxSelectEditMode,
  boxSelectState,
}: Props): JSX.Element | null => {
  if (!isBoxSelectEditMode) {
    return null;
  }
  const {
    startPoint, endPoint,
  } = boxSelectState;

  const selectBoxLines = {
    left: getAdditionalLine(startPoint, startPoint.x, endPoint.y),
    bottom: getAdditionalLine(endPoint, startPoint.x, endPoint.y),
    right: getAdditionalLine(endPoint, endPoint.x, startPoint.y),
    top: getAdditionalLine(startPoint, endPoint.x, startPoint.y),
  };

  return (
    <>
      <>
        {Object.entries(selectBoxLines).map(item => (
          <SelectedSegmentLine
            key={item[0]}
            wallPoints={item[1]}
          />
        ))}
      </>
    </>
  );
};

export default connect((state: RootState): StateProps => ({
  isBoxSelectEditMode: editModeSelectors.isBoxSelectEditMode(state),
  boxSelectState: boxSelectSelectors.getBoxSelectState(state),
  unitOfMeasurement: settingsSelectors.getUnitOfMeasure(state),
  precision: settingsSelectors.getPrecision(state),
}))(BoxSelect);
