import { CoordinatePoint } from 'types/point';
import { LineOrientationType } from 'types/lineOrientationType';

export const getLineOrientation = (a: CoordinatePoint, b: CoordinatePoint): LineOrientationType => {
  const dx: number = Math.abs(a.x - b.x);
  const dy: number = Math.abs(a.y - b.y);
  if (dy === 0) {
    return LineOrientationType.HORIZONTAL;
  }

  const k = dx / dy;

  // '|'
  if (k < 0.1) {
    return LineOrientationType.VERTICAL;
  }

  // '-'
  if (k > 10) {
    return LineOrientationType.HORIZONTAL;
  }

  // '\'
  if ((a.x < b.x && a.y < b.y) || (a.x > b.x && a.y > b.y)) {
    return LineOrientationType.DIAGONAL_DOWN;
  }

  // '/'
  return LineOrientationType.DIAGONAL_UP;
};
