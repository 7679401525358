import Immutable from 'immutable';

import { geometryConfig } from 'config/geometryConfig';
import { Point, CoordinatePoint, isPoint } from 'types/point';
import { GeometryType } from 'types/geometryType';
import { WallType } from 'types/wallType';
import { SelectableObjects, CollidedObject } from 'types/selection';
import { isClosedFigure } from 'types/figure';
import { isPointsCollision } from 'helpers/collision/pointCollision';
import {
  isPointToWallCollision,
  isWallToWallCollision,
  isCommonPoint,
  isIdentical,
  isSharedPointWallsCollision,
} from 'helpers/collision/wallCollision';
import { isPointToFigureCollision } from 'helpers/collision/figureCollision';
import { isPointToPositionedLabelCollision } from 'helpers/collision/positionedLabelCollision';
import { getWallPoints } from 'helpers/model/wallPoints';
import { getFigurePoints } from 'helpers/model/figurePoints';
import { distanceToWall } from 'helpers/collision/distanceToWall';
import { arcToLines } from 'helpers/curve/arcToLines';
import { polygonArea } from 'helpers/polygonArea';
import { distance } from 'helpers/geometry';
import { CircleWall } from 'types/wall';

interface CollidedFigure {
  id: string;
  area: number;
}

/**
 * Find collided figure with the point (if some figure collided)
 *
 * @param selectableObjects - all selectable objects
 * @param point testing point
 * @param exceptObjectIds objects which should not check for collision
 * @param selectedFigures
 * @returns objectId and type if collided
 */
export const getCollidedFigureWithPoint = (
  selectableObjects: SelectableObjects,
  point: CoordinatePoint,
  exceptObjectIds: string[],
  selectedFigures?: string[],
): CollidedObject => {
  const { points, walls, figures } = selectableObjects;

  const collidedFigures: CollidedFigure[] = figures
    .filter((figure) => {
      if (exceptObjectIds.includes(figure.figureId)) {
        return false;
      }
      const isClosed = isClosedFigure(figure);

      const figureWalls = walls.filter(({ wallId }) => figure.walls.includes(wallId));

      const figurePoints = getFigurePoints(walls, points, figure);

      if (figureWalls.size === 1 && isClosed) {
        const { radius } = (figureWalls.toArray()[0][1] as CircleWall);
        const centerPoint: Point | undefined = figurePoints.get(0);
        if (centerPoint) {
          const distanceFromCenter = distance(centerPoint, point);
          if (distanceFromCenter <= radius) {
            return true;
          }
        }
      } else {
        return isPointToFigureCollision(figurePoints, isClosed, point);
      }
      return false;
    })
    .toArray()
    .map(([key, figure]) => ({
      id: key,
      area: polygonArea(getFigurePoints(walls, points, figure)),
    }));

  if (collidedFigures.length) {
    const sortedCollidedFigures = collidedFigures.sort((a, b) => a.area - b.area);
    const smallestFigure = sortedCollidedFigures[0];
    if (selectedFigures) { // if the smallest selected figure has same area, it has priority
      const smallestSelectedCollidedFigures = sortedCollidedFigures
        .filter((figure) => figure.area === sortedCollidedFigures[0].area && selectedFigures.includes(figure.id));
      return {
        objectId: smallestSelectedCollidedFigures.length ? smallestSelectedCollidedFigures[0].id : smallestFigure.id,
        objectType: GeometryType.FIGURE,
      };
    }
    return {
      objectId: smallestFigure.id,
      objectType: GeometryType.FIGURE,
    };
  }

  return {
    objectId: '',
    objectType: GeometryType.UNKNOWN,
  };
};

/**
 * Find collided object with the point (if some object collided)
 *
 * @param selectableObjects
 * @param point testing point
 * @param exceptObjectIds objects which should not check for collision
 * @param selectedFigures figures currently selected
 * @returns objectId and type if collided
 */
export const getCollidedObjectWithPoint = (
  selectableObjects: SelectableObjects,
  point: CoordinatePoint,
  exceptObjectIds: string[],
  zoomInPercent: number,
  selectedFigures?: string[],
): CollidedObject => {
  const { points, walls, positionedLabels } = selectableObjects;

  const collidedPoint = points.find((p) => {
    if (exceptObjectIds.includes(p.pointId)) {
      return false;
    }
    return isPointsCollision(p, point, zoomInPercent);
  });

  if (collidedPoint) {
    return {
      objectId: collidedPoint.pointId,
      objectType: GeometryType.POINT,
    };
  }

  const collidedWall = walls.find((wall) => {
    if (wall.wallType === WallType.CIRCLE) return false;

    if (exceptObjectIds.includes(wall.wallId)) {
      return false;
    }
    const wPoints = getWallPoints(points, wall);
    if (wall.wallType === WallType.ARC) {
      // for collision with arc we use lines which connect center arc and corners
      // it is done to prevent massive calculation
      const arcLines = arcToLines(wPoints);
      return !!arcLines.find(arcLine => isPointToWallCollision(arcLine, point, zoomInPercent));
    }
    return isPointToWallCollision(wPoints, point, zoomInPercent);
  });

  if (collidedWall) {
    return {
      objectId: collidedWall.wallId,
      objectType: GeometryType.WALL,
    };
  }

  const collidedLabel = positionedLabels.find((positionedLabel) => {
    if (exceptObjectIds.includes(positionedLabel.positionedLabelId)) {
      return false;
    }
    const labelPosition = points.find(p => p.pointId === positionedLabel.pointId);
    return isPointToPositionedLabelCollision(labelPosition!, point);
  });

  if (collidedLabel) {
    return {
      objectId: collidedLabel.positionedLabelId,
      objectType: GeometryType.POSITIONED_LABEL,
    };
  }

  return getCollidedFigureWithPoint(selectableObjects, point, exceptObjectIds, selectedFigures);
};

/**
 * Find first collided wall with the new wall (if some wall collided)
 */
export const getCollidedObjectWithWall = (
  selectableObjects: SelectableObjects,
  wPoints: Immutable.List<Point>,
  exceptObjectIds: string[],
  zoomInPercent: number
): CollidedObject => {
  const { points, walls } = selectableObjects;

  const collidedWall = walls.find((wall) => {
    const wPoints2 = getWallPoints(points, wall);
    if (isCommonPoint(wPoints, wPoints2)) {
      if (isIdentical(wPoints, wPoints2)) {
        return false;
      }

      return isSharedPointWallsCollision(wPoints, wPoints2, zoomInPercent);
    }

    if (exceptObjectIds.includes(wall.wallId)) {
      return false;
    }

    const p1 = wPoints2.get(0)!;
    if (exceptObjectIds.includes(p1.pointId)) {
      return false;
    }

    const p2 = wPoints2.get(1)!;
    if (exceptObjectIds.includes(p2.pointId)) {
      return false;
    }

    if (wall.wallType === WallType.ARC) {
      // for collision with arc we use lines which connect center arc and corners
      // it is done to prevent massive calculation
      const arcLines = arcToLines(wPoints);
      return !!arcLines.find(arcLine => isWallToWallCollision(wPoints, arcLine));
    }
    return isWallToWallCollision(wPoints, wPoints2);
  });

  if (collidedWall) {
    return {
      objectId: collidedWall.wallId,
      objectType: GeometryType.WALL,
    };
  }

  return {
    objectId: '',
    objectType: GeometryType.UNKNOWN,
  };
};

const checkStrongCollision = (
  wPoints: Immutable.List<Point | CoordinatePoint>,
  wPoints2: Immutable.List<Point | CoordinatePoint>,
  exceptObjectIds: string[],
  zoomInPercent: number
): boolean => {
  if (isCommonPoint(wPoints, wPoints2)) {
    if (isIdentical(wPoints, wPoints2)) {
      return false;
    }

    return isSharedPointWallsCollision(wPoints, wPoints2, zoomInPercent);
  }

  const p1 = wPoints2.get(0)!;
  if (isPoint(p1) && exceptObjectIds.includes(p1.pointId)) {
    return false;
  }

  const p2 = wPoints2.get(1)!;
  if (isPoint(p2) && exceptObjectIds.includes(p2.pointId)) {
    return false;
  }

  if (!isWallToWallCollision(wPoints, wPoints2)) {
    return false;
  }

  const lastPoint = wPoints.get(1)!;
  return distanceToWall(wPoints2, lastPoint) > geometryConfig.getExteriorLineWidth(zoomInPercent);
};

/**
 * Find first strong collision
 * i.e. collision with strong intersection, but not just light touching
 */
export const getStrongCollisionsWithWall = (
  selectableObjects: SelectableObjects,
  wPoints: Immutable.List<Point | CoordinatePoint>,
  exceptObjectIds: string[],
  zoomInPercent: number
): CollidedObject => {
  const { points, walls } = selectableObjects;

  const collidedWall = walls.find((wall) => {
    if (exceptObjectIds.includes(wall.wallId)) {
      return false;
    }

    const wPoints2 = getWallPoints(points, wall);
    if (wall.wallType === WallType.ARC) {
      // for collision with arc we use lines which connect center arc and corners
      // it is done to prevent massive calculation
      const arcLines = arcToLines(wPoints2);
      return !!arcLines.find(arcLine => checkStrongCollision(wPoints, arcLine, exceptObjectIds, zoomInPercent));
    }
    if (wall.wallType === WallType.CIRCLE) {
      return false;
    }

    return checkStrongCollision(wPoints, wPoints2, exceptObjectIds, zoomInPercent);
  });

  if (collidedWall) {
    return {
      objectId: collidedWall.wallId,
      objectType: GeometryType.WALL,
      wallType: collidedWall.wallType,
    };
  }

  return {
    objectId: '',
    objectType: GeometryType.UNKNOWN,
  };
};
