import { toBoolean } from 'helpers/environment';
import * as appConfig from './appConfig.json';

export const sketchConfig = {
  // whether to load test model data
  loadTestModel: toBoolean(appConfig.REACT_APP_LOAD_TEST_MODEL, false),
  // spacing between measurement lines and structures
  labelSpace: 15,
  // spacing between text and lines in measurement lines
  labelLineSpace: 20,

  minFontZoom: 50,
  maxFontZoom: 125,

  sidebarWidth: 264,

  showLoadButton: toBoolean(process.env.REACT_APP_SHOW_LOAD_BUTTON, false),
  showLogoutButton: toBoolean(process.env.REACT_APP_SHOW_LOGOUT_BUTTON, true),

  topToolBarHeight: 50,
};
