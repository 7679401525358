import { printConfig } from 'config/printConfig';
import { Dimensions } from 'types/dimensions';
import {
  PrintedTable,
  PrintedColumn,
  PrintedCell,
  PrintTableDefinition,
} from 'types/printTable';

export const isHeaderRow = (index: number): boolean => (index === 0);

export const getAreaTableDefinition = (): PrintTableDefinition => {
  const tableDef: PrintTableDefinition = {
    size: {
      width: printConfig.tableWidth,
      height: printConfig.tableHeight,
    },
    columnsDef: [
      {
        sizeInPercent: 0.3,
      },
      {
        sizeInPercent: 0.4,
      },
      {
        sizeInPercent: 0.3,
      },
    ],
    dyForData: 2, // 2em
    dyForHeader: 1.5, // 1.5em
    maxNumberOfLines: 10,
  };

  return tableDef;
};

export const getTableSize = (): Dimensions => ({
  width: printConfig.tableWidth,
  height: printConfig.tableHeight,
});

export const getPrintTable = (
  tableDef: PrintTableDefinition,
  strings: string[][],
): PrintedTable => {
  const printedTable: PrintedTable = {
    columns: [],
  };

  const rowNumber = Math.min(tableDef.maxNumberOfLines, strings.length);

  const headerRow = strings[0];
  const columnNumber = headerRow.length;
  let columnX = 0;
  for (let j = 0; j < columnNumber; j++) {
    const printedColumn: PrintedColumn = {
      topLeftPosition: { x: columnX, y: 0 },
      cells: [],
    };
    for (let i = 0; i < rowNumber; i++) {
      const str = strings[i][j];
      const printedCell: PrintedCell = {
        dy: isHeaderRow(i) ? tableDef.dyForHeader : tableDef.dyForData,
        text: str,
        isBold: isHeaderRow(i),
      };

      printedColumn.cells.push(printedCell);
    }

    printedTable.columns.push(printedColumn);
    columnX += tableDef.columnsDef[j].sizeInPercent * tableDef.size.width;
  }

  return printedTable;
};
