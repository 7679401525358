import Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { Point, CoordinatePoint } from 'types/point';
import { SelectableObjects, CollidedObject } from 'types/selection';
import { GeometryType } from 'types/geometryType';
import { getCollidedObjectWithPoint, getStrongCollisionsWithWall } from 'helpers/collision/getCollidedObject';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as viewportSelectors } from 'ducks/viewport';

/**
 * get collided object by user click (point, wall, label, figure or nothing)
 */
const collidesWith = (rootState: RootState, p: CoordinatePoint): CollidedObject => {
  const zoomInPercent = viewportSelectors.getZoomInPercent(rootState);
  const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(rootState);
  const collidedObject: CollidedObject = getCollidedObjectWithPoint(selectableObjects, p, [], zoomInPercent);

  return collidedObject;
};

/**
 * get collided object by user click (point, wall, label, figure or nothing)
 * but except some objects from collision check
 */
const collidesWithExcept = (rootState: RootState, p: Point, objectIds: string[]): CollidedObject => {
  const zoomInPercent = viewportSelectors.getZoomInPercent(rootState);
  const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(rootState);
  const collidedObject = getCollidedObjectWithPoint(selectableObjects, p, objectIds, zoomInPercent);

  return collidedObject;
};

/**
 * check if point collided with some object
 */
const isCollidedWithObject = (rootState: RootState, p: Point): boolean => {
  const collidedObject: CollidedObject = collidesWith(rootState, p);
  const intersection = collidedObject.objectType !== GeometryType.UNKNOWN;

  return intersection;
};

/**
 * check if new wall collided with other walls
 * here is more complex logic, because it is ok to collide with labels, previous walls (in the same figure),
 * and interior walls (if the line being drawn is ALSO an interior wall)
 * note: collisions with other polygon walls is prohibited
 */
const isCollidedWithWall = (rootState: RootState, wallPoints: Immutable.List<Point | CoordinatePoint>,
  isInteriorWall: boolean, zoomInPercent: number): boolean => {
  const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(rootState);
  const collidedObject: CollidedObject = getStrongCollisionsWithWall(selectableObjects, wallPoints, [], zoomInPercent);
  const intersection = collidedObject.objectType !== GeometryType.UNKNOWN;

  return !isInteriorWall && intersection;
};

export const selectors = {
  collidesWith,
  collidesWithExcept,
  isCollidedWithObject,
  isCollidedWithWall,
};
