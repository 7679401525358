import { CoordinatePoint } from 'types/point';
import { SelectableObjects } from 'types/selection';
import { polygonBound } from 'helpers/polygonBound';

import Immutable from 'immutable';

/**
 * Find all objects that intersect, or are inside, the select box area, and return their object IDs
 *
 * @param startPoint the select box's start point
 * @param endPoint the select box's end point
 * @param selectObjects all possible selectable objects on the sketch area
 * @returns string array of all objectIds for objects that collide with, or are within, the select box
 */
export const getIdsInSelectBox = (startPoint: CoordinatePoint, endPoint: CoordinatePoint, selectableObjects: SelectableObjects): string[] => {
  const bounds = Immutable.List<CoordinatePoint>([{
    x: startPoint.x, y: startPoint.y,
  }, {
    x: startPoint.x, y: endPoint.y,
  }, {
    x: endPoint.x, y: endPoint.y,
  }, {
    x: endPoint.x, y: startPoint.y,
  },
  ]);

  const {
    walls, points, figures, positionedSymbols, positionedLabels,
  } = selectableObjects;

  const wallIdsInSelectBox = figures.reduce((result, figure) => {
    const isFigureInSelectBox = figure.walls.some(wallId => {
      const currentWall = walls.find(wall => wall.wallId === wallId);

      return currentWall?.points.some(pointId => {
        const currentPoint = points.find(point => point.pointId === pointId);

        return polygonBound(bounds, currentPoint?.x as number, currentPoint?.y as number);
      });
    });

    // If even one wall intersects, or is contained by, the select box, we will select the entire figure
    if (isFigureInSelectBox) return result.concat(figure.figureId);

    return result;
  }, [] as Array<string>);

  const labelIdsInSelectBox = positionedLabels.reduce((result, label) => {
    const labelPoint = points.find(point => point.pointId === label.pointId);

    const isLabelInSelectBox = polygonBound(bounds, labelPoint?.x as number, labelPoint?.y as number);

    if (isLabelInSelectBox) result.push(label.positionedLabelId);

    return result;
  }, [] as Array<string>);

  const symbolIdsInSelectBox = positionedSymbols.reduce((result, symbol) => {
    const symbolPoint = points.find(point => point.pointId === symbol.pointId);

    const isSymbolInSelectBox = polygonBound(bounds, symbolPoint?.x as number, symbolPoint?.y as number);

    if (isSymbolInSelectBox) result.push(symbol.positionedSymbolId);

    return result;
  }, [] as Array<string>);

  return [...wallIdsInSelectBox, ...labelIdsInSelectBox, ...symbolIdsInSelectBox];
};
