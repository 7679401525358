/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';

const globalStyle = css`
  body {
    background-color: #F6F6F6;
    padding: 0 2em;
  }
`;

const box = css`
  max-width: 800px;
  margin: 100px auto;
  padding: 1em;
  border-top: 5px solid #7b73ff;
  border-radius: 5px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;

  p {
    margin: 2em 0;
    font-size: 16px;
  }

  a {
    color: #0870FD;
  }
`;

const artwork = css`
    width: 200px;
    margin: 2em;
`;

const container = css`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    margin: 0 2em;
  }
`;

const browserLogo = css`
  width: 32px;
  height: 32px;
  margin-bottom: 1em;
`;

const UnsupportedBrowserPage = (): JSX.Element => (
  <div css={box}>
    <Global styles={globalStyle} />
    <img src="/browser.png" alt="" css={artwork} />
    <h1>
      ACI Sketch is not currently supported
      <br />
      in your browser.
    </h1>
    <p>Get the latest version of</p>
    <p css={container}>
      <a href="https://www.google.com/chrome">
        <img src="/chrome.png" css={browserLogo} alt="Chrome" />
        <br />
        Google Chrome
      </a>
      <span>or</span>
      <a href="https://www.mozilla.org/firefox/new/">
        <img src="/firefox.png" css={browserLogo} alt="Firefox" />
        <br />
        Mozilla Firefox
      </a>
    </p>
    <p>
      These browsers support all of the features that allow you to
      <br />
      easily create beautiful floor plan sketches.
    </p>
  </div>
);

export default UnsupportedBrowserPage;
