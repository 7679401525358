/** @jsx jsx */
import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';
import uuid from 'uuid';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { colors, sidebar } from 'config/paletteConfig';
import { messages } from 'config/messages';
import { Label } from 'types/label';
import { CoordinatePoint, Point } from 'types/point';
import { StampObjectType, StampId } from 'types/stamp';
import { actions as sidebarActions, selectors as sidebarSelectors } from 'ducks/sidebar/sidebar';
import { actions as drawActions } from 'ducks/draw/draw';
import { selectors as labelSelectors, actions as labelsActions } from 'ducks/model/labels';
import { selectors as labelPersistenceSelectors, actions as labelPersistenceActions } from 'ducks/persistence/labelPersistence';
import { ReactComponent as IconAdd } from 'assets/icons/plus.svg';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { ReactComponent as IconStamp } from 'assets/icons/stamp.svg';
import { scrollbar } from 'components/globalStyles';
import { DisplayPose, withHoverAndFocus, Animation, scaleYIn } from 'components/animations';
import { withTooltip, TooltipContent } from 'components/elements/Tooltip';
import { ButtonModalHeader } from 'components/modal/Modal';
import LabelTextEdit from 'components/elements/LabelTextEdit';
import { LabelItem } from 'components/elements/LabelItem';
import { compose, listToArray, filterBy, defaultTo } from 'helpers/utils';
import { sidebarItemStyles, scrollWrapperStyles, headerButtonSelected } from './styles';
import Sidebar from './Sidebar';


export const ListItemStyled = styled.li`
  position: relative;
  display: flex;
  max-width: 100%; /* https://github.com/philipwalton/flexbugs#flexbug-2 */
  align-items: center;
  margin-bottom: 3px;
  background-color: ${sidebar.item.base};

  &:hover,
  &:focus {
    background-color: ${sidebar.item.hovered};
    outline: 0;
  }

  :focus ~ button {
    display: block;
    opacity: 1;
  }
`;

export const ListItem = withHoverAndFocus(ListItemStyled, scaleYIn);

export const inputStyles = css`
  box-sizing: border-box;
  margin-bottom: 3px;

  &:hover {
    background-color: ${colors.white};
  }

  input {
    width: 100%;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    &::-webkit-input-placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
    &:-ms-input-placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
    &::placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
  }
`;

const ScrollArea = styled.ul(scrollWrapperStyles, scrollbar);
const Button = withTooltip(ButtonModalHeader);

interface InputProps {
  readonly pose: DisplayPose;
  readonly left?: number;
}

interface StateProps {
  readonly isLoading: boolean;
  readonly textLabels: Immutable.Map<string, Label>;
  readonly isInStampMode: boolean;
  readonly stampId?: StampId;
}

interface ActionProps {
  readonly loadLabels: typeof labelPersistenceActions.loadLabels;
  readonly drawPositionedLabel: (labelId: string, at: Point | CoordinatePoint) => void;
  readonly deleteLabel: (labelId: string) => void;
  readonly toggleStampMode: () => void;
  readonly setStampId: (objectType: StampObjectType, objectId: string) => void;
  readonly close: () => void;
  readonly show: () => void;
  readonly persistLabelOrder: typeof labelPersistenceActions.persistLabelOrder;
}

interface State {
  readonly isEditing: boolean;
  readonly editLabelId?: string;
}

type Props = InputProps & StateProps & ActionProps;

const isNonCategorizedLabel = (label: any) => !label.labelCategory;

const LabelSidebar = ({
  loadLabels,
  close,
  setStampId,
  isLoading,
  textLabels,
  left,
  pose,
  drawPositionedLabel,
  deleteLabel,
  isInStampMode,
  toggleStampMode,
  persistLabelOrder,
  stampId,
  show,
}: Props & State): JSX.Element => {
  const [state, setState] = useState({ isEditing: false, editLabelId: undefined });

  const [filteredTextLabels, setFilteredTextLabels] = useState([]);

  useEffect(() => {
    loadLabels();
  }, [pose]);

  useEffect(() => {
    setFilteredTextLabels(compose(
      // eslint-disable-next-line no-nested-ternary
      (labels: any) => [...labels].sort((l1, l2) => defaultTo(0)(l1.index) === defaultTo(0)(l2.index)
        // if indexes are equal then sort by alpha
        ? l1.text.localeCompare(l2.text)
        : defaultTo(0)(l1.index) < defaultTo(0)(l2.index)
          ? -1
          : 1),
      filterBy(isNonCategorizedLabel),
      listToArray
    )(textLabels));
  }, [textLabels]);

  const makeStartEditing = (labelId?: string): void => {
    setState({ isEditing: true, editLabelId: labelId });
  };

  const finishEditing = (): void => {
    setState({ isEditing: false, editLabelId: undefined });
  };

  const onClose = (): void => close();

  const showSidebar = (): void => {
    show();
  };

  const handleSetStampId = (labelId?: string): void => {
    if (isInStampMode && labelId) {
      setStampId('label', labelId);
    }
  };

  const CloseButton = (): JSX.Element => (
    <Button onClick={onClose} tooltip={messages.closeModal} tooltipPosition="bottom">
      <IconClose />
    </Button>
  );

  const AddButton = (): JSX.Element => (
    <Button
      onClick={makeStartEditing}
      aria-label={messages.addLabelCreate}
      tooltipPosition="bottom"
      tooltip={<TooltipContent title={messages.addLabelCreate} description="Add a new custom label to the list" />}
    >
      <IconAdd />
    </Button>
  );

  const StampButton = (): JSX.Element => (
    <Button
      css={isInStampMode && headerButtonSelected}
      onClick={toggleStampMode}
      aria-label={messages.stampMode}
      tooltipPosition="bottom"
      tooltip={<TooltipContent title={messages.stampMode} description={isInStampMode ? 'Leave Stamp Mode' : 'Enter Stamp Mode'} />}
    >
      <IconStamp />
    </Button>
  );

  const moveDown = (index: number) => () => {
    if (index < filteredTextLabels.length - 1) {
      const labels = [...filteredTextLabels];
      const swap = labels[index + 1];
      labels[index + 1] = labels[index];
      labels[index] = swap;
      setFilteredTextLabels(labels);
      persistLabelOrder({ labels });
    }
  };

  const moveUp = (index: number) => () => {
    if (index > 0) {
      const labels = [...filteredTextLabels];
      const swap = labels[index - 1];
      labels[index - 1] = labels[index];
      labels[index] = swap;
      setFilteredTextLabels(labels);
      persistLabelOrder({ labels });
    }
  };

  return (
    <Sidebar left={left} pose={pose}>
      <h2>
        <CloseButton />
        <span>{messages.addLabelTitle}</span>
        <AddButton />
        <StampButton />
      </h2>
      {state.isEditing && <LabelTextEdit labelId={state.editLabelId} css={[sidebarItemStyles, inputStyles]} onEditFinished={finishEditing} />}
      {isLoading && !filteredTextLabels ? (
        messages.loadingLabels
      ) : (
        <ScrollArea>
          <Animation enterPose="show" exitPose="hide" animateOnMount={false}>
            {filteredTextLabels.map((textLabel: any, index: number) => (
              textLabel?.labelId ? (
                <LabelItem
                  index={index}
                  key={textLabel.labelId}
                  textLabel={textLabel}
                  editLabelId={state.editLabelId}
                  drawPositionedLabel={drawPositionedLabel}
                  deleteLabel={deleteLabel}
                  close={onClose}
                  show={showSidebar}
                  makeStartEditing={makeStartEditing}
                  setStampId={handleSetStampId}
                  isSelected={stampId?.objectType === 'label' && textLabel.labelId === stampId?.objectId}
                  moveDown={moveDown}
                  moveUp={moveUp}
                />
              ) : <Fragment key={uuid()} />
            ))}
          </Animation>
        </ScrollArea>
      )}
    </Sidebar>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    isLoading: labelPersistenceSelectors.isLoading(state),
    textLabels: labelSelectors.getTextLabels(state),
    isInStampMode: sidebarSelectors.isInStampMode(state),
    stampId: sidebarSelectors.getStampId(state),
  }),
  {
    loadLabels: labelPersistenceActions.loadLabels,
    drawPositionedLabel: drawActions.drawPositionedLabel,
    deleteLabel: labelsActions.delete,
    close: sidebarActions.hide,
    show: sidebarActions.showLabels,
    toggleStampMode: sidebarActions.toggleStampMode,
    setStampId: sidebarActions.setStampId,
    persistLabelOrder: labelPersistenceActions.persistLabelOrder,
  },
)(LabelSidebar);
