import { apiConfig } from 'config/apiConfig';
import { HttpMethod } from 'types/httpMethod';
import { ApiSketch } from 'types/api/apiSketch';
import { ApiLabel } from 'types/api/apiLabel';
import { ApiAreaType } from 'types/api/apiAreaType';
import { ApiSymbol } from 'types/api/apiSymbol';
import { ApiSymbolGroup } from 'types/api/apiSymbolGroup';
import { makeApiRequest } from 'effects/request';
import { Setting } from 'types/settings';
import { getAccessToken } from './auth';

const accessToken = async (): any => {
  const accessToken = await getAccessToken();
  return accessToken || '';
};

export const getLabels = async (at: string): Promise<ApiLabel[]> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, apiConfig.label)
);

export const createLabel = async (at: string, label: any): Promise<ApiLabel> => (
  makeApiRequest(await accessToken(), HttpMethod.POST, apiConfig.label, label)
);

export const updateLabel = async (at: string, label: any): any => (
  makeApiRequest(await accessToken(), HttpMethod.PUT, `${apiConfig.label}/${label.id}`, label)
);

export const deleteLabel = async (at: string, labelId: string): any => (
  makeApiRequest(await accessToken(), HttpMethod.DEL, `${apiConfig.label}/${labelId}`)
);

export const getAreaTypes = async (at: string): Promise<ApiAreaType[]> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, apiConfig.areaType)
);

export const getSymbols = async (at: string): Promise<ApiSymbol[]> => makeApiRequest(await accessToken(), HttpMethod.GET, apiConfig.symbol);

export const getSymbolGroups = async (at: string): Promise<ApiSymbolGroup[]> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, apiConfig.symbolGroup)
);

export const getSketch = async (at: string, sketchId: string): Promise<ApiSketch> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, `${apiConfig.sketch}/${sketchId}`)
);

export const createSketch = async (at: string, sketch: ApiSketch): Promise<ApiSketch> | null => (
  makeApiRequest(await accessToken(), HttpMethod.POST, apiConfig.sketch, sketch)
);

export const saveSketch = async (at: string, sketchId: string, sketch: ApiSketch): Promise<void> => (
  makeApiRequest(await accessToken(), HttpMethod.PUT, `${apiConfig.sketch}/${sketchId}`, sketch)
);

export const getSketches = async (at: string): Promise<ApiSketch[]> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, `${apiConfig.sketches}/`)
);

export const getSketchesBySearch = async (at: string, value: string): Promise<any> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, `${apiConfig.sketches}/search/${value}`)
);

export const getSettings = async (at: string, options: any): Promise<void> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, `${apiConfig.settings}`, null, options)
);

export const updateSetting = async (at: string, { uuid, value }: Setting): Promise<any> => (
  makeApiRequest(await accessToken(), HttpMethod.POST, `${apiConfig.settings}`, { uuid, value: `${value}` })
);

export const persistLabelOrder = async (at: string, { labelIds }: any): Promise<any> => (
  makeApiRequest(await accessToken(), HttpMethod.POST, `${apiConfig.label}/order`, { labels: labelIds }, { apiOnly: true })
);

export const getOnline = async (at: string): Promise<void> => (
  makeApiRequest(await accessToken(), HttpMethod.GET, `${apiConfig.online}`)
);

export const getCompanies = async (): any => {
  const result = await makeApiRequest(await accessToken(), HttpMethod.GET, apiConfig.company);
  const companies = await result;
  return companies;
};
