import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import styled from '@emotion/styled';

import { messages } from 'config/messages';
import { actions as resizeSketchModalActions } from 'ducks/modal/resizeSketchModal';
import { actions as drawActions } from 'ducks/draw/draw';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { positionedModal, fade } from 'components/animations';
import { ModalWallResize, ModalBackdrop } from 'components/modal/Modal';
import { sidebarItemButtonStyles } from 'components/sidebar/styles';

const Backdrop = styled(ModalBackdrop)({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
});

const BackdropAnimated = posed(Backdrop)(fade);

const ModalAnimated = posed(ModalWallResize)(positionedModal);
const CloseButton = styled.button(sidebarItemButtonStyles);

interface ActionProps {
  readonly close: () => void;
  readonly toggleDrawing: (drawingWalls: boolean) => void;
}

type Props = ActionProps;

interface State {
  readonly position: ResizePosition;
}

interface ResizePosition {
  readonly top?: number;
  readonly left?: number;
}
class KnownLengthPromptModal extends Component<Props, State> {
  // eslint-disable-next-line react/sort-comp

  private readonly modalRef: React.RefObject<HTMLInputElement>;

  public constructor(props: Props) {
    super(props);
    this.modalRef = createRef();

    this.state = {
      position: this.getModalPosition(),
    };
  }

  public componentDidMount(): void {
    const position = this.getModalPosition();
    this.setState({ position });
  }

  // eslint-disable-next-line class-methods-use-this
  private getModalPosition(): ResizePosition {
    return {
      top: 202,
      left: 540,
    };
  }

  private close = (): void => {
    const { close, toggleDrawing } = this.props;
    close();
    toggleDrawing(true);
  };

  public render = (): JSX.Element | null => {
    const { position } = this.state;

    return (
      <BackdropAnimated
        pose="enter"
        initialPose="exit"
      >
        <ModalAnimated
          pose="enter"
          initialPose="exit"
          position={window.screen.width > 400 ? position : { top: '15rem', left: '1rem' }}
          poseKey={position}
          ref={this.modalRef}
        >
          <h2>
            <CloseButton onClick={this.close} title={messages.closeModal}>
              <IconClose />
            </CloseButton>
            <span>Trace a wall of known length on the image to set the scale</span>
          </h2>
        </ModalAnimated>
      </BackdropAnimated>
    );
  };
}

export default connect(
  null,
  {
    close: resizeSketchModalActions.hideRescale,
    toggleDrawing: drawActions.toggleDrawingStraightWalls,
  },
)(KnownLengthPromptModal);
