/** @window.isNativeApp isNativeApp */
import * as superagent from 'superagent';
import { apiConfig } from 'config/apiConfig';
import { HttpMethod } from 'types/httpMethod';
import { LogLevel } from 'types/log';
import log from 'helpers/log';
import { isDesktopApplication } from 'effects/auth';
import {
  compose, mapBy, isSomething, findIndexBy, defaultTo, filterBy
} from 'helpers/utils';
import mocks from '../mockData/v1';

const REACT_APP_USE_MOCKS = [true, 'true'].includes(process.env.REACT_APP_USE_MOCKS!);

const mockResponses: Record<string, any> = {};
const mockData: Record<string, any> = mocks;

export const makeApiRequest = async (
  accessToken: string,
  method: HttpMethod,
  url: string,
  payload?: object,
  options?: any
): Promise<any> => { // returns superagent.Response body
  const splitUrl: any = url.split('/');
  const resource: string = splitUrl[1];

  const fullUrl = `${apiConfig.baseUrl}${url}`;
  const request: superagent.SuperAgentRequest = superagent[method](fullUrl);
  if (payload) {
    request.send(payload);
  }

  const userCompany: string = sessionStorage.getItem('userCompany')!;
  // if (isNothing(userCompany)) return false;

  if (REACT_APP_USE_MOCKS && (method === HttpMethod.POST || method === HttpMethod.PUT)) {
    return payload;
  }

  request.set('Authorization', `Bearer ${accessToken}`);
  request.set('Company', userCompany);
  request.ok(
    compose(
      ({ status, body }: superagent.Response) => status < 400
        ? true
        : (log(LogLevel.error, `API responded with ${status} status.`, body), false),
    ),
  );

  const addOrUpdateLabel = (item: any) => (list: any) => {
    // trace(`addOrUpdateLabel ${JSON.stringify(item)} ${JSON.stringify(list)}`);
    // const result = [...filterBy((label: any) => label.isDefault || label.text !== item.text)(list), ...item];
    const result = [...filterBy(({ isDefault, text }: any) => isDefault || text !== item.text)(list), item];
    return result;
  };

  const response = await request.then(
    (res) => {
      if (resource === 'label' && splitUrl.includes('order')) {
        return res;
      }
      let { body } = res;
      if (method === HttpMethod.POST) {
        const { uuid = undefined, id = undefined, id: payloadId, uuid: payloadUuid } = payload as Record<string, string>;
        body = compose(
          // eslint-disable-next-line no-nested-ternary, @typescript-eslint/no-explicit-any
          (parsedBody: Record<string, any>) => Array.isArray(parsedBody)
            // eslint-disable-next-line no-nested-ternary
            ? isSomething(res.body)
              // if we get a response from API, try to find an existing record with the id of our payload so we can replace it with the response id
              ? resource === 'label'
                ? addOrUpdateLabel(res.body)(parsedBody)
                : [...filterBy((item: any) => item.id !== payloadId)(parsedBody), ...res.body]
              : mapBy((item: any) => item.uuid !== payloadUuid ? item : { ...item, ...payload })(parsedBody)
            : uuid === parsedBody.uuid || id && id === parsedBody.id
              ? { ...parsedBody, ...(isSomething(res.body) ? res.body : payload) }
              : { ...parsedBody },
          JSON.parse,
          defaultTo(JSON.stringify(body)),
          (key: string) => localStorage.getItem(key),
        )(splitUrl[1]);
      }

      if (![HttpMethod.DEL, HttpMethod.PUT, HttpMethod.PATCH].includes(method)) {
        if (splitUrl[1] !== 'sketches' && splitUrl[1] !== 'sketch') {
          window.postMessage({ key: splitUrl[1], message: JSON.stringify(body) }, '*');
        }
      }
      return res;
    },
  ).catch(response => {
    if (options?.apiOnly) {
      return null;
    }
    return compose(
      (body: JSON) => ({ body }),
      (data: Array<{}>) => {
        if (method === HttpMethod.POST) {
          const { uuid, id } = payload as Record<string, string>;
          if (Array.isArray(data)) {
            const index = findIndexBy((v: Record<string, string>) => isSomething(v.uuid) && uuid === v.uuid || isSomething(v.id) && id === v.id)(data);
            const result = index < 0
              ? [...data, { ...payload }] // add entry to list
              : [...data.slice(0, index), { ...data[index], ...payload }, ...data.slice(index + 1)]; // update an entry
            if (splitUrl[1] !== 'sketches' && splitUrl[1] !== 'sketch') window.postMessage({ key: splitUrl[1], message: JSON.stringify(result) }, '*');
          }
          return payload;
        }
        if (method === HttpMethod.DEL) {
          const newData = filterBy((v: Record<string, string>) => isSomething(v.id) && splitUrl[2] !== v.id)(data);
          window.postMessage({ key: splitUrl[1], message: JSON.stringify(newData) }, '*');
        }
        return data;
      },
      JSON.parse,
      () => localStorage.getItem(splitUrl[1]),
    )(response);
  });

  if (mockData[resource]) {
    mockResponses[resource] = mockData[resource];
    if (REACT_APP_USE_MOCKS) {
      if (splitUrl[1] !== 'sketches' && splitUrl[1] !== 'sketch') {
        window.postMessage({ key: splitUrl[1], message: JSON.stringify(mockResponses[resource]) }, '*');
      }
      return mockResponses[resource];
    }
    if (isDesktopApplication()) {
      const localStorageResource = localStorage.getItem(resource)
      if (splitUrl[1] !== 'sketches' && splitUrl[1] !== 'sketch' && !localStorageResource) {
        window.postMessage({ key: splitUrl[1], message: JSON.stringify(mockResponses[resource]) }, '*');
      }
      if ([HttpMethod.GET].includes(method)) {
        return localStorageResource?.length ? JSON.parse(localStorageResource) : mockResponses[resource];
      }
    }
  }

  // special case for when we are POSTing to v1/label/order since response status code is not normally returned
  if (resource === 'label' && splitUrl.includes('order')) {
    return response;
  }

  return response.body || !options?.apiOnly && resource !== 'sketches' && resource !== 'sketch' && mockData[resource];
};

window.onmessage = (event: MessageEvent) => {
  const { key, message } = event.data;
  if (key) {
    localStorage.setItem(key, message);
  }
};
