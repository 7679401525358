/** @jsx jsx */
/* eslint-disable react/no-array-index-key */
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';

import { RootState } from 'reducers/rootReducer';
import { PrintedTable } from 'types/printTable';
import { Dimensions } from 'types/dimensions';
import { selectors as printPreviewModalSelectors } from 'ducks/modal/printPreviewModal';
import {
  getAreaTableDefinition, getPrintTable, getTableSize,
} from 'helpers/print/printTable';

export const PRINT_TABLE_CONTAINER = 'printTableContainer';

const tableStyles = css`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const headTextStyles = css`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
`;

const textStyles = css`
  font-size: 14px;
  font-family: 'Montserrat';
  font-weight: 300;
  padding: 0.5em;
`;

interface StateProps {
  readonly stringTable: string[][] | undefined;
}

type Props = StateProps;

const PrintTableView = ({ stringTable }: Props): (JSX.Element | null) => {
  if (!stringTable) {
    return null;
  }

  const printedTable: PrintedTable = getPrintTable(
    getAreaTableDefinition(),
    stringTable,
  );

  const tableSize: Dimensions = getTableSize();

  const xOffset = 45;

  return (
    <div css={tableStyles}>
      <svg
        id={PRINT_TABLE_CONTAINER}
        width={tableSize.width}
        height={tableSize.height}
      >
        {printedTable.columns.map((column, columnIndex) => (
          <text
            key={columnIndex}
            x={column.topLeftPosition.x}
            y={column.topLeftPosition.y}
            css={textStyles}
          >
            {column.cells.map((cell, cellIndex) => (
              <tspan
                key={cellIndex}
                x={column.topLeftPosition.x + xOffset}
                dy={`${cell.dy}em`}
                css={cell.isBold ? headTextStyles : null}
              >
                {cell.text}
              </tspan>
            ))}
          </text>
        ))}
      </svg>
    </div>
  );
};

export default connect((state: RootState): StateProps => ({
  stringTable: printPreviewModalSelectors.getStringTable(state),
}))(PrintTableView);
