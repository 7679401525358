import { pointConfig } from 'config/pointConfig';
import { colors, points as pointsColors } from 'config/paletteConfig';
import { isTouchDevice } from 'helpers/browserDetect';

interface PointStyles {
  get: (zoomInPercent: number) => any;
}

export const pointStyles: PointStyles = {
  get: (zoomInPercent: number) => (
    {
      base: {
        strokeWidth: '2px',
        stroke: colors.base,
        fill: pointsColors.base,
        '&:hover': {
          stroke: colors.hovered,
          strokeWidth: '2px',
          cursor: 'pointer',
          r: `${isTouchDevice ? pointConfig.getRadiusTouchSelected(zoomInPercent) : pointConfig.getRadiusSelected(zoomInPercent)}px`,
        },
      },
      active: {
        stroke: pointsColors.selected,
        strokeWidth: '2px',
        r: `${pointConfig.getRadiusSelected(zoomInPercent)}px`,
        '&:hover': {
          r: `${isTouchDevice ? pointConfig.getRadiusTouchSelected(zoomInPercent) : pointConfig.getRadiusSelected(zoomInPercent)}px`,
          stroke: colors.hovered,
          cursor: 'move',
        },
      },
    }
  )
};

export default pointStyles;
