import React from 'react';
import posed, { PoseGroup } from 'react-pose';

import { isTouchDevice } from 'helpers/browserDetect';
import { ModalProps } from 'components/modal/Modal';

export const Animation = PoseGroup;

export type DisplayPose = 'exit' | 'enter';
type DisplayCSSProp = 'flex' | 'block' | 'inline-block' | 'inline';
export const getDisplayPose = (show: boolean): DisplayPose => (show ? 'enter' : 'exit');

const touchDevice = isTouchDevice
  ? {
    exit: {
      x: 0,
      opacity: 0,
      applyAtStart: {
        display: 'none',
      },
      applyAtEnd: {
        display: 'none',
      },
      transition: {
        x: { duration: 0 },
      },
    },
  }
  : {
    exit: {
      x: 0,
      opacity: 0,
      applyAtEnd: {
        display: 'none',
      },
      transition: {
        x: { ease: 'easeIn' },
      },
    },
  };

export const appearFromLeft = {
  ...touchDevice,
  enter: {
    x: 0,
    opacity: 1,
    applyAtStart: {
      display: 'flex',
    },
    transition: {
      x: { ease: 'easeInOut' },
    },
  },
};

interface AppearProps {
  display?: DisplayCSSProp;
}

export const appearFromRight = {
  exit: {
    x: '100%',
    opacity: 0,
    applyAtEnd: {
      display: 'none',
    },
    transition: {
      x: { ease: 'easeIn' },
    },
  },
  enter: {
    x: '0%',
    opacity: 1,
    applyAtStart: {
      display: ({ display }: AppearProps) => display || 'block',
    },
    transition: {
      x: { ease: 'easeInOut' },
    },
  },
};

export const appearFromTop = {
  exit: {
    y: -25,
    scale: 0.9,
    opacity: 0,
    applyAtEnd: {
      display: 'none',
    },
    transition: {
      y: { ease: 'easeIn' },
    },
  },
  enter: {
    y: 0,
    scale: 1,
    opacity: 1,
    applyAtStart: {
      display: 'flex',
    },
    transition: {
      y: { ease: 'easeOut' },
    },
  },
};

export const appearModalDialog = {
  exit: {
    y: 40,
    scale: 0.85,
    opacity: 0,
  },
  enter: {
    y: 0,
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 250,
      damping: 15,
      mass: 0.5,
      delay: 200,
    },
  },
};

export const fade = {
  exit: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
};

export const fadeAndHide = {
  focusable: true,
  exit: {
    opacity: 0,
    applyAtEnd: {
      display: 'none',
    },
  },
  focus: {
    opacity: 1,
    applyAtStart: {
      display: 'block',
    },
  },
  hover: {
    opacity: 1,
    applyAtStart: {
      display: 'block',
    },
  },
  enter: {
    opacity: 1,
    applyAtStart: {
      display: 'block',
    },
  },
};

export const scaleYIn = {
  hide: {
    scaleY: 0,
    opacity: 0,
  },
  show: {
    scaleY: 1,
    opacity: 1,
  },
};

export const scaleIn = {
  exit: {
    scale: 0.8,
    x: '-50%',
    opacity: 0,
  },
  enter: {
    scale: 1,
    x: '-50%',
    opacity: 1,
  },
};

export const circleGlow = {
  hide: {
    fillOpacity: 0.5,
  },
  show: {
    fillOpacity: 1,
    transition: {
      type: 'spring',
      damping: 0,
      mass: 5,
    },
  },
};

export const positionedModal = {
  exit: {
    x: ({ position }: { position: ModalProps }) => position.left!,
    y: ({ position }: { position: ModalProps }) => position.top! - 50,
    opacity: 0,
  },
  enter: {
    x: ({ position }: { position: ModalProps }) => position.left!,
    y: ({ position }: { position: ModalProps }) => position.top!,
    opacity: 1,
  },
};

export const ButtonWithFade = posed.button(fadeAndHide);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const withHoverAndFocus = <T extends object>(Component: React.ComponentType<T>, pose?: object) => posed(Component)({
  hoverable: true,
  focusable: true,
  ...pose,
});
