import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { pointConfig } from 'config/pointConfig';
import { Point, CoordinatePoint } from 'types/point';
import { mapMovedPoint } from 'helpers/move/mapMovedPoint';
import { selectors as pointSelectors } from 'ducks/model/points';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { isTouchDevice } from 'helpers/browserDetect';
import { selectors as viewPortSelectors } from 'ducks/viewport';

import pointStyles from './styles';

interface InputProps {
  readonly pointId: string;
}

interface StateProps {
  readonly point: Point;
  readonly isSelectMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isMovingMode: boolean;
  readonly isHighlighted?: boolean;
  readonly selectedObjects: Immutable.List<string>;
  readonly movedPoints: Immutable.List<Point>;
  readonly zoomInPercent: number;
}

interface ActionProps {
  readonly switchToSelected: typeof editModeActions.switchToSelected;
}

type Props = InputProps & StateProps & ActionProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Circle = styled.circle<any>(
  ({ zoomInPercent }) => pointStyles.get(zoomInPercent).base,
  ({ isSelected, zoomInPercent }) => isSelected && pointStyles.get(zoomInPercent).active,
);

class FigurePoint extends Component<Props> {
  private handleClick = (mouseEvent: React.MouseEvent<HTMLElement>): void => {
    if (mouseEvent.button !== 0) {
      return;
    }

    const {
      isSelectMode, isSelectedMode, switchToSelected, pointId,
    } = this.props;

    if (isSelectMode || isSelectedMode) {
      switchToSelected([pointId]);
    }
  };

  public render(): JSX.Element | null {
    const {
      pointId, point, isMovingMode, selectedObjects, movedPoints, isHighlighted, zoomInPercent
    } = this.props;

    if (!point) {
      return null;
    }
    const selected = selectedObjects.includes(pointId) || isHighlighted;
    let p: CoordinatePoint = { ...point };
    if (isMovingMode) {
      p = mapMovedPoint(movedPoints, p);
    }

    return (
      <Circle
        cx={p.x}
        cy={p.y}
        r={!isTouchDevice ? pointConfig.getRadius(zoomInPercent) : pointConfig.getRadiusTouch(zoomInPercent)}
        isSelected={selected}
        onClick={this.handleClick}
        zoomInPercent={zoomInPercent}
      />
    );
  }
}

export default connect(
  (state: RootState, { pointId }: InputProps): StateProps => ({
    isSelectMode: editModeSelectors.isSelectMode(state),
    isSelectedMode: editModeSelectors.isSelectedMode(state),
    isMovingMode: editModeSelectors.isMovingMode(state),
    selectedObjects: editModeSelectors.getSelectedObjects(state),
    point: pointSelectors.getPointById(state, pointId),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
    zoomInPercent: viewPortSelectors.getZoomInPercent(state),
  }),
  {
    switchToSelected: editModeActions.switchToSelected,
  },
)(FigurePoint);
