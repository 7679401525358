/** @jsx jsx */
import React from 'react';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';

import { positionedLabels as colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { CoordinatePoint } from 'types/point';
import { Label } from 'types/label';
import { Dimensions } from 'types/dimensions';
import { selectors as labelsSelectors } from 'ducks/model/labels';
import { selectors as positionedLabelsSelectors } from 'ducks/model/positionedLabels';

const style = css`
  font-weight: bold;
  fill: ${colors.base};
  text-anchor: middle;
  dominant-baseline: middle;
`;

interface InputProps {
  /* eslint-disable-next-line react/no-unused-prop-types */
  readonly positionedLabelId: string;
  readonly labelRef: React.RefObject<SVGTextElement>;
  readonly position: CoordinatePoint;
  readonly size: Dimensions;
  readonly fontScale?: number;
}

interface StateProps {
  readonly label?: Label;
}

type Props = InputProps & StateProps;

const PositionedLabelText = ({
  label, labelRef, position, size, fontScale = 100,
}: Props): JSX.Element | null => (label)
  ? (
    <text
      ref={labelRef}
      css={[style, css`font-size: ${fontScale}%;`]}
      x={position.x}
      y={position.y}
      width={size.width}
      height={size.height}
    >
      {label!.text}
    </text>
  ) : null;

export default connect(
  (state: RootState, { positionedLabelId }: InputProps): StateProps => {
    const positionedLabel = positionedLabelsSelectors.getPositionedLabelById(state, positionedLabelId);
    return ({
      label: positionedLabel && labelsSelectors.getLabelById(state, positionedLabel!.labelId),
    });
  },
)(PositionedLabelText);
