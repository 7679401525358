/* eslint-disable camelcase */
import Immutable from 'immutable';

import { Label } from 'types/label';
import { ApiLabel } from 'types/api/apiLabel';
import { ApiSymbol } from 'types/api/apiSymbol';
import { ApiSymbolGroup } from 'types/api/apiSymbolGroup';
import { LabelType } from 'types/labelType';
import { ApiAreaType } from 'types/api/apiAreaType';
import { AreaType } from 'types/areaType';
import { GlaType } from 'types/glaType';
import { SketchSymbol } from 'types/sketchSymbol';
import { SketchSymbolGroup } from 'types/sketchSymbolGroup';

const apiGlaMap = Immutable.Map({
  gla: GlaType.GLA,
  non_gla: GlaType.NON_GLA,
  negative_gla: GlaType.NEGATIVE,
});

export const mapLabelFromApi = ({ id, ...labelData }: ApiLabel): Label => (
  { labelId: id, type: LabelType.PRESET_TEXT, ...labelData }
);

export const mapAreaTypeFromApi = ({
  // eslint-disable-next-line camelcase
  id, gla_type, name, meta,
}: ApiAreaType, sortOrder: number): AreaType => ({
  areaTypeId: id, text: name, glaType: apiGlaMap.get(gla_type)!, sortOrder, meta,
});

export const mapSymbolFromApi = (apiSymbol: ApiSymbol): SketchSymbol => {
  const {
    id, base64SVG, symbolGroupId, description, name,
  } = apiSymbol;
  return {
    symbolId: id,
    data: `data:image/svg+xml;base64,${base64SVG}`,
    symbolGroupId,
    description,
    name,
  };
};

export const mapSymbolGroupFromApi = ({ id, ...symbolGroupData }: ApiSymbolGroup): SketchSymbolGroup => (
  {
    symbolGroupId: id,
    ...symbolGroupData,
  }
);
