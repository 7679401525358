/** @jsx jsx */
import React from 'react';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';

import { selection as colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { MousePointToSVGPointFunction, selectors as viewportSelectors } from 'ducks/viewport';
import { ReactComponent as IconRotate } from 'assets/icons/rotate-hint.svg';
import { CoordinatePoint } from 'types/point';

const ROTATE_SIZE = 50;
const ROTATE_ICON_SIZE = 30;

const rotateIcon = css`
  fill: ${colors.active};
  stroke: ${colors.active};
`;

const rotateClickArea = css`
  fill: ${colors.transparent};
  stroke: ${colors.transparent};
  stroke-width: 3px;
  cursor: move;
`;

interface InputProps {
  readonly center: CoordinatePoint;
  readonly position: CoordinatePoint;
}

interface StateProps {
  readonly isSelectedMode: boolean;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
}

interface ActionProps {
  readonly startRotate: typeof editModeActions.switchToRotating;
}

type Props = InputProps & StateProps & ActionProps;

const RotateHandle = (props: Props): JSX.Element => {
  const {
    position, isSelectedMode, startRotate, center, getMousePointToSvgPoint,
  } = props;

  const handleMouseDownRotate = (event: React.MouseEvent<SVGGElement>): void => {
    if (event.button !== 0) {
      return;
    }

    if (isSelectedMode) {
      handlePointerDownRotate(event.clientX, event.clientY);
      event.stopPropagation();
    }
  };

  const handleTouchStartRotate = (event: React.TouchEvent<SVGGElement>): void => {
    if (event.touches.length !== 1) {
      return;
    }

    if (isSelectedMode) {
      handlePointerDownRotate(event.touches[0].clientX, event.touches[0].clientY);
      event.stopPropagation();
    }
  };

  const handlePointerDownRotate = (clientX: number, clientY:number): void => {
    const point = getMousePointToSvgPoint({ clientX, clientY });
    startRotate(center, point);
  };

  return (
    <>
      <IconRotate
        css={rotateIcon}
        x={position.x}
        y={position.y}
        width={ROTATE_ICON_SIZE}
        height={ROTATE_ICON_SIZE}
      />
      <rect
        css={rotateClickArea}
        x={position.x}
        y={position.y}
        width={ROTATE_SIZE}
        height={ROTATE_SIZE}
        onMouseDown={handleMouseDownRotate}
        onTouchStart={handleTouchStartRotate}
      />
    </>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
    isSelectedMode: editModeSelectors.isSelectedMode(state),
  }),
  {
    startRotate: editModeActions.switchToRotating,
  },
)(RotateHandle);
