import { toNumber } from 'helpers/environment';

export const printConfig = {
  backgroundColor: '#ffffff',
  // encoderOptions: 0.6,
  // scale: 1,
  // 3594x2256 required
  // ... now 1340x850 required...
  width: toNumber(process.env.REACT_APP_PRINT_WIDTH, 850),
  height: toNumber(process.env.REACT_APP_PRINT_HEIGHT, 1340),

  // config for area table
  tableWidth: 600,
  tableHeight: 600,
};
