export enum LabelBaselineType {
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
}

export enum LabelOrientationType {
  HORIZONTAL_ABOVE = 'HORIZONTAL_ABOVE',
  HORIZONTAL_BELOW = 'HORIZONTAL_BELOW',
  VERTICAL_LEFT = 'VERTICAL_LEFT',
  VERTICAL_RIGHT = 'VERTICAL_RIGHT',
  UP_DIAGONAL_ABOVE_LEFT = 'UP_DIAGONAL_ABOVE_LEFT',
  UP_DIAGONAL_BELOW_RIGHT = 'UP_DIAGONAL_BELOW_RIGHT',
  DOWN_DIAGONAL_ABOVE_RIGHT = 'DOWN_DIAGONAL_ABOVE_RIGHT',
  DOWN_DIAGONAL_BELOW_LEFT = 'DOWN_DIAGONAL_BELOW_LEFT',
}
