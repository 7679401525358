/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css, jsx } from '@emotion/react';

import { selection as colors } from 'config/paletteConfig';
import { transparentize } from 'helpers/colorTransforms';
import { RootState } from 'reducers/rootReducer';
import { Corner, Corners } from 'types/corner';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { MousePointToSVGPointFunction, selectors as viewportSelectors } from 'ducks/viewport';
import RotateHandle from '../Rotate/RotateHandle';

const resizeCorner = css`
  fill: ${colors.active};
  stroke: ${colors.white};
  cursor: move;
`;

const NWResize = css`
  cursor: nw-resize;
`;

const NEResize = css`
  cursor: ne-resize;
`;

const frame = css`
  fill: ${transparentize(colors.background, 0.8)};
  stroke: ${colors.active};
  stroke-width: 1px;
`;

interface InputProps {
  readonly hideHandles?: boolean | false;
  readonly width: number;
  readonly height: number;
  readonly x: number;
  readonly y: number;
}

interface StateProps {
  readonly isSelectedMode: boolean;
  readonly hasMultiselected: boolean;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
}

interface ActionProps {
  readonly startResize: typeof editModeActions.switchToResizing;
}

type Props = InputProps & StateProps & ActionProps;

class SelectionBorder extends Component<Props> {
  private handleMouseDownCorner = (mouseEvent: React.PointerEvent<SVGGElement>, corner: Corner): void => {
    if (mouseEvent.button !== 0) {
      return;
    }

    const { isSelectedMode, startResize, getMousePointToSvgPoint } = this.props;

    if (isSelectedMode) {
      const point = getMousePointToSvgPoint(mouseEvent);
      startResize(point, corner);
      mouseEvent.stopPropagation();
    }
  };

  public render(): JSX.Element {
    const {
      x, y, width, height, hasMultiselected, hideHandles,
    } = this.props;

    return (
      <React.Fragment>
        {!hasMultiselected && (
          <RotateHandle
            center={{
              x: x + width / 2,
              y: y + height / 2,
            }}
            position={{
              x: x + width + 5,
              y: y + height + 5,
            }}
          />
        )}
        {!hideHandles && (
          <React.Fragment>
            <rect
              css={frame}
              x={x}
              y={y}
              width={width}
              height={height}
            />
            {
              Object.values(Corners).map((c, index) => (
                /* eslint react/no-array-index-key: "off" */
                <rect
                  key={index}
                  css={[resizeCorner, index === 0 || index === 3 ? NWResize : NEResize]}
                  x={x - 5 + (c.x / 2 + 0.5) * width}
                  y={y - 5 + (c.y / 2 + 0.5) * height}
                  width={10}
                  height={10}
                  onPointerDown={e => this.handleMouseDownCorner(e, c)}
                />
              ))
            }
          </React.Fragment>
        )
        }
      </React.Fragment>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
    isSelectedMode: editModeSelectors.isSelectedMode(state),
    hasMultiselected: editModeSelectors.hasMultiselected(state),
  }),
  {
    startResize: editModeActions.switchToResizing,
  },
)(SelectionBorder);
