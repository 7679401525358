import { CoordinatePoint } from 'types/point';

const CLICK_SENSITIVITY = 25;

// For now, just assume click within 25 pixel radius of position (center) is a collision.
export const isPointToPositionedLabelCollision = (labelPosition: CoordinatePoint, point: CoordinatePoint): boolean => (
  point.x > labelPosition.x - CLICK_SENSITIVITY
  && point.x < labelPosition.x + CLICK_SENSITIVITY
) && (
  point.y > labelPosition.y - CLICK_SENSITIVITY
  && point.y < labelPosition.y + CLICK_SENSITIVITY
);
