import Immutable from 'immutable';

import { Figure, isClosedFigure } from 'types/figure';
import { getFiguresWithWall } from 'helpers/model/figureWalls';

export const canRemoveMiddlePoint = (
  figures: Immutable.Map<string, Figure>,
  wallsWithPoint: string[],
): boolean => {
  if (wallsWithPoint.length !== 2) {
    return false;
  }

  const prevWallId = wallsWithPoint[0];
  const nextWallId = wallsWithPoint[1];
  const figuresWithPrevWall = getFiguresWithWall(figures, prevWallId);
  if (figuresWithPrevWall.length !== 1) {
    return false;
  }

  const figuresWithNextWall = getFiguresWithWall(figures, nextWallId);
  if (figuresWithNextWall.length !== 1) {
    return false;
  }

  if (figuresWithPrevWall[0] !== figuresWithNextWall[0]) {
    return false;
  }

  const figureId = figuresWithPrevWall[0];
  const figure = figures.get(figureId)!;

  // cannot remove one wall from triangle and create closed two points-figure
  return !(figure.walls.length < 4 && isClosedFigure(figure));
};
