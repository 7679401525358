import { createBrowserHistory } from 'history';
import {
  applyMiddleware, createStore, Store, compose, AnyAction,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { batchDispatchMiddleware } from 'redux-batched-actions';

import { rootSaga } from 'sagas/rootSaga';
import { RootState, createRootReducer } from 'reducers/rootReducer';
import { configureAuth, isMobileApplication } from 'effects/auth';

export const history = createBrowserHistory();

/* eslint-disable @typescript-eslint/no-explicit-any */
const devToolsOptions: any = {
  serialize: {
    replacer: (_key: any, value: any) => {
      if (value && value instanceof SVGElement) return '[SVGElement]';
      return value;
    },
  },
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const sagaMiddleware = createSagaMiddleware();

const composer: Function = process.env.NODE_ENV === 'development' ? composeWithDevTools(devToolsOptions) : compose;

const configureStore = (): Store<RootState, AnyAction> => {
  const store = createStore(
    createRootReducer(history),
    composer(applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      batchDispatchMiddleware,
    )),
  );
  configureAuth();
  sagaMiddleware.run(rootSaga);

  // For mobile, we need to use a callback to continue execution after local storage is updated
  window.SendSketchToMobile = function () {
    if (isMobileApplication()) {
      store.dispatch({
        // this will update the sketch in local storage
        type: 'sketchPersistence/SET_SKETCH',
        callback: (): void => {
          const currentSketch = window.localStorage.getItem('currentSketch');
          if (currentSketch) {
            window.ReactNativeWebView.postMessage(currentSketch);
            window.localStorage.removeItem('currentSketch');
          }
        }
      });
    }
  };

  return store;
};

export default configureStore;
