import Immutable from 'immutable';

import { CoordinatePoint, isPoint, Point } from 'types/point';

/**
 * Map point if it is presented in the moved point list
 * @param movedPoints points which moved by user (and snapped to grid / corner)
 * @param p point for test
 * @returns mapped point if a point with the same id is in the list
 */
export const mapMovedPoint = <T extends Point | CoordinatePoint = Point | CoordinatePoint>(
  movedPoints: Immutable.List<Point>, p: T,
): T => {
  if (isPoint(p)) {
    const pMoved = movedPoints.find(p1 => p1.pointId === p.pointId);
    if (pMoved) {
      return pMoved as T;
    }
  }

  return p;
};
