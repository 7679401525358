/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type */
import { gridConfig } from 'config/gridConfig';
import {
  compose, feetToMeters, sqFeetToSqMeters, divideBy, roundToNearest, multiplyBy, metersToFeet, toFixed
} from './utils';
import { FEET, INCHES, EXP_2 } from './utils/constants';

const { gridSizeInFeet, gridSize } = gridConfig;
const divideByGridSize = divideBy(gridSize);
const precisionedMeasurement = (precision: number) => (rawMeasurement: number) => +roundToNearest(precision / precision ** 2)(rawMeasurement);

export interface UsSize {
  readonly feet: number;
  readonly inches: number;
}

export const feetToPixels = (
  feet: number,
  inches = 0,
  currentGridSize = gridConfig.gridSizeInFeet,
): number => (feet + (inches / 12)) * (gridConfig.gridSize / currentGridSize);

export const metersToPixels = (meters: number, currentGridSize = gridConfig.gridSizeInFeet): number => +compose(
  (feet: number) => feetToPixels(feet, 0, currentGridSize),
  metersToFeet,
  parseFloat,
)(meters);

export const pixelDistanceInFeet = (
  pixelDistance: number,
  currentGridSize = gridConfig.gridSizeInFeet,
): number => divideByGridSize(pixelDistance * currentGridSize);

export const pixelsToMeters = (pixels: number = gridConfig.gridSizeInFeet, precision: number): number => +compose(
  (meters: number) => precision ? precisionedMeasurement(precision)(meters) : toFixed(3)(meters),
  feetToMeters,
  pixelDistanceInFeet,
)(pixels);

export const pixelAreaToSquareFeet = compose(
  toFixed(10),
  divideByGridSize,
  pixelDistanceInFeet,
);

// TODO: remove the need for this function
export const formatSquareFeetArea = (feetArea: number): string => `${feetArea} ft${EXP_2}`;

export const pixelDistanceInMeters = compose(feetToMeters, pixelDistanceInFeet);
export const pixelAreaToSquareMeters = compose(sqFeetToSqMeters, pixelAreaToSquareFeet);

const formatMetric = (measurement: number): string => compose(
  (pm: number): string => `${pm}m`,
)(measurement);

const formatStandardDecimal = (measurement: number): string => compose(
  (pm: number): string => `${pm}ft`,
)(measurement);

const formatStandardPrime = (precision: number) => compose(
  ({ feet, inches }: UsSize): string => `${inches === 12 ? feet + 1 : feet}${FEET}${inches && inches < 12 ? ` ${inches}${INCHES}` : ''}`,
  (length: number) => ({
    feet: Math.trunc(length),
    inches: precisionedMeasurement(precision)((length % 1) * 12),
  }),
);
const areaifyLabel = (formattedMeasurement: string): string => `${formattedMeasurement}${EXP_2}`;
const primeToText = (str: string): any => compose(
  (regexp: RegExp) => str.replace(regexp, match => match === FEET && 'ft'
    || match === INCHES && 'in '
    || match),
)(new RegExp(`${FEET}|${INCHES}`, 'gi'));

const format = (formatters: any) => (uom: string, precision: number, scale = gridSizeInFeet) => (pixelDistance: number) => compose(
  (formatFn: any) => formatFn(precision, scale)(pixelDistance),
  (fns: any) => fns[uom] || fns.default,
)(formatters);

const precisionedLinearFeet = compose(
  toFixed(3),
  pixelDistanceInFeet,
);
const precisionedLinearMetric = compose(
  toFixed(3),
  pixelDistanceInMeters,
);

export const precisionedAreaFeet = compose(
  toFixed(3),
  pixelAreaToSquareFeet,
);

export const precisionedAreaMetric = compose(
  toFixed(3),
  pixelAreaToSquareMeters,
);

// Linear Formatters

const pixelDistanceToFormattedMetric = (precision: number, scale: number) => compose(
  formatMetric,
  precisionedLinearMetric,
  multiplyBy(scale),
);

const pixelDistanceToFormattedFeetPrime = (precision: number, scale: number) => compose(
  formatStandardPrime(precision),
  precisionedLinearFeet,
  multiplyBy(scale),
);

const pixelDistanceToFormattedFeetDecimal = (precision: number, scale: number) => compose(
  formatStandardDecimal,
  precisionedLinearFeet,
  multiplyBy(scale),
);

// Area Formatters

const pixelAreaToFormattedMetric = (precision: number, scale: number) => compose(
  areaifyLabel,
  formatMetric,
  precisionedAreaMetric,
  multiplyBy(scale ** 2),
);

const pixelAreaToFormattedFeetPrime = (precision: number, scale: number) => compose(
  primeToText,
  areaifyLabel,
  formatStandardPrime(precision),
  precisionedAreaFeet,
  multiplyBy(scale ** 2),
);

const pixelAreaToFormattedFeetDecimal = (precision: number, scale: number) => compose(
  areaifyLabel,
  formatStandardDecimal,
  precisionedAreaFeet,
  multiplyBy(scale ** 2),
);

const linearFormatters = {
  meters: pixelDistanceToFormattedMetric,
  feet: pixelDistanceToFormattedFeetPrime,
  default: pixelDistanceToFormattedFeetDecimal,
};

const areaFormatters = {
  meters: pixelAreaToFormattedMetric,
  feet: pixelAreaToFormattedFeetPrime,
  default: pixelAreaToFormattedFeetDecimal,
};

export const formatLinearLabel = format(linearFormatters);
export const formatAreaLabel = format(areaFormatters);
