/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';

import { messages } from 'config/messages';
import { ModalDialog, OnClick } from './ModalDialog';

const messageStyle = css`
  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    vertical-align: middle;
  }
  > span {
    margin: 0 1em;
  }
`;

const IEWarning: React.FC<{ action: OnClick }> = ({ action }) => (
  <ModalDialog
    title={messages.browserWarningTitle}
    dismissText={messages.browserWarningButton}
    dismissAction={action}
  >
    <p>
      <b>Printing </b>
      is not currently supported in your browser.
    </p>
    <p>Get the latest version of</p>
    <p css={messageStyle}>
      <a href="https://www.google.com/chrome">
        <img src="/chrome.png" alt="Chrome Logo" />
        Google Chrome
      </a>
      <span>or</span>
      <a href="https://www.mozilla.org/firefox/new/">
        <img src="/firefox.png" alt="Firefox Logo" />
        Mozilla Firefox
      </a>
    </p>
    <p>
      These browsers support all of the features that allow you to
      <br />
      easily create beautiful floor plan sketches.
    </p>
  </ModalDialog>
);

export default IEWarning;
