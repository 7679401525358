import { CoordinatePoint } from 'types/point';
import { Keys } from 'helpers/keys';

export function getMoveByKey(key: string): CoordinatePoint {
  const d: CoordinatePoint = { x: 0, y: 0 };
  switch (key) {
    case Keys.arrowUp:
      d.y = -1;
      break;
    case Keys.arrowDown:
      d.y = 1;
      break;
    case Keys.arrowLeft:
      d.x = -1;
      break;
    case Keys.arrowRight:
      d.x = 1;
      break;
    default:
      break;
  }

  return d;
}
