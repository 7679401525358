// /<summary>
// /The purpose of this class is to hold all Color Definitions we utilize in this library in a single place.
// /</summary>
//----------

// /<summary>
// /A List of all the colors we plan to support and use
// /</summary>
/*
aciColors Notes:
1) using the gradients with the element style attribute (<p style = {}>) does not work. @material-ui styles (makeStyles) have no such problems.
2) yellow5ToRed5 actually uses orange5 instead of yellow5. This is the correct color, it was named wrong on the figma doc, and we are keeping it consistent.
*/
export const aciColors = {
  // The Aci Theme Colors: Meant to be used all throughout the site.
  white: '#FFFFFF',

  yellow400: '#FA9D31',
  yellow500: '#F48606',

  orange50: '#FFF9F5',
  orange100: '#FDEADD',
  orange200: '#FCDCC5',
  orange300: '#F7A063',
  orange400: '#F58333',
  orange500: '#DF6510',
  orange600: '#C85B0E',

  red400: '#F16428',
  red500: '#D74A0E',
  red600: '#A83A0B',

  grey25: '#FBFBFB',
  grey50: '#F5F5F5',
  grey100: '#E8E8E8',
  grey200: '#D3D3D4',
  grey300: '#B9BABB',
  grey400: '#9FA0A2',
  grey500: '#868789',
  grey600: '#6D6E70',
  grey700: '#535455',
  grey800: '#3A3A3B',
  grey900: '#212122',

  // Gradients
  yellow4ToRed5: 'radial-gradient(93.62% 100% at 50% 0%, #FA9D31 0%, #D74A0E 100%);',
  yellow5ToRed5: 'radial-gradient(93.62% 100% at 50% 0%, #DF6510 0%, #D74A0E 100%);',

  // The Utility Colors: Action commands and notifications
  utilityAction: '#059EE3',
  utilityInformation: '#3A6694',
  utilityConfirmation: '#2AA100',
  utilityWarning: '#E5B217',
  utilityCritical: '#E81C00',

  // The Rating Score Colors: These colors reflect the quality of a property
  rating1: '#F26B16',
  rating2: '#E78818',
  rating3: '#DCA21A',
  rating4: '#D2B81B',
  rating5: '#BDC01E',
  rating6: '#A3BE1F',
  rating7: '#86B320',
  rating8: '#6BA822',
  rating9: '#559F24',
  rating10: '#439326',

  // Extra Colors: These colors are defined, but not meant for widespread use.
  black: '#000000',
};

// /<summary>
// /A list of all the hex opacity values we plan to use.
// /</summary>
export const aciOpacities = {
  _100: 'FF',
  _80: 'CC',
  _70: 'B3',
  _40: '66',
  _12: '1F',
  _10: '1A',
  _7: '12',
  _5: '0D',
  _0: '00',
};

// /<summary>
// /Creates the shorthand hex for color + opacity
// /</summary>
// /</param name="color">A string of the hex value base color, taken from the aciColors List.</param>
// /</param name="numOpacity">A string of the hex value opacity amount we desire, taken from aciOpacities</param>
// /<returns>A hex color shorthand string that combines the hex value and desired opacity</returns>
// eslint-disable-next-line no-underscore-dangle
export function makeOpacityColorHex(color = aciColors.black, opacity = aciOpacities._100): string {
  // Returns combined Hex of Color plus Opacity
  // (black = #000000 ) (opacity 12 = 1F) (black with opacity 12 = #0000001F)
  return `${color}${opacity}`;
}

// /<summary>
// /A single collection of all the Color Definitions
// /</summary>
const AciStyleColors = {
  makeOpacityColorHex,
  aciColors,
  aciOpacities,
};

export default AciStyleColors;
