import { printConfig } from 'config/printConfig';
import log from 'helpers/log';
import {
  compose, concat, coordinateExtremes, filterBy, findBy, flatten, isSomething, listToArray, mapBy
} from 'helpers/utils';
import { LogLevel } from 'types/log';
import { CoordinatePoint, Point } from 'types/point';
import { SelectableObjects } from 'types/selection';
import { CircleWall } from 'types/wall';

const xmlNs = 'http://www.w3.org/2000/xmlns/';
const xhtmlNs = 'http://www.w3.org/1999/xhtml';
const svgNs = 'http://www.w3.org/2000/svg';
// eslint-disable-next-line max-len
const doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd" [<!ENTITY nbsp "&#160;">]>';
const urlRegex = /url\(["']?(.+?)["']?\)/;
interface FontFormats {
  [key: string]: string;
}
const fontFormats: FontFormats = {
  woff2: 'font/woff2',
  woff: 'font/woff',
  otf: 'application/x-font-opentype',
  ttf: 'application/x-font-ttf',
  eot: 'application/vnd.ms-fontobject',
  sfnt: 'application/font-sfnt',
  svg: 'image/svg+xml',
};

interface Dimensions {
  [index: string]: number | CanvasImageSource | string;
  height: number;
  width: number;
}

interface Options {
  tableEl?: SVGElement;
  encoderOptions?: number;
  backgroundColor?: string;
  width?: number;
  height?: number;
  tableHeight?: number;
  tableWidth?: number;
  scale?: number;
  fabric?: any;
  viewBox?: Record<string, number>;
}

interface FontOption {
  text: string;
  format: string;
  url: string;
}

const isExternal = (url: string): boolean => !!url
  && url.lastIndexOf('http', 0) === 0
  && url.lastIndexOf(window.location.host) === -1;

const getFontMimeTypeFromUrl = (fontUrl: string): string => {
  const formats = Object.keys(fontFormats)
    .filter(extension => fontUrl.indexOf(`.${extension}`) > 0)
    .map(extension => fontFormats[extension]);
  if (formats) {
    return formats[0];
  }
  log(LogLevel.error, `Unknown font format for ${fontUrl}. Fonts may not be working correctly.`);
  return 'application/octet-stream';
};

const arrayBufferToBase64 = (buffer: any): string => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) binary += String.fromCharCode(bytes[i]);
  return window.btoa(binary);
};

const getDimension = (el: SVGElement, clone: SVGElement, dim: 'width' | 'height'): number => {
  const v: number = (
    el instanceof SVGSVGElement
    && el.viewBox.baseVal
    && el.viewBox.baseVal[dim]
  )
    || (
      clone.getAttribute(dim)
      && !(clone.getAttribute(dim)!.match(/%$/))
      && parseFloat(clone.getAttribute(dim)!)
    )
    || el.getBoundingClientRect()[dim]
    || parseFloat(clone.style[dim])
    || parseFloat(window.getComputedStyle(el).getPropertyValue(dim));
  // eslint-disable-next-line no-restricted-globals
  return v && !isNaN(v) ? v : 0;
};

const circleOrthogonalPoints = ({ centerPoint: { x, y }, radius }: CircleWall): CoordinatePoint[] => [
  { x: x + radius, y },
  { x, y: y + radius },
  { x: x - radius, y },
  { x, y: y - radius },
];

const positionedSymbolOrLabelCorners = ({ x, y, size: { height, width } }: any): any => [
  { x: x + width / 2, y },
  { x, y: y + height / 2 },
  { x: x - width / 2, y },
  { x, y: y - height / 2 },
];

const getOrthogonalsOfCircleWalls = ({ walls, points }: SelectableObjects): CoordinatePoint[] => compose(
  flatten,
  mapBy(circleOrthogonalPoints),
  mapBy((wall: CircleWall) => ({ ...wall, centerPoint: findBy(({ pointId }: Point) => pointId === wall.centerPoint.pointId)(points) })),
  filterBy((wall: CircleWall) => isSomething(wall.centerPoint)),
  listToArray,
)(walls);

const getDimensions = (el: SVGElement, clone: SVGElement, width = 0, height = 0): Dimensions => ({
  width: width || getDimension(el, clone, 'width'),
  height: height || getDimension(el, clone, 'height'),
});

const reEncode = (data: string): string => decodeURIComponent(
  encodeURIComponent(data).replace(
    /%([0-9A-F]{2})/g,
    (_match, p1) => {
      const c = String.fromCharCode(parseInt(`0x${p1}`, 16));
      return c === '%' ? '%25' : c;
    },
  ),
);

interface CssFont {
  text: string;
  format: string;
  url: string;
}

const detectCssFont = (cssText: string, href: string): CssFont | null => {
  const match = cssText.match(urlRegex);
  const url = (match && match[1]) || '';
  if (!url || url.match(/^data:/) || url === 'about:blank') return null;
  // eslint-disable-next-line no-nested-ternary
  const fullUrl = url.startsWith('../')
    ? `${href}/../${url}`
    : url.startsWith('./')
      ? `${href}/.${url}`
      : url;
  return {
    text: cssText,
    format: getFontMimeTypeFromUrl(fullUrl),
    url: fullUrl,
  };
};

const inlineImages = (el: SVGElement): Promise<(null | HTMLImageElement)[]> => Promise.all(
  Array.from(el.querySelectorAll('image')).map((image) => {
    let href = image.getAttributeNS('http://www.w3.org/1999/xlink', 'href') || image.getAttribute('href');
    if (!href) return Promise.resolve(null);
    if (isExternal(href)) {
      href += `${href.indexOf('?') === -1 ? '?' : '&'}t=${new Date().valueOf()}`;
    }
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = href!;
      img.onerror = () => reject(new Error(`Could not load ${href}`));
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d')!.drawImage(img, 0, 0);
        const dataURI = canvas.toDataURL('image/png');
        if (image.getAttributeNS('http://www.w3.org/1999/xlink', 'href')) {
          image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', dataURI);
        } else {
          image.setAttribute('href', dataURI);
        }
        resolve(img);
      };
    });
  }),
);

interface FontCache {
  [key: string]: string;
}
const cachedFonts: FontCache = {};
const inlineFonts = async (fonts: FontOption[]): Promise<string> => {
  const fontCss = await Promise.all(
    fonts.map(font => new Promise((resolve) => {
      if (cachedFonts[font.url]) {
        resolve(cachedFonts[font.url]!);
      }

      const req = new XMLHttpRequest();
      req.addEventListener('load', () => {
        const fontInBase64 = arrayBufferToBase64(req.response);
        const fontUri = font.text.replace(urlRegex, `url("data:${font.format};base64,${fontInBase64}")`);
        cachedFonts[font.url] = `${fontUri}\n`;
        resolve(fontUri);
      });
      req.addEventListener('error', (e) => {
        log(LogLevel.warn, `Failed to load font from: ${font.url}`, e);
        delete cachedFonts[font.url];
        resolve();
      });
      req.addEventListener('abort', (e) => {
        log(LogLevel.warn, `Aborted loading font from: ${font.url}`, e);
        resolve();
      });
      req.open('GET', font.url);
      req.responseType = 'arraybuffer';
      req.send();
    })),
  );
  return fontCss.filter(x => x).join('');
};

interface CSSRuleMap {
  rules: CSSRuleList;
  href: string | null;
}
const styleSheetRules = (): CSSRuleMap[] => Array.from(document.styleSheets).reduce(
  (mappedRules: CSSRuleMap[], sheet: StyleSheet): CSSRuleMap[] => {
    if (sheet instanceof CSSStyleSheet) {
      try {
        return [
          { rules: sheet.cssRules, href: sheet.href },
          ...mappedRules,
        ];
      } catch (e) {
        log(LogLevel.warn, `Stylesheet could not be loaded: ${sheet.href}`, e);
      }
    }
    return mappedRules;
  },
  [],
);

const inlineCss = async (): Promise<string> => {
  const css: string[] = [];
  const fontList: FontOption[] = [];
  styleSheetRules().forEach(({ rules, href }) => {
    if (!rules) return;
    Array.from(rules).forEach((rule) => {
      if (rule instanceof CSSStyleRule) {
        css.push(`${rule.selectorText}{${rule.style.cssText}}\n`);
      } else if (rule.cssText.match(/^@font-face/)) {
        const font = detectCssFont(rule.cssText, href!);
        if (font) {
          fontList.push(font);
        }
      } else {
        css.push(rule.cssText);
      }
    });
  });
  const fontCss = await inlineFonts(fontList);
  return `${css.join('\n')}${fontCss}`;
};

const prepareSvg = async (
  el: SVGElement, options: Options = {},
): Promise<{ src: string; width: number; height: number }> => {
  const {
    width: w,
    height: h,
    scale = 1,
    backgroundColor,
    viewBox,
  } = options;

  let clone = el.cloneNode(true) as SVGElement;
  await inlineImages(clone);
  clone.style.backgroundColor = backgroundColor || el.style.backgroundColor;
  const { width, height } = getDimensions(el, clone, w, h);

  if (!(el instanceof SVGSVGElement)) {
    if (el instanceof SVGGraphicsElement) {
      if (clone.getAttribute('transform') != null) {
        const transform = clone.getAttribute('transform');
        if (transform) {
          clone.setAttribute('transform', transform.replace(/translate\(.*?\)/, ''));
        }
      }
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.appendChild(clone);
      clone = svg;
    } else {
      throw new Error(`Attempted to render non-SVG element ${el}`);
    }
  }

  clone.setAttribute('version', '1.1');
  if (viewBox) {
    clone.setAttribute('viewBox', [viewBox?.x, viewBox?.y, viewBox?.width, viewBox?.height].join(' '));
    const gridContainer = clone.querySelector('#gridContainer');
    // trace({ gridFill: gridContainer?.getAttribute('fill') });

    if (gridContainer) {
      mapBy((val: string, key: string) => {
        gridContainer?.setAttribute(key, val);
      })(viewBox);
    }
  }

  if (!clone.getAttribute('xmlns')) {
    clone.setAttributeNS(xmlNs, 'xmlns', svgNs);
  }

  if (!clone.getAttribute('xmlns:xlink')) {
    clone.setAttributeNS(xmlNs, 'xmlns:xlink', 'http://www.w3.org/1999/xlink');
  }

  clone.setAttribute('style', `${clone.getAttribute('style')} max-width: ${(printConfig.width || width) * scale}`);

  Array.from(clone.querySelectorAll('foreignObject > *')).forEach((foreignObject) => {
    foreignObject.setAttributeNS(xmlNs, 'xmlns', (foreignObject instanceof SVGElement) ? svgNs : xhtmlNs);
  });

  const css = await inlineCss();
  const style = document.createElement('style');
  style.setAttribute('type', 'text/css');
  style.innerHTML = `<![CDATA[\n${css}\n]]>`;

  const defs = document.createElement('defs');
  defs.appendChild(style);
  clone.insertBefore(defs, clone.firstChild);

  const outer = document.createElement('div');
  outer.appendChild(clone);
  const src = outer.innerHTML.replace(/NS\d+:href/gi, 'xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href');
  return { src, width, height };
};

const dataURIPreamble = 'data:image/svg+xml;base64,';
const svgAsDataUri = async (el: SVGElement, options: Options): Promise<string> => {
  const { src } = await prepareSvg(el, options);
  return `${dataURIPreamble}${window.btoa(reEncode(doctype + src))}`;
};

interface ImageInfo extends Dimensions {
  src: CanvasImageSource | string;
}

const convertToPng = (
  {
    src, width, height,
  }: ImageInfo,
  options: Options = {},
): Promise<string> => {
  const {
    encoderOptions = 0.8,
    fabric,
  } = options;

  const canvas = document.createElement('canvas');
  const pixelRatio = 1;

  canvas.width = width * pixelRatio;
  canvas.height = height * pixelRatio;
  canvas.style.width = `${canvas.width}px`;
  canvas.style.height = `${canvas.height}px`;

  if (fabric) {
    const fc = new fabric.Canvas(canvas);
    fabric.loadSVGFromString(src as string, async (objects: any, fOptions: any) => {
      const object = fabric.util.groupSVGElements(objects, fOptions);
      fc.add(object);
      return Promise.resolve(fc.toDataURL({ format: 'png', quality: encoderOptions }));
    });
  }

  const context = canvas.getContext('2d')!;
  context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  context.drawImage(src as CanvasImageSource, 0, 0);

  try {
    const pngUri = canvas.toDataURL('image/png', encoderOptions);

    return Promise.resolve(pngUri);
  } catch (e) {
    if (e?.name === 'SecurityError') {
      log(LogLevel.error, 'Rendered SVG images cannot be downloaded in this browser.');
    }
    return Promise.reject(e);
  }
};

// TODO: Investigate the necessity of these args
export const svgAsPngUri = async (el: SVGSVGElement, options: Options = {}, sketchObjects?: SelectableObjects): Promise<string> => {
  if (!el) {
    return Promise.resolve('');
  }
  const viewBox = sketchObjects ? compose(
    ({
      lx, ly, hx, hy
    }: Record<string, number>) => ({
      x: lx, y: ly, width: (hx - lx), height: (hy - ly),
    }),
    mapBy((val: number, key: string) => ['lx', 'ly'].includes(key) ? val - 50 : val + 50),
    coordinateExtremes,
    concat(getOrthogonalsOfCircleWalls(sketchObjects)),
    (points: any) => points.reduce((acc: any, point: any) => {
      if (point.positionedLabelId || point.positionedSymbolId) {
        const pointById = findBy(({ pointId, positionedLabelId, positionedSymbolId }: any) =>
          pointId === point.pointId && !positionedLabelId && !positionedSymbolId)(points);
        // trace({ pointById });
        return [
          ...acc,
          ...positionedSymbolOrLabelCorners({ ...point, ...pointById }),
        ];
      }
      return [...acc, point];
    }, []),
    listToArray
  )(sketchObjects.points) : { x: 0, y: 0, width: options.tableWidth, height: options.tableHeight };

  if (options.fabric) {
    try {
      const imageInfo = await prepareSvg(el, ({ ...options, viewBox }));
      return convertToPng(imageInfo, options);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  const preparedSvg = el;
  const { height, width } = viewBox;

  const uri = await svgAsDataUri(preparedSvg, ({ ...options, viewBox }));

  return new Promise<string>((resolve, reject) => {
    try {
      const image = new Image();

      image.onload = () => resolve(convertToPng({
        src: image,
        height,
        width,
      }, options));
      image.onerror = () => {
        // eslint-disable-next-line max-len
        reject(new Error(`There was an error loading the data URI as an image on the following SVG\n${window.atob(uri.slice(dataURIPreamble.length))}\nOpen the following link to see browser's diagnosis\n${uri}`));
      };
      image.src = uri;
    } catch (e) {
      reject(e);
    }
  });
};
