import {
  PublicClientApplication, RedirectRequest,
} from '@azure/msal-browser';
import { authConfig } from 'config/authConfig';
import log from 'helpers/log';
import { compose, defaultToObj, trace } from 'helpers/utils';
import { LogLevel } from 'types/log';
import { createHmac } from 'crypto';

let pca: PublicClientApplication;
/*
simple hash challenge of the 'application' query string value
use a shared value and UTC date
*/

// dev testing shortcut allows viewing as logged out/desktop from web browser
// export const isDesktopApplication = (): boolean => true

let desktopApplication = false;
export const isDesktopApplication = (): boolean => {
  if (desktopApplication) return true;
  // the chromium control will have this userAgent
  if (!navigator.userAgent.match(/Report32.exe/)) {
    return false;
  }

  // get date in utc
  const utc = new Date();
  // a shared string
  const shared = Buffer.from('b831e171-3902-40a1-9dae-dd36fd8dd224');
  // hash from the query
  const appQuery = new URLSearchParams(window.location.search).get('application');
  if (!appQuery) {
    return false;
  }

  // not valid after time
  const nvaQuery = new URLSearchParams(window.location.search).get('nva');
  if (!nvaQuery) {
    return false;
  }

  // not valid after comes from the query
  const notValidAfter = parseInt(nvaQuery);
  if (Number.isNaN(notValidAfter)) {
    return false;
  }

  // if now is after not valid after
  if (utc.getTime() > notValidAfter) {
    return false;
  }

  // the hour the hash was created
  const utcHashTime =
    utc.getUTCDate() +
    utc.getUTCFullYear() +
    utc.getUTCMonth() +
    utc.getUTCHours();

  // these need to match
  desktopApplication = appQuery === createHmac('sha256', shared).update(Buffer.from((utcHashTime + notValidAfter + navigator.userAgent)
    .toString())).digest('hex').toLowerCase();
  return desktopApplication;
};

let mobileApplication = false;
// STP SSS-420 Mobile uses isDesktopApplication (above), but it also
// attaches the ReactNativeWebView to the window.  So, if ReactNativeWebView
// is there and isDesktopApplication is true, then this is the mobile app.
export const isMobileApplication = (): boolean => {
  if (mobileApplication) return true;
  // set the boolean so we don't re-evaluate this over and over
  mobileApplication = window.ReactNativeWebView;
  return mobileApplication;
};

// utility functions
export const configureAuth = (): void => {
  authConfig.msalConfig.auth.authority = setAuthority();
  pca = new PublicClientApplication({ ...authConfig.msalConfig });
};

const getSession = async (): any => {
  let session;
  try {
    const accounts = await pca.getAllAccounts();
    [session] = accounts;
  } catch (err) {
    if (authConfig.debugEnabled) {
      log(LogLevel.debug, 'getSession - unable to get current session', err);
    }
  }
  return session;
};

export const getUserIsValid = async (): Promise<boolean> => {
  const accounts = await pca.getAllAccounts();
  const valid = accounts.length > 0;

  if (authConfig.debugEnabled) {
    log(LogLevel.debug, 'getUserIsValid', valid);
  }

  return valid;
};

export const getAccessToken = async (): any => {
  pca.config.auth.authority = await setAuthority();
  const request = compose(
    (account: any) => ({
      scopes: [authConfig.eagleScope],
      account,
    }),
    ([acnt]: AccountInfo[]) => defaultToObj(acnt)
  )(pca.getAllAccounts());

  return pca.acquireTokenSilent(request).then((response) => response.accessToken)
    .catch(async (error) => trace(error))
    .catch(error => trace(error));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUserInfo = async (): Promise<{ [id: string]: any } | undefined> => {
  const session = await getSession();
  const userInfo = session && session.getIdToken().decodePayload();
  if (authConfig.debugEnabled) {
    log(LogLevel.debug, 'getUserInfo', userInfo);
  }
  return userInfo;
};

export const getEmail = async (): Promise<string | undefined> => {
  const userInfo = await getUserInfo();
  return userInfo && userInfo.email;
};

export const setAuthority = async (): string => {
  const session = await getSession();
  // console.log("session is:");
  // console.log(session);
  if (session !== undefined) {
    if (Object.prototype.hasOwnProperty.call(session.idTokenClaims, 'AzureObjectID')) {
      return authConfig.b2cPolicies.authorities.signUpSignIn.authority;
    }
    return authConfig.b2cPolicies.authorities.b2esignin.authority;
  }
  return authConfig.b2cPolicies.authorities.signUpSignIn.authority;
};


export const login = async (policy: RedirectRequest | undefined): Promise<void> => {
  await pca.handleRedirectPromise();
  await pca.loginRedirect(policy);
};

export const logout = async ({ beforeLogout }: Record<string, any>): Promise<void> => {
  pca.config.auth.authority = await setAuthority();
  await pca.handleRedirectPromise();
  if (typeof beforeLogout === 'function') {
    await beforeLogout();
  }
  await pca.logoutRedirect();
};
