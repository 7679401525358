import Immutable from 'immutable';

import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { Point } from 'types/point';

// Action Types
const NAME = 'points';

export const ADD = `${NAME}/ADD`;
export const UPDATE = `${NAME}/UPDATE`;
export const REMOVE = `${NAME}/REMOVE`;
export const CLEAR = `${NAME}/CLEAR`;
export interface PointsState {
  readonly points: Immutable.Map<string, Point>;
}

// Initial State
const initialState: PointsState = {
  points: Immutable.Map<string, Point>(),
};

// Action Creators
export const actions = {
  add: (point: Point) => ({
    type: ADD,
    payload: point,
  }),

  update: (point: Point) => ({
    type: UPDATE,
    payload: point,
  }),

  remove: (pointId: string) => ({
    type: REMOVE,
    payload: pointId,
  }),

  clear: () => ({
    type: CLEAR
  })
};

// Selectors
const getPoints = (rootState: RootState): PointsState => rootState.points;

const getAllPoints = (
  rootState: RootState,
): Immutable.Map<string, Point> => getPoints(rootState).points;

const getAllPointsList = (
  rootState: RootState,
): Immutable.List<Point> => getAllPoints(rootState).valueSeq().toList();

const getPointsIds = (
  rootState: RootState,
): string[] => getAllPoints(rootState).keySeq().toArray();

const getPointById = (
  rootState: RootState,
  pointId: string,
): Point => getAllPoints(rootState).get(pointId)!;

export const selectors = {
  getPoints,
  getAllPoints,
  getAllPointsList,
  getPointsIds,
  getPointById,
};

// Reducers
const addPointReducer = (state: PointsState, point: Point): PointsState => ({
  ...state,
  points: state.points.set(point.pointId, point),
});

const removePointReducer = (state: PointsState, pointId: string): PointsState => ({
  ...state,
  points: state.points.delete(pointId),
});

const clearPointsReducer = (state: PointsState): PointsState => ({
  ...state,
  points: Immutable.Map<string, Point>(),
});

export const reducer = (state: PointsState = initialState, action: PayloadAction): PointsState => {
  switch (action.type) {
    case ADD:
    case UPDATE:
      return addPointReducer(state, action.payload);

    case REMOVE:
      return removePointReducer(state, action.payload);

    case CLEAR:
      return clearPointsReducer(state);

    default:
      return state;
  }
};
