import Immutable from 'immutable';

import { sketchConfig } from 'config/sketchConfig';
import { CoordinatePoint } from 'types/point';
import { MeasurementLabelType } from 'types/measurementLabel';
import { distance, getCentroid } from 'helpers/geometry';
import { formatLinearLabel } from 'helpers/pixelsToDistance';
import { lineDirection } from 'helpers/lineDirection';
import { DEGREES_PER_RADIAN } from 'helpers/rotation';
import {
  getLabelOrientation, getLabelBaseline, getBestPosition, getBestRotation,
} from 'helpers/label/labelOrientation';
import { compose } from 'helpers/utils';

export const createMeasurementLabel = (
  figurePoints: Immutable.List<CoordinatePoint>,
  isFigureClosed: boolean,
  wallPoints: Immutable.List<CoordinatePoint>,
  isBaselined?: boolean,
  unitOfMeasurement = 'meters',
  precision = 2,
  gridSize?: number,
): MeasurementLabelType => {
  const wallStart: CoordinatePoint = wallPoints.get(0)!;
  const wallEnd: CoordinatePoint = wallPoints.get(1)!;

  const polygon = isFigureClosed
    ? figurePoints.concat(figurePoints.get(0)!)
    : figurePoints;

  const centerOfWall: CoordinatePoint = {
    x: (wallStart.x + wallEnd.x) / 2,
    y: (wallStart.y + wallEnd.y) / 2,
  };

  const angle = Math.atan2(
    wallEnd.y - wallStart.y,
    wallEnd.x - wallStart.x,
  );

  const text = compose(
    formatLinearLabel(unitOfMeasurement, precision, gridSize),
    ([p1, p2]: CoordinatePoint[]) => distance(p1, p2),
  )([wallStart, wallEnd]);

  const orientation = compose(
    getLabelOrientation(angle),
    lineDirection(centerOfWall),
    getCentroid,
  )(polygon);

  const rotation = getBestRotation(orientation, angle) * DEGREES_PER_RADIAN;

  if (isBaselined) {
    const baseline = getLabelBaseline(orientation);

    return {
      text,
      position: centerOfWall,
      rotation,
      baseline,
    };
  }

  const position = compose(
    (bestPosition: any) => ({
      x: centerOfWall.x + bestPosition.x * sketchConfig.labelSpace,
      y: centerOfWall.y + bestPosition.y * sketchConfig.labelSpace,
    }),
  )(getBestPosition(orientation, centerOfWall, polygon));

  return {
    text,
    position,
    rotation,
  };
};
