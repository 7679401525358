import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';

import { NetworkPersistenceState, reducer as networkPersistenceReducer } from 'ducks/persistence/networkPersistence';
import { SketchPersistenceState, reducer as sketchPersistenceReducer } from 'ducks/persistence/sketchPersistence';
import { SketchesPersistenceState, reducer as sketchesPersistenceReducer } from 'ducks/persistence/sketchesPersistence';
import { AreaTypePersistenceState, reducer as areaTypePersistenceReducer } from 'ducks/persistence/areaTypePersistence';
import { LabelPersistenceState, reducer as labelPersistenceReducer } from 'ducks/persistence/labelPersistence';
import { SettingsPersistenceState, reducer as settingsPersistenceReducer } from 'ducks/persistence/settingsPersistence';
import { SymbolPersistenceState, reducer as symbolPersistenceReducer } from 'ducks/persistence/symbolPersistence';
import { EditModeState, reducer as editModeReducer } from 'ducks/editMode';
import { MoveState, reducer as moveObjectsReducer } from 'ducks/moveObjects';
import { ResizeState, reducer as resizeObjectsReducer } from 'ducks/resizeObjects';
import { RotateState, reducer as rotateObjectsReducer } from 'ducks/rotateObjects';
import { ViewportState, reducer as viewportReducer } from 'ducks/viewport';
import { BluePrintImageState, reducer as bluePrintImageReducer } from 'ducks/bluePrintImage/bluePrintImage';
import { AreaTypeModalState, reducer as areaTypeModalReducer } from 'ducks/modal/areaTypeModal';
import { SubAreaModalState, reducer as subAreaModalReducer } from 'ducks/modal/subAreaModal';
import { GlaBreakdownModalState, reducer as glaBreakdownModalReducer } from 'ducks/modal/glaBreakdownModal';
import { SketchSearchModalState, reducer as sketchSearchModalReducer } from 'ducks/modal/sketchSearchModal';
import { SettingsModalState, reducer as settingsModalReducer } from 'ducks/modal/settingsModal';
import { NumpadModalState, reducer as numpadModalReducer } from 'ducks/modal/numpadModal';
import { PageGroupsModalState, reducer as pageGroupsModalReducer } from 'ducks/modal/pageGroupsModal';
import { PrintPreviewModalState, reducer as printPreviewReducer } from 'ducks/modal/printPreviewModal';
import { ResizeSketchModalState, reducer as resizeSketchModalReducer } from 'ducks/modal/resizeSketchModal';
import { SaveSketchModalState, reducer as saveSketchModalReducer } from 'ducks/modal/saveSketchModal';
import { DrawState, reducer as drawReducer } from 'ducks/draw/draw';
import { GroupsState, reducer as groupsReducer } from 'ducks/groupObjects';
import { BoxSelectState, reducer as boxSelectReducer } from 'ducks/boxSelect';
import { DrawKeypadState, reducer as drawWithKeypadReducer } from 'ducks/draw/drawWithKeypad';
import { PagesState, reducer as pagesReducer } from 'ducks/model/pages';
import { FiguresState, reducer as figuresReducer } from 'ducks/model/figures';
import { WallsState, reducer as wallsReducer } from 'ducks/model/walls';
import { PointsState, reducer as pointsReducer } from 'ducks/model/points';
import { LabelsState, reducer as labelsReducer } from 'ducks/model/labels';
import { AreaTypesState, reducer as areaTypesReducer } from 'ducks/model/areaTypes';
import { PositionedLabelsState, reducer as positionedLabelsReducer } from 'ducks/model/positionedLabels';
import { SymbolsState, reducer as symbolsReducer } from 'ducks/model/symbols';
import { SymbolGroupsState, reducer as symbolGroupsReducer } from 'ducks/model/symbolGroups';
import { PositionedSymbolsState, reducer as positionedSymbolsReducer } from 'ducks/model/positionedSymbols';
import { SidebarState, reducer as sidebarReducer } from 'ducks/sidebar/sidebar';
import { HistoryState, reducer as historyReducer } from 'ducks/history/history';
import { MessageContainerState, reducer as messageContainerReducer } from 'ducks/messageContainer';
import { SettingsState, reducer as settingsReducer } from 'ducks/settings';

export interface RootState {
  router: RouterState;
  networkPersistence: NetworkPersistenceState;
  sketchPersistence: SketchPersistenceState;
  sketchesPersistence: SketchesPersistenceState;
  areaTypePersistence: AreaTypePersistenceState;
  labelPersistence: LabelPersistenceState;
  settingsPersistence: SettingsPersistenceState;
  symbolPersistence: SymbolPersistenceState;
  editMode: EditModeState;
  move: MoveState;
  resize: ResizeState;
  rotate: RotateState;
  viewport: ViewportState;
  bluePrintImage: BluePrintImageState;
  sidebar: SidebarState;
  areaTypeModal: AreaTypeModalState;
  subAreaModal: SubAreaModalState;
  glaBreakdownModal: GlaBreakdownModalState;
  settingsModal: SettingsModalState;
  sketchSearchModal: SketchSearchModalState;
  saveSketchModal: SaveSketchModalState;
  numpadModal: NumpadModalState;
  pageGroupsModal: PageGroupsModalState;
  printPreviewModal: PrintPreviewModalState;
  resizeSketchModal: ResizeSketchModalState;
  draw: DrawState;
  boxSelect: BoxSelectState;
  drawKeypad: DrawKeypadState;
  pages: PagesState;
  groups: GroupsState;
  figures: FiguresState;
  walls: WallsState;
  points: PointsState;
  labels: LabelsState;
  areaTypes: AreaTypesState;
  positionedLabels: PositionedLabelsState;
  settings: SettingsState;
  symbols: SymbolsState;
  symbolGroups: SymbolGroupsState;
  positionedSymbols: PositionedSymbolsState;
  history: HistoryState;
  messageContainer: MessageContainerState;
}

export const createRootReducer = (history: History): Reducer<RootState> => combineReducers({
  router: connectRouter(history),
  // app reducers
  networkPersistence: networkPersistenceReducer,
  sketchPersistence: sketchPersistenceReducer,
  sketchesPersistence: sketchesPersistenceReducer,
  areaTypePersistence: areaTypePersistenceReducer,
  labelPersistence: labelPersistenceReducer,
  settingsPersistence: settingsPersistenceReducer,
  symbolPersistence: symbolPersistenceReducer,
  editMode: editModeReducer,
  move: moveObjectsReducer,
  resize: resizeObjectsReducer,
  rotate: rotateObjectsReducer,
  viewport: viewportReducer,
  bluePrintImage: bluePrintImageReducer,
  sidebar: sidebarReducer,
  numpadModal: numpadModalReducer,
  areaTypeModal: areaTypeModalReducer,
  subAreaModal: subAreaModalReducer,
  glaBreakdownModal: glaBreakdownModalReducer,
  settingsModal: settingsModalReducer,
  sketchSearchModal: sketchSearchModalReducer,
  saveSketchModal: saveSketchModalReducer,
  pageGroupsModal: pageGroupsModalReducer,
  printPreviewModal: printPreviewReducer,
  resizeSketchModal: resizeSketchModalReducer,
  draw: drawReducer,
  drawKeypad: drawWithKeypadReducer,
  boxSelect: boxSelectReducer,
  pages: pagesReducer,
  groups: groupsReducer,
  figures: figuresReducer,
  walls: wallsReducer,
  points: pointsReducer,
  labels: labelsReducer,
  areaTypes: areaTypesReducer,
  positionedLabels: positionedLabelsReducer,
  symbols: symbolsReducer,
  symbolGroups: symbolGroupsReducer,
  positionedSymbols: positionedSymbolsReducer,
  history: historyReducer,
  messageContainer: messageContainerReducer,
  settings: settingsReducer,
});
