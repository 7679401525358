const labels = [
  {
    id: '4b681e9e-04fa-429c-a8ef-84daa0284095',
    text: 'Above Ground Pool',
    isDefault: true,
    index: 1,
    meta: {
      'Water Feature': 'yes',
      Faucet: 'no',
    },
  },
  {
    id: '33731ac9-bf7a-4cc5-a1bf-3f09973b7624',
    text: 'Atrium',
    index: null,
    isDefault: true,
  },
  {
    id: 'f9596930-bee5-4ce4-938c-94924fff097e',
    text: 'Bath',
    isDefault: true,
    meta: {
      'Bath Tub': 'yes',
    },
  },
  {
    id: '4dde6238-98ce-4950-af4e-27c3fad2d766',
    text: 'Bedroom',
    isDefault: true,
    meta: {
      Balcony: 'yes',
    },
  },
  {
    id: 'ea6852ed-ee69-4ed3-9915-41df5357db68',
    text: 'Bonus/Family Room',
    isDefault: true,
  },
  {
    id: 'd3b98e68-4197-45d4-b997-c7163541a34f',
    text: 'Breakfast',
    isDefault: true,
  },
  {
    id: 'e8806b74-f938-4496-870e-ea91c0f61d4a',
    text: 'Den',
    isDefault: true,
  },
  {
    id: '6e0fe184-254f-4062-ae29-7d3a910f3fdb',
    text: 'Den/Office',
    isDefault: true,
  },
  {
    id: '555dea4d-4fa9-4e00-95ec-6090de55463a',
    text: 'Dining',
    isDefault: true,
  },
  {
    id: 'ef280312-a6e7-4a8a-bcd3-3ebea88b473c',
    text: 'Entrance',
    isDefault: true,
  },
  {
    id: '4cc3e373-aa5c-43b5-b45c-829c41d542a6',
    text: 'Entry Hall',
    isDefault: true,
  },
  {
    id: 'd59efe91-606b-4ff1-bb3d-6b9a49659ecc',
    text: 'Family',
    isDefault: true,
  },
  {
    id: '50f58590-1f6f-4916-b850-37b52e04e790',
    text: 'Fence',
    isDefault: true,
  },
  {
    id: 'd20fd850-2afd-498c-a8d8-b19d2687cd7a',
    text: 'Finished Attic',
    isDefault: true,
  },
  {
    id: 'f2ccc3b3-b0ad-4d62-a461-4ebe57eda45d',
    text: 'Full Bathroom',
    isDefault: true,
  },
  {
    id: '7872db94-3b0b-493d-9679-ff31ac5373ac',
    text: 'Game Room',
    isDefault: true,
  },
  {
    id: 'eacedf77-13fa-4e64-b754-a9b146a97f39',
    text: 'Half Bathroom',
    isDefault: true,
  },
  {
    id: 'ab9a9002-a791-47b6-a852-56f01d51b31d',
    text: 'Hazard',
    isDefault: true,
  },
  {
    id: 'f2e86de9-5bcf-4f34-813d-1263756ad9ca',
    text: 'Inground Pool',
    isDefault: true,
  },
  {
    id: 'f42136bb-ec24-4643-a368-86d999f7f8c6',
    text: 'Kitchen',
    isDefault: true,
  },
  {
    id: '8beca925-0dcf-40a9-8439-46da990b11e7',
    text: 'Laundry',
    isDefault: true,
  },
  {
    id: '2b9c1b1c-5844-4ed9-8c52-6acdbee6d55e',
    text: 'Living Room',
    isDefault: true,
  },
  {
    id: '37b2f0ae-bde8-4ee1-90dd-a8739eceefdd',
    text: 'Loft',
    isDefault: true,
  },
  {
    id: '22745be3-ca0f-475f-a12f-2d9e00b164b8',
    text: 'Media',
    isDefault: true,
  },
  {
    id: '2537622d-24f1-466b-ab31-fa3c57e57a2b',
    text: 'Mudroom',
    isDefault: true,
  },
  {
    id: '1c9996fe-1134-4db6-b73c-0f92a69fc14b',
    text: 'Study',
    isDefault: true,
  },
  {
    id: '700d2159-7901-4f0d-ba3c-8ccd43ec2f7e',
    text: 'Sun Room',
    isDefault: true,
  },
  {
    id: 'e27bcc29-9613-46b3-a503-61691a565669',
    text: 'Trampoline',
    isDefault: true,
  },
  {
    id: 'd727f319-8b80-47a5-b2f0-9751c340ccaf',
    text: 'Utility',
    isDefault: true,
  },
];

export default labels;
