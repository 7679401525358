import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import uuid from 'uuid/v4';

import { colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { Dimensions } from 'types/dimensions';
import { CoordinatePoint, Point } from 'types/point';
import { SizingInfo } from 'types/sizingInfo';
import { SketchSymbol } from 'types/sketchSymbol';
import { PositionedSymbol } from 'types/positionedSymbol';
import { PositioningInfo } from 'types/positioningInfo';
import { determinePositioning } from 'helpers/positioning';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { actions as moveObjectsActions, selectors as moveObjectsSelectors } from 'ducks/moveObjects';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { selectors as symbolsSelectors } from 'ducks/model/symbols';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import {
  actions as positionedSymbolActions,
  selectors as positionedSymbolsSelectors,
} from 'ducks/model/positionedSymbols';
import { selectors as viewportSelectors, MousePointToSVGPointFunction } from 'ducks/viewport';
import Rotate from 'components/sketch/Rotate/Rotate';
import SelectionBorder from 'components/sketch/SelectionBorder/SelectionBorder';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import { isAppleTouchDevice } from 'helpers/browserDetect';

interface OwnProps {
  readonly positionedSymbolId: string;
}

interface StateProps {
  readonly positionedSymbol: PositionedSymbol | undefined;
  readonly position: Point;
  readonly symbol: SketchSymbol | undefined;
  readonly isMultiselectMode: boolean;
  readonly isSelectMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isMovingMode: boolean;
  readonly isResizingMode: boolean;
  readonly hasObjectMoved: boolean;
  readonly selectedObjects: Immutable.List<string>;
  readonly movedPoints: Immutable.List<Point>;
  readonly objectSizes: Immutable.Map<string, SizingInfo>;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
}

interface ActionProps {
  readonly selectObjects: typeof editModeActions.selectObjects;
  readonly startMove: typeof editModeActions.switchToMoving;
  readonly resize: (positionedSymbolId: string, size: Dimensions) => void;
  readonly endMove: typeof moveObjectsActions.endMove;
}

type Props = OwnProps & StateProps & ActionProps;

class SymbolComponent extends Component<Props> {
  // eslint-disable-next-line react/sort-comp
  private readonly symbolImageRef: React.RefObject<SVGImageElement>;

  private readonly positionedSymbolUuid: string;

  private lastTouchDate?: number;

  public constructor(props: Props) {
    super(props);
    this.symbolImageRef = React.createRef();
    this.positionedSymbolUuid = uuid();
    this.lastTouchDate = undefined;
  }

  public componentDidMount = (): void => {
    // TODO: browser is not able to calculate the bbox immediately after mounting the svg, find a better solution
    setTimeout(() => this.calculateBBox(), 200);
  };

  public componentDidUpdate = ({ positionedSymbol: prevPositionedSymbol }: Props): void => {
    const { positionedSymbol } = this.props;
    if (prevPositionedSymbol && prevPositionedSymbol.size
      && positionedSymbol && positionedSymbol.size
      && positionedSymbol.size.width !== prevPositionedSymbol.size.width
    ) {
      this.calculateBBox();
    }
  };

  private calculateBBox = (): void => {
    const { resize, positionedSymbolId } = this.props;
    if (!this.symbolImageRef.current) return;
    const { width, height } = this.symbolImageRef.current!.getBBox();
    resize(positionedSymbolId, { width, height });
  };

  private handleTouchStart = (event: React.TouchEvent<SVGGElement>): void => {
    // Unable to preventDefault inside passive event listener due to target being treated as passive. See https://www.chromestatus.com/feature/5093566007214080
    // event.preventDefault();
    const {
      isSelectMode, isSelectedMode, selectedObjects, positionedSymbolId, getMousePointToSvgPoint
    } = this.props;

    // if this is not a single touch
    if (event.touches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.touches[0].clientX, clientY: event.touches[0].clientY });

    // if we don't have a relative point
    if (!point) {
      return;
    }

    // if this is an apple touch device (iOS)
    // set this on touch and reset on touch move, pan or zoom
    if (isAppleTouchDevice) {
      this.lastTouchDate = new Date().getTime();
    }

    this.handlePointerDown(point);
    const isThisSelected = selectedObjects!.includes(positionedSymbolId);
    if (isSelectedMode && isThisSelected) {
      event.stopPropagation();
    } else if (isSelectMode || !isThisSelected) {
      event.stopPropagation();
    }
  }

  private handleMouseDown = (event: React.MouseEvent<SVGGElement>): void => {
    event.preventDefault();
    const {
      isSelectMode, isSelectedMode, selectedObjects, positionedSymbolId, getMousePointToSvgPoint
    } = this.props;

    const point = getMousePointToSvgPoint({ clientX: event.clientX, clientY: event.clientY });

    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !point) {
      return;
    }

    this.handlePointerDown(point);
    const isThisSelected = selectedObjects!.includes(positionedSymbolId);
    if (isSelectedMode && isThisSelected) {
      event.stopPropagation();
    } else if (isSelectMode || !isThisSelected) {
      event.stopPropagation();
    }
  }

  private handlePointerDown = (point: CoordinatePoint): void => {
    const {
      isSelectMode, selectObjects, isSelectedMode, startMove, selectedObjects, positionedSymbolId
    } = this.props;

    const isThisSelected = selectedObjects!.includes(positionedSymbolId);

    if (isSelectedMode && isThisSelected) {
      startMove(point);
    } else if (isSelectMode || !isThisSelected) {
      selectObjects([positionedSymbolId]);
      startMove(point);
    }
  };

  private handleTouchEnd = (event: React.TouchEvent<SVGGElement>): void => {
    // this stops the context menu from showing
    // event.preventDefault();
    const {
      hasObjectMoved, isMovingMode, isMultiselectMode, getMousePointToSvgPoint
    } = this.props;

    // if this is not a single touch
    // note that on touch end we will use event.changedTouches instead of
    // event.touches- the later is not available here
    if (event.changedTouches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.changedTouches[0].clientX, clientY: event.changedTouches[0].clientY });

    // if we don't have a relative point
    if (!point) {
      return;
    }

    // if now is 1.5 seconds after lastTouchDate then show the context menu and bail
    if (this.lastTouchDate && new Date().getTime() > (this.lastTouchDate + 1000)) {
      this.lastTouchDate = undefined;

      event.target.dispatchEvent.call(event.target, new MouseEvent('contextmenu', {
        bubbles: true,
        clientX: event.changedTouches[0].clientX,
        clientY: event.changedTouches[0].clientY
      }));

      event.stopPropagation();
      return;
    }

    this.handlePointerUp(point);
    if (isMovingMode && !hasObjectMoved) {
      event.stopPropagation();
    }
    if (isMultiselectMode) {
      event.stopPropagation();
    }
  }

  private handleMouseUp = (event: React.MouseEvent<SVGGElement>): void => {
    event.preventDefault();
    const {
      hasObjectMoved, isMovingMode, isMultiselectMode, getMousePointToSvgPoint
    } = this.props;

    const point = getMousePointToSvgPoint({ clientX: event.clientX, clientY: event.clientY });
    // if this is not the left button, or we don't have a point
    if (event.button !== 0 || !point) {
      return;
    }

    this.handlePointerUp(point);
    if (isMovingMode && !hasObjectMoved) {
      event.stopPropagation();
    }
    if (isMultiselectMode) {
      event.stopPropagation();
    }
  }

  private handlePointerUp = (point: CoordinatePoint): void => {
    const {
      hasObjectMoved, isMovingMode, positionedSymbolId, selectObjects, endMove, isMultiselectMode
    } = this.props;

    if (isMovingMode && !hasObjectMoved) {
      endMove(point);
      selectObjects([positionedSymbolId]);
    }
    if (isMultiselectMode) {
      selectObjects([positionedSymbolId]);
    }
  };

  private positionSymbol = (positionedSymbolId: string): PositioningInfo => {
    const {
      positionedSymbol, position,
      movedPoints, objectSizes,
      isMovingMode, isResizingMode,
    } = this.props;

    return determinePositioning(
      positionedSymbolId, positionedSymbol!, position,
      movedPoints!, objectSizes,
      isMovingMode!, isResizingMode!,
    );
  };

  public render(): JSX.Element {
    const { positionedSymbolId, symbol, selectedObjects } = this.props;
    const selectedSymbol = selectedObjects && selectedObjects.includes(positionedSymbolId);
    const { position, size, rotation } = this.positionSymbol(positionedSymbolId);

    if (!symbol) return (<> </>);

    return (
      <>
        <ContextMenu type={`positionedSymbol${this.positionedSymbolUuid}`} parentRef={this.symbolImageRef} />
        <Rotate degrees={rotation} position={position}>
          {selectedSymbol && (
            <SelectionBorder
              x={position.x - size.width / 2}
              y={position.y - size.height / 2}
              width={size.width}
              height={size.height}
            />
          )}
          <image
            ref={this.symbolImageRef}
            x={position.x - size.width / 2}
            y={position.y - size.height / 2}
            stroke={selectedSymbol ? colors.highlighted : undefined}
            href={symbol!.data}
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
            onTouchEnd={this.handleTouchEnd}
            onMouseUp={this.handleMouseUp}
            width={size.width || (symbol!.width || 100)}
            height={size.height || 100}
          />
        </Rotate>
      </>
    );
  }
}

export default connect(
  (state: RootState, { positionedSymbolId }: OwnProps): StateProps => {
    const positionedSymbol = positionedSymbolsSelectors.getPositionedSymbolById(state, positionedSymbolId);
    return {
      positionedSymbol,
      symbol: symbolsSelectors.getSymbolById(state, positionedSymbol!.symbolId),
      position: pointsSelectors.getPointById(state, positionedSymbol!.pointId),
      isMultiselectMode: editModeSelectors.isMultiselectMode(state),
      isSelectMode: editModeSelectors.isSelectMode(state),
      isSelectedMode: editModeSelectors.isSelectedMode(state),
      isMovingMode: editModeSelectors.isMovingMode(state),
      isResizingMode: editModeSelectors.isResizingMode(state),
      hasObjectMoved: moveObjectsSelectors.hasMoved(state),
      selectedObjects: editModeSelectors.getSelectedObjects(state),
      movedPoints: selectionSelectors.getMovedSelectedPoints(state),
      objectSizes: selectionSelectors.getResizedSelectedObjectsSizes(state),
      getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
    };
  },
  {
    selectObjects: editModeActions.selectObjects,
    startMove: editModeActions.switchToMoving,
    resize: positionedSymbolActions.resize,
    endMove: moveObjectsActions.endMove,
  },
)(SymbolComponent);
