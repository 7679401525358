/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { positionedLabels as colors } from 'config/paletteConfig';
import { CoordinatePoint } from 'types/point';
import { Dimensions } from 'types/dimensions';
import LabelTextEdit from 'components/elements/LabelTextEdit';

const style = css`
  font-family: inherit;
  font-size: 1em;

  input {
    width: 100%;
    padding: 0;
    border: 0;
    color: ${colors.base};
    background-color: transparent;
    text-align: center;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
  }
`;

interface Props {
  readonly positionedLabelId: string;
  readonly position: CoordinatePoint;
  readonly size: Dimensions;
  readonly fontScale: number;

  readonly onEditFinished: () => void;
}
const PositionedLabelTextEdit = ({
  positionedLabelId, position, size, onEditFinished, fontScale,
}: Props): JSX.Element => (
  <foreignObject
    x={Math.floor(position.x - size.width)}
    y={Math.floor(position.y - size.height / 2)}
    width={size.width * 2}
    height={size.height}
  >
    <LabelTextEdit
      css={[style, css`font-size: ${fontScale}%;`]}
      positionedLabelId={positionedLabelId}
      onEditFinished={onEditFinished}
    />
  </foreignObject>
);

export default PositionedLabelTextEdit;
