import Immutable from 'immutable';
import { createAction, getType, ActionType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { AreaType } from 'types/areaType';
import { GlaType } from 'types/glaType';
import { sortOrderable } from 'helpers/sort/sortOrderable';

// -- data for API disconnect --

const positiveArea: AreaType = {
  areaTypeId: 'positiveArea',
  text: 'Floor',
  glaType: GlaType.GLA,
  sortOrder: 0,
  meta: {
    include_in_gba: true,
  },
};

const nonGLAArea: AreaType = {
  areaTypeId: 'nonGLAArea',
  text: 'Garage',
  glaType: GlaType.NON_GLA,
  sortOrder: 1,
  meta: {
    include_in_gba: true,
  },
};

const nonGBAArea: AreaType = {
  areaTypeId: 'nonGBAArea',
  text: 'Attic',
  glaType: GlaType.NON_GLA,
  sortOrder: 3,
  meta: {
    include_in_gba: false,
  },
};

const negativeArea: AreaType = {
  areaTypeId: 'negativeArea',
  text: 'Empty space',
  glaType: GlaType.NEGATIVE,
  sortOrder: 2,
  meta: {
    include_in_gba: true,
  },
};

const offlineAreaTypes: [string, AreaType][] = [
  [positiveArea.areaTypeId, positiveArea],
  [nonGLAArea.areaTypeId, nonGLAArea],
  [nonGBAArea.areaTypeId, nonGBAArea],
  [negativeArea.areaTypeId, negativeArea],
];

export interface AreaTypesState {
  readonly areaTypes: Immutable.Map<string, AreaType>;
}

// Initial State
const initialState: AreaTypesState = {
  areaTypes: Immutable.Map<string, AreaType>(offlineAreaTypes),
};

interface SetAreaPayload {
  clear: boolean;
  areaTypes: AreaType[];
}

// Action Creators
export const actions = {
  set: createAction('areaTypes/set')<SetAreaPayload>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getAreaTypesState = (rootState: RootState): AreaTypesState => rootState.areaTypes;

const getAllAreaTypes = (
  rootState: RootState,
): Immutable.Map<string, AreaType> => getAreaTypesState(rootState).areaTypes;

const getAreaTypesForDisplay = (
  rootState: RootState,
): Immutable.Map<string, AreaType> => getAllAreaTypes(rootState).sort(sortOrderable);

const getAreaTypeById = (
  rootState: RootState,
  areaTypeId: string,
): AreaType | undefined => getAllAreaTypes(rootState).get(areaTypeId);

const getAreaTypeByMaybeId = (
  rootState: RootState,
  areaTypeId?: string,
): AreaType | undefined => areaTypeId ? getAreaTypeById(rootState, areaTypeId) : undefined;

export const selectors = {
  getAllAreaTypes,
  getAreaTypesForDisplay,
  getAreaTypeById,
  getAreaTypeByMaybeId,
};

// Reducers
const setAreaTypeReducer = (state: AreaTypesState, { clear, areaTypes }: SetAreaPayload): AreaTypesState => {
  const newAreaTypes = Immutable.Map<string, AreaType>(areaTypes.map((area) => [area.areaTypeId, area]));
  return {
    ...state,
    areaTypes: clear ? newAreaTypes : state.areaTypes.merge(newAreaTypes),
  };
};

export const reducer = (state: AreaTypesState = initialState, action: Actions): AreaTypesState => {
  switch (action.type) {
    case getType(actions.set):
      return setAreaTypeReducer(state, action.payload);

    default:
      return state;
  }
};
