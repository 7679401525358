import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { actions as messageActions, selectors as messageSelectors } from 'ducks/messageContainer';
import { ModalDialog } from 'components/modal/ModalDialog';

interface StateProps {
  readonly message?: string | JSX.Element;
  readonly title?: string;
  readonly onSubmitText?: string;
  readonly onSubmit?: () => void;
  readonly isDissmissable: boolean;
}

interface ActionProps {
  readonly hide: () => void;
}

type Props = StateProps & ActionProps;

class MessageContainer extends Component<Props> {
  private confirmAction = (): void => {
    const { onSubmit, hide } = this.props;
    if (onSubmit) onSubmit();
    hide();
  };

  public render = (): JSX.Element | null => {
    const {
      message, title, onSubmit, onSubmitText, hide, isDissmissable
    } = this.props;
    const confirmAction = (onSubmit) ? this.confirmAction : undefined;
    return message ? (
      <ModalDialog
        title={title}
        confirmText={onSubmitText}
        dismissText={messages.cancel}
        confirmAction={confirmAction}
        dismissAction={isDissmissable ? hide : undefined}
      >
        {message}
      </ModalDialog>
    ) : null;
  };
}

export default connect(
  (state: RootState): StateProps => ({
    message: messageSelectors.getMessage(state),
    title: messageSelectors.getTitle(state),
    onSubmit: messageSelectors.getOnSubmit(state),
    onSubmitText: messageSelectors.getOnSubmitText(state),
    isDissmissable: messageSelectors.getIsDissmisable(state),
  }),
  (dispatch: Dispatch): ActionProps => bindActionCreators(
    {
      hide: messageActions.hide,
    },
    dispatch,
  ),
)(MessageContainer);
