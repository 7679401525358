import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Link, Switch } from 'react-router-dom';
import { history } from 'configureStore';

import Sketchpad from 'components/Sketchpad/Sketchpad';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import UnsupportedBrowserPage from 'components/ErrorPage/UnsupportedBrowserPage';
import { authConfig } from 'config/authConfig';
import { login } from 'effects/auth';

const Routes = (): JSX.Element => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route
        path="/Home/SignUp"
        component={() => {
          // eslint-disable-next-line no-console
          login(authConfig.b2cPolicies.authorities.signUp);
          return null;
        }}
      />
      <Route
        path="/Home/ForgotPassword"
        component={() => {
          // eslint-disable-next-line no-console
          login(authConfig.b2cPolicies.authorities.forgotPassword);
          return null;
        }}
      />
      <Route
        path="/Home/ForgotUsername"
        component={() => {
          // eslint-disable-next-line no-console
          login(authConfig.b2cPolicies.authorities.forgotUsername);
          return null;
        }}
      />
      <Route
        path="/Home/B2C_1A_B2E_Signin"
        component={() => {
          // eslint-disable-next-line no-console
          login(authConfig.b2cPolicies.authorities.b2esignin);
          return null;
        }}
      />
      <Route path="/error" component={ErrorPage} />
      <Route path="/unsupported" component={UnsupportedBrowserPage} />
      <Route exact path="/" component={Sketchpad} />
      <Route
        render={({ location }) => (
          <div>
            <h1>
              Could not find path:
              {location.pathname}
            </h1>
            <Link to="/">Go Home</Link>
          </div>
        )}
      />
    </Switch>
  </ConnectedRouter>
);

export default Routes;
