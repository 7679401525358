import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { pointConfig } from 'config/pointConfig';
import { autoFinishButton as colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { CoordinatePoint, Point } from 'types/point';
import { actions as drawActions, selectors as drawSelectors } from 'ducks/draw/draw';
import { selectors as editModeSelectors } from 'ducks/editMode';
import { selectors as viewPointSelectors } from 'ducks/viewport';

interface StateProps {
  readonly isDrawingMode: boolean;
  readonly segmentStartPoint: CoordinatePoint | Point;
  readonly drawStartPoint: CoordinatePoint | Point;
  readonly drawEndPoint: CoordinatePoint | Point;
  readonly zoomInPercent: number;
}

interface ActionProps {
  readonly autoFinishSegment: () => void;
}

type Props = StateProps & ActionProps;

const Circle = styled.circle({
  strokeWidth: 2,
  stroke: colors.stroke,
  fill: colors.base,
});

class AutoFinishButton extends Component<Props> {
  private onPointerUp = (mouseEvent: React.PointerEvent<SVGElement>): void => {
    const { autoFinishSegment, drawStartPoint, drawEndPoint } = this.props;
    if (drawStartPoint.x === drawEndPoint.x && drawStartPoint.y === drawEndPoint.y) return;
    autoFinishSegment();
    mouseEvent.stopPropagation();
  };

  public render(): JSX.Element | null {
    const { segmentStartPoint, drawStartPoint, isDrawingMode, zoomInPercent } = this.props;

    if (!isDrawingMode || segmentStartPoint === drawStartPoint) {
      return null;
    }

    return <Circle r={pointConfig.getRadiusAutoFinish(zoomInPercent)} cx={segmentStartPoint.x} cy={segmentStartPoint.y} onPointerUp={this.onPointerUp} />;
  }
}

export default connect(
  (state: RootState): StateProps => ({
    segmentStartPoint: drawSelectors.getSegmentStartPoint(state),
    drawStartPoint: drawSelectors.getStartPoint(state),
    drawEndPoint: drawSelectors.getEndPoint(state),
    isDrawingMode: editModeSelectors.isDrawingMode(state),
    zoomInPercent: viewPointSelectors.getZoomInPercent(state),
  }),
  (dispatch: Dispatch) => bindActionCreators(
    {
      autoFinishSegment: drawActions.autoFinishSegment,
    },
    dispatch,
  ),
)(AutoFinishButton);
