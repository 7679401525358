import { CoordinatePoint } from './point';

export enum ItemTypes {
  Page = 'Page',
  Symbol = 'Symbol',
  Label = 'Label',
}

export interface PageDragItem {
  type: ItemTypes.Page;
  preview: React.ReactNode;
  items: string[];
}

export interface PageDropResult {
  pageId: string;
}

export interface LabelDragItem {
  type: ItemTypes.Label;
  preview: React.ReactNode;
  labelId: string;
  index?: number;
}

export interface SymbolDragItem {
  type: ItemTypes.Symbol;
  preview: string;
  symbolId: string;
}

export interface LabelOrSymbolDropResult {
  position: CoordinatePoint;
}

export type DragItem = PageDragItem | LabelDragItem | SymbolDragItem
