import { sidebar, modal, colors } from 'config/paletteConfig';
import { isTouchDevice } from 'helpers/browserDetect';

export const verticalSpacing = {
  '& + &': { marginTop: 3 },
} as const;

export const scrollWrapperStyles = {
  margin: 0,
  padding: 0,
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'stretch',
  flex: '1 1 auto',
} as const;

export const sidebarOrderSortActionStyles = {
  paddingLeft: 8,
  paddingRight: 8,
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: 40
} as const;

export const sidebarItemStyles = {
  display: 'block',
  width: '100%',
  padding: '12px 25px',
  border: 0,
  color: sidebar.item.color,
  backgroundColor: sidebar.item.base,
  fontSize: 14,
  fontWeight: 600,
  textAlign: 'left',
  transition: isTouchDevice ? 'background-color 0s linear, color 0s linear' : 'background-color 0.15s ease, color 0.15s ease',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  '&:focus': {
    outline: 'none',
    color: sidebar.item.colorActive,
  },
} as const;

export const sidebarLabelItemStyles = {
  display: 'block',
  width: '100%',
  padding: '12px 25px',
  paddingLeft: 0,
  border: 0,
  color: sidebar.item.color,
  backgroundColor: sidebar.item.base,
  fontSize: 14,
  fontWeight: 600,
  textAlign: 'left',
  transition: isTouchDevice ? 'background-color 0s linear, color 0s linear' : 'background-color 0.15s ease, color 0.15s ease',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  '&:focus': {
    outline: 'none',
    color: sidebar.item.colorActive,
  },
} as const;

export const sidebarItemSelectedStyles = {
  color: sidebar.item.colorActive,
} as const;

export const sidebarItemHidingStyles = {
  display: 'none'
} as const;

export const sidebarItemMedatdataStyles = {
  backgroundColor: sidebar.item.metaHighlight,
} as const;

export const sidebarItemButtonStyles = {
  display: 'block',
  width: 20,
  height: 20,
  border: 0,
  padding: 0,
  color: 'inherit',
  borderRadius: 2,
  backgroundColor: sidebar.itemButton.base,

  '&:hover, &:focus': {
    outline: 'none',
    backgroundColor: sidebar.itemButton.hovered,
  },

  '&:active': {
    backgroundColor: sidebar.itemButton.active,
  },

  svg: {
    width: 16,
    height: 16,
    margin: 2,
  },
} as const;

export const headerButtonSelected = {
  backgroundColor: modal.headerButton.selected,
  color: modal.headerButton.colorSelected,

  '&:hover, &:focus': {
    backgroundColor: modal.headerButton.selectedHovered,
  },
} as const;

export const labelItemTextStyles = {
  width: 'fit-content',

  '&:hover, &:focus': {
    cursor: 'grab',
  },
} as const;

export const metaDataTooltipStyles = {
  width: '100%',
  height: 'fit-content',
  paddingLeft: 0,
  marginLeft: 0,
} as const;

export const sketchItemTextStyles = {
  width: 'fit-content',

  '&:hover, &:focus': {
    cursor: 'grab',
  },
} as const;

export const labelItemStyles = {
  backgroundColor: `${colors.none}`,
  color: 'inherit',
} as const;

export const sketchDataContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between'
} as const;

export const sketchDataTitleStyles = {
  display: 'flex',
  fontWeight: 400,
  fontSize: 12,
  color: 'grey'
} as const;

export const sketchDataRightTitleStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  fontWeight: 400,
  fontSize: 12,
  color: 'grey'
} as const;

export const sketchDataContentStyles = {
  width: 175,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
} as const;
