import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';

// Action Types
const NAME = 'saveSketchModal';

const SHOW = `${NAME}/SHOW`;
const HIDE = `${NAME}/HIDE`;

export interface SaveSketchModalState {
  readonly isShowing: boolean;
}

// Initial State
const initialState: SaveSketchModalState = {
  isShowing: false,
};

// Action Creators
export const actions = {
  hide: () => ({ type: HIDE }),
  show: () => ({ type: SHOW }),
};

// Selectors
const getSaveSketchModal = (rootState: RootState): SaveSketchModalState => rootState.saveSketchModal;

const isShowing = (rootState: RootState): boolean => getSaveSketchModal(rootState).isShowing;

export const selectors = {
  isShowing,
};

// Reducers
const showReducer = (state: SaveSketchModalState): SaveSketchModalState => ({
  ...state,
  isShowing: true,
});

const hideReducer = (state: SaveSketchModalState): SaveSketchModalState => ({
  ...state,
  isShowing: false,
});

export const reducer = (
  state: SaveSketchModalState = initialState,
  action: PayloadAction,
): SaveSketchModalState => {
  switch (action.type) {
    case HIDE:
      return hideReducer(state);

    case SHOW:
      return showReducer(state);

    default:
      return state;
  }
};
