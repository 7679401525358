import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';
import { actions as drawActions } from 'ducks/draw/draw';
import { actions as sidebarActions } from 'ducks/sidebar/sidebar';
import { selectors as symbolPersistenceSelectors } from 'ducks/persistence/symbolPersistence';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { scrollbar } from 'components/globalStyles';
import { withTooltip } from 'components/elements/Tooltip';
import { ButtonModalHeader } from 'components/modal/Modal';
import { actions as editModeActions } from 'ducks/editMode';
import { sidebarItemStyles, scrollWrapperStyles } from './styles';
import Sidebar from './Sidebar';
import { polygonSidebarItems } from './sidebarConfig';

const ScrollArea = styled.ul(scrollWrapperStyles, scrollbar);

const Button = withTooltip(ButtonModalHeader);
const SidebarItemButton = styled.button(sidebarItemStyles, { marginBottom: 3 });
interface InputProps {
  readonly pose: string;
  readonly left?: number;
}
interface StateProps {
  /* eslint-disable-next-line react/no-unused-prop-types */
  readonly isLoading: boolean;
}
interface ActionProps {
  readonly close: () => void;
  readonly switchToEdit: typeof editModeActions.switchToEdit;
  readonly setPreshapeDefaults: typeof drawActions.setPreshapeDefaults;
  readonly toggleDrawingInteriorWalls: (drawInteriorWalls: boolean) => void;
  readonly toggleDrawingPreshapes: (drawPreshapes: boolean) => void;
}

type Props = InputProps & StateProps & ActionProps;

interface PreshapeEditModeInput {
  readonly sides: number;
  readonly rotation?: number;
  readonly maintainAspectRatio?: boolean | true;
}

const PolygonSidebar = (
  {
    close, switchToEdit, toggleDrawingInteriorWalls, toggleDrawingPreshapes, setPreshapeDefaults, left, pose,
  }: Props,
): JSX.Element => {
  const onClose = (): void => {
    close();
  };

  const switchToPreshapeEditMode = ({ sides, rotation = -90, maintainAspectRatio = true }: PreshapeEditModeInput): void => {
    toggleDrawingInteriorWalls(false);
    toggleDrawingPreshapes(true);
    setPreshapeDefaults({ sides, rotation, maintainAspectRatio });
    switchToEdit();
    close();
  };

  return (
    <Sidebar left={left} pose={pose}>
      <h2>
        <Button onClick={onClose} tooltip={messages.closeModal} tooltipPosition="bottom">
          <IconClose />
        </Button>
        <span>{messages.addPolygonAreaTitle}</span>
      </h2>
      <ScrollArea>
        {polygonSidebarItems.map(({ label, options }) => (
          <li key={label}>
            <SidebarItemButton onClick={() => switchToPreshapeEditMode(options)}>
              {label}
              {' '}
              (
              {options.sides}
              {' '}
              walls)
            </SidebarItemButton>
          </li>
        ))}
      </ScrollArea>
    </Sidebar>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    isLoading: symbolPersistenceSelectors.isLoading(state),
  }),
  {
    close: sidebarActions.hide,
    switchToEdit: editModeActions.switchToEdit,
    toggleDrawingInteriorWalls: drawActions.toggleDrawingInteriorWalls,
    toggleDrawingPreshapes: drawActions.toggleDrawingPreshapes,
    setPreshapeDefaults: drawActions.setPreshapeDefaults,
  },
)(PolygonSidebar);
