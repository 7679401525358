import { defaultTo } from 'helpers/utils';
import * as appConfig from './appConfig.json';

const { REACT_APP_API_BASE_URL } = process.env;
const defaultApiConfigUnless = defaultTo(appConfig.REACT_APP_API_BASE_URL);

export const apiConfig = {
  baseUrl: defaultApiConfigUnless(REACT_APP_API_BASE_URL),
  label: 'v1/label',
  areaType: 'v1/area',
  settings: 'v1/settings',
  sketch: 'v1/sketch',
  sketches: 'v1/sketches',
  symbol: 'v1/symbol',
  symbolGroup: 'v1/symbol-group',
  company: 'v1/company',
  online: 'v1/online',
};
