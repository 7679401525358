import { CoordinatePoint } from 'types/point';
import { Dimensions } from 'types/dimensions';

export const getCenterPoint = (points: CoordinatePoint[]): CoordinatePoint => {
  const xValues = points.map(point => point.x);
  const yValues = points.map(point => point.y);
  return {
    x: (Math.max(...xValues) + Math.min(...xValues)) / 2,
    y: (Math.max(...yValues) + Math.min(...yValues)) / 2,
  };
};

export const getMinimalDimensions = (points: CoordinatePoint[]): Dimensions => {
  const xValues = points.map(point => point.x);
  const yValues = points.map(point => point.y);
  return {
    width: (Math.max(...xValues) - Math.min(...xValues)),
    height: (Math.max(...yValues) - Math.min(...yValues)),
  };
};

let svgPoint: DOMPoint | undefined;
let lastSvgElement: SVGSVGElement | undefined;
export const convertSvgPointCoordinates = (svgElement: SVGSVGElement, mousePoint: CoordinatePoint): CoordinatePoint => {
  if (lastSvgElement !== svgElement || svgPoint === undefined) {
    svgPoint = svgElement.createSVGPoint();
  }
  lastSvgElement = svgElement;
  // calculations inspired by: https://stackoverflow.com/a/11936865
  svgPoint.x = mousePoint.x;
  svgPoint.y = mousePoint.y;
  const transformationMatrix = svgElement.getScreenCTM()!;
  const coordinates = svgPoint.matrixTransform(transformationMatrix.inverse());
  return { x: coordinates.x, y: coordinates.y };
};
