import React, { useEffect } from 'react';
import posed from 'react-pose';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { RootState } from 'reducers/rootReducer';
import { settingsTextConfig } from 'config/messages';
import { sidebar } from 'config/paletteConfig';
import { Precision } from 'types/precision';
import { actions as settingsModalActions } from 'ducks/modal/settingsModal';
import { actions as settingsActions, selectors as settingsSelectors } from 'ducks/settings';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { appearFromTop, DisplayPose } from 'components/animations';
import { Modal, ButtonModalHeader as Button } from 'components/modal/Modal';
import { sidebarItemStyles, verticalSpacing } from 'components/sidebar/styles';
import RadioButton from 'components/elements/RadioButton';
import { feetToMeters } from 'helpers/utils';
import { actions as settingsPersistenceActions } from '../../ducks/persistence/settingsPersistence';

const flexRow = {
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
} as const;

const settingsStyles = {
  'z-index': 1,
  right: window.screen.width > 400 ? 0 : 'unset',
  width: window.screen.width > 400 ? 500 : '100%',
  h2: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    textTransform: 'none',

    span: {
      marginLeft: 0,
    },
  },
} as const;

const itemStyles = {
  width: 'auto',
  minHeight: 30,
} as const;

const ModalStyled = styled(Modal)(settingsStyles);
const ModalAnimated = posed(ModalStyled)(appearFromTop);

const Item = styled.li(sidebarItemStyles, itemStyles, flexRow, verticalSpacing);

const Label = styled.span({
  color: sidebar.item.color,
  fontWeight: 'bold',
  width: '100%',
});

const SubLabel = styled.div({
  color: sidebar.item.color,
  fontWeight: 400,
  width: '100%',

  '&:nth-of-type(1)': {
    paddingTop: 16,
  },
});

const GridSubLabelContainer = styled.div({
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%',
});

const RadioGroup = styled.div(flexRow, {
  display: 'flex',
  flexDirection: window.screen.width > 400 ? 'row' : 'column',
  alignItems: 'baseline',
  width: '100%',
  margin: '15px 0',
});

interface InputProps {
  readonly top: number;
  readonly pose?: DisplayPose;
}

interface StateProps {
  readonly gridLines: string;
  readonly precision: Precision;
  readonly unitOfMeasure: string;
  readonly gridSizeInFeet: any;
  readonly settingsModalForm: any;
}

interface ActionProps {
  readonly close: () => void;
  readonly loadSettings: typeof settingsPersistenceActions.loadSettings;
  readonly updateSetting: typeof settingsPersistenceActions.updateSetting;
  readonly switchGridShow: typeof settingsActions.switchGridShow;
  readonly switchPrecision: typeof settingsActions.switchPrecision;
  readonly switchUnitOfMeasurement: typeof settingsActions.switchUnitOfMeasurement;
  readonly updateLastSetting: typeof settingsActions.updateLastSetting;
}

type Props = InputProps & StateProps & ActionProps;

const SettingsModal: React.FC<Props> = ({
  close, top, pose, loadSettings, settingsModalForm,
  switchGridShow, precision, switchUnitOfMeasurement,
  unitOfMeasure, switchPrecision, gridSizeInFeet,
  gridLines, updateLastSetting, updateSetting,
}) => {
  useEffect(() => {
    loadSettings();
  }, []);

  useEffect(() => {
    if (settingsModalForm.length) {
      const gridScaleSetting = settingsModalForm.find((setting: any) => setting.name === 'gridSizeInFeet');
      updateLastSetting({ uuid: gridScaleSetting.uuid, value: gridSizeInFeet });
      updateSetting();
    }
  }, [gridSizeInFeet]);

  const handleUpdateLastSetting = (uuid: string, name: string, event: any) => {
    if (name === 'gridLines' && event) {
      switchGridShow(String((event.target as HTMLSelectElement).value) as string);
      updateLastSetting({ uuid, value: String((event.target as HTMLSelectElement).value) as string });
    }
    if (name === 'unitOfMeasurement' && event) {
      switchUnitOfMeasurement(String((event.target as HTMLSelectElement).value) as string);
      updateLastSetting({ uuid, value: String((event.target as HTMLSelectElement).value) as string });
    }
    if (name === 'precision' && event) {
      switchPrecision(Number((event.target as HTMLSelectElement).value) as Precision);
      updateLastSetting({ uuid, value: String((event.target as HTMLSelectElement).value) as string });
    }
    updateSetting();
  };

  const gridScaleUnitConversion = () => {
    if (unitOfMeasure === 'decimalFeet') return ' Feet';
    if (unitOfMeasure === 'feet') return ' Feet';
    if (feetToMeters(gridSizeInFeet) % 1 === 0) return ' Meter';
    return 'Meters';
  };

  const gridScaleValue = () => {
    const nearestThousandth = +(unitOfMeasure === 'meters' ? feetToMeters(gridSizeInFeet) : gridSizeInFeet).toFixed(3);
    return `${nearestThousandth} ${gridScaleUnitConversion()}`;
  };

  return (
    <ModalAnimated top={top} right={28} pose={pose}>
      <h2>
        <Button onClick={close}>
          <IconClose />
        </Button>
        <span>{settingsTextConfig.heading}</span>
        <div />
      </h2>
      <ul>
        <Item>
          <Label>{settingsTextConfig.grid.label}</Label>
          {settingsModalForm.map((setting: any) => {
            if (setting.type === 'static' && setting.name === 'gridSizeInFeet') {
              return (
                <GridSubLabelContainer key={setting.id}>
                  <SubLabel>{settingsTextConfig.grid.subLabelGridScale}</SubLabel>
                  <span>
                    {' '}
                    {gridScaleValue()}
                  </span>
                </GridSubLabelContainer>
              );
            }
            return undefined;
          })}
          {settingsModalForm.map((setting: any) => {
            if (setting.type === 'radio' && setting.name === 'gridLines') {
              return (
                <div key={setting.id}>
                  <SubLabel>{settingsTextConfig.grid.subLabelGridLines}</SubLabel>
                  <RadioGroup
                    onChange={(event: any) => handleUpdateLastSetting(setting.uuid, setting.name, event)}
                  >
                    {setting.options.map((option: any) => (
                      <RadioButton key={option.id} label={option.label} id={setting.id} value={option.value} checked={gridLines === option.value} />
                    ))}
                  </RadioGroup>
                </div>
              );
            }
            return undefined;
          })}
        </Item>
        <Item>
          <Label>{settingsTextConfig.measurement.label}</Label>
          {settingsModalForm.map((setting: any) => {
            if (setting.type === 'radio' && setting.name === 'unitOfMeasurement') {
              return (
                <div key={setting.id}>
                  <SubLabel>{settingsTextConfig.measurement.subLabelUnit}</SubLabel>
                  <RadioGroup
                    onChange={(event: any) => handleUpdateLastSetting(setting.uuid, setting.name, event)}
                  >
                    {setting.options.map((option: any) => (
                      <RadioButton key={option.id} label={option.label} id={setting.id} value={option.value} checked={unitOfMeasure === option.value} />
                    ))}
                  </RadioGroup>
                </div>
              );
            }
            return undefined;
          })}
          {settingsModalForm.map((setting: any) => {
            if (setting.type === 'radio' && setting.name === 'precision') {
              return (
                <div key={setting.id}>
                  <SubLabel>{settingsTextConfig.measurement.subLabelPrecision}</SubLabel>
                  <RadioGroup
                    onChange={(event: any) => handleUpdateLastSetting(setting.uuid, setting.name, event)}
                  >
                    {setting.options.map((option: any) => (
                      <RadioButton key={option.id} label={option.label} id={setting.id} value={option.value} checked={`${precision}` === option.value} />
                    ))}
                  </RadioGroup>
                </div>
              );
            }
            return undefined;
          })}
        </Item>
      </ul>
    </ModalAnimated>
  );
};

export default connect(
  (state: RootState): StateProps => ({
    gridLines: settingsSelectors.gridLines(state),
    precision: settingsSelectors.getPrecision(state),
    unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
    gridSizeInFeet: settingsSelectors.getGridSizeInFeet(state),
    settingsModalForm: settingsSelectors.getSettingsModalForm(state),
  }),
  {
    loadSettings: settingsPersistenceActions.loadSettings,
    updateSetting: settingsPersistenceActions.updateSetting,
    close: settingsModalActions.hide,
    switchGridShow: settingsActions.switchGridShow,
    switchPrecision: settingsActions.switchPrecision,
    switchUnitOfMeasurement: settingsActions.switchUnitOfMeasurement,
    updateLastSetting: settingsActions.updateLastSetting,
  },
)(SettingsModal);
