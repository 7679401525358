import { colors, modal } from 'config/paletteConfig';
import { sketchConfig } from 'config/sketchConfig';

export const modalStyles = {
  maxWidth: '70%',

  position: 'absolute',
  minWidth: sketchConfig.sidebarWidth,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  backgroundColor: modal.base,
  boxShadow: `0 3px 6px 0 ${modal.shadow}`,

  h2: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: '12px 25px 12px 5px',
    backgroundColor: modal.header.bg,
    color: modal.header.color,
    fontSize: 18,
    textAlign: 'left',
    fontWeight: 600,
    textTransform: 'capitalize',

    span: {
      marginLeft: 20,
    },

    'button + button': {
      marginLeft: 5,
    },

    'button + span': {
      marginLeft: 5,
    },

    'span + button': {
      marginLeft: 'auto',
    },
  },
} as const;

export const modalWallResizeStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: 15,
  width: window.screen.width > 400 ? 'fit-content' : '85%',
  backgroundColor: colors.white,
  boxShadow: `0 3px 8px 2px ${modal.shadow}`,

  form: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',

    button: {
      width: '100%',
      marginTop: 15,
    },
  },

  h2: {
    marginTop: 0,
    marginRight: 24,
    color: colors.text,
    fontSize: 14,
    fontWeight: 600,

    button: {
      position: 'absolute',
      top: 12,
      right: 12,
    },
  },

  input: {
    boxSizing: 'border-box',
    width: 108,
    height: 36,
    marginRight: 5,
    border: '1px solid #A2A9BE',
    borderRadius: 2,
    padding: 5,
    color: colors.text,
    textAlign: 'center',
    fontSize: 12,

    '&::placeholder': { color: '#B3B8C5' },
  },

  label: {
    marginRight: 10,
    color: colors.text,
    fontSize: 14,
  },
} as const;

export const modalBackdropStyles = {
  position: 'fixed',
  zIndex: 1,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: modal.backdrop,
} as const;

export const buttonStyles = {
  padding: '10px 5px 12px',
  border: 0,
  borderRadius: 2,
  color: modal.button.color,
  backgroundColor: modal.button.base,
  fontSize: 14,
  boxShadow: `0 0px 0px 0 ${modal.button.base}`,
  transition: 'all 0.12s ease',

  '&:hover': {
    backgroundColor: modal.button.hovered,
  },

  '&:focus': {
    boxShadow: `0 1px 5px 0 ${modal.button.base}`,
  },
} as const;

export const headerButtonStyles = {
  display: 'block',
  width: 24,
  height: 24,
  border: 0,
  padding: 0,
  color: 'inherit',
  borderRadius: 2,
  backgroundColor: modal.headerButton.base,

  '&:hover, &:focus': {
    outline: 'none',
    backgroundColor: modal.headerButton.hovered,
  },

  '&:active': {
    backgroundColor: modal.headerButton.active,
  },

  svg: {
    width: 20,
    height: 20,
    margin: 2,
  },
} as const;
