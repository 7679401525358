import Immutable from 'immutable';
import {
  all, takeLatest, select, put,
} from 'redux-saga/effects';
import uuid from 'uuid/v4';

import { WallType } from 'types/wallType';
import { SelectableObjects } from 'types/selection';
import { isClosedFigure } from 'types/figure';
import { getFiguresWithWall } from 'helpers/model/figureWalls';
import { getFigurePoints } from 'helpers/model/figurePoints';
import { isPointToFigureCollision } from 'helpers/collision/figureCollision';
import { getArcPoint } from 'helpers/curve/getArcPoint';
import { actions as pointsActions } from 'ducks/model/points';
import { actions as wallsActions } from 'ducks/model/walls';
import { selectors as modelSelectors } from 'ducks/model/model';
import { selectors as editModeSelectors } from 'ducks/editMode';

const NAME = 'drawCurve';

const ADD_CURVE = `${NAME}/ADD_CURVE`;
export const CURVE_ADDED = `${NAME}/CURVE_ADDED`;

/**
 * Initial height for new arc (arc is circle-based)
 * from the center of "base" wall to arc
 */
const initialArcHeight = 100;

// Action Creators
export const actions = {
  addCurve: () => ({
    type: ADD_CURVE,
  }),
  curveAdded: () => ({
    type: CURVE_ADDED,
  }),
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doAddCurve() {
    const selectedObjects: Immutable.List<string> = editModeSelectors.getSelectedObjects(yield select());
    const wallId = selectedObjects.get(0)!;
    const selectableObjects: SelectableObjects = modelSelectors.getSelectableObjects(yield select());
    const { points, walls, figures } = selectableObjects;

    const wall = walls.get(wallId)!;
    if (wall.wallType === WallType.ARC) {
      return;
    }

    const p1 = points.get(wall.points[0])!;
    const p2 = points.get(wall.points[1])!;
    let arcPoint = getArcPoint(p1, p2, initialArcHeight);
    // check if arcPoint inside polygon
    // we try to add (by default) arcPoint outside of closed polygon (if it is closed)
    const figuresWithWall = getFiguresWithWall(figures, wallId);
    const figure = figures.get(figuresWithWall[0])!;
    if (isClosedFigure(figure)) {
      const figurePoints = getFigurePoints(walls, points, figure);
      if (isPointToFigureCollision(figurePoints, true, arcPoint)) {
        // choose symmetric point from other side of ellipse
        arcPoint = getArcPoint(p1, p2, -initialArcHeight);
      }
    }

    const pointId = uuid();

    yield put(pointsActions.add({
      pointId,
      ...arcPoint,
    }));

    yield put(wallsActions.update(wall.wallId, {
      wallType: WallType.ARC,
      points: [p1.pointId, p2.pointId, pointId],
    }));

    yield put(actions.curveAdded());
  }

  return function* saga() {
    yield all([
      takeLatest(ADD_CURVE, doAddCurve),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
