import { geometryConfig } from 'config/geometryConfig';
import { CoordinatePoint } from 'types/point';
import { distance } from 'helpers/geometry';

/**
 * If two points so close that they are collided
 * @param a first point
 * @param b second point
 * @returns true if collided
 */
export const isPointsCollision = (a: CoordinatePoint, b: CoordinatePoint, zoomInPercent: number): boolean =>
  // distance(a, b) <= (touchDevice ? geometryConfig.thickness * 5 : geometryConfig.thickness);
  // geometryConfig.thickness * 5 is 50 or 2.5 grid squares (1.25 squares on either side of the cursor)
  // 50 is also the original size of the AutoFinishButton, and this doesn't work- it needs to be smaller
  // auto finish and a collision cannot occur at the same point
  distance(a, b) <= geometryConfig.getExteriorLineWidth(zoomInPercent); // eslint-disable-line implicit-arrow-linebreak
