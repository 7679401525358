/**
 * Here is mode for sketch-editor
 * for example when user press select button, editor move to
 * SELECTION state, i.e. user than can select something for edit
 * or 'Edit' button switch editor to
 * READY_TO_DRAW state means that editor ready to user input for draw new sketch
 */
import Immutable from 'immutable';
import { createAction, getType, ActionType } from 'typesafe-actions';

import { CoordinatePoint } from 'types/point';
import { Corner } from 'types/corner';
import { RootState } from 'reducers/rootReducer';
import {
  takeLatest, all, put
} from 'redux-saga/effects';

export interface EditModeState {
  readonly editMode: EditMode;
  readonly selectedObjects: Immutable.List<string>; // List of selected objects (for move or remove or change label)
  readonly isInMultiselectMode: boolean; // Is in multiselect mode -> shift key is being held
  readonly isInBoxSelectMode: boolean; // Is in box select mode -> alt key is being held
  readonly isAdjustingDrawPoint: boolean; // Is adjusting draw point position with drag handle (touch screens)
  readonly currentSelectedGroupId: string | undefined; // keeps track of a group on select in order to update
}

enum EditMode {
  loading = 'loading', // If app is loading model from BE
  select = 'select', // User can select sketch to move, zoom and so on. edits or changes are not permitted.
  boxSelect = 'boxSelect', // User can resize a selection box on the canvas
  edit = 'edit', // User can draw new polygon/label or change polygon/labels
  splitting = 'splitting', // User choose mode for split wall. User can click on any wall and split it
  selected = 'selected', // User select some objects and they are ready to move that objects to new place
  labelEdit = 'labelEdit', // User change text for label
  moving = 'moving', // User select some objects and is moving those objects to new place
  resizing = 'resizing', // User select some objects and is resizing those objects
  rotating = 'rotating', // User select some objects and is rotating those objects
  drawing = 'drawing', // User draw new figure
}

// Initial State
const initialState: EditModeState = {
  editMode: EditMode.loading,
  selectedObjects: Immutable.List<string>(),
  isInMultiselectMode: false,
  isInBoxSelectMode: false,
  isAdjustingDrawPoint: false,
  currentSelectedGroupId: undefined,
};

// Action Creators
export const actions = {
  switchToLoading: createAction('editMode/switchToLoading')<void>(),

  switchToSelection: createAction('editMode/switchToSelection')<void>(),

  switchToBoxSelectEditMode: createAction('editMode/switchToBoxSelectEditMode')<void>(),

  switchToEdit: createAction('editMode/switchToEdit')<void>(),

  switchToSplit: createAction('editMode/switchToSplit')<void>(),

  switchToSelected: createAction('editMode/switchToSelected')<string[]>(),

  switchToMoving: createAction('editMode/switchToMoving')<CoordinatePoint>(),

  switchToResizing: createAction(
    'editMode/switchToResizing',
    (point: CoordinatePoint, corner: Corner) => ({ point, corner }),
  )(),

  switchToRotating: createAction(
    'editMode/switchToRotating',
    (center: CoordinatePoint, point: CoordinatePoint) => ({ center, point }),
  )(),

  switchToDrawing: createAction('editMode/switchToDrawing')<void>(),

  switchToLabelEdit: createAction('editMode/switchToLabelEdit')<void>(),

  startMultiselectMode: createAction('editMode/startMultiselectMode')<void>(),

  startBoxSelectMode: createAction('editMode/startBoxSelectMode')<void>(),

  endMultiselectMode: createAction('editMode/endMultiselectMode')<void>(),

  endBoxSelectMode: createAction('editMode/endBoxSelectMode')<void>(),

  startAdjustingDrawPoint: createAction('editMode/startAdjustingDrawPoint')<void>(),

  endAdjustingDrawPoint: createAction('editMode/endAdjustingDrawPoint')<void>(),

  selectObjects: createAction('editMode/selectObjects')<string[]>(),

  setCurrentSelectedGroupId: createAction('editMode/setCurrentSelectedGroupId')<string | undefined>(),

  clearSelection: createAction('editMode/clearSelection')<void>(),

  clearSelectionFromPrint: createAction('editMode/clearSelectionFromPrint')<void>(),

  clearSelectionAction: createAction('editMode/clearSelectionAction')<void>(),

  clearSelectionSuccess: createAction('editMode/clearSelectionSuccess')<void>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getEditModeState = (rootState: RootState): EditModeState => rootState.editMode;

const getSelectedObjects = (
  rootState: RootState,
): Immutable.List<string> => getEditModeState(rootState).selectedObjects;

const getEditMode = (
  rootState: RootState,
): EditMode => getEditModeState(rootState).editMode;

const isLoading = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.loading;

const isSelectMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.select;

const isEditMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.edit;

const isSplittingMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.splitting;

const isSelectedMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.selected;

const isBoxSelectEditMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.boxSelect;

const isLabelEditMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.labelEdit;

const isMovingMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.moving;

const isResizingMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.resizing;

const isRotatingMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.rotating;

const isDrawingMode = (
  rootState: RootState,
): boolean => getEditMode(rootState) === EditMode.drawing;

const isMultiselectMode = (
  rootState: RootState,
): boolean => getEditModeState(rootState).isInMultiselectMode;

const isBoxSelectMode = (
  rootState: RootState,
): boolean => getEditModeState(rootState).isInBoxSelectMode;

const isAdjustingDrawPoint = (
  rootState: RootState,
): boolean => getEditModeState(rootState).isAdjustingDrawPoint;

const hasMultiselected = (rootState: RootState): boolean => getSelectedObjects(rootState).size > 1;

const getCurrentSelectedGroupId = (rootState: RootState): string | undefined => getEditModeState(rootState).currentSelectedGroupId;

export const selectors = {
  getEditMode,
  isLoading,
  isSelectMode,
  isEditMode,
  isSplittingMode,
  isSelectedMode,
  isBoxSelectEditMode,
  isLabelEditMode,
  getSelectedObjects,
  isMovingMode,
  isResizingMode,
  isRotatingMode,
  isDrawingMode,
  isMultiselectMode,
  isBoxSelectMode,
  isAdjustingDrawPoint,
  hasMultiselected,
  getCurrentSelectedGroupId
};

// Reducers
const switchToLoadingReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.loading,
  selectedObjects: Immutable.List<string>(),
});

const switchToSelectReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.select,
  selectedObjects: Immutable.List<string>(),
});

const switchToBoxSelectEditModeReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.boxSelect,
  selectedObjects: Immutable.List<string>(),
});

const switchToEditReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.edit,
  selectedObjects: Immutable.List<string>(),
});

const switchToSplitReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.splitting,
  selectedObjects: Immutable.List<string>(),
});

const switchToSelectedReducer = (state: EditModeState, objectIds: string[]): EditModeState => ({
  ...state,
  editMode: EditMode.selected,
  selectedObjects: Immutable.List<string>(objectIds),
});

const switchToLabelEditReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.labelEdit,
});

const switchToMovingReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.moving,
});

const switchToResizingReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.resizing,
});

const switchToRotatingReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.rotating,
});

const switchToDrawingReducer = (state: EditModeState): EditModeState => ({
  ...state,
  editMode: EditMode.drawing,
});

const startMultiselectModeReducer = (state: EditModeState): EditModeState => ({
  ...state,
  isInMultiselectMode: true,
});

const endMultiselectModeReducer = (state: EditModeState): EditModeState => ({
  ...state,
  isInMultiselectMode: false,
});

const startBoxSelectModeReducer = (state: EditModeState): EditModeState => ({
  ...state,
  isInBoxSelectMode: true,
});

const endBoxSelectModeReducer = (state: EditModeState): EditModeState => ({
  ...state,
  isInBoxSelectMode: false,
});

const startAdjustingDrawPointReducer = (state: EditModeState): EditModeState => ({
  ...state,
  isAdjustingDrawPoint: true,
});

const endAdjustingDrawPointReducer = (state: EditModeState): EditModeState => ({
  ...state,
  isAdjustingDrawPoint: false,
});

const toggleSelectedObjects = (state: EditModeState, objectIds: string[]): Immutable.List<string> => {
  const { selectedObjects } = state;

  const alreadySelected = selectedObjects.filter(objectId => objectIds.includes(objectId)).toSet();

  return selectedObjects
    .filter(objectId => !alreadySelected.has(objectId))
    .merge(
      objectIds.filter(objectId => !alreadySelected.has(objectId)),
    );
};

const selectObjectsReducer = (state: EditModeState, objectIds: string[]): EditModeState => {
  const { isInMultiselectMode } = state;

  // in multiselect mode we're toggling the selection status of the objects
  const newSelectedObjects = isInMultiselectMode
    ? toggleSelectedObjects(state, objectIds) : Immutable.List<string>(objectIds);

  return {
    ...state,
    selectedObjects: newSelectedObjects,
    editMode: newSelectedObjects.size > 0 ? EditMode.selected : EditMode.select,
  };
};

const setGroupIdReducer = (state: EditModeState, groupId: string | undefined): EditModeState => ({
  ...state,
  currentSelectedGroupId: groupId,
});

const clearSelectionReducer = (state: EditModeState): EditModeState => ({
  ...state,
  selectedObjects: Immutable.List<string>(),
});

export const reducer = (state: EditModeState = initialState, action: Actions): EditModeState => {
  switch (action.type) {
    case getType(actions.switchToLoading):
      return switchToLoadingReducer(state);

    case getType(actions.switchToSelection):
      return switchToSelectReducer(state);

    case getType(actions.switchToBoxSelectEditMode):
      return switchToBoxSelectEditModeReducer(state);

    case getType(actions.switchToEdit):
      return switchToEditReducer(state);

    case getType(actions.switchToSplit):
      return switchToSplitReducer(state);

    case getType(actions.switchToSelected):
      return switchToSelectedReducer(state, action.payload);

    case getType(actions.switchToMoving):
      return switchToMovingReducer(state);

    case getType(actions.switchToResizing):
      return switchToResizingReducer(state);

    case getType(actions.switchToRotating):
      return switchToRotatingReducer(state);

    case getType(actions.switchToDrawing):
      return switchToDrawingReducer(state);

    case getType(actions.switchToLabelEdit):
      return switchToLabelEditReducer(state);

    case getType(actions.startMultiselectMode):
      return startMultiselectModeReducer(state);

    case getType(actions.endMultiselectMode):
      return endMultiselectModeReducer(state);

    case getType(actions.startBoxSelectMode):
      return startBoxSelectModeReducer(state);

    case getType(actions.endBoxSelectMode):
      return endBoxSelectModeReducer(state);

    case getType(actions.startAdjustingDrawPoint):
      return startAdjustingDrawPointReducer(state);

    case getType(actions.endAdjustingDrawPoint):
      return endAdjustingDrawPointReducer(state);

    case getType(actions.selectObjects):
      return selectObjectsReducer(state, action.payload);

    case getType(actions.setCurrentSelectedGroupId):
      return setGroupIdReducer(state, action.payload);

    case getType(actions.clearSelection):
      return clearSelectionReducer(state);

    default:
      return state;
  }
};

// sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doClearSelection() {
    yield put(actions.clearSelectionAction());
    yield put(actions.clearSelectionSuccess());
  }

  return function* saga() {
    yield all([
      takeLatest(actions.clearSelection, doClearSelection),
      takeLatest(actions.clearSelectionFromPrint, doClearSelection),
    ]);
  };
};
