/** @jsx jsx */

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import posed from 'react-pose';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/react';
import { RootState } from 'reducers/rootReducer';
import { messages } from 'config/messages';

import { selectors as sketchPersistenceSelectors, actions as sketchPersistenceActions } from 'ducks/persistence/sketchPersistence';
import { selectors as saveSketchModalSelectors, actions as saveSketchModalActions } from 'ducks/modal/saveSketchModal';
import { ReactComponent as IconClose } from 'assets/icons/x.svg';
import { positionedModal, fade } from 'components/animations';
import { ModalWallResize, ButtonBig, ModalBackdrop } from 'components/modal/Modal';

import { sidebarItemButtonStyles } from 'components/sidebar/styles';

const Backdrop = styled(ModalBackdrop)({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
});

const saveSketchInputStyles = css`
  width: 100%;
  pointer-events: none;
  margin-bottom: 12px;
`;

const saveSketchFormStyles = css`
  width: 100%;
  display: flex;
  flexDirection: column;

  > div {
    margin-right: auto;
    padding-top: 12px;
  }

  > label {
    width: 100%;
    > input {
      width: 100%;
    }
  }
`;

const BackdropAnimated = posed(Backdrop)(fade);
const ModalAnimated = posed(ModalWallResize)(positionedModal);
const CloseButton = styled.button(sidebarItemButtonStyles);

interface StateProps {
  readonly isShowingSaveSketchModal: boolean;
  readonly currentSketch: any;
  readonly isCreated: boolean;
}
interface ActionProps {
  readonly close: () => void;
  readonly saveSketchToApi: (payload?: any) => void;
}

type Props = StateProps & ActionProps;

interface State {
  readonly sketchName: string;
  readonly address: string;
  readonly orderId: string;
}

class SaveSketchModal extends Component<Props, State> {
  // eslint-disable-next-line react/sort-comp
  private readonly focusRef: React.RefObject<HTMLInputElement>;

  public constructor(props: Props) {
    super(props);
    this.focusRef = createRef();
    const { currentSketch } = this.props;
    this.state = {
      sketchName: currentSketch?.sketch_name ? currentSketch.sketch_name : '',
      address: currentSketch?.property_address ? currentSketch.property_address : '',
      orderId: currentSketch?.order_id ? currentSketch.order_id : ''
    };
  }

  public componentDidMount(): void {
    this.focusRef.current!.focus();
  }


  private save = (event?: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>): void => {
    const { sketchName, address, orderId } = this.state;
    const { close, saveSketchToApi } = this.props;

    if (event) {
      event.preventDefault();
    }

    saveSketchToApi({ sketchName, propertyAddress: address, orderId });
    close();
  };

  private close = (): void => {
    const { close } = this.props;

    close();
  };

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      this.save();
    }
    if (event.key === 'Escape') {
      this.close();
    }
  };

  public handleUserInput(event: React.ChangeEvent<HTMLInputElement>, type: string): void {
    event.preventDefault();
    const { sketchName, address, orderId } = this.state;
    const { value } = event.target;
    if (type === 'sketchName' && sketchName !== value) {
      this.setState({ sketchName: value });
    } else if (type === 'address' && address !== value) {
      this.setState({ address: value });
    } else if (type === 'orderId' && orderId !== value) {
      this.setState({ orderId: value });
    }
  }

  public render = (): JSX.Element | null => {
    const { isCreated } = this.props;
    const { sketchName, address, orderId } = this.state;

    const cancelEvent = (event: React.PointerEvent | React.WheelEvent | React.DragEvent): void => {
      event.stopPropagation();
    };

    return (
      <BackdropAnimated
        onPointerDown={cancelEvent}
        onPointerMove={cancelEvent}
        onPointerUp={cancelEvent}
        onPointerLeave={cancelEvent}
        onWheel={cancelEvent}
        onDragOver={cancelEvent}
        onDrop={cancelEvent}
        pose="enter"
        initialPose="exit"
      >
        <ModalAnimated
          pose="enter"
          initialPose="exit"
          position={window.screen.width > 400 ? { top: '15rem', left: '90%' } : { top: '15rem', left: '1rem' }}
          style={window.screen.width > 400 ? { width: '30%' } : { width: '85%' }}
        >
          <h2>
            <CloseButton onClick={this.close} title={messages.closeModal} style={{ cursor: 'pointer' }}>
              <IconClose />
            </CloseButton>
            <span>{isCreated ? 'Save Sketch' : 'Create Sketch'}</span>
          </h2>
          <form onSubmit={this.save} css={saveSketchFormStyles}>
            <div>
              Sketch Name
            </div>
            <label htmlFor="Sketch Name">
              <input
                css={saveSketchInputStyles}
                id="Sketch Name"
                ref={this.focusRef}
                type="text"
                value={sketchName}
                placeholder="Sketch Name"
                onChange={(e: any) => this.handleUserInput(e, 'sketchName')}
                onKeyDown={this.handleKeyDown}
              />
            </label>
            <div>
              Property Address
            </div>
            <label htmlFor="Property Address">
              <input
                css={saveSketchInputStyles}
                id="Property Address"
                type="text"
                value={address}
                placeholder="Property Address"
                onChange={(e: any) => this.handleUserInput(e, 'address')}
                onKeyDown={this.handleKeyDown}
              />
            </label>
            <div>
              Order Id
            </div>
            <label htmlFor="Order Id">
              <input
                css={saveSketchInputStyles}
                id="Order Id"
                type="text"
                value={orderId}
                placeholder="Order Id"
                onChange={(e: any) => this.handleUserInput(e, 'orderId')}
                onKeyDown={this.handleKeyDown}
              />
            </label>
            <div style={{ marginLeft: 'auto', marginRight: '11px' }}>
              {/* <ButtonBig type="submit">{isCreated ? `Save Sketch` : `Create Sketch`}</ButtonBig> */}
              <ButtonBig type="submit">Save Sketch</ButtonBig>
            </div>
          </form>
        </ModalAnimated>
      </BackdropAnimated>
    );
  };
}

export default connect(
  (state: RootState): StateProps => ({
    isShowingSaveSketchModal: saveSketchModalSelectors.isShowing(state),
    currentSketch: sketchPersistenceSelectors.getSketch(state),
    isCreated: sketchPersistenceSelectors.isCreated(state),
  }),
  {
    close: saveSketchModalActions.hide,
    saveSketchToApi: sketchPersistenceActions.saveSketch,
  },
)(SaveSketchModal);
