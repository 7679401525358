/** @jsx jsx */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';
import uuid from 'uuid/v4';

import { RootState } from 'reducers/rootReducer';
import { Dimensions } from 'types/dimensions';
import { CoordinatePoint, Point } from 'types/point';
import { PositionedLabel } from 'types/positionedLabel';
import { PositioningInfo } from 'types/positioningInfo';
import { SizingInfo } from 'types/sizingInfo';
import { determinePositioning } from 'helpers/positioning';
import { selectors as positionedLabelsSelectors, actions as positionedLabelActions } from 'ducks/model/positionedLabels';
import { selectors as pointsSelectors } from 'ducks/model/points';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { actions as moveObjectsActions, selectors as moveObjectsSelectors } from 'ducks/moveObjects';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { selectors as viewportSelectors, MousePointToSVGPointFunction } from 'ducks/viewport';
import { selectors as sketchPersistenceSelectors } from 'ducks/persistence/sketchPersistence';
import SelectionBorder from 'components/sketch/SelectionBorder/SelectionBorder';
import Rotate from 'components/sketch/Rotate/Rotate';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import { isAppleTouchDevice } from 'helpers/browserDetect';
import PositionedLabelText from './PositionedLabelText';
import PositionedLabelTextEdit from './PositionedLabelTextEdit';


const style = css`
  fill: transparent;
  cursor: pointer;
  user-select: none;
`;

const styleInnerRect = css`
  fill: transparent;
`;

interface InputProps {
  readonly positionedLabelId: string;
}

interface StateProps {
  readonly positionedLabel: PositionedLabel;
  readonly position: Point;
  readonly isSelectMode: boolean;
  readonly isSelectedMode: boolean;
  readonly isMovingMode: boolean;
  readonly isResizingMode: boolean;
  readonly isLabelEditMode: boolean;
  readonly hasObjectMoved: boolean;
  readonly selectedObjects: Immutable.List<string>;
  readonly movedPoints: Immutable.List<Point>;
  readonly objectSizes: Immutable.Map<string, SizingInfo>;
  readonly zoomInPercent: number;
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
  readonly isApplyingModel: boolean;
}

interface ActionProps {
  readonly resize: (labelId: string, size: Dimensions, fontScale: number) => void;
  readonly selectObjects: typeof editModeActions.selectObjects;
  readonly switchToLabelEdit: typeof editModeActions.switchToLabelEdit;
  readonly startMove: typeof editModeActions.switchToMoving;
  readonly endMove: typeof moveObjectsActions.endMove;
}

type Props = InputProps & StateProps & ActionProps;

class PositionedLabelComponent extends Component<Props> {
  // eslint-disable-next-line react/sort-comp
  private readonly labelRef: React.RefObject<SVGTextElement>;

  private readonly positionedLabelUuid: string;

  private lastTouchDate?: number;

  public constructor(props: Props) {
    super(props);
    this.labelRef = React.createRef();
    this.positionedLabelUuid = uuid();
    this.lastTouchDate = undefined;
  }

  private updateTextSize = (): void => {
    const { resize, positionedLabelId, positionedLabel, isApplyingModel } = this.props;
    if (!this.labelRef.current || isApplyingModel) return;
    const { width, height } = this.labelRef.current!.getBBox();
    resize(positionedLabelId, { width, height }, positionedLabel!.fontScale!);
  };

  public componentDidMount = (): void => this.updateTextSize();

  public componentDidUpdate = ({ positionedLabel: prevPositionedLabel, zoomInPercent: prevZoomInPercent }: Props): void => {
    const { positionedLabel, zoomInPercent } = this.props;
    if (
      prevPositionedLabel &&
      prevPositionedLabel.labelId &&
      positionedLabel &&
      positionedLabel.labelId &&
      positionedLabel.labelId !== prevPositionedLabel.labelId
    ) {
      this.updateTextSize();
    }

    if (zoomInPercent !== prevZoomInPercent) {
      this.updateTextSize();
    }
  };

  private handleTouchStart = (event: React.TouchEvent<SVGGElement>): void => {
    // Unable to preventDefault inside passive event listener due to target being treated as passive. See https://www.chromestatus.com/feature/5093566007214080
    // event.preventDefault();
    const {
      isSelectMode, isSelectedMode, getMousePointToSvgPoint
    } = this.props;

    // if this is not a single touch
    if (event.touches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.touches[0].clientX, clientY: event.touches[0].clientY });

    // if we don't have a relative point
    if (!point) {
      return;
    }

    // if this is an apple touch device (iOS)
    // set this on touch and reset on touch move, pan or zoom
    if (isAppleTouchDevice) {
      this.lastTouchDate = new Date().getTime();
    }

    this.handlePointerDown(point);
    if (isSelectMode || isSelectedMode) {
      event.stopPropagation();
    }
  }

  private handleMouseDown = (event: React.MouseEvent<SVGGElement>): void => {
    event.preventDefault();
    const {
      isSelectMode, isSelectedMode, getMousePointToSvgPoint
    } = this.props;

    const point = getMousePointToSvgPoint({ clientX: event.clientX, clientY: event.clientY });

    // if this is not the left button, or we don't have a relative point
    if (event.button !== 0 || !point) {
      return;
    }

    this.handlePointerDown(point);
    if (isSelectMode || isSelectedMode) {
      event.stopPropagation();
    }
  }

  private handlePointerDown = (point: CoordinatePoint): void => {
    const {
      isSelectMode, isSelectedMode, selectObjects, startMove, positionedLabelId, selectedObjects
    } = this.props;

    if (isSelectMode || isSelectedMode) {
      const selected = !!(selectedObjects && selectedObjects.includes(positionedLabelId));
      if (!selected) {
        selectObjects([positionedLabelId]);
      }
      startMove(point);
    }
  };

  private handleTouchEnd = (event: React.TouchEvent<SVGGElement>): void => {
    // this stops the context menu from showing
    // event.preventDefault();
    const {
      hasObjectMoved, isMovingMode, getMousePointToSvgPoint
    } = this.props;

    // if this is not a single touch
    // note that on touch end we will use event.changedTouches instead of
    // event.touches- the later is not available here
    if (event.changedTouches.length !== 1) {
      return;
    }

    const point = getMousePointToSvgPoint({ clientX: event.changedTouches[0].clientX, clientY: event.changedTouches[0].clientY });

    // if we don't have a relative point
    if (!point) {
      return;
    }

    // if now is 1.5 seconds after lastTouchDate then show the context menu and bail
    if (this.lastTouchDate && new Date().getTime() > (this.lastTouchDate + 1000)) {
      this.lastTouchDate = undefined;

      event.target.dispatchEvent.call(event.target, new MouseEvent('contextmenu', {
        bubbles: true,
        clientX: event.changedTouches[0].clientX,
        clientY: event.changedTouches[0].clientY
      }));

      event.stopPropagation();
      return;
    }

    this.handlePointerUp(point);
    if (isMovingMode && !hasObjectMoved) {
      event.stopPropagation();
    }
  }

  private handleMouseUp = (event: React.MouseEvent<SVGGElement>): void => {
    event.preventDefault();
    const {
      hasObjectMoved, isMovingMode, getMousePointToSvgPoint
    } = this.props;

    const point = getMousePointToSvgPoint({ clientX: event.clientX, clientY: event.clientY });
    // if this is not the left button, or we don't have a point
    if (event.button !== 0 || !point) {
      return;
    }

    this.handlePointerUp(point);
    if (isMovingMode && !hasObjectMoved) {
      event.stopPropagation();
    }
  }

  private handlePointerUp = (point: CoordinatePoint): void => {
    const {
      hasObjectMoved, isMovingMode, endMove
    } = this.props;

    if (isMovingMode && !hasObjectMoved) {
      endMove(point);
    }
  };

  private handleDoubleClick = (mouseEvent: React.MouseEvent<SVGGElement>): void => {
    const { selectObjects, switchToLabelEdit, positionedLabelId } = this.props;

    selectObjects([positionedLabelId]);
    switchToLabelEdit();
    mouseEvent.stopPropagation();
  };

  private finishEditing = (): void => {
    const { selectObjects, positionedLabelId } = this.props;
    selectObjects([positionedLabelId]);
  };

  private positionLabel = (positionedLabelId: string): PositioningInfo => {
    const { positionedLabel, position, movedPoints, objectSizes, isMovingMode, isResizingMode } = this.props;

    return determinePositioning(positionedLabelId, positionedLabel!, position!, movedPoints!, objectSizes!, isMovingMode!, isResizingMode!);
  };

  public render(): JSX.Element {
    const { positionedLabelId, selectedObjects, isSelectMode, isSelectedMode, isLabelEditMode, isApplyingModel } = this.props;

    // if the model is still being applied, avoid returning component
    if (isApplyingModel) return <></>;

    const selected = !!(selectedObjects && selectedObjects.includes(positionedLabelId));
    const { position = { x: 0, y: 0 }, size, rotation, fontScale } = this.positionLabel(positionedLabelId);

    if (selected && isLabelEditMode) {
      return (
        <PositionedLabelTextEdit
          positionedLabelId={positionedLabelId}
          position={position}
          size={size}
          fontScale={fontScale!}
          onEditFinished={this.finishEditing}
        />
      );
    }

    return (
      <React.Fragment>
        {!isApplyingModel && this.labelRef.current && <ContextMenu type={`positionedLabel${this.positionedLabelUuid}`} parentRef={this.labelRef} />}
        <Rotate degrees={rotation} position={position}>
          {selected && (
            <SelectionBorder x={position.x - size.width / 2 - 10} y={position.y - size.height / 2 - 10} width={size.width + 20} height={size.height + 20} />
          )}
          <g
            onTouchStart={this.handleTouchStart}
            onMouseDown={this.handleMouseDown}
            onTouchEnd={this.handleTouchEnd}
            onMouseUp={this.handleMouseUp}
            onDoubleClick={this.handleDoubleClick}
            css={[(isSelectMode || isSelectedMode) && style]}
          >
            <rect x={position.x - size.width / 2} y={position.y - size.height / 2} width={size.width} height={size.height} css={styleInnerRect} />
            <PositionedLabelText positionedLabelId={positionedLabelId} labelRef={this.labelRef} position={position} size={size} fontScale={fontScale} />
          </g>
        </Rotate>
      </React.Fragment>
    );
  }
}

export default connect(
  (state: RootState, { positionedLabelId }: InputProps): StateProps => {
    const positionedLabel = positionedLabelsSelectors.getPositionedLabelById(state, positionedLabelId);
    return {
      positionedLabel,
      position: pointsSelectors.getPointById(state, positionedLabel!.pointId),
      isSelectMode: editModeSelectors.isSelectMode(state),
      isSelectedMode: editModeSelectors.isSelectedMode(state),
      isLabelEditMode: editModeSelectors.isLabelEditMode(state),
      isMovingMode: editModeSelectors.isMovingMode(state),
      isResizingMode: editModeSelectors.isResizingMode(state),
      hasObjectMoved: moveObjectsSelectors.hasMoved(state),
      selectedObjects: editModeSelectors.getSelectedObjects(state),
      movedPoints: selectionSelectors.getMovedSelectedPoints(state),
      objectSizes: selectionSelectors.getResizedSelectedObjectsSizes(state),
      zoomInPercent: viewportSelectors.getZoomInPercent(state),
      getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
      isApplyingModel: sketchPersistenceSelectors.isApplyingModel(state),
    };
  },
  {
    selectObjects: editModeActions.selectObjects,
    switchToLabelEdit: editModeActions.switchToLabelEdit,
    resize: positionedLabelActions.resize,
    startMove: editModeActions.switchToMoving,
    endMove: moveObjectsActions.endMove,
  },
)(PositionedLabelComponent);
