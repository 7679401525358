import Immutable from 'immutable';

import { messages } from 'config/messages';
import { AreaType } from 'types/areaType';
import { SelectableObjects } from 'types/selection';
import { formatAreaLabel, formatSquareFeetArea } from 'helpers/pixelsToDistance';
import * as figurePoints from 'helpers/model/figurePoints';
import { calculateTotalArea } from 'helpers/polygonArea';
import { getGlaRowData, getFigureAreaType } from 'helpers/label/areaTypeLabel';
import { Precision } from 'types/precision';

// get complete area breakdown table with all strings
export const getStringTable = (
  selectableObjects: SelectableObjects,
  areaTypes: Immutable.Map<string, AreaType>,
  precision: Precision,
  unitOfMeasure: string,
  gridSizeInFeet: number
): string[][] => {
  const { figures, walls, points } = selectableObjects;

  const table: string[][] = [];

  // header
  table.push([
    messages.areaBreakdownName,
    messages.areaBreakdownCalculation,
    messages.areaBreakdownInclude,
  ]);

  // table
  figures.valueSeq().forEach((figure) => {
    const fPoints = figurePoints.getFigurePointsForDraw(walls, points, figure);
    const areaTypeId = getFigureAreaType(figure);
    const areaType = areaTypeId ? areaTypes.get(areaTypeId) : undefined;

    const rowData = getGlaRowData({ figure, walls, figurePoints: fPoints, areaType, precision, unitOfMeasure, gridSizeInFeet });
    if (rowData) {
      table.push([
        rowData.areaTypeText,
        rowData.areaSizeText,
        rowData.isGlaText,
      ]);
    }
  });

  // footer
  const totalArea = formatAreaLabel(unitOfMeasure, precision, gridSizeInFeet)(calculateTotalArea(figures, points, walls));

  table.push([
    messages.areaBreakdownTotal,
    formatSquareFeetArea(totalArea),
    '',
  ]);

  return table;
};
