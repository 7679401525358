/** @jsx jsx */
import React, { PureComponent, RefObject } from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import MultiBackend, { Backends, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import Immutable from 'immutable';
import { messages, cannotGoBackDialog } from 'config/messages';

import { RootState } from 'reducers/rootReducer';
import { printConfig } from 'config/printConfig';
import { Wall, CircleWall } from 'types/wall';
import { CoordinatePoint } from 'types/point';
import { LogLevel } from 'types/log';
import { Page } from 'types/page';
import { Direction } from 'types/direction';
import { Dimensions } from 'types/dimensions';

import { Keys, arrowDeltas } from 'helpers/keys';
import { isIE11 } from 'helpers/browserDetect';
import log from 'helpers/log';
import { coordinatesByAngle } from 'helpers/geometry';
import { formatSketchObjects } from 'helpers/save/formatSketchObjects';
import { svgAsPngUri } from 'helpers/save/svgToPng';
import { parseString } from 'helpers/draw/parseDrawInput';
import { feetToPixels, metersToPixels } from 'helpers/pixelsToDistance';

import { actions as networkPersistenceActions } from 'ducks/persistence/networkPersistence';
import { getUserIsValid, isDesktopApplication, isMobileApplication, login, logout } from 'effects/auth';
import { selectors as pagesSelectors, } from 'ducks/model/pages';
import { actions as numpadModalActions, selectors as numpadModalSelectors } from 'ducks/modal/numpadModal';
import { selectors as areaTypeModalSelectors } from 'ducks/modal/areaTypeModal';
import { selectors as subAreaModalSelectors } from 'ducks/modal/subAreaModal';
import { selectors as glaBreakdownModalSelectors } from 'ducks/modal/glaBreakdownModal';
import { selectors as pageGroupsModalSelectors } from 'ducks/modal/pageGroupsModal';
import { selectors as printPreviewModalSelectors } from 'ducks/modal/printPreviewModal';
import { selectors as resizeSketchModalSelectors } from 'ducks/modal/resizeSketchModal';
import { selectors as saveSketchModalSelectors } from 'ducks/modal/saveSketchModal';
import { selectors as sketchSearchModalSelectors } from 'ducks/modal/sketchSearchModal';
import { selectors as settingsModalSelectors } from 'ducks/modal/settingsModal';
import { selectors as settingsSelectors } from 'ducks/settings';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { selectors as wallSelectors } from 'ducks/model/walls';
import { selectors as sketchPersistenceSelectors } from 'ducks/persistence/sketchPersistence';
import { actions as sidebarActions, selectors as sidebarSelectors } from 'ducks/sidebar/sidebar';
import { actions as editModeActions, selectors as editModeSelectors } from 'ducks/editMode';
import { actions as viewportActions, selectors as viewportSelectors, ZoomByIncrementPayload } from 'ducks/viewport';
import { selectors as drawsSelectors, actions as drawActions } from 'ducks/draw/draw';
import { actions as drawCurveActions } from 'ducks/draw/drawCurve';
import { selectors as drawWithKeypadSelectors, actions as drawWithKeypadActions } from 'ducks/draw/drawWithKeypad';
import { actions as moveObjectsActions } from 'ducks/moveObjects';
import { actions as removeObjects } from 'ducks/remove/removeObjects';
import { actions as historyActions, selectors as historySelectors } from 'ducks/history/history';
import { actions as messageContainerActions, onSubmitEventType } from 'ducks/messageContainer';
import { actions as inspectionActions } from 'ducks/inspection';
import { actions as copyActions } from 'ducks/copy/copy';
import { actions as areaTypePersistenceActions } from 'ducks/persistence/areaTypePersistence';
import { getDisplayPose } from 'components/animations';
import SketchArea from 'components/sketch/SketchArea/SketchArea';
import AppVersion from 'components/AppVersion/AppVersion';
import ToolbarLeft from 'components/Toolbar/ToolbarLeft';
import LabelSidebar from 'components/sidebar/LabelSidebar';
import SymbolSidebar from 'components/sidebar/SymbolSidebar';
import PolygonSidebar from 'components/sidebar/PolygonSidebar';
import ToolbarTop from 'components/Toolbar/ToolbarTop';
import AreaTypeModal from 'components/modal/AreaTypeModal';
import SubAreaModal from 'components/modal/SubAreaModal';
import GlaBreakdownModal from 'components/modal/GlaBreakdownModal';
import PageGroupsModal from 'components/modal/PageGroupsModal';
import PrintPreviewModal from 'components/modal/PrintPreviewModal';
import ResizeSketchModal from 'components/modal/ResizeSketchModal';
import RescaleGridModal from 'components/modal/RescaleGridModal';
import KnownLengthPromptModal from 'components/modal/KnownLengthPromptModal';
import SettingsModal from 'components/modal/SettingsModal';
import SketchSearchModal from 'components/modal/SketchSearchModal';
import NumpadMenuModal from 'components/modal/NumpadMenuModal';
import PrintTableView from 'components/sketch/PrintTable/PrintTableView';
import MessageContainer from 'components/message/MessageContainer';
import Preview from 'components/elements/DnDPreview';
import { ThemeProvider } from '@mui/styles';
import AciCoreTheme from 'figma/themes/acitheme';
import SaveSketchModal from 'components/modal/SaveSketchModal';
import { getCompanies } from 'effects/api';
import CompanyList from 'components/CompanyList';
import { ReactComponent as MobileSketchIcon } from 'assets/icons/mobile-sketch.svg';
import { ReactComponent as MobileOrdersIcon } from 'assets/icons/mobile-orders.svg';

// https://github.com/LouisBrunner/dnd-multi-backend/blob/master/packages/react-dnd-multi-backend/src/HTML5toTouch.js
const HTML5toTouch: Backends = {
  backends: [
    {
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

const styles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: none;
`;

const Main = styled.main`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const mobileTabDivStyle = css`
  position: absolute;
  height: 70px;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 2;
  text-align: center;
`;

const mobileTabBtnDivStyle = css`
  display: inline-block;
  width: 50%;
`;

const mobileIconStyle = css`
  vertical-align: middle;
  margin: 0 0 2px 0;
`;

const mobileTabSpanStyle = css`
  line-height: 70px;
  font-size: 13px;
  margin: 0 8px;
`;

interface StateProps {
  readonly unitOfMeasure: string;
  readonly currentGridSize: number;
  readonly startPoint: CoordinatePoint;
  readonly endPoint: CoordinatePoint;
  readonly previousStartPoint: CoordinatePoint;
  readonly viewCenterPoint: CoordinatePoint;
  readonly isDrawing: boolean;
  readonly isPanning: boolean;
  readonly isSelectedMode: boolean;
  readonly isShowingNumpadModal: boolean;
  readonly isShowingAreaTypeModal: boolean;
  readonly isShowingSubAreaModal: boolean;
  readonly isShowingGlaBreakdownModal: boolean;
  readonly isShowingPageGroupsModal: boolean;
  readonly isShowingPrintPreviewModal: boolean;
  readonly isShowingSaveSketchModal: boolean;
  readonly isShowingResizeSketchModal: boolean;
  readonly isShowingRescaleGridModal: boolean;
  readonly isShowingKnownLengthPromptModal: boolean;
  readonly isShowingSketchSearchModal: boolean;
  readonly isShowingSettingsModal: boolean;
  readonly isShowingLabelSidebar: boolean;
  readonly isShowingToolbarLeft: boolean;
  readonly isShowingSymbolSidebar: boolean;
  readonly isShowingPolygonSidebar: boolean;
  readonly isInStampMode: boolean;
  readonly canCreateCurve: boolean;
  readonly canUndo: boolean;
  readonly canRedo: boolean;
  readonly selectedObjects: Immutable.List<string>;
  readonly currentPage: Page | undefined;
  readonly svgElement: SVGSVGElement | null;
  readonly svgSize: Dimensions;
  readonly drawInputSequence: string[];
  readonly currentValue: string;
  readonly currentDirection: Direction | string | undefined;
  readonly currentAngleValue: string;
  readonly walls: Immutable.Map<string, Wall | CircleWall>;
  readonly isAngleInputEnabled: boolean;
  readonly isApplyingModel: boolean;
  readonly userCompany: string;
}

interface ActionProps {
  readonly setEndPoint: (endPoint: CoordinatePoint, center: boolean) => void;
  readonly loadAreaTypes: typeof areaTypePersistenceActions.loadAreaTypes;
  readonly toggleDrawingStraightWalls: (value: boolean) => void;
  readonly addCurve: () => void;
  readonly zoomViewBox: (zoomByIncrementPayload: ZoomByIncrementPayload) => void;
  readonly startPan: (point: CoordinatePoint) => void;
  readonly panViewBox: (point: CoordinatePoint) => void;
  readonly endPan: () => void;
  readonly resetViewBox: () => void;
  readonly centerAll: () => void;
  readonly removeLastInput: () => void;
  readonly showNumpadModal: () => void;
  readonly addDrawInput: (inputKey: string) => void;
  readonly finishDrawInput: () => void;
  readonly finishSegment: () => void;
  readonly moveByKeypad: (key: string) => void;
  readonly undo: () => void;
  readonly redo: () => void;
  readonly createSketch: () => void;
  readonly removeSelectedObjects: () => void;
  readonly showLabelSidebar: () => void;
  readonly showSymbolSidebar: () => void;
  readonly hideSidebar: () => void;
  readonly hidePolygonSidebar: () => void;
  readonly showPolygonSidebar: () => void;
  readonly toggleStampMode: () => void;
  readonly autoFinishSegment: () => void;
  readonly toggleDrawingInteriorWalls: (drawInteriorWalls: boolean) => void;
  readonly setCurrentDirection: typeof drawWithKeypadActions.setCurrentDirection;
  readonly setCurrentAngleValue: typeof drawWithKeypadActions.setCurrentAngleValue;
  readonly setAngleInput: typeof drawWithKeypadActions.setAngleInput;
  readonly resetCurrentValue: typeof drawWithKeypadActions.resetCurrentValue;
  readonly resetDrawInput: typeof drawWithKeypadActions.resetDrawInput;
  readonly endDraw: typeof editModeActions.switchToSelection;
  readonly switchToSelection: typeof editModeActions.switchToSelection;
  readonly switchToEdit: typeof editModeActions.switchToEdit;
  readonly switchToSplit: typeof editModeActions.switchToSplit;
  readonly startMultiselectMode: typeof editModeActions.startMultiselectMode;
  readonly endMultiselectMode: typeof editModeActions.endMultiselectMode;
  readonly startBoxSelectMode: typeof editModeActions.startBoxSelectMode;
  readonly endBoxSelectMode: typeof editModeActions.endBoxSelectMode;
  readonly showMessage: (title: string, message: string | JSX.Element, onSubmit?: onSubmitEventType, onSubmitText?: string, isDissmisable?: boolean) => void;
  readonly fromInspectionMessage: typeof inspectionActions.fromInspectionMessage;
  readonly copy: typeof copyActions.copy;
  readonly paste: typeof copyActions.paste;
  readonly flipSelection: typeof copyActions.flip;
  readonly mirrorSelection: typeof copyActions.mirror;
  readonly checkConnection: typeof networkPersistenceActions.checkConnection;
  readonly toggleDrawingPreshapes: (drawPreshapes: boolean) => void;
}

type Props = StateProps & ActionProps & RouteComponentProps;

interface State {
  userIsValid: boolean;
  sidebarLeft: number;
  sidebarTop: number;
  sketchHeight: number;
  isEmbedded: boolean;
}

const beforeLogout = async (): Promise<void> => {
  await sessionStorage.removeItem('userCompany');
  await localStorage.clear();
};

class Sketchpad extends PureComponent<Props, State> {
  // eslint-disable-next-line react/sort-comp
  private readonly focusRef: RefObject<HTMLDivElement>;

  private readonly toolBarRef: RefObject<HTMLDivElement>;

  public constructor(props: Props) {
    super(props);
    this.focusRef = React.createRef<HTMLDivElement>();
    this.toolBarRef = React.createRef<HTMLDivElement>();

    this.state = {
      userIsValid: false,
      sidebarLeft: 0,
      sidebarTop: 0,
      sketchHeight: 0,
      isEmbedded: Boolean(new URLSearchParams(props.location.search).get('embedded')),
    };
  }

  private logoutUser = async (): Promise<void> => {
    await logout({ beforeLogout });
  };

  // eslint-disable-next-line react/sort-comp
  private setLocalStore = (params: URLSearchParams): void => {
    // domain?newSketch=true
    // we should clear all variables
    const newSketch = params.get('newSketch');
    if (newSketch) {
      localStorage.removeItem('sketchId');
      localStorage.removeItem('isCreated');
      localStorage.removeItem('isDirty');
    }

    // domain?sketchId=<id>
    // we should start application with existing sketch (load sketch)
    const sketchId = params.get('sketchId');
    if (sketchId) {
      localStorage.setItem('sketchId', sketchId);
      localStorage.setItem('isCreated', 'true');
      localStorage.setItem('isDirty', 'true');
    }
  };

  // eslint-disable-next-line react/sort-comp
  private grabFocus = (): void => {
    // we have to set focus to the svg editor
    // due to editor have to handle keyDown events
    // but not for modals (modal could have inputs and we don't want to lose focus on each change)
    const {
      isShowingAreaTypeModal,
      isShowingSubAreaModal,
      isShowingGlaBreakdownModal,
      isShowingPageGroupsModal,
      isShowingPrintPreviewModal,
      isShowingResizeSketchModal,
      isShowingSaveSketchModal,
      isShowingRescaleGridModal,
      isShowingLabelSidebar,
      isShowingSymbolSidebar,
      isPanning,
      hideSidebar
    } = this.props;
    if (
      !isShowingSaveSketchModal &&
      !isShowingRescaleGridModal &&
      !isShowingAreaTypeModal &&
      !isShowingSubAreaModal &&
      !isShowingGlaBreakdownModal &&
      !isShowingPageGroupsModal &&
      !isShowingPrintPreviewModal &&
      !isShowingResizeSketchModal &&
      !isShowingSymbolSidebar
    ) {
      // resolves issue with not being able to select labelItems by hiding during pan
      if (isPanning && isShowingLabelSidebar) hideSidebar();

      if (document.activeElement !== this.focusRef.current) {
        if (this.focusRef.current) {
          this.focusRef.current.focus();
        }
      }
    }
  };

  private handleMessage = (message: MessageEvent): void => {
    if (message.data && typeof message.data === 'string') {
      try {
        const { fromInspectionMessage } = this.props;
        fromInspectionMessage(JSON.parse(message.data));
      } catch (err) {
        log(LogLevel.error, err);
      }
    }
  };

  public componentDidMount = async (): Promise<void> => {
    const { loadAreaTypes, checkConnection } = this.props;

    window.history.pushState(null, ''); // locks app on one state
    window.addEventListener('popstate', this.handleBackAction); //  after first interaction with the app
    this.grabFocus();
    this.setSidebarPos();
    loadAreaTypes();

    // start network connection check
    checkConnection();

    window.addEventListener('message', this.handleMessage);

    const userIsValid = await getUserIsValid();
    if (!userIsValid && !isDesktopApplication()) {
      await login();
    }
    this.setState((state: State) => ({ ...state, userIsValid }));
  };

  public componentDidUpdate = (): void => {
    const { showMessage, userCompany } = this.props;
    this.grabFocus();
    this.setSidebarPos();

    getUserIsValid().then((isValid) => {
      if (isValid && !userCompany) {
        getCompanies().then((body: any) => {
          let newBody = body;
          if (body.body && typeof body.body === 'string') {
            newBody = JSON.parse(body.body);
          }
          window.history.replaceState({}, '', '/');
          if (body.companies.length > 0) {
            showMessage(
              'Your Companies',
              <CompanyList body={newBody} />,
              () => {
                // submit should write company to session storage, but if for any reason it is not there, then use the default..
                if (sessionStorage.getItem('selectedCompany')) {
                  sessionStorage.setItem('userCompany', sessionStorage.getItem('selectedCompany') || '');
                  sessionStorage.removeItem('selectedCompany');
                }
                window.location.reload();
              },
              'Continue Login',
              false
            );
          } else {
            showMessage(
              'Auth Error',
              <div>
                There was an Authorization Error with your profile.
                <br />
                Please retry login or contact ACI Support.
                <br />
                <br />
                ACI Support: 800.234.8727
              </div>,
              () => {
                this.logoutUser();
              },
              'Retry Login',
              false
            );
          }
        });
      }
    });
  };

  public componentWillUnmount = (): void => {
    window.removeEventListener('popstate', this.handleBackAction);
    window.removeEventListener('message', this.handleMessage);
  };

  private setSidebarPos = (): void => {
    if (this.toolBarRef.current) {
      const { width } = this.toolBarRef.current.getBoundingClientRect();

      this.setState({ sidebarLeft: width });
    }

    const sketchDiv: any = document.getElementsByClassName('sketchDiv')[0];
    if (sketchDiv) {
      const sketchRect = sketchDiv.getBoundingClientRect();
      const { top, height } = sketchRect;

      this.setState({ sidebarTop: top });
      this.setState({ sketchHeight: height });
    }
  };

  private handleBackAction = (): void => {
    const { showMessage } = this.props;
    showMessage(cannotGoBackDialog.title, cannotGoBackDialog.text);
    window.history.pushState(null, ''); // locks app on one state
  };

  private handleKeyDown = (keyEvent: React.KeyboardEvent): void => {
    // keyEvent.preventDefault();

    // Prevent IE from navigating back on Backspace key press
    if (keyEvent.key === Keys.backspace) keyEvent.preventDefault();

    //
    // metaKey is Cmd key on Mac
    // some keyboard's shortcuts on Mac use Cmd + key instead of Ctrl + key
    // warning: metaKey is working only for keydown event,
    // i.e. for keyUp and keyPress we should use other solution, more details here
    // https://stackoverflow.com/questions/3902635/how-does-one-capture-a-macs-command-key-via-javascript
    //

    if (keyEvent.ctrlKey || keyEvent.metaKey) {
      this.handleCtrlKey(keyEvent);
    } else {
      this.handleKey(keyEvent);
    }
  };

  /** UPDATE BOTH handleCtrlKey & handleKey methods for PC & Mac keyboards  * */

  private handleCtrlKey = (keyEvent: React.KeyboardEvent): void => {
    const { key, keyCode } = keyEvent;
    const {
      currentPage,
      svgElement,
      resetViewBox,
      canRedo,
      redo,
      zoomViewBox,
      selectedObjects,
      copy,
      paste,
      flipSelection,
      mirrorSelection,
      canUndo,
      undo,
      viewCenterPoint,
      createSketch,
      isShowingNumpadModal,
    } = this.props;

    /** Object Action Shortcuts */

    if (key.toLowerCase() === Keys.c && selectedObjects.size) {
      copy();
    }

    if (key.toLowerCase() === Keys.v) {
      paste();
    }

    if (key.toLowerCase() === Keys.d) {
      copy();
      paste();
    }

    if (key.toLowerCase() === Keys.y && canRedo) {
      keyEvent.preventDefault();
      redo();
    }

    if (key.toLowerCase() === Keys.z && canUndo) undo();

    if (key.toLowerCase() === Keys.g && selectedObjects.size) {
      keyEvent.preventDefault();
      mirrorSelection();
    }

    if (key.toLowerCase() === Keys.f && selectedObjects.size) {
      keyEvent.preventDefault();
      flipSelection();
    }

    /** Sketch View Shortcuts */

    if (key === Keys.zero) resetViewBox();

    if (Keys.plusKeys.includes(key) || Keys.plusKeysCodes.includes(keyCode)) {
      keyEvent.preventDefault();
      zoomViewBox({ location: viewCenterPoint, increments: -1 });
    }

    if (Keys.minusKeys.includes(key) || Keys.minusKeysCodes.includes(keyCode)) {
      keyEvent.preventDefault();
      zoomViewBox({ location: viewCenterPoint, increments: 1 });
    }

    /** Sketch Data Shortcuts */

    if ((key === Keys.delete || key === Keys.backspace) && !isShowingNumpadModal) {
      keyEvent.preventDefault();
      createSketch();
    }

    if (key.toLowerCase() === Keys.s) {
      keyEvent.preventDefault();

      if (currentPage) {
        const formattedSketchObjects = formatSketchObjects(currentPage.objects);

        if (formattedSketchObjects.points.size && svgElement) {
          const pngUriPromise = svgAsPngUri(svgElement, printConfig, formattedSketchObjects);

          pngUriPromise.then((sketchPngUri) => {
            const downloadLink = document.createElement('a');

            downloadLink.href = sketchPngUri;
            downloadLink.download = 'sketch-save.png';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
          });
        }
      }
    }
  };

  private handleKey = (keyEvent: React.KeyboardEvent): void => {
    const { key } = keyEvent;
    const {
      isDrawing,
      switchToSelection,
      switchToEdit,
      switchToSplit,
      addDrawInput,
      addCurve,
      finishDrawInput,
      isSelectedMode,
      startPan,
      panViewBox,
      endPan,
      viewCenterPoint,
      startMultiselectMode,
      endMultiselectMode,
      startBoxSelectMode,
      endBoxSelectMode,
      toggleDrawingStraightWalls,
      moveByKeypad,
      removeSelectedObjects,
      autoFinishSegment,
      toggleDrawingInteriorWalls,
      isShowingLabelSidebar,
      isShowingSymbolSidebar,
      showLabelSidebar,
      showSymbolSidebar,
      hideSidebar,
      canCreateCurve,
      isInStampMode,
      toggleStampMode,
      resetDrawInput,
      drawInputSequence,
      currentValue,
      currentDirection,
      setAngleInput,
      walls,
      showNumpadModal,
      setCurrentDirection,
      isAngleInputEnabled,
      endPoint,
      unitOfMeasure,
      currentGridSize,
      currentAngleValue,
      setEndPoint,
      centerAll,
      isShowingNumpadModal,
      removeLastInput,
      selectedObjects,
      isShowingPolygonSidebar,
      previousStartPoint,
      hidePolygonSidebar,
      showPolygonSidebar,
      toggleDrawingPreshapes
    } = this.props;

    /** Drawing Action Shortcuts */

    if (key.toLowerCase() === Keys.a) {
      autoFinishSegment();
    }

    if (key.toLowerCase() === Keys.t) {
      this.cancelDraw();
      switchToSelection();
    }

    if (key.toLowerCase() === Keys.l || key.toLowerCase() === Keys.r) {
      if (keyEvent.shiftKey || keyEvent.metaKey) return;

      if (!isDrawing) {
        if (!isShowingLabelSidebar && key.toLowerCase() === Keys.l) {
          showLabelSidebar();
        } else {
          hideSidebar();
        }
        return;
      }

      if (!walls.size && isDrawing) {
        toast(messages.angleTriggerWithoutWalls, {
          type: toast.TYPE.WARNING,
        });
        return;
      }

      if (!+currentValue && isDrawing) {
        toast(messages.angleTriggerWithoutDistance, {
          type: toast.TYPE.WARNING,
        });
        return;
      }

      if (key === 'l') setCurrentDirection('ArrowLeft');
      if (key === 'r') setCurrentDirection('ArrowRight');

      if (currentValue !== '' && walls.size && !isAngleInputEnabled) {
        setAngleInput();
      }
    }

    /** Sketch View shortcuts * */

    if (key.toLowerCase() === Keys.c && !isDrawing) {
      centerAll();
    }

    /** ToolbarLeft shortcuts * */

    if (key.toLowerCase() === Keys.d && !isDrawing) {
      toggleDrawingInteriorWalls(false);
      toggleDrawingPreshapes(false);
      switchToEdit();
    }

    if (key.toLowerCase() === Keys.i && !isDrawing) {
      toggleDrawingInteriorWalls(true);
      switchToEdit();
    }

    if (key.toLowerCase() === Keys.n) {
      if (!isShowingSymbolSidebar) {
        showSymbolSidebar();
      } else {
        hideSidebar();
      }
    }

    if (key.toLowerCase() === Keys.p) {
      if (keyEvent.shiftKey) {
        if (!isShowingPolygonSidebar) {
          showPolygonSidebar();
        } else {
          hidePolygonSidebar();
        }
      } else {
        switchToSplit();
      }
    }

    if (key.toLowerCase() === Keys.u && canCreateCurve) {
      addCurve();
    }

    /** Editing Shortcuts */

    if (key === Keys.escape) {
      this.cancelDraw();
      if (isInStampMode) {
        toggleStampMode();
      }
      endBoxSelectMode();
    }

    if (key === Keys.delete || key === Keys.backspace) {
      if (selectedObjects.size) removeSelectedObjects();

      if (drawInputSequence.length > 0 && isShowingNumpadModal) removeLastInput();
    }

    if (key === Keys.shift) {
      endBoxSelectMode();
      startMultiselectMode();
      toggleDrawingStraightWalls(true);
    }

    if (key === Keys.alt) {
      endMultiselectMode();
      startBoxSelectMode();
    }

    if (isDrawing) {
      if (Keys.arrowKeys.includes(key) || Keys.digitKeys.includes(key)) {
        showNumpadModal();
        addDrawInput(key);
      }

      if (key === Keys.enter) {
        keyEvent.preventDefault();
        if (isAngleInputEnabled && currentDirection) {
          const { feet, inches } = parseString(currentValue);
          const pixels = unitOfMeasure === 'meters' ? metersToPixels(parseFloat(currentValue), currentGridSize) : feetToPixels(feet, inches);
          const newCoordinates = coordinatesByAngle(previousStartPoint, endPoint, pixels, +currentAngleValue, currentDirection);
          setEndPoint({ ...newCoordinates }, true);
          centerAll();
          resetDrawInput();
        } else {
          finishDrawInput();
        }
      }
    } else if (isSelectedMode) {
      if (Keys.arrowKeys.includes(key)) {
        moveByKeypad(key);
      }
    } else if (Keys.arrowKeys.includes(key)) {
      startPan(viewCenterPoint);
      const delta = arrowDeltas[key];
      panViewBox({
        x: viewCenterPoint.x + delta.x,
        y: viewCenterPoint.y + delta.y,
      });
      endPan();
    }
  };

  private handleKeyUp = (keyEvent: React.KeyboardEvent): void => {
    // TODO: triggers even when modals are op=
    const { endMultiselectMode, endBoxSelectMode, toggleDrawingStraightWalls } = this.props;
    if (keyEvent.key === Keys.shift) {
      endMultiselectMode();
      toggleDrawingStraightWalls(false);
    } else if (keyEvent.key === Keys.alt) {
      endBoxSelectMode();
    }
  };

  private cancelDraw = (): void => {
    const { isDrawing, endDraw } = this.props;

    if (isDrawing) {
      endDraw();
    }
  };

  // STP SSS-420 This will replace the bottom tab in the mobile application
  private inspectionTouchStart = (): void => {
    window.SendSketchToMobile();
  };

  public render = (): JSX.Element => {
    const {
      location: { search },
      isShowingAreaTypeModal,
      isShowingSubAreaModal,
      isShowingGlaBreakdownModal,
      isShowingPageGroupsModal,
      isShowingPrintPreviewModal,
      isShowingResizeSketchModal,
      isShowingSaveSketchModal,
      isShowingRescaleGridModal,
      isShowingKnownLengthPromptModal,
      isShowingSettingsModal,
      isShowingSketchSearchModal,
      isShowingToolbarLeft,
      isShowingLabelSidebar,
      isShowingSymbolSidebar,
      isShowingPolygonSidebar,
      isShowingNumpadModal,
      isApplyingModel,
      userCompany,
    } = this.props;
    const { userIsValid, sidebarLeft, sidebarTop, sketchHeight, isEmbedded } = this.state;

    this.setLocalStore(new URLSearchParams(search));
    return userIsValid || isDesktopApplication() ? (
      <ThemeProvider theme={AciCoreTheme}>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          {/* eslint-disable jsx-a11y/no-noninteractive-tabindex */}
          <div css={styles} ref={this.focusRef} tabIndex={0} role="presentation" onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp}>
            {!isEmbedded && <ToolbarTop />}
            <Main>
              <ToolbarLeft innerRef={this.toolBarRef} pose={getDisplayPose(isShowingToolbarLeft)} />
              <LabelSidebar left={sidebarLeft} pose={getDisplayPose(isShowingLabelSidebar)} />
              <SymbolSidebar left={sidebarLeft} pose={getDisplayPose(isShowingSymbolSidebar)} />
              <PolygonSidebar left={sidebarLeft} pose={getDisplayPose(isShowingPolygonSidebar)} />
              {(userCompany || isDesktopApplication()) && <SketchArea />}
              <AppVersion />
            </Main>

            {!isApplyingModel && <PrintTableView />}
            {isShowingAreaTypeModal && <AreaTypeModal />}
            {isShowingSubAreaModal && <SubAreaModal />}
            <PageGroupsModal top={sidebarTop} pose={getDisplayPose(isShowingPageGroupsModal)} />
            {!isApplyingModel && <GlaBreakdownModal top={sidebarTop} pose={getDisplayPose(isShowingGlaBreakdownModal)} />}
            <NumpadMenuModal top={sidebarTop} left={sidebarLeft} height={sketchHeight} pose={getDisplayPose(isShowingNumpadModal)} />
            <SettingsModal top={sidebarTop} pose={getDisplayPose(isShowingSettingsModal)} />
            <SketchSearchModal top={sidebarTop} pose={getDisplayPose(isShowingSketchSearchModal)} />
            {isShowingKnownLengthPromptModal && <KnownLengthPromptModal />}
            {isShowingSaveSketchModal && <SaveSketchModal />}
            {isShowingResizeSketchModal && <ResizeSketchModal />}
            {isShowingRescaleGridModal && <RescaleGridModal />}
            {!isIE11 && <PrintPreviewModal pose={getDisplayPose(isShowingPrintPreviewModal)} />}
            <ToastContainer />
            <MessageContainer />
          </div>
          <Preview />
          {/* eslint-enable jsx-a11y/no-noninteractive-tabindex */}
        </DndProvider>
        {isMobileApplication() && (
          <div role="presentation" css={mobileTabDivStyle}>
            <div css={mobileTabBtnDivStyle}>
              <MobileSketchIcon css={mobileIconStyle} />
              <span css={mobileTabSpanStyle}>Sketch</span>
            </div>
            <div css={mobileTabBtnDivStyle} onTouchStart={this.inspectionTouchStart}>
              <MobileOrdersIcon css={mobileIconStyle} />
              <span css={mobileTabSpanStyle}>Inspection</span>
            </div>
          </div>
        )}
      </ThemeProvider>
    ) : (
      <div>Redirecting to Login...</div>
    );
  };
}

export default React.memo(
  connect(
    (state: RootState): StateProps => ({
      unitOfMeasure: settingsSelectors.getUnitOfMeasure(state),
      currentGridSize: settingsSelectors.getGridSizeInFeet(state),
      startPoint: drawsSelectors.getStartPoint(state),
      endPoint: drawsSelectors.getEndPoint(state),
      previousStartPoint: drawsSelectors.getPreviousStartPoint(state),
      viewCenterPoint: viewportSelectors.getViewCenterPoint(state),
      isDrawing: editModeSelectors.isDrawingMode(state),
      isPanning: viewportSelectors.isPanning(state),
      isSelectedMode: editModeSelectors.isSelectedMode(state),
      isShowingNumpadModal: numpadModalSelectors.isShowing(state),
      isShowingAreaTypeModal: areaTypeModalSelectors.isShowing(state),
      isShowingSubAreaModal: subAreaModalSelectors.isShowing(state),
      isShowingGlaBreakdownModal: glaBreakdownModalSelectors.isShowing(state),
      isShowingPageGroupsModal: pageGroupsModalSelectors.isShowing(state),
      isShowingPrintPreviewModal: printPreviewModalSelectors.isShowing(state),
      isShowingSaveSketchModal: saveSketchModalSelectors.isShowing(state),
      isShowingResizeSketchModal: resizeSketchModalSelectors.isResizeShowing(state),
      isShowingRescaleGridModal: resizeSketchModalSelectors.isRescaleShowing(state),
      isShowingKnownLengthPromptModal: resizeSketchModalSelectors.isKnownLengthPromptShowing(state),
      isShowingSketchSearchModal: sketchSearchModalSelectors.isShowing(state),
      isShowingSettingsModal: settingsModalSelectors.isShowing(state),
      isShowingToolbarLeft: sidebarSelectors.isShowingToolbarLeft(state),
      isShowingLabelSidebar: sidebarSelectors.isShowingLabels(state),
      isShowingSymbolSidebar: sidebarSelectors.isShowingSymbols(state),
      isShowingPolygonSidebar: sidebarSelectors.isShowingPolygons(state),
      isInStampMode: sidebarSelectors.isInStampMode(state),
      canCreateCurve: selectionSelectors.isLineWallSelected(state),
      canUndo: historySelectors.canUndo(state),
      canRedo: historySelectors.canRedo(state),
      selectedObjects: editModeSelectors.getSelectedObjects(state),
      currentPage: pagesSelectors.getCurrentPage(state),
      svgElement: viewportSelectors.getSvgElement(state),
      svgSize: viewportSelectors.getSvgSize(state),
      drawInputSequence: drawWithKeypadSelectors.getDrawInputSequence(state),
      currentDirection: drawWithKeypadSelectors.getCurrentDirection(state),
      currentValue: drawWithKeypadSelectors.getCurrentValue(state),
      currentAngleValue: drawWithKeypadSelectors.getCurrentAngleValue(state),
      isAngleInputEnabled: drawWithKeypadSelectors.getIsAngleInputEnabled(state),
      walls: wallSelectors.getAllWalls(state),
      isApplyingModel: sketchPersistenceSelectors.isApplyingModel(state),
      userCompany: sessionStorage.getItem('userCompany') || '',
    }),
    {
      loadAreaTypes: areaTypePersistenceActions.loadAreaTypes,
      toggleDrawingStraightWalls: drawActions.toggleDrawingStraightWalls,
      showNumpadModal: numpadModalActions.show,
      endDraw: editModeActions.switchToSelection,
      switchToSelection: editModeActions.switchToSelection,
      switchToEdit: editModeActions.switchToEdit,
      switchToSplit: editModeActions.switchToSplit,
      startMultiselectMode: editModeActions.startMultiselectMode,
      startBoxSelectMode: editModeActions.startBoxSelectMode,
      endMultiselectMode: editModeActions.endMultiselectMode,
      endBoxSelectMode: editModeActions.endBoxSelectMode,
      addCurve: drawCurveActions.addCurve,
      zoomViewBox: viewportActions.zoomViewBoxByIncrement,
      startPan: viewportActions.startPan,
      panViewBox: viewportActions.panViewBox,
      endPan: viewportActions.endPan,
      resetViewBox: viewportActions.resetViewBox,
      centerAll: viewportActions.centerAll,
      removeLastInput: drawWithKeypadActions.removeLastInput,
      setCurrentDirection: drawWithKeypadActions.setCurrentDirection,
      setCurrentAngleValue: drawWithKeypadActions.setCurrentAngleValue,
      setAngleInput: drawWithKeypadActions.setAngleInput,
      addDrawInput: drawWithKeypadActions.addDrawInput,
      finishDrawInput: drawWithKeypadActions.finishDrawInput,
      resetDrawInput: drawWithKeypadActions.resetDrawInput,
      resetCurrentValue: drawWithKeypadActions.resetCurrentValue,
      moveByKeypad: moveObjectsActions.moveByKeypad,
      undo: historyActions.undo,
      redo: historyActions.redo,
      removeSelectedObjects: removeObjects.removeSelectedObjects,
      showPolygonSidebar: sidebarActions.showPolygons,
      hidePolygonSidebar: sidebarActions.hide,
      showSymbolSidebar: sidebarActions.showSymbols,
      hideSidebar: sidebarActions.hide,
      toggleStampMode: sidebarActions.toggleStampMode,
      autoFinishSegment: drawActions.autoFinishSegment,
      toggleDrawingInteriorWalls: drawActions.toggleDrawingInteriorWalls,
      showMessage: messageContainerActions.show,
      fromInspectionMessage: inspectionActions.fromInspectionMessage,
      copy: copyActions.copy,
      paste: copyActions.paste,
      flipSelection: copyActions.flip,
      mirrorSelection: copyActions.mirror,
      setEndPoint: drawActions.setEndPointWithoutSnapping,
      finishSegment: drawActions.finishSegment,
      checkConnection: networkPersistenceActions.checkConnection,
      toggleDrawingPreshapes: drawActions.toggleDrawingPreshapes,
    },
  )(withRouter(Sketchpad)),
);
