import { Figure } from 'types/figure';
import { SelectableObjects } from 'types/selection';
import { getFiguresWithWall } from 'helpers/model/figureWalls';
import { findPath } from 'helpers/path/findPath';

export interface RemoveWallResult {
  updatedFigures: Figure[];
  removedFigures: string[];
  removedWalls: string[];
}

export const removeSharedWall = (
  selectableObjects: SelectableObjects, wallId: string,
): RemoveWallResult => {
  const result: RemoveWallResult = {
    removedWalls: [],
    removedFigures: [],
    updatedFigures: [],
  };

  const { walls, figures } = selectableObjects;
  const figuresWithWall = getFiguresWithWall(figures, wallId);

  const figure1 = figures.get(figuresWithWall[0])!;
  const figure2 = figures.get(figuresWithWall[1])!;

  const figuresWalls = walls.filter((wall) => {
    const wId = wall.wallId;
    return figure1.walls.includes(wId) || figure2.walls.includes(wId);
  });

  // find new path
  let newPath: string[] = [];
  figure1.walls.find((wId) => {
    if (wId === wallId) {
      return false;
    }

    const otherWall = walls.get(wId)!;
    const path = findPath(figuresWalls, otherWall.points[0], otherWall.points[1], [wallId, wId]);
    if (!path.length) {
      return false;
    }
    path.push(wId);
    newPath = path;

    return true;
  });

  if (!newPath.length) {
    return result;
  }

  result.updatedFigures.push({
    ...figure1,
    walls: newPath,
  });

  result.removedFigures.push(figure2.figureId);

  figuresWalls.forEach((wall) => {
    if (!newPath.includes(wall.wallId)) {
      result.removedWalls.push(wall.wallId);
    }
  });

  return result;
};
