import { RootState } from 'reducers/rootReducer';
import { PayloadAction } from 'types/payloadAction';
import { StampId, StampObjectType } from 'types/stamp';

// Action Types
const NAME = 'sidebar';

const TOGGLE_SIDEBAR = `${NAME}/TOGGLE_SIDEBAR`;
const TOGGLE_MOBILE_MENU = `${NAME}/TOGGLE_MOBILE_MENU`;
const SHOW_LABELS = `${NAME}/SHOW_LABELS`;
const SHOW_SYMBOLS = `${NAME}/SHOW_SYMBOLS`;
const SHOW_POLYGONS = `${NAME}/SHOW_POLYGONS`;
const HIDE = `${NAME}/HIDE`;
const SELECT_SYMBOL_CATEGORY = `${NAME}/SELECT_SYMBOL_CATEGORY`;
const TOGGLE_STAMP_MODE = `${NAME}/TOGGLE_STAMP_MODE`;
const SET_STAMP_ID = `${NAME}/SET_STAMP_ID`;

enum SidebarType {
  Labels,
  Symbols,
  Polygons,
}

export interface SidebarState {
  readonly isShowingMobileMenu: boolean;
  readonly isShowingToolbarLeft: boolean;
  readonly isShowing: boolean;
  readonly sidebarType: SidebarType | null;
  readonly symbolCategory: string | null;
  readonly stampMode: boolean;
  readonly stampId?: StampId;
}

// Initial State
const initialState: SidebarState = {
  isShowingMobileMenu: false,
  isShowingToolbarLeft: true,
  isShowing: false,
  sidebarType: null,
  symbolCategory: null,
  stampMode: false,
  stampId: undefined,
};

// Action Creators
export const actions = {
  toggleToolbarLeft: () => ({ type: TOGGLE_SIDEBAR }),

  toggleMobileMenu: () => ({ type: TOGGLE_MOBILE_MENU }),

  showLabels: () => ({ type: SHOW_LABELS }),

  showSymbols: () => ({ type: SHOW_SYMBOLS }),

  showPolygons: () => ({ type: SHOW_POLYGONS }),

  hide: () => ({ type: HIDE }),

  selectSymbolCategory: (categoryId: string | null) => ({
    type: SELECT_SYMBOL_CATEGORY,
    payload: categoryId,
  }),

  toggleStampMode: () => ({ type: TOGGLE_STAMP_MODE }),

  setStampId: (objectType: StampObjectType, objectId: string) => ({
    type: SET_STAMP_ID,
    payload: { objectType, objectId },
  }),
};

// Selectors
const getSidebarState = (rootState: RootState): SidebarState => rootState.sidebar;

const isShowingMobileMenu = (rootState: RootState): boolean => {
  const sidebarState = getSidebarState(rootState);
  return sidebarState.isShowingMobileMenu;
};

const isShowingToolbarLeft = (rootState: RootState): boolean => {
  const sidebarState = getSidebarState(rootState);
  return sidebarState.isShowingToolbarLeft;
};

const isShowingLabels = (rootState: RootState): boolean => {
  const sidebarState = getSidebarState(rootState);
  return sidebarState.isShowing && sidebarState.sidebarType === SidebarType.Labels;
};

const isShowingSymbols = (rootState: RootState): boolean => {
  const sidebarState = getSidebarState(rootState);
  return sidebarState.isShowing && sidebarState.sidebarType === SidebarType.Symbols;
};

const isShowingPolygons = (rootState: RootState): boolean => {
  const sidebarState = getSidebarState(rootState);
  return sidebarState.isShowing && sidebarState.sidebarType === SidebarType.Polygons;
};

const getSymbolCategory = (rootState: RootState): string | null => getSidebarState(rootState).symbolCategory;

const isInStampMode = (rootState: RootState): boolean => getSidebarState(rootState).stampMode;

const getStampId = (rootState: RootState): StampId | undefined => getSidebarState(rootState).stampId;

export const selectors = {
  isShowingMobileMenu,
  isShowingToolbarLeft,
  isShowingLabels,
  isShowingSymbols,
  isShowingPolygons,
  getSymbolCategory,
  isInStampMode,
  getStampId,
};

// Reducers
const showMobileMenu = (state: SidebarState): SidebarState => ({
  ...state,
  isShowingMobileMenu: !state.isShowingMobileMenu,
});

const showSidebar = (state: SidebarState): SidebarState => ({
  ...state,
  isShowingToolbarLeft: !state.isShowingToolbarLeft,
});

const showLabels = (state: SidebarState): SidebarState => ({
  ...state,
  isShowing: true,
  sidebarType: SidebarType.Labels,
});

const showSymbols = (state: SidebarState): SidebarState => ({
  ...state,
  isShowing: true,
  sidebarType: SidebarType.Symbols,
  symbolCategory: null,
});

const showPolygons = (state: SidebarState): SidebarState => ({
  ...state,
  isShowing: true,
  sidebarType: SidebarType.Polygons,
  symbolCategory: null,
});

const selectSymbolCategoryReducer = (state: SidebarState, categoryId: string | null): SidebarState => ({
  ...state,
  symbolCategory: categoryId,
});

const hide = (state: SidebarState): SidebarState => ({
  ...state,
  isShowing: false,
  sidebarType: null,
  stampMode: false,
  stampId: undefined,
});

const toggleStampMode = (state: SidebarState): SidebarState => ({
  ...state,
  stampMode: !state.stampMode,
  stampId: undefined,
});

const setStampId = (state: SidebarState, stampId: StampId): SidebarState =>
  // eslint-disable-next-line implicit-arrow-linebreak
  !state.stampId || state.stampId.objectId !== stampId.objectId || state.stampId.objectType !== stampId.objectType
    ? {
      ...state,
      stampMode: true,
      stampId,
      isShowing: false, // Hide sidebar so the user has all of the Sketchpad space after a label/symbol has been selected
    }
    : {
      ...state,
      stampMode: false,
      stampId: undefined,
    };

export const reducer = (state: SidebarState = initialState, action: PayloadAction): SidebarState => {
  switch (action.type) {
    case TOGGLE_MOBILE_MENU:
      return showMobileMenu(state);

    case TOGGLE_SIDEBAR:
      return showSidebar(state);

    case SHOW_LABELS:
      return showLabels(state);

    case SHOW_SYMBOLS:
      return showSymbols(state);

    case SHOW_POLYGONS:
      return showPolygons(state);

    case HIDE:
      return hide(state);

    case SELECT_SYMBOL_CATEGORY:
      return selectSymbolCategoryReducer(state, action.payload);

    case TOGGLE_STAMP_MODE:
      return toggleStampMode(state);

    case SET_STAMP_ID:
      return setStampId(state, action.payload);

    default:
      return state;
  }
};
