import Immutable from 'immutable';
import { PageObject } from 'types/page';
import { Point, } from 'types/point';
import { SelectableObjects } from 'types/selection';
import { Wall } from 'types/wall';
import { Figure } from 'types/figure';
import { PositionedLabel } from 'types/positionedLabel';
import { PositionedSymbol } from 'types/positionedSymbol';

export const formatSketchObjects = (pageObjects: Immutable.Map<string, PageObject>): SelectableObjects => {
  const parsedObjects = Object.entries(pageObjects.toJS());

  const formattedSketchObjects = {
    points: Immutable.Map<string, Point>(),
    walls: Immutable.Map<string, Wall>(),
    figures: Immutable.Map<string, Figure>(),
    positionedLabels: Immutable.Map<string, PositionedLabel>(),
    positionedSymbols: Immutable.Map<string, PositionedSymbol>(),
  } as SelectableObjects;

  // eslint-disable-next-line
  for (const [key, currentObj] of parsedObjects) {
    if (currentObj.computed) break;

    if (currentObj.object.pointId) {
      formattedSketchObjects.points = formattedSketchObjects.points.set(currentObj.objectId, currentObj.object);
    }

    if (currentObj.object.wallId) {
      formattedSketchObjects.walls = formattedSketchObjects.walls.set(currentObj.objectId, currentObj.object);
    }

    if (currentObj.object.figureId) {
      formattedSketchObjects.figures = formattedSketchObjects.figures.set(currentObj.objectId, currentObj.object);
    }

    if (currentObj.object.positionedLabelId) {
      formattedSketchObjects.positionedLabels = formattedSketchObjects.positionedLabels.set(currentObj.objectId, currentObj.object);
    }

    if (currentObj.object.positionedSymbolId) {
      formattedSketchObjects.positionedSymbols = formattedSketchObjects.positionedSymbols.set(currentObj.objectId, currentObj.object);
    }
  }

  return formattedSketchObjects;
};
