// /<summary>
// /The purpose of this class is to hold all Border Definitions we utilize in this library in a single place.
// /</summary>
//----------

// /<summary>
// /An Object with all the defined border Definitions we support and use
// /</summary>
export const aciBorders = {
  default: '5px',
  circle: '100px',
};

// /<summary>
// /A single collection of all the Border Definitions
// /</summary>
const AciStyleBorders = {
  aciBorders,
};

export default AciStyleBorders;
