import React from 'react';
import posed from 'react-pose';

const G = posed.g({});
const Circle = posed.circle({
  init: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: ({ i }: { i: number }) => ({
      delay: i * 100,
      duration: 200,
    }),
  },
});

const Rect = posed.rect({
  init: {
    strokeDashoffset: 190,
  },
  enter: {
    strokeDashoffset: 0,
    transition: {
      type: 'keyframes',
      values: [190, 142.5, 95, 47.5, 0, -47.5, -95, -142.5, -190],
      duration: 2000,
      easing: 'easeIn',
      loop: 500,
      delay: 400,
    },
  },
});

const Throbber = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="60" height="60" {...props}>
    <G initialPose="init" pose="enter">
      <Rect
        width="47"
        height="47"
        x="6.5"
        y="6.5"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeDasharray="190"
      />
      <Circle i={0} cx="6.5" cy="6.5" r="1.5" fill="#fff" stroke="#000" />
      <Circle i={1} cx="53.5" cy="6.5" r="1.5" fill="#fff" stroke="#000" />
      <Circle i={2} cx="53.5" cy="53.5" r="1.5" fill="#fff" stroke="#000" />
      <Circle i={3} cx="6.5" cy="53.5" r="1.5" fill="#fff" stroke="#000" />
    </G>
  </svg>
);

export default Throbber;
