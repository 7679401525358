import { useState, useEffect, useCallback } from 'react';
import { on, off, trigger } from 'helpers/customEvents';

interface ContextMenuHook {
  xPos: number | undefined;
  yPos: number | undefined;
  showMenu: boolean | false;
}

const useContextMenu = (parentRef: any, type: string): ContextMenuHook => {
  const [[xPos, yPos], setPosition] = useState([0, 0]);
  const [showMenu, setShowMenu] = useState(false);

  const handleClick = useCallback(() => {
    setShowMenu(false);
  }, [showMenu]);

  const handleContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      setPosition([e.pageX, e.pageY]);
      setShowMenu(true);
      trigger('openContextMenu', type);
      e.stopPropagation();
    },
    [setPosition],
  );

  const handleOpenContextMenu = useCallback((e) => {
    const { detail } = e;

    // This ensures we don't have duplicate context menus
    if (detail !== type) setShowMenu(false);
  },
  [showMenu]);

  useEffect(() => {
    const parent = parentRef.current;
    [parent, window].forEach((ref) => ref.addEventListener('click', handleClick));
    parent.addEventListener('contextmenu', handleContextMenu);
    on('openContextMenu', handleOpenContextMenu);
    return () => {
      [parent, window].forEach((ref) => ref.removeEventListener('click', handleClick));
      parent.removeEventListener('contextmenu', handleContextMenu);
      parent.removeEventListener('openContextMenu', handleOpenContextMenu);
      off('openContextMenu', handleOpenContextMenu);
    };
  }, []);

  return { xPos, yPos, showMenu };
};

export default useContextMenu;
