/** @jsx jsx */
import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { css, jsx } from '@emotion/react';

import { colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { Point } from 'types/point';
import { SizingInfo } from 'types/sizingInfo';
import { getPositioningWithoutRotation } from 'helpers/positioning';
import { selectors as editModeSelectors } from 'ducks/editMode';
import {
  actions as bluePrintImageActions,
  selectors as bluePrintImageSelectors,
  BluePrintImageState,
} from 'ducks/bluePrintImage/bluePrintImage';
import { selectors as selectionSelectors } from 'ducks/selection/selection';
import { ReactComponent as IconLocked } from 'assets/icons/lock.svg';
import { ReactComponent as IconUnlocked } from 'assets/icons/unlock.svg';

interface StateProps {
  readonly image: BluePrintImageState;
  readonly isMovingMode: boolean;
  readonly isResizingMode: boolean;
  readonly movedPoints: Immutable.List<Point>;
  readonly imagePosition: Point;
  readonly locked: boolean;
  readonly objectSizes: Immutable.Map<string, SizingInfo>;
}

interface ActionProps {
  readonly setImageLock: (locked: boolean) => void;
}

type Props = StateProps & ActionProps;

const style = css`
  cursor: pointer;
`;

const SIZE = 60;

class LockImageButton extends React.Component<Props> {
  private handleMouseDown = (): void => {
    const { locked, setImageLock } = this.props;

    setImageLock(!locked);
  };

  public render(): JSX.Element | null {
    const {
      image, imagePosition, movedPoints, objectSizes,
      isMovingMode, isResizingMode, locked,
    } = this.props;

    if (!imagePosition) {
      return null;
    }

    const { topLeftCorner } = getPositioningWithoutRotation(
      image.pointId, image, imagePosition,
      movedPoints, objectSizes,
      isMovingMode, isResizingMode,
    );

    const Icon = locked ? IconLocked : IconUnlocked;

    return (
      <g
        css={style}
        transform={`translate(${topLeftCorner.x}, ${topLeftCorner.y})`}
        onClick={this.handleMouseDown}
      >
        <filter id="dropShadow">
          <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
          <feOffset dx="0" dy="2" />
        </filter>
        <circle r={SIZE / 2} cx={0} cy={0} fill="#1A244F" opacity="0.16" filter="url(#dropShadow)" />
        <circle r={SIZE / 2} cx={0} cy={0} fill={locked ? '#3461de' : colors.white} />
        <Icon x={-SIZE / 2} y={-SIZE / 2} width={SIZE} height={SIZE} />
      </g>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    isMovingMode: editModeSelectors.isMovingMode(state),
    isResizingMode: editModeSelectors.isResizingMode(state),
    movedPoints: selectionSelectors.getMovedSelectedPoints(state),
    image: bluePrintImageSelectors.getImage(state),
    imagePosition: bluePrintImageSelectors.getImagePosition(state),
    locked: bluePrintImageSelectors.isImageLocked(state),
    objectSizes: selectionSelectors.getResizedSelectedObjectsSizes(state),
  }),
  (dispatch: Dispatch): ActionProps => bindActionCreators(
    {
      setImageLock: bluePrintImageActions.setImageLock,
    },
    dispatch,
  ),
)(LockImageButton);
