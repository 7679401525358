/* eslint-disable no-shadow */
import { createAction, getType, ActionType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { gridConfig } from 'config/gridConfig';
import { Precision } from 'types/precision';
import { Setting } from 'types/settings';

export interface SettingsState {
  readonly gridLines: string;
  gridSizeInFeet: number;
  readonly precision: Precision;
  readonly unitOfMeasurement: string;
  readonly settingsModalForm: any;
  readonly lastUpdatedSetting: Setting;
}

// Action Creators
export const actions = {
  switchGridShow: createAction('settings/switchGridShow')<string>(),
  switchPrecision: createAction('settings/switchPrecision')<number>(),
  switchUnitOfMeasurement: createAction('settings/switchUnitOfMeasurement')<string>(),
  setGridSize: createAction('settings/gridSizeInFeet')<number>(),
  setUserSettings: createAction('settings/setUserSettings')<Record<string, string | boolean | number>>(),
  setSettingsModalForm: createAction('settings/setSettingsModalForm')<any>(),
  updateLastSetting: createAction('settings/updateLastSetting')<Record<string, string | boolean | number>>(),
};

export type Actions = ActionType<typeof actions>;

// Initial State
const initialState: SettingsState = {
  gridLines: gridConfig.defaultGridLines,
  precision: gridConfig.defaultPrecision,
  unitOfMeasurement: gridConfig.defaultUnitOfMeasurement,
  gridSizeInFeet: gridConfig.gridSizeInFeet,
  settingsModalForm: [],
  lastUpdatedSetting: { uuid: '', value: undefined },
};

// Selectors
const getSettings = (rootState: RootState): SettingsState => rootState.settings;

const gridLines = (rootState: RootState): string => getSettings(rootState).gridLines;

const getPrecision = (rootState: RootState): Precision => getSettings(rootState).precision;

const getUnitOfMeasure = (rootState: RootState): string => getSettings(rootState).unitOfMeasurement;

const getGridSizeInFeet = (rootState: RootState): number => getSettings(rootState).gridSizeInFeet;

const getSettingsModalForm = (rootState: RootState): number => getSettings(rootState).settingsModalForm;

const getLastUpdatedSetting = (rootState: RootState): Setting => getSettings(rootState).lastUpdatedSetting;

export const selectors = {
  getPrecision,
  gridLines,
  getUnitOfMeasure,
  getGridSizeInFeet,
  getSettingsModalForm,
  getLastUpdatedSetting,
};

// Reducers
const switchGridShowReducer = (state: SettingsState, gridLines: string): SettingsState => ({
  ...state,
  gridLines,
});

const switchPrecisionReducer = (state: SettingsState, precision: Precision): SettingsState => ({
  ...state,
  precision,
});

const switchUnitOfMeasureReducer = (state: SettingsState, unitOfMeasurement: string): SettingsState => ({
  ...state,
  unitOfMeasurement,
});

const setGridSizeInFeetReducer = (state: SettingsState, gridSizeInFeet: number): SettingsState => ({
  ...state,
  gridSizeInFeet,
});

const setUserSettingsReducer = (state: SettingsState, settings: any): SettingsState => ({
  ...state,
  ...settings,
});

const setSettingsModalFormReducer = (state: SettingsState, settingsModalForm: any): SettingsState => ({
  ...state,
  settingsModalForm,
});

const setLastUpdatedSetting = (state: SettingsState, lastUpdatedSetting: any): SettingsState => ({
  ...state,
  lastUpdatedSetting,
});

export const reducer = (state: SettingsState = initialState, action: Actions): SettingsState => {
  switch (action.type) {
    case getType(actions.switchGridShow):
      return switchGridShowReducer(state, action.payload);

    case getType(actions.switchPrecision):
      return switchPrecisionReducer(state, action.payload);

    case getType(actions.switchUnitOfMeasurement):
      return switchUnitOfMeasureReducer(state, action.payload);

    case getType(actions.setGridSize):
      return setGridSizeInFeetReducer(state, action.payload);

    case getType(actions.setUserSettings):
      return setUserSettingsReducer(state, action.payload);

    case getType(actions.setSettingsModalForm):
      return setSettingsModalFormReducer(state, action.payload);

    case getType(actions.updateLastSetting):
      return setLastUpdatedSetting(state, action.payload);

    default:
      return state;
  }
};
