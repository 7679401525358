import {
  all, takeLatest, put, select,
} from 'redux-saga/effects';
import { createAction, getType, ActionType } from 'typesafe-actions';

import { RootState } from 'reducers/rootReducer';
import { ClosedFigure } from 'types/figure';
import { PayloadAction } from 'types/payloadAction';
import { selectors as figuresSelectors, actions as figuresActions } from 'ducks/model/figures';
import { actions as sidebarActions } from 'ducks/sidebar/sidebar';

// Action Types
const NAME = 'subAreaModal';

export interface MultiFamilyInfoPayload {
  readonly figureId: string;
  readonly areaTypeId: string;
  readonly subAreaText: string;
}

export interface SubAreaModalState {
  readonly isShowing: boolean;
  readonly selectedFigureId: string | null;
}

// Initial State
const initialState: SubAreaModalState = {
  isShowing: false,
  selectedFigureId: null,
};

// Action Creators
export const actions = {
  hide: createAction(`${NAME}/hide`)<void>(),

  showForFigure: createAction(`${NAME}/showForFigure`)<string>(),

  changeSubAreaForFigure: createAction(
    `${NAME}/changeSubAreaForFigure`,
    (figureId: string, areaTypeId: string, subAreaText: string) => ({ figureId, areaTypeId, subAreaText }),
  )<MultiFamilyInfoPayload>(),
};

export type Actions = ActionType<typeof actions>

// Selectors
const getSubAreaModalState = (rootState: RootState): SubAreaModalState => rootState.subAreaModal;

const isShowing = (rootState: RootState): boolean => getSubAreaModalState(rootState).isShowing;

const getSelectedFigureId = (rootState: RootState): string | null => getSubAreaModalState(rootState).selectedFigureId;

export const selectors = {
  isShowing,
  getSelectedFigureId,
};

// Reducers
const showForFigureReducer = (state: SubAreaModalState, figureId: string): SubAreaModalState => ({
  ...state,
  isShowing: true,
  selectedFigureId: figureId,
});

const hideReducer = (state: SubAreaModalState): SubAreaModalState => ({
  ...state,
  isShowing: false,
  selectedFigureId: null,
});

export const reducer = (state: SubAreaModalState = initialState, action: Actions): SubAreaModalState => {
  switch (action.type) {
    case getType(actions.hide):
      return hideReducer(state);

    case getType(actions.showForFigure):
      return showForFigureReducer(state, action.payload as string);

    default:
      return state;
  }
};

// Sagas
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const createSagas = () => {
  function* doChangeAreaType({ payload }: PayloadAction) {
    const { figureId, areaTypeId, subAreaText }: {
      figureId: string; areaTypeId: string; subAreaText: string;
    } = payload;

    const figure: ClosedFigure = figuresSelectors.getClosedFigureById(yield select(), figureId)!;

    yield put(figuresActions.update(figureId, {
      positionedAreaLabel: {
        ...figure.positionedAreaLabel!,
      },
      ...figure,
      areaTypeId,
      subAreaText,
    }));
  }

  function* doHideSidebar() {
    yield put(sidebarActions.hide());
  }

  return function* saga() {
    yield all([
      takeLatest(actions.changeSubAreaForFigure, doChangeAreaType),
      takeLatest(actions.showForFigure, doHideSidebar),
    ]);
  };
};
/* eslint-enable @typescript-eslint/explicit-function-return-type */
