import styled from '@emotion/styled';
import { menu } from 'config/paletteConfig';

interface StyledContextMenuProps {
  readonly left: number | undefined;
  readonly top: number | undefined;
}

interface StyledContextMenuItemProps {
  readonly disabled: boolean;
}

export const StyledContextMenu = styled.div<StyledContextMenuProps>(({ left, top }) => ({
  top: 0,
  transform: `translateX(min(${left}px, calc(100vw - 100%))) translateY(min(${top}px, calc(100vh - 100%)))`,
  width: '13rem',
  opacity: '100%',
  position: 'absolute',
  zIndex: 1000,
  backgroundColor: 'white',
  padding: '6px 0px',
  display: 'inline',
  overflow: 'hidden auto',
  boxShadow: '0 2px 6px 2px rgba(60,64,67,.15)',
  borderRadius: '3px',
}));

export const StyledContextMenuItem = styled.button<StyledContextMenuItemProps>(({ disabled }) => ({
  backgroundColor: 'unset',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '6px 12px',
  border: 0,
  color: disabled ? 'grey' : menu.listItem.text,
  fontSize: 12,
  fontWeight: disabled ? 400 : 600,
  textAlign: 'left',
  transition: 'background-color 0.5s ease, color 0.5s ease',

  'div:nth-of-type(2)': {
    paddingLeft: 24,
    fontWeight: 500,
  },

  '&:hover': {
    backgroundColor: disabled ? 'unset' : menu.listItem.hover,
  },

  borderTop: `3px solid ${menu.listItem.border}`,
  '&:first-of-type': {
    borderTop: 'unset',
  },
}));
