import Immutable from 'immutable';

import { Point, CoordinatePoint } from 'types/point';
import { polygonBound } from 'helpers/polygonBound';

/**
 * If point belongs to polygon not
 * @param figurePoints polygon's points
 * @param isClosed if last point connected with first one
 * @param p testing point
 * @returns true if points belongs to polygon
 */
export const isPointToFigureCollision = (
  figurePoints: Immutable.List<Point>, isClosed: boolean, p: CoordinatePoint,
): boolean => {
  if (!isClosed) {
    return false;
  }

  return polygonBound(figurePoints, p.x, p.y);
};
