import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selection as colors } from 'config/paletteConfig';
import { RootState } from 'reducers/rootReducer';
import { Corner, Corners } from 'types/corner';
import { actions as editModeActions } from 'ducks/editMode';
import { MousePointToSVGPointFunction, selectors as viewportSelectors } from 'ducks/viewport';

interface InputProps {
  readonly active: boolean;
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly size?: number;
}

interface StateProps {
  readonly getMousePointToSvgPoint: MousePointToSVGPointFunction;
}

interface ActionProps {
  readonly startResize: typeof editModeActions.switchToResizing;
}

type Props = InputProps & StateProps & ActionProps;

class SelectedBorder extends Component<Props> {
  private handleMouseDownCorner = (mouseEvent: React.PointerEvent<SVGGElement>, corner: Corner): void => {
    if (mouseEvent.button !== 0) {
      return;
    }

    const { active, startResize, getMousePointToSvgPoint } = this.props;

    if (active) {
      const point = getMousePointToSvgPoint(mouseEvent);
      startResize(point, corner);
      mouseEvent.stopPropagation();
    }
  };

  public render(): JSX.Element {
    const {
      active, x, y, width, height, size = 10,
    } = this.props;
    const cornerProps = {
      width: size,
      height: size,
      fill: active ? colors.active : colors.base,
      stroke: colors.white,
      strokeWidth: size / 5,
    };

    const resizeCornerProps = {
      cursor: 'move',
    };

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke={active ? colors.active : colors.base}
          strokeWidth={size / 4}
          fill="none"
        />
        <rect
          x={x - size / 2}
          y={y - size / 2}
          {...cornerProps}
        />
        <rect
          x={x - size / 2}
          y={y + height - size / 2}
          {...cornerProps}
          {...resizeCornerProps}
          onPointerDown={e => this.handleMouseDownCorner(e, Corners.BOTTOM_LEFT)}
        />
        <rect
          x={x + width - size / 2}
          y={y - size / 2}
          {...cornerProps}
        />
        <rect
          x={x + width - size / 2}
          y={y + height - size / 2}
          {...cornerProps}
          {...resizeCornerProps}
          onPointerDown={e => this.handleMouseDownCorner(e, Corners.BOTTOM_RIGHT)}
        />
      </g>
    );
  }
}

export default connect(
  (state: RootState): StateProps => ({
    getMousePointToSvgPoint: viewportSelectors.getMousePointToSvgPoint(state),
  }),
  {
    startResize: editModeActions.switchToResizing,
  },
)(SelectedBorder);
