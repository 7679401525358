// /<summary>
// /The purpose of this class is to define the default themes we provide/use
// /</summary>
//----------

import { createTheme, adaptV4Theme } from '@mui/material/styles';

import { aciFontFamilies, aciFontWeights, useAciFont } from '../styles/acistyletypography';
import { aciThemeSpacing } from '../styles/acistylespacing';
import { aciBorders } from '../styles/acistyleborders';
import { aciColors, makeOpacityColorHex, aciOpacities } from '../styles/acistylecolors';
// import { aciShadows } from '../styles/acistylelayering'

const createAciFont = useAciFont;
const createAciColor = makeOpacityColorHex;

// Define most of the theme here; Define Props and overrides separately so we can use these definitions
const AciCoreTheme = createTheme(adaptV4Theme({
  palette: {
    /*
        Key:{
            main:               required for theme palette. This is the mainBackground
            mainBackground:     custom attribute: mainBackground for Gradient
            ...gradient:        custom attribute: breaks up gradient colors

            activeBackground:   custom attribute: background while clicked
            fontColor:          custom attribute: color of the font
            borderColor:        custom attribute: color of the border

            light:              optional attribute: color used if theme is light
            dark:               optional attribute: color used if theme is dark
        }
        */

    primary: {
      // Base
      main: aciColors.red500,
      mainBackground: aciColors.yellow4ToRed5, // light to main
      light: aciColors.yellow400,
      fontColor: aciColors.white,
      borderColor: 'none',

      // If Active
      activeBackground: aciColors.yellow5ToRed5, // dark to main
      dark: aciColors.orange500,
    },
    secondary: {
      // Base
      main: aciColors.orange50,
      fontColor: aciColors.orange500,
      borderColor: aciColors.orange200,

      // If Active
      activeBackground: aciColors.orange100,
    },
    grey: {
      // Base
      main: aciColors.grey50,
      fontColor: aciColors.grey700,
      borderColor: aciColors.grey200,

      // Font Color alternatives
      light: aciColors.grey600,
      dark: aciColors.grey900,

      // If Active
      activeBackground: aciColors.grey100,
      activeBorderColor: aciColors.grey500,
    },
    tertiary: {
      // Base
      main: aciColors.white,
      fontColor: aciColors.orange500,
      borderColor: aciColors.orange200,

      // If Active
      activeBackground: aciColors.orange50,
    },
    error: {
      // eslint-disable-next-line no-underscore-dangle
      main: createAciColor(aciColors.utilityCritical, aciOpacities._5),
      fontColor: aciColors.utilityCritical,
      borderColor: aciColors.utilityCritical,
    },
    warning: {
      // eslint-disable-next-line no-underscore-dangle
      main: createAciColor(aciColors.utilityWarning, aciOpacities._5),
      fontColor: aciColors.utilityWarning,
      borderColor: aciColors.utilityWarning,
    },
  },

  // shadows: aciShadows,

  shape: {
    borderRadius: aciBorders.default,
  },

  spacing: aciThemeSpacing,

  typography: {
       useAciFont,
       fontFamily: aciFontFamilies,
       fontWeightRegular: aciFontWeights.normal,
       fontWeightMedium: aciFontWeights.semiBold,
       fontWeightBold: aciFontWeights.bold,
     },
}));

// Add the overrides
AciCoreTheme.overrides = {
  ...AciCoreTheme.overrides,
  MuiButton: {
    root: {
      textTransform: 'none',
      minWidth: '0 ',

      // sizeMedium
      padding: '8px 24px', // Was 12px, 24px, but was identical to sizeLarge
      font: createAciFont('5', 'Semi'),

      '&:disabled': {
        '& span ': {
          opacity: '40%',
        },
      },
    },
    outlined: {
      // sizeMedium
      padding: '8px 24px', // Was 12px, 24px, but was identical to sizeLarge
    },
    sizeSmall: {
      font: createAciFont('6', 'Bold'),
      padding: '8px 12px',
    },
    sizeLarge: {
      font: createAciFont('5', 'Semi'),
      padding: '16px 24px',
    },
  },
  MuiCheckbox: {
    // the default "color" prop is secondary; we only have 1 button type so let's go with the default.
    colorSecondary: {
      color: aciColors.grey600,

      '&$checked': {
        // When selected, Outline is grey
        color: aciColors.grey600,

        '& :nth-child(3)': {
          // When selected, check is Orange
          color: AciCoreTheme.palette.secondary.fontColor,
          position: 'absolute',
          fontSize: '14px',
        },
      },
    },
  },
  MuiFormGroup: {
    root: {
      alignItems: 'left',
    },
    row: {
      justifyContent: 'center',
    },
  },
  MuiMenuItem: {
    root: {
      color: aciColors.grey600,
      '&:hover': {
        backgroundColor: aciColors.orange50,
        color: aciColors.grey900,
      },
    },
  },
  // Don't like abusing !important like this, but couldn't get it to work otherwise.
  // The Material-Ui class set up was quite a conundrum I just couldn't fully crack.
  MuiListItem: {
    root: {
      '&:hover': {
        backgroundColor: `${aciColors.orange50}!important`,
      },
    },
    selected: {
      backgroundColor: `${aciColors.white}!important`,
      '&:hover': {
        backgroundColor: `${aciColors.orange50}!important`,
        color: `${aciColors.orange500}!important`,
      },
    },
    disabled: {
      color: `${aciColors.grey200}!important`,
    },
  },
  MuiTab: {
    root: {
      minWidth: '0 !Important',
      padding: 0,
      margin: '6px 12px',
      marginBottom: 0,
    },
    selected: {
      color: AciCoreTheme.palette.secondary.fontColor,
    },
  },
  MuiToggleButton: {
    root: {
      textTransform: 'none',
      font: createAciFont('6', 'Semi'),
      height: '24px',

      color: aciColors.grey800,
    },
  },
  MuiRadio: {
    // the default "color" prop is secondary; we only have 1 radio type so let's go with the default.
    colorSecondary: {
      color: aciColors.grey600,

      '&$checked': {
        // When selected, Outline is grey
        color: aciColors.grey600,

        '& :nth-child(2)': {
          '& :nth-child(2)': {
            // When selected, Dot is Orange
            color: AciCoreTheme.palette.secondary.fontColor,
          },
        },
      },
    },
  },
};

// Add the props
AciCoreTheme.props = {
  ...AciCoreTheme.props,
  MuiButtonBase: {
    disableRipple: true,
    focusRipple: false,
  },
  colorSecondary: {
    color: AciCoreTheme.palette.tertiary.fontColor,
  },
  MuiCheckbox: {
    disableRipple: true,
    focusRipple: false,
  },
};

export default AciCoreTheme;
