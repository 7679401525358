import { LineOrientationType } from 'types/lineOrientationType';
import { CoordinatePoint } from 'types/point';
import { getLineOrientation } from 'helpers/getLineOrientation';
import { getSnappedToGrid } from 'helpers/snap/snapToGrid';
import { UnreachableCaseError } from 'helpers/UnreachableCaseError';

interface SnapLineToGridResult<T extends CoordinatePoint> {
  p1: T;
  p2: T;
}

export const snapLineToGrid = <T extends CoordinatePoint>(
  first: T, second: T, d: CoordinatePoint, snapDivision: number,
): SnapLineToGridResult<T> => {
  const p1 = { ...first };
  const p2 = { ...second };
  let c = {
    x: (p1.x + p2.x) / 2 + d.x,
    y: (p1.y + p2.y) / 2 + d.y,
  };
  c = getSnappedToGrid(c, snapDivision);

  const lineType = getLineOrientation(p1, p2);
  switch (lineType) {
    case LineOrientationType.HORIZONTAL:
      p1.y = c.y;
      p2.y = c.y;
      break;

    case LineOrientationType.VERTICAL:
      p1.x = c.x;
      p2.x = c.x;
      break;

    case LineOrientationType.DIAGONAL_DOWN:
    case LineOrientationType.DIAGONAL_UP:
      p1.x += d.x;
      p1.y += d.y;

      p2.x += d.x;
      p2.y += d.y;
      break;

    default: throw new UnreachableCaseError(lineType);
  }

  return {
    p1,
    p2,
  };
};
