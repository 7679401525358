/** @jsx jsx */
import React, { useState, Fragment } from 'react';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { useDrag, DragSourceMonitor, DragPreviewImage } from 'react-dnd';

import { colors, sidebar } from 'config/paletteConfig';
import { messages } from 'config/messages';
import { Label } from 'types/label';
import { CoordinatePoint, Point } from 'types/point';
import { ItemTypes, LabelDragItem, LabelOrSymbolDropResult } from 'types/dnd';
import { emptyImageSrc } from 'helpers/image';
import { ReactComponent as IconEdit } from 'assets/icons/edit.svg';
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg';
import { ButtonWithFade, withHoverAndFocus, scaleYIn } from 'components/animations';
import { withTooltip, TooltipContent } from 'components/elements/Tooltip';
import {
  sidebarLabelItemStyles,
  labelItemTextStyles,
  sidebarItemSelectedStyles,
  sidebarItemButtonStyles,
  sidebarItemHidingStyles,
  sidebarOrderSortActionStyles,
} from 'components/sidebar/styles';
import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';

export const ListItemStyled = styled.li`
  position: relative;
  display: flex;
  max-width: 100%; /* https://github.com/philipwalton/flexbugs#flexbug-2 */
  align-items: center;
  margin-bottom: 3px;
  background-color: ${sidebar.item.base};

  &:hover,
  &:focus {
    background-color: ${sidebar.item.hovered};
    outline: 0;
  }

  :focus ~ button {
    display: block;
    opacity: 1;
  }
`;

const ActionButtonStyled = styled(ButtonWithFade)(sidebarItemButtonStyles, {
  boxSizing: 'content-box',
  flex: '0 0 auto',
  padding: 4,
  marginLeft: 5,
  color: 'inherit',

  '&:hover, &:focus': {
    backgroundColor: 'transparent',
    color: colors.blue,
  },
});

export const ListItem = withHoverAndFocus(ListItemStyled, scaleYIn);

const trashIconColor = css({ color: colors.red });
const selected = css({ backgroundColor: sidebar.item.hovered });
const ActionButton = withTooltip(ActionButtonStyled);

const labelItemStyles = css`
  background-color: ${colors.none};
  color: inherit;
`;

export const inputStyles = css`
  box-sizing: border-box;
  margin-bottom: 3px;

  &:hover {
    background-color: ${colors.white};
  }

  input {
    width: 100%;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    &::-webkit-input-placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
    &:-ms-input-placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
    &::placeholder {
      color: rgba(26, 36, 79, 0.25);
    }
  }
`;

//  provides styling for metadata attributes
// const MetaDataTooltipStyled = styled(ButtonWithFade)(sidebarItemButtonStyles, {
//   boxSizing: 'content-box',
//   flex: '0 0 auto',
//   padding: 4,
//   marginLeft: 5,
//   color: 'inherit',

//   '&:hover, &:focus': {
//     backgroundColor: 'transparent',
//     color: colors.blue,
//   },
// });

// const MetaDataTooltip = withTooltip(MetaDataTooltipStyled);

interface LabelItemProps {
  readonly textLabel: Label;
  readonly editLabelId?: string;
  readonly isSelected: boolean;
  readonly drawPositionedLabel: (labelId: string, at: Point | CoordinatePoint) => void;
  readonly deleteLabel: (labelId: string) => void;
  readonly makeStartEditing: (labelId: string) => void;
  readonly setStampId: (labelId: string) => void;
  readonly close: () => void;
  readonly show: () => void;
  readonly index?: number;
  readonly moveDown?: any;
  readonly moveUp?: any;
}


export const LabelItem: React.FC<LabelItemProps> = React.memo((props) => {
  const { textLabel, editLabelId, drawPositionedLabel, deleteLabel, makeStartEditing, setStampId, isSelected, index, moveDown, moveUp, close, show } = props;
  const item: LabelDragItem = {
    type: ItemTypes.Label,
    labelId: textLabel.labelId,
    preview: textLabel.text,
  };
  const [isShowing, setIsShowing] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [, drag, preview] = useDrag({
    item,
    // eslint-disable-next-line arrow-body-style
    isDragging: (monitor: DragSourceMonitor) => {
      if (monitor.getItem().labelId === item.labelId) close();
      return monitor.getItem().labelId === item.labelId;
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (draggedItem: LabelDragItem | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult() as LabelOrSymbolDropResult | undefined;
      monitor.getItemType();
      if (draggedItem && dropResult?.position) {
        drawPositionedLabel(draggedItem.labelId, dropResult.position);
        show();
      }
    },
  });

  const handleDeleteLabel = (): void => {
    setIsShowing(false);
    deleteLabel(textLabel.labelId);
  };
  const handleMakeStartEditing = (): void => makeStartEditing(textLabel.labelId);
  const handleClick = (): void => setStampId(textLabel.labelId);

  return (
    <ListItem
      css={[isSelected && sidebarItemSelectedStyles, !isShowing && sidebarItemHidingStyles]}
      //  provides styling for metadata attributes
      // css={[isSelected && sidebarItemSelectedStyles, textLabel.meta && sidebarItemMedatdataStyles]}
      tabIndex={0}
      withParent={false}
      onClick={handleClick}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div css={sidebarOrderSortActionStyles}>
        {isHovering && (
          <Fragment>
            <ArrowUpwardIcon fontSize="small" color="action" onClick={moveUp(index)} />
            <ArrowDownwardIcon fontSize="small" color="action" onClick={moveDown(index)} />
          </Fragment>
        )}
      </div>
      <div css={[sidebarLabelItemStyles, labelItemStyles]}>
        <div ref={drag} css={labelItemTextStyles}>
          {`${textLabel.text}`}
        </div>
        <DragPreviewImage connect={preview} src={emptyImageSrc} />
      </div>
      {!textLabel.isDefault && (
        <React.Fragment>
          <ActionButton
            css={textLabel.labelId === editLabelId && selected}
            onClick={handleMakeStartEditing}
            aria-label={messages.editLabelTitle}
            tooltipPosition="bottom"
            tooltip={<TooltipContent title={messages.editLabelTitle} />}
            initialPose="exit"
          >
            <IconEdit />
          </ActionButton>
          <ActionButton
            css={[trashIconColor, textLabel.labelId === editLabelId && selected]}
            onClick={handleDeleteLabel}
            aria-label={messages.deleteLabelTitle}
            tooltipPosition="bottom"
            tooltip={<TooltipContent title={messages.deleteLabelTitle} description={messages.deleteLabelDesc} />}
            initialPose="exit"
          >
            <IconTrash />
          </ActionButton>
        </React.Fragment>
      )}
    </ListItem>
  );
  //  provides styling for metadata attributes
  //   <MetaDataTooltip tooltip={<MetaDataTooltipContent meta={textLabel.meta} />} tooltipPosition="left" css={metaDataTooltipStyles}>
  //     {LabelListItem}
  //   </MetaDataTooltip>
});
